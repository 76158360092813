import {Routes, Route} from "react-router-dom"
import Home from "./pages/Home";
import CEF from "./pages/CEF";


function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="cef" element={<CEF/>}/>
            </Routes>
        </div>
    );
}

export default App;
