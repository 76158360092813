import React from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster'
import {MapContainer, TileLayer, Marker, Popup, LayersControl} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const customIcon = L.icon({
    iconUrl: 'marker.svg', // Replace with the actual path to your SVG icon
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
});

const getExtraInfo = function(id) {
  return <div>
      <h1 className="font-semibold">This is dynamically generated extra information.</h1>
      <h2>Venda Direta</h2>
      <p>1º Leilão: 29/09/2023</p>
      <a href={"..."}>CEF Link</a> | <a href={"..."}>Matrícula</a> | <a href={"..."}>Edital</a> |
      </div>
}

const OpenMap = ({markers}) => {
    return (
        <MapContainer center={[-13.299170, -50.476609]} zoom={4} style={{width: '100%', height: '600px'}}>
            <LayersControl>
                <LayersControl.BaseLayer name="Street View OpenMap" checked>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={18}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street View Mapbox Light">
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib3JhYmVsbHkiLCJhIjoiY2l1M2cyazd5MGgwMzJ6bnZkNTNvbWI1eiJ9.8yMXHNUexOBOBvs7rtvnlw"
                        attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                        id="mapbox/streets-v11" // Mapbox style ID
                        tileSize={512}
                        zoomOffset={-1}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street View Mapbox Dark">
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib3JhYmVsbHkiLCJhIjoiY2l1M2cyazd5MGgwMzJ6bnZkNTNvbWI1eiJ9.8yMXHNUexOBOBvs7rtvnlw"
                        attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                        id="mapbox/dark-v10" // Mapbox style ID
                        tileSize={512}
                        zoomOffset={-1}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street View CartoDB Positron">
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                        attribution='&copy; CartoDB'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street View Jawg Maps">
                    <TileLayer
                        url="https://tile.jawg.io/jawg-sunny/{z}/{x}/{y}.png?access-token=RN4we09tqYrrYbJyf04iCWsFJkI5kiGKgk70e6ACgtkwEMqK71Dv7V8O4TEgqs9B"
                        attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib">&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>'
                        maxZoom={22}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street View Stadia Light">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                        attribution='© <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        tileSize={512}
                        zoomOffset={-1}
                        maxZoom={20}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street View Stadia Dark">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                        attribution='© <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        tileSize={512}
                        zoomOffset={-1}
                        maxZoom={20}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Transport View Thunderforest">
                    <TileLayer
                        url="https://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=d2995dc9ccb64521a5390a42125bf093"
                        attribution='© <a href="https://www.thunderforest.com/">Thunderforest</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite View Mapbox">
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib3JhYmVsbHkiLCJhIjoiY2l1M2cyazd5MGgwMzJ6bnZkNTNvbWI1eiJ9.8yMXHNUexOBOBvs7rtvnlw"
                        attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                        id="mapbox/satellite-v9"
                        tileSize={512}
                        zoomOffset={-1}
                        maxZoom={20}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite View Esri World Imagery">
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        tileSize={512}
                        zoomOffset={-1}
                        maxZoom={20}
                    />
                </LayersControl.BaseLayer>
            </LayersControl>
            <MarkerClusterGroup>
                {markers.map((marker, index) => (
                    <Marker key={index} position={[marker.latitude, marker.longitude]} icon={customIcon}>
                        <Popup>
                            {getExtraInfo(marker.id)}
                        </Popup>
                    </Marker>
                ))}
            </MarkerClusterGroup>
        </MapContainer>
    );
};

export default OpenMap;