import OpenMap from "../components/OpenMap";
import IconLogo from "../components/IconLogo";

function CEF() {
    const markers = {
  "data": {
    "cef_properties": [
      {
        "id": 3,
        "latitude": -7.623652,
        "longitude": -72.681868
      },
      {
        "id": 4,
        "latitude": -10.298205,
        "longitude": -36.617206
      },
      {
        "id": 5,
        "latitude": -10.298205,
        "longitude": -36.617206
      },
      {
        "id": 20,
        "latitude": -9.969578,
        "longitude": -67.802053
      },
      {
        "id": 21,
        "latitude": -9.969578,
        "longitude": -67.802053
      },
      {
        "id": 30,
        "latitude": -9.508632,
        "longitude": -36.522708
      },
      {
        "id": 34,
        "latitude": -9.728786,
        "longitude": -36.683065
      },
      {
        "id": 35,
        "latitude": -9.753674,
        "longitude": -36.65535
      },
      {
        "id": 36,
        "latitude": -9.895981,
        "longitude": -36.679295
      },
      {
        "id": 37,
        "latitude": -9.515225,
        "longitude": -36.004894
      },
      {
        "id": 38,
        "latitude": -9.406616,
        "longitude": -35.505489
      },
      {
        "id": 39,
        "latitude": -9.955423,
        "longitude": -36.792608
      },
      {
        "id": 41,
        "latitude": -9.881267,
        "longitude": -36.830385
      },
      {
        "id": 42,
        "latitude": -9.881267,
        "longitude": -36.830385
      },
      {
        "id": 53,
        "latitude": -9.881267,
        "longitude": -36.830385
      },
      {
        "id": 56,
        "latitude": -9.881267,
        "longitude": -36.830385
      },
      {
        "id": 91,
        "latitude": -9.747904,
        "longitude": -35.844131
      },
      {
        "id": 59,
        "latitude": -9.596312,
        "longitude": -35.771546
      },
      {
        "id": 61,
        "latitude": -9.647684,
        "longitude": -35.733926
      },
      {
        "id": 62,
        "latitude": -9.594208,
        "longitude": -35.958045
      },
      {
        "id": 64,
        "latitude": -9.56289,
        "longitude": -35.73913
      },
      {
        "id": 65,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 66,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 67,
        "latitude": -9.656702,
        "longitude": -35.708275
      },
      {
        "id": 68,
        "latitude": -9.633872,
        "longitude": -35.703585
      },
      {
        "id": 69,
        "latitude": -9.594293,
        "longitude": -35.760115
      },
      {
        "id": 70,
        "latitude": -9.539533,
        "longitude": -35.727227
      },
      {
        "id": 71,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 72,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 73,
        "latitude": -9.667423,
        "longitude": -35.721398
      },
      {
        "id": 74,
        "latitude": -9.614791,
        "longitude": -35.71829
      },
      {
        "id": 75,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 76,
        "latitude": -9.614791,
        "longitude": -35.71829
      },
      {
        "id": 77,
        "latitude": -9.596312,
        "longitude": -35.771546
      },
      {
        "id": 78,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 79,
        "latitude": -9.566744,
        "longitude": -35.74731
      },
      {
        "id": 80,
        "latitude": -9.617417,
        "longitude": -35.732662
      },
      {
        "id": 81,
        "latitude": -9.550681,
        "longitude": -35.725236
      },
      {
        "id": 82,
        "latitude": -9.586961,
        "longitude": -35.735557
      },
      {
        "id": 83,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 84,
        "latitude": -9.647684,
        "longitude": -35.733926
      },
      {
        "id": 85,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 86,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 88,
        "latitude": -9.753392,
        "longitude": -35.846242
      },
      {
        "id": 89,
        "latitude": -9.737197,
        "longitude": -35.837646
      },
      {
        "id": 90,
        "latitude": -9.753392,
        "longitude": -35.846242
      },
      {
        "id": 96,
        "latitude": -9.155625,
        "longitude": -37.359825
      },
      {
        "id": 97,
        "latitude": -9.418604,
        "longitude": -36.634225
      },
      {
        "id": 98,
        "latitude": -9.415473,
        "longitude": -36.634226
      },
      {
        "id": 101,
        "latitude": -9.599772,
        "longitude": -35.955783
      },
      {
        "id": 102,
        "latitude": -9.595343,
        "longitude": -35.952088
      },
      {
        "id": 103,
        "latitude": -9.599942,
        "longitude": -35.953021
      },
      {
        "id": 104,
        "latitude": -9.586623,
        "longitude": -35.964426
      },
      {
        "id": 105,
        "latitude": -9.608042,
        "longitude": -35.957003
      },
      {
        "id": 106,
        "latitude": -9.595757,
        "longitude": -35.953926
      },
      {
        "id": 107,
        "latitude": -9.602669,
        "longitude": -35.821726
      },
      {
        "id": 108,
        "latitude": -9.586623,
        "longitude": -35.964426
      },
      {
        "id": 114,
        "latitude": -5.233303,
        "longitude": -37.312936
      },
      {
        "id": 115,
        "latitude": -9.600767,
        "longitude": -35.821711
      },
      {
        "id": 117,
        "latitude": -9.569113,
        "longitude": -35.822697
      },
      {
        "id": 118,
        "latitude": -9.570672,
        "longitude": -35.82598
      },
      {
        "id": 119,
        "latitude": -9.473233,
        "longitude": -35.556219
      },
      {
        "id": 120,
        "latitude": -9.570672,
        "longitude": -35.82598
      },
      {
        "id": 121,
        "latitude": -9.570672,
        "longitude": -35.82598
      },
      {
        "id": 122,
        "latitude": -9.576052,
        "longitude": -35.735703
      },
      {
        "id": 123,
        "latitude": -9.552576,
        "longitude": -35.789867
      },
      {
        "id": 124,
        "latitude": -9.539533,
        "longitude": -35.727227
      },
      {
        "id": 125,
        "latitude": -9.570672,
        "longitude": -35.82598
      },
      {
        "id": 127,
        "latitude": -9.899383,
        "longitude": -36.356207
      },
      {
        "id": 128,
        "latitude": -9.760359,
        "longitude": -36.079481
      },
      {
        "id": 129,
        "latitude": -9.906274,
        "longitude": -36.357134
      },
      {
        "id": 134,
        "latitude": 0.067195,
        "longitude": -51.049889
      },
      {
        "id": 135,
        "latitude": -4.40056,
        "longitude": -59.89194
      },
      {
        "id": 138,
        "latitude": -4.393478,
        "longitude": -59.594772
      },
      {
        "id": 140,
        "latitude": -4.10992,
        "longitude": -63.143162
      },
      {
        "id": 144,
        "latitude": -3.103248,
        "longitude": -60.026587
      },
      {
        "id": 145,
        "latitude": -2.990883,
        "longitude": -60.030052
      },
      {
        "id": 146,
        "latitude": -3.131633,
        "longitude": -59.982504
      },
      {
        "id": 147,
        "latitude": -3.069309,
        "longitude": -60.035285
      },
      {
        "id": 148,
        "latitude": -3.130238,
        "longitude": -60.011096
      },
      {
        "id": 149,
        "latitude": -3.009214,
        "longitude": -60.056208
      },
      {
        "id": 150,
        "latitude": -3.131633,
        "longitude": -59.982504
      },
      {
        "id": 152,
        "latitude": -8.773553,
        "longitude": -67.327749
      },
      {
        "id": 153,
        "latitude": -3.031357,
        "longitude": -60.067437
      },
      {
        "id": 154,
        "latitude": -3.031357,
        "longitude": -60.067437
      },
      {
        "id": 155,
        "latitude": -3.131633,
        "longitude": -59.982504
      },
      {
        "id": 157,
        "latitude": -3.011942,
        "longitude": -59.979328
      },
      {
        "id": 158,
        "latitude": -3.009214,
        "longitude": -60.056208
      },
      {
        "id": 159,
        "latitude": -3.096072,
        "longitude": -60.015662
      },
      {
        "id": 160,
        "latitude": -3.028179,
        "longitude": -59.964257
      },
      {
        "id": 161,
        "latitude": -3.048707,
        "longitude": -59.989305
      },
      {
        "id": 162,
        "latitude": -8.773553,
        "longitude": -67.327749
      },
      {
        "id": 163,
        "latitude": -3.009214,
        "longitude": -60.056208
      },
      {
        "id": 272,
        "latitude": -15.658388,
        "longitude": -40.066095
      },
      {
        "id": 164,
        "latitude": -2.976722,
        "longitude": -60.027875
      },
      {
        "id": 165,
        "latitude": -3.127953,
        "longitude": -60.02705
      },
      {
        "id": 166,
        "latitude": -16.02758,
        "longitude": -50.65051
      },
      {
        "id": 167,
        "latitude": -3.106404,
        "longitude": -60.061577
      },
      {
        "id": 168,
        "latitude": -3.007604,
        "longitude": -60.034943
      },
      {
        "id": 169,
        "latitude": -3.11529,
        "longitude": -60.0318
      },
      {
        "id": 170,
        "latitude": -16.02758,
        "longitude": -50.65051
      },
      {
        "id": 171,
        "latitude": -2.990883,
        "longitude": -60.030052
      },
      {
        "id": 172,
        "latitude": -3.086006,
        "longitude": -60.032012
      },
      {
        "id": 173,
        "latitude": -2.990883,
        "longitude": -60.030052
      },
      {
        "id": 174,
        "latitude": -3.131633,
        "longitude": -59.982504
      },
      {
        "id": 175,
        "latitude": -3.08382,
        "longitude": -60.039722
      },
      {
        "id": 176,
        "latitude": -3.131633,
        "longitude": -59.982504
      },
      {
        "id": 179,
        "latitude": -2.687784,
        "longitude": -59.696828
      },
      {
        "id": 180,
        "latitude": -2.687784,
        "longitude": -59.696828
      },
      {
        "id": 181,
        "latitude": -2.687784,
        "longitude": -59.696828
      },
      {
        "id": 184,
        "latitude": -3.348528,
        "longitude": -64.710228
      },
      {
        "id": 185,
        "latitude": -3.348528,
        "longitude": -64.710228
      },
      {
        "id": 186,
        "latitude": -12.146191,
        "longitude": -38.398591
      },
      {
        "id": 187,
        "latitude": -12.121076,
        "longitude": -38.433639
      },
      {
        "id": 190,
        "latitude": -12.139081,
        "longitude": -45.015094
      },
      {
        "id": 191,
        "latitude": -12.130586,
        "longitude": -44.986834
      },
      {
        "id": 192,
        "latitude": -13.250438,
        "longitude": -43.392699
      },
      {
        "id": 197,
        "latitude": -12.699819,
        "longitude": -38.326076
      },
      {
        "id": 199,
        "latitude": -12.825021,
        "longitude": -38.223425
      },
      {
        "id": 200,
        "latitude": -12.599478,
        "longitude": -38.042415
      },
      {
        "id": 201,
        "latitude": -12.699819,
        "longitude": -38.326076
      },
      {
        "id": 202,
        "latitude": -12.86568,
        "longitude": -38.28828
      },
      {
        "id": 205,
        "latitude": -12.327422,
        "longitude": -38.772118
      },
      {
        "id": 208,
        "latitude": -12.367829,
        "longitude": -38.816437
      },
      {
        "id": 210,
        "latitude": -13.650209,
        "longitude": -41.842632
      },
      {
        "id": 220,
        "latitude": -11.793859,
        "longitude": -37.938616
      },
      {
        "id": 221,
        "latitude": -11.79401,
        "longitude": -37.947044
      },
      {
        "id": 223,
        "latitude": -10.506084,
        "longitude": -39.012988
      },
      {
        "id": 224,
        "latitude": -10.50344,
        "longitude": -39.31587
      },
      {
        "id": 225,
        "latitude": -16.368586,
        "longitude": -39.57719
      },
      {
        "id": 228,
        "latitude": -12.23025,
        "longitude": -38.991554
      },
      {
        "id": 229,
        "latitude": -12.22041,
        "longitude": -38.944128
      },
      {
        "id": 231,
        "latitude": -12.254579,
        "longitude": -38.95322
      },
      {
        "id": 232,
        "latitude": -12.249094,
        "longitude": -38.977149
      },
      {
        "id": 233,
        "latitude": -12.248321,
        "longitude": -38.95461
      },
      {
        "id": 234,
        "latitude": -12.246199,
        "longitude": -38.91494
      },
      {
        "id": 235,
        "latitude": -12.248321,
        "longitude": -38.95461
      },
      {
        "id": 236,
        "latitude": -12.2869,
        "longitude": -38.949877
      },
      {
        "id": 237,
        "latitude": -12.248321,
        "longitude": -38.95461
      },
      {
        "id": 239,
        "latitude": -14.989554,
        "longitude": -39.924153
      },
      {
        "id": 240,
        "latitude": -12.77521,
        "longitude": -40.220546
      },
      {
        "id": 246,
        "latitude": -14.266443,
        "longitude": -42.257307
      },
      {
        "id": 247,
        "latitude": -14.067095,
        "longitude": -39.640098
      },
      {
        "id": 251,
        "latitude": -11.316841,
        "longitude": -41.852444
      },
      {
        "id": 252,
        "latitude": -11.300929,
        "longitude": -41.853871
      },
      {
        "id": 254,
        "latitude": -12.529813,
        "longitude": -40.301384
      },
      {
        "id": 255,
        "latitude": -12.529813,
        "longitude": -40.301384
      },
      {
        "id": 256,
        "latitude": -12.529813,
        "longitude": -40.301384
      },
      {
        "id": 257,
        "latitude": -12.529813,
        "longitude": -40.301384
      },
      {
        "id": 258,
        "latitude": -12.177622,
        "longitude": -42.90589
      },
      {
        "id": 261,
        "latitude": -14.775817,
        "longitude": -39.261012
      },
      {
        "id": 263,
        "latitude": -14.802492,
        "longitude": -39.281413
      },
      {
        "id": 265,
        "latitude": -15.24994,
        "longitude": -40.241608
      },
      {
        "id": 268,
        "latitude": -15.245448,
        "longitude": -40.246005
      },
      {
        "id": 269,
        "latitude": -15.24994,
        "longitude": -40.241608
      },
      {
        "id": 271,
        "latitude": -15.652092,
        "longitude": -40.059907
      },
      {
        "id": 273,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 274,
        "latitude": -15.651663,
        "longitude": -40.066195
      },
      {
        "id": 275,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 276,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 277,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 278,
        "latitude": -15.651663,
        "longitude": -40.066195
      },
      {
        "id": 279,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 280,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 281,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 282,
        "latitude": -15.592057,
        "longitude": -39.874713
      },
      {
        "id": 283,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 284,
        "latitude": -22.993504,
        "longitude": -45.541931
      },
      {
        "id": 286,
        "latitude": -11.342774,
        "longitude": -41.65817
      },
      {
        "id": 287,
        "latitude": -9.449836,
        "longitude": -40.497
      },
      {
        "id": 288,
        "latitude": -9.449836,
        "longitude": -40.497
      },
      {
        "id": 289,
        "latitude": -13.932872,
        "longitude": -41.903059
      },
      {
        "id": 291,
        "latitude": -15.526937,
        "longitude": -40.37109
      },
      {
        "id": 293,
        "latitude": -15.526937,
        "longitude": -40.37109
      },
      {
        "id": 294,
        "latitude": -15.621518,
        "longitude": -40.265054
      },
      {
        "id": 295,
        "latitude": -15.617308,
        "longitude": -40.263621
      },
      {
        "id": 296,
        "latitude": -15.621587,
        "longitude": -40.265719
      },
      {
        "id": 298,
        "latitude": -14.265081,
        "longitude": -43.158705
      },
      {
        "id": 299,
        "latitude": -9.383916,
        "longitude": -38.232435
      },
      {
        "id": 301,
        "latitude": -9.395318,
        "longitude": -38.241331
      },
      {
        "id": 302,
        "latitude": -9.376947,
        "longitude": -38.241896
      },
      {
        "id": 303,
        "latitude": -9.404532,
        "longitude": -38.227359
      },
      {
        "id": 304,
        "latitude": -9.584492,
        "longitude": -37.99905
      },
      {
        "id": 306,
        "latitude": -16.403278,
        "longitude": -39.092436
      },
      {
        "id": 314,
        "latitude": -12.705211,
        "longitude": -38.32431
      },
      {
        "id": 315,
        "latitude": -12.846985,
        "longitude": -38.355929
      },
      {
        "id": 316,
        "latitude": -13.01989,
        "longitude": -39.155027
      },
      {
        "id": 319,
        "latitude": -12.932131,
        "longitude": -38.41922
      },
      {
        "id": 320,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 321,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 323,
        "latitude": -12.936964,
        "longitude": -38.428321
      },
      {
        "id": 324,
        "latitude": -12.93075,
        "longitude": -38.416562
      },
      {
        "id": 325,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 326,
        "latitude": -12.913368,
        "longitude": -38.468826
      },
      {
        "id": 327,
        "latitude": -12.913368,
        "longitude": -38.468826
      },
      {
        "id": 328,
        "latitude": -12.887568,
        "longitude": -38.472447
      },
      {
        "id": 329,
        "latitude": -12.846985,
        "longitude": -38.355929
      },
      {
        "id": 330,
        "latitude": -12.910915,
        "longitude": -38.468852
      },
      {
        "id": 331,
        "latitude": -12.95543,
        "longitude": -38.440695
      },
      {
        "id": 332,
        "latitude": -12.846985,
        "longitude": -38.355929
      },
      {
        "id": 333,
        "latitude": -12.846985,
        "longitude": -38.355929
      },
      {
        "id": 335,
        "latitude": -12.941605,
        "longitude": -38.424053
      },
      {
        "id": 336,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 337,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 339,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 340,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 341,
        "latitude": -12.883622,
        "longitude": -38.475181
      },
      {
        "id": 342,
        "latitude": -9.477658,
        "longitude": -40.813369
      },
      {
        "id": 343,
        "latitude": -12.887,
        "longitude": -38.363063
      },
      {
        "id": 344,
        "latitude": -12.887,
        "longitude": -38.363063
      },
      {
        "id": 345,
        "latitude": -12.936833,
        "longitude": -38.379315
      },
      {
        "id": 346,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 347,
        "latitude": -12.887,
        "longitude": -38.363063
      },
      {
        "id": 348,
        "latitude": -12.887,
        "longitude": -38.363063
      },
      {
        "id": 349,
        "latitude": -12.919091,
        "longitude": -38.411538
      },
      {
        "id": 350,
        "latitude": -12.883622,
        "longitude": -38.475181
      },
      {
        "id": 352,
        "latitude": -12.846985,
        "longitude": -38.355929
      },
      {
        "id": 353,
        "latitude": -12.919091,
        "longitude": -38.411538
      },
      {
        "id": 354,
        "latitude": -12.887568,
        "longitude": -38.472447
      },
      {
        "id": 355,
        "latitude": -12.920792,
        "longitude": -38.456665
      },
      {
        "id": 356,
        "latitude": -9.476534,
        "longitude": -40.812781
      },
      {
        "id": 357,
        "latitude": -12.867507,
        "longitude": -38.471722
      },
      {
        "id": 358,
        "latitude": -12.846985,
        "longitude": -38.355929
      },
      {
        "id": 359,
        "latitude": -3.297483,
        "longitude": -52.538294
      },
      {
        "id": 360,
        "latitude": -12.98225,
        "longitude": -38.481277
      },
      {
        "id": 361,
        "latitude": -12.913368,
        "longitude": -38.468826
      },
      {
        "id": 362,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 363,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 364,
        "latitude": -12.95396,
        "longitude": -38.351247
      },
      {
        "id": 365,
        "latitude": -12.743705,
        "longitude": -38.869858
      },
      {
        "id": 367,
        "latitude": -12.431883,
        "longitude": -38.952262
      },
      {
        "id": 368,
        "latitude": -10.459719,
        "longitude": -40.183953
      },
      {
        "id": 369,
        "latitude": -10.459719,
        "longitude": -40.183953
      },
      {
        "id": 370,
        "latitude": -10.460251,
        "longitude": -40.189151
      },
      {
        "id": 371,
        "latitude": -10.459719,
        "longitude": -40.183953
      },
      {
        "id": 372,
        "latitude": -11.66199,
        "longitude": -39.003402
      },
      {
        "id": 373,
        "latitude": -11.66199,
        "longitude": -39.003402
      },
      {
        "id": 374,
        "latitude": -11.670186,
        "longitude": -39.006441
      },
      {
        "id": 375,
        "latitude": -17.541996,
        "longitude": -39.732944
      },
      {
        "id": 376,
        "latitude": -11.032542,
        "longitude": -38.790405
      },
      {
        "id": 381,
        "latitude": -14.856749,
        "longitude": -40.84148
      },
      {
        "id": 382,
        "latitude": -14.873991,
        "longitude": -40.842653
      },
      {
        "id": 383,
        "latitude": -14.880181,
        "longitude": -40.837074
      },
      {
        "id": 387,
        "latitude": -3.906312,
        "longitude": -38.387504
      },
      {
        "id": 388,
        "latitude": -3.83187,
        "longitude": -38.411921
      },
      {
        "id": 389,
        "latitude": -3.906312,
        "longitude": -38.387504
      },
      {
        "id": 390,
        "latitude": -3.907198,
        "longitude": -38.373718
      },
      {
        "id": 391,
        "latitude": -3.847603,
        "longitude": -38.390817
      },
      {
        "id": 393,
        "latitude": -7.489647,
        "longitude": -38.986954
      },
      {
        "id": 394,
        "latitude": -4.158568,
        "longitude": -40.941905
      },
      {
        "id": 403,
        "latitude": -3.688862,
        "longitude": -38.669052
      },
      {
        "id": 405,
        "latitude": -3.765463,
        "longitude": -38.631871
      },
      {
        "id": 406,
        "latitude": -3.761087,
        "longitude": -38.633623
      },
      {
        "id": 407,
        "latitude": -19.956629,
        "longitude": -44.043563
      },
      {
        "id": 408,
        "latitude": -3.757871,
        "longitude": -38.634192
      },
      {
        "id": 409,
        "latitude": -3.725214,
        "longitude": -38.663167
      },
      {
        "id": 410,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 414,
        "latitude": -3.796543,
        "longitude": -38.518116
      },
      {
        "id": 415,
        "latitude": -3.76212,
        "longitude": -38.632353
      },
      {
        "id": 416,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 417,
        "latitude": -3.851043,
        "longitude": -38.609459
      },
      {
        "id": 418,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 419,
        "latitude": -3.765463,
        "longitude": -38.631871
      },
      {
        "id": 420,
        "latitude": -3.761082,
        "longitude": -38.632826
      },
      {
        "id": 421,
        "latitude": -3.859348,
        "longitude": -38.643215
      },
      {
        "id": 422,
        "latitude": -3.716167,
        "longitude": -38.667422
      },
      {
        "id": 423,
        "latitude": -3.759415,
        "longitude": -38.626287
      },
      {
        "id": 424,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 425,
        "latitude": -3.756132,
        "longitude": -38.638662
      },
      {
        "id": 426,
        "latitude": -3.716167,
        "longitude": -38.667422
      },
      {
        "id": 427,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 428,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 429,
        "latitude": -3.716167,
        "longitude": -38.667422
      },
      {
        "id": 430,
        "latitude": -3.796543,
        "longitude": -38.518116
      },
      {
        "id": 435,
        "latitude": -3.911453,
        "longitude": -38.457311
      },
      {
        "id": 436,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 437,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 438,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 439,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 441,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 442,
        "latitude": -3.725945,
        "longitude": -38.552578
      },
      {
        "id": 443,
        "latitude": -3.81682,
        "longitude": -38.571167
      },
      {
        "id": 444,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 445,
        "latitude": -3.897016,
        "longitude": -38.445278
      },
      {
        "id": 446,
        "latitude": -3.758756,
        "longitude": -38.572677
      },
      {
        "id": 447,
        "latitude": -3.829177,
        "longitude": -38.506878
      },
      {
        "id": 457,
        "latitude": -3.795999,
        "longitude": -38.482729
      },
      {
        "id": 460,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 461,
        "latitude": -3.829177,
        "longitude": -38.506878
      },
      {
        "id": 462,
        "latitude": -3.751533,
        "longitude": -38.549948
      },
      {
        "id": 463,
        "latitude": -3.825213,
        "longitude": -38.577763
      },
      {
        "id": 465,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 466,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 467,
        "latitude": -3.801091,
        "longitude": -38.489294
      },
      {
        "id": 468,
        "latitude": -3.788059,
        "longitude": -38.495262
      },
      {
        "id": 469,
        "latitude": -3.801091,
        "longitude": -38.489294
      },
      {
        "id": 470,
        "latitude": -3.74713,
        "longitude": -38.477841
      },
      {
        "id": 471,
        "latitude": -3.729071,
        "longitude": -38.481248
      },
      {
        "id": 472,
        "latitude": -3.741849,
        "longitude": -38.478322
      },
      {
        "id": 473,
        "latitude": -3.777283,
        "longitude": -38.547017
      },
      {
        "id": 475,
        "latitude": -3.823315,
        "longitude": -38.484059
      },
      {
        "id": 476,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 478,
        "latitude": -3.814217,
        "longitude": -38.524759
      },
      {
        "id": 479,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 480,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 481,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 482,
        "latitude": -3.73397,
        "longitude": -38.49171
      },
      {
        "id": 483,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 484,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 485,
        "latitude": -3.786534,
        "longitude": -38.46877
      },
      {
        "id": 486,
        "latitude": -3.723811,
        "longitude": -38.513813
      },
      {
        "id": 487,
        "latitude": -3.784309,
        "longitude": -38.457546
      },
      {
        "id": 488,
        "latitude": -3.727261,
        "longitude": -38.497632
      },
      {
        "id": 489,
        "latitude": -3.825565,
        "longitude": -38.520947
      },
      {
        "id": 490,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 491,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 492,
        "latitude": -3.81855,
        "longitude": -38.562159
      },
      {
        "id": 493,
        "latitude": -3.768041,
        "longitude": -38.494082
      },
      {
        "id": 494,
        "latitude": -3.789547,
        "longitude": -38.60699
      },
      {
        "id": 495,
        "latitude": -3.79632,
        "longitude": -38.495307
      },
      {
        "id": 496,
        "latitude": -3.740046,
        "longitude": -38.506035
      },
      {
        "id": 497,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 498,
        "latitude": -3.729197,
        "longitude": -38.572116
      },
      {
        "id": 499,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 500,
        "latitude": -3.755897,
        "longitude": -38.521931
      },
      {
        "id": 501,
        "latitude": -3.73547,
        "longitude": -38.501879
      },
      {
        "id": 502,
        "latitude": -3.805979,
        "longitude": -38.541557
      },
      {
        "id": 503,
        "latitude": -3.806673,
        "longitude": -38.5858
      },
      {
        "id": 504,
        "latitude": -3.735584,
        "longitude": -38.469713
      },
      {
        "id": 505,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 506,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 507,
        "latitude": -3.817626,
        "longitude": -38.540865
      },
      {
        "id": 508,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 509,
        "latitude": -3.738132,
        "longitude": -38.462276
      },
      {
        "id": 510,
        "latitude": -3.79868,
        "longitude": -38.509347
      },
      {
        "id": 511,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 512,
        "latitude": -3.820202,
        "longitude": -38.449645
      },
      {
        "id": 513,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 514,
        "latitude": -3.806673,
        "longitude": -38.5858
      },
      {
        "id": 515,
        "latitude": -3.830296,
        "longitude": -38.566334
      },
      {
        "id": 516,
        "latitude": -3.830296,
        "longitude": -38.566334
      },
      {
        "id": 517,
        "latitude": -3.830296,
        "longitude": -38.566334
      },
      {
        "id": 518,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 519,
        "latitude": -3.790022,
        "longitude": -38.592121
      },
      {
        "id": 520,
        "latitude": -3.774593,
        "longitude": -38.484999
      },
      {
        "id": 521,
        "latitude": -3.835663,
        "longitude": -38.591839
      },
      {
        "id": 522,
        "latitude": -3.820738,
        "longitude": -38.473434
      },
      {
        "id": 523,
        "latitude": -3.95,
        "longitude": -38.71667
      },
      {
        "id": 524,
        "latitude": -3.809736,
        "longitude": -38.521447
      },
      {
        "id": 526,
        "latitude": -3.748029,
        "longitude": -38.585342
      },
      {
        "id": 527,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 528,
        "latitude": -3.705165,
        "longitude": -38.585446
      },
      {
        "id": 529,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 530,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 531,
        "latitude": -3.771154,
        "longitude": -38.479151
      },
      {
        "id": 532,
        "latitude": -3.742687,
        "longitude": -38.486529
      },
      {
        "id": 533,
        "latitude": -3.81855,
        "longitude": -38.562159
      },
      {
        "id": 534,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 535,
        "latitude": -3.861345,
        "longitude": -38.491763
      },
      {
        "id": 536,
        "latitude": -3.705699,
        "longitude": -38.586066
      },
      {
        "id": 537,
        "latitude": -3.702784,
        "longitude": -38.579253
      },
      {
        "id": 538,
        "latitude": -3.746943,
        "longitude": -38.473195
      },
      {
        "id": 539,
        "latitude": -3.742263,
        "longitude": -38.567224
      },
      {
        "id": 540,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 541,
        "latitude": -3.804541,
        "longitude": -38.522374
      },
      {
        "id": 542,
        "latitude": -3.780736,
        "longitude": -38.607097
      },
      {
        "id": 543,
        "latitude": -3.730451,
        "longitude": -38.521799
      },
      {
        "id": 544,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 545,
        "latitude": -3.71184,
        "longitude": -38.57148
      },
      {
        "id": 546,
        "latitude": -3.843848,
        "longitude": -38.474129
      },
      {
        "id": 547,
        "latitude": -3.810118,
        "longitude": -38.559794
      },
      {
        "id": 548,
        "latitude": -7.23133,
        "longitude": -39.350589
      },
      {
        "id": 549,
        "latitude": -3.806105,
        "longitude": -38.535967
      },
      {
        "id": 550,
        "latitude": -3.798845,
        "longitude": -38.510716
      },
      {
        "id": 551,
        "latitude": -3.837101,
        "longitude": -38.486798
      },
      {
        "id": 552,
        "latitude": -3.795569,
        "longitude": -38.55056
      },
      {
        "id": 553,
        "latitude": -3.813507,
        "longitude": -38.497546
      },
      {
        "id": 554,
        "latitude": -3.785395,
        "longitude": -38.549854
      },
      {
        "id": 555,
        "latitude": -3.804889,
        "longitude": -38.561411
      },
      {
        "id": 556,
        "latitude": -3.840988,
        "longitude": -38.516344
      },
      {
        "id": 557,
        "latitude": -3.789136,
        "longitude": -38.461731
      },
      {
        "id": 558,
        "latitude": -3.698131,
        "longitude": -38.583889
      },
      {
        "id": 559,
        "latitude": -3.822148,
        "longitude": -38.467887
      },
      {
        "id": 560,
        "latitude": -3.740882,
        "longitude": -38.486814
      },
      {
        "id": 561,
        "latitude": -3.810435,
        "longitude": -38.474421
      },
      {
        "id": 568,
        "latitude": -4.0932,
        "longitude": -38.492474
      },
      {
        "id": 618,
        "latitude": -4.0932,
        "longitude": -38.492474
      },
      {
        "id": 619,
        "latitude": -4.115298,
        "longitude": -38.485959
      },
      {
        "id": 620,
        "latitude": -4.0932,
        "longitude": -38.492474
      },
      {
        "id": 621,
        "latitude": -4.115209,
        "longitude": -38.483167
      },
      {
        "id": 622,
        "latitude": -4.096462,
        "longitude": -38.496557
      },
      {
        "id": 623,
        "latitude": -4.084837,
        "longitude": -38.728593
      },
      {
        "id": 624,
        "latitude": -4.0932,
        "longitude": -38.492474
      },
      {
        "id": 625,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 633,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 634,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 635,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 636,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 637,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 638,
        "latitude": -6.368648,
        "longitude": -39.293482
      },
      {
        "id": 640,
        "latitude": -4.325149,
        "longitude": -40.708058
      },
      {
        "id": 644,
        "latitude": -7.229527,
        "longitude": -39.293273
      },
      {
        "id": 647,
        "latitude": -3.877944,
        "longitude": -38.62477
      },
      {
        "id": 650,
        "latitude": -3.866243,
        "longitude": -38.577618
      },
      {
        "id": 651,
        "latitude": -3.854463,
        "longitude": -38.620381
      },
      {
        "id": 652,
        "latitude": -3.95,
        "longitude": -38.71667
      },
      {
        "id": 653,
        "latitude": -3.838841,
        "longitude": -38.603083
      },
      {
        "id": 654,
        "latitude": -3.95,
        "longitude": -38.71667
      },
      {
        "id": 655,
        "latitude": -3.877944,
        "longitude": -38.62477
      },
      {
        "id": 656,
        "latitude": -3.854463,
        "longitude": -38.620381
      },
      {
        "id": 657,
        "latitude": -3.854463,
        "longitude": -38.620381
      },
      {
        "id": 658,
        "latitude": -3.879297,
        "longitude": -38.466926
      },
      {
        "id": 663,
        "latitude": -7.250569,
        "longitude": -39.14206
      },
      {
        "id": 664,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 687,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 696,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 701,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 702,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 703,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 705,
        "latitude": -4.171073,
        "longitude": -38.464988
      },
      {
        "id": 706,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 707,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 708,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 709,
        "latitude": -4.176154,
        "longitude": -38.480325
      },
      {
        "id": 710,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 711,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 712,
        "latitude": -4.171073,
        "longitude": -38.464988
      },
      {
        "id": 713,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 714,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 715,
        "latitude": -4.171073,
        "longitude": -38.464988
      },
      {
        "id": 720,
        "latitude": -4.097884,
        "longitude": -39.054501
      },
      {
        "id": 722,
        "latitude": -5.463266,
        "longitude": -39.715209
      },
      {
        "id": 723,
        "latitude": -5.463266,
        "longitude": -39.715209
      },
      {
        "id": 724,
        "latitude": -5.463266,
        "longitude": -39.715209
      },
      {
        "id": 755,
        "latitude": -7.827506,
        "longitude": -39.075746
      },
      {
        "id": 801,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 802,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 803,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 804,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 805,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 806,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 807,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 808,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 809,
        "latitude": -7.830772,
        "longitude": -38.768154
      },
      {
        "id": 810,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 812,
        "latitude": -6.090996,
        "longitude": -39.453562
      },
      {
        "id": 814,
        "latitude": -4.040236,
        "longitude": -40.870366
      },
      {
        "id": 815,
        "latitude": -4.047989,
        "longitude": -40.864301
      },
      {
        "id": 818,
        "latitude": -10.906605,
        "longitude": -37.132813
      },
      {
        "id": 822,
        "latitude": -10.923227,
        "longitude": -38.056655
      },
      {
        "id": 823,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 3364,
        "latitude": -20.079459,
        "longitude": -44.588078
      },
      {
        "id": 826,
        "latitude": -17.400272,
        "longitude": -46.110888
      },
      {
        "id": 827,
        "latitude": -15.819863,
        "longitude": -48.114624
      },
      {
        "id": 828,
        "latitude": -16.011348,
        "longitude": -48.057913
      },
      {
        "id": 829,
        "latitude": -15.813846,
        "longitude": -48.105497
      },
      {
        "id": 830,
        "latitude": -3.595105,
        "longitude": -52.712628
      },
      {
        "id": 831,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 832,
        "latitude": -15.92204,
        "longitude": -48.10484
      },
      {
        "id": 833,
        "latitude": -15.800967,
        "longitude": -48.108662
      },
      {
        "id": 834,
        "latitude": -15.820163,
        "longitude": -47.919733
      },
      {
        "id": 836,
        "latitude": -16.044143,
        "longitude": -48.031366
      },
      {
        "id": 837,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 838,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 839,
        "latitude": -15.739718,
        "longitude": -47.90083
      },
      {
        "id": 842,
        "latitude": -15.908407,
        "longitude": -48.061819
      },
      {
        "id": 843,
        "latitude": -15.606399,
        "longitude": -47.658156
      },
      {
        "id": 845,
        "latitude": -27.842943,
        "longitude": -53.153095
      },
      {
        "id": 846,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 847,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 848,
        "latitude": -15.859446,
        "longitude": -48.027359
      },
      {
        "id": 855,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 856,
        "latitude": -15.758593,
        "longitude": -47.842056
      },
      {
        "id": 857,
        "latitude": -15.80406,
        "longitude": -48.06283
      },
      {
        "id": 858,
        "latitude": -15.806774,
        "longitude": -47.898908
      },
      {
        "id": 859,
        "latitude": -15.868859,
        "longitude": -47.966285
      },
      {
        "id": 860,
        "latitude": -15.865584,
        "longitude": -48.073058
      },
      {
        "id": 1215,
        "latitude": -14.317151,
        "longitude": -49.157044
      },
      {
        "id": 861,
        "latitude": -15.81667,
        "longitude": -48.11667
      },
      {
        "id": 862,
        "latitude": -16.000501,
        "longitude": -48.060567
      },
      {
        "id": 863,
        "latitude": -15.811945,
        "longitude": -48.081114
      },
      {
        "id": 864,
        "latitude": -15.838522,
        "longitude": -48.033307
      },
      {
        "id": 865,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 867,
        "latitude": -15.763961,
        "longitude": -47.938334
      },
      {
        "id": 868,
        "latitude": -15.685974,
        "longitude": -47.830987
      },
      {
        "id": 869,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 870,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 871,
        "latitude": -15.814666,
        "longitude": -47.903021
      },
      {
        "id": 872,
        "latitude": -15.893951,
        "longitude": -47.776256
      },
      {
        "id": 873,
        "latitude": -15.862005,
        "longitude": -48.039664
      },
      {
        "id": 875,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 876,
        "latitude": -15.803598,
        "longitude": -47.903099
      },
      {
        "id": 877,
        "latitude": -15.794068,
        "longitude": -48.015444
      },
      {
        "id": 878,
        "latitude": -15.807375,
        "longitude": -47.958445
      },
      {
        "id": 879,
        "latitude": -15.802859,
        "longitude": -47.892687
      },
      {
        "id": 880,
        "latitude": -15.897085,
        "longitude": -47.771784
      },
      {
        "id": 881,
        "latitude": -16.030652,
        "longitude": -50.706971
      },
      {
        "id": 882,
        "latitude": -15.811945,
        "longitude": -48.081114
      },
      {
        "id": 883,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 884,
        "latitude": -27.876476,
        "longitude": -54.487916
      },
      {
        "id": 885,
        "latitude": -15.88721,
        "longitude": -48.15084
      },
      {
        "id": 886,
        "latitude": -15.638606,
        "longitude": -47.823581
      },
      {
        "id": 887,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 889,
        "latitude": 0.00328,
        "longitude": -51.087232
      },
      {
        "id": 890,
        "latitude": -19.940128,
        "longitude": -44.0376
      },
      {
        "id": 891,
        "latitude": -15.807846,
        "longitude": -47.897143
      },
      {
        "id": 892,
        "latitude": -26.278866,
        "longitude": -49.383243
      },
      {
        "id": 910,
        "latitude": -18.540562,
        "longitude": -40.294019
      },
      {
        "id": 893,
        "latitude": -15.749287,
        "longitude": -47.909202
      },
      {
        "id": 894,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 896,
        "latitude": -15.817339,
        "longitude": -48.104577
      },
      {
        "id": 897,
        "latitude": -15.83333,
        "longitude": -47.9
      },
      {
        "id": 898,
        "latitude": -15.618195,
        "longitude": -47.65557
      },
      {
        "id": 899,
        "latitude": -7.137922,
        "longitude": -39.962686
      },
      {
        "id": 900,
        "latitude": -15.802878,
        "longitude": -47.893208
      },
      {
        "id": 901,
        "latitude": -10.924003,
        "longitude": -38.055406
      },
      {
        "id": 902,
        "latitude": -10.953173,
        "longitude": -38.112685
      },
      {
        "id": 903,
        "latitude": -15.876999,
        "longitude": -48.0881
      },
      {
        "id": 905,
        "latitude": -16.037705,
        "longitude": -48.023548
      },
      {
        "id": 906,
        "latitude": -20.7663,
        "longitude": -41.593106
      },
      {
        "id": 907,
        "latitude": -28.306695,
        "longitude": -54.275061
      },
      {
        "id": 908,
        "latitude": -20.776658,
        "longitude": -40.584429
      },
      {
        "id": 913,
        "latitude": -20.848084,
        "longitude": -41.11129
      },
      {
        "id": 914,
        "latitude": -20.845771,
        "longitude": -41.153503
      },
      {
        "id": 915,
        "latitude": -20.285584,
        "longitude": -40.392637
      },
      {
        "id": 916,
        "latitude": -20.285584,
        "longitude": -40.392637
      },
      {
        "id": 918,
        "latitude": -19.526358,
        "longitude": -40.642015
      },
      {
        "id": 919,
        "latitude": -20.778437,
        "longitude": -41.674613
      },
      {
        "id": 920,
        "latitude": -21.019017,
        "longitude": -40.841339
      },
      {
        "id": 922,
        "latitude": -18.724713,
        "longitude": -39.847915
      },
      {
        "id": 923,
        "latitude": -18.724713,
        "longitude": -39.847915
      },
      {
        "id": 924,
        "latitude": -20.129019,
        "longitude": -40.192393
      },
      {
        "id": 925,
        "latitude": -20.126892,
        "longitude": -40.300815
      },
      {
        "id": 929,
        "latitude": -20.128008,
        "longitude": -40.273738
      },
      {
        "id": 930,
        "latitude": -20.183713,
        "longitude": -40.196027
      },
      {
        "id": 931,
        "latitude": -20.145671,
        "longitude": -40.270525
      },
      {
        "id": 932,
        "latitude": -20.143217,
        "longitude": -40.189293
      },
      {
        "id": 933,
        "latitude": -20.197721,
        "longitude": -40.228847
      },
      {
        "id": 935,
        "latitude": -20.236729,
        "longitude": -40.273274
      },
      {
        "id": 936,
        "latitude": -20.128008,
        "longitude": -40.273738
      },
      {
        "id": 937,
        "latitude": -20.125296,
        "longitude": -40.306448
      },
      {
        "id": 938,
        "latitude": -20.125296,
        "longitude": -40.306448
      },
      {
        "id": 939,
        "latitude": -20.116838,
        "longitude": -40.307742
      },
      {
        "id": 940,
        "latitude": -20.204986,
        "longitude": -40.243879
      },
      {
        "id": 941,
        "latitude": -20.168218,
        "longitude": -40.192592
      },
      {
        "id": 942,
        "latitude": -20.18971,
        "longitude": -40.272021
      },
      {
        "id": 943,
        "latitude": -24.260785,
        "longitude": -48.896497
      },
      {
        "id": 944,
        "latitude": -20.169822,
        "longitude": -40.21681
      },
      {
        "id": 2734,
        "latitude": -5.814574,
        "longitude": -46.149117
      },
      {
        "id": 945,
        "latitude": -20.221347,
        "longitude": -40.208665
      },
      {
        "id": 946,
        "latitude": -20.166342,
        "longitude": -40.215634
      },
      {
        "id": 947,
        "latitude": -20.236729,
        "longitude": -40.273274
      },
      {
        "id": 948,
        "latitude": -20.191448,
        "longitude": -40.267008
      },
      {
        "id": 949,
        "latitude": -20.154055,
        "longitude": -40.235721
      },
      {
        "id": 950,
        "latitude": -20.125296,
        "longitude": -40.306448
      },
      {
        "id": 951,
        "latitude": -20.166342,
        "longitude": -40.215634
      },
      {
        "id": 952,
        "latitude": -20.126664,
        "longitude": -40.283692
      },
      {
        "id": 953,
        "latitude": -20.145671,
        "longitude": -40.270525
      },
      {
        "id": 955,
        "latitude": -20.125296,
        "longitude": -40.306448
      },
      {
        "id": 956,
        "latitude": -20.391447,
        "longitude": -40.496659
      },
      {
        "id": 959,
        "latitude": -20.361796,
        "longitude": -40.30052
      },
      {
        "id": 960,
        "latitude": -20.354182,
        "longitude": -40.285623
      },
      {
        "id": 1176,
        "latitude": -16.829382,
        "longitude": -49.295346
      },
      {
        "id": 961,
        "latitude": -20.351363,
        "longitude": -40.319922
      },
      {
        "id": 962,
        "latitude": -20.341843,
        "longitude": -40.312534
      },
      {
        "id": 963,
        "latitude": -20.338057,
        "longitude": -40.296512
      },
      {
        "id": 964,
        "latitude": -20.329704,
        "longitude": -40.292017
      },
      {
        "id": 965,
        "latitude": -20.329704,
        "longitude": -40.292017
      },
      {
        "id": 966,
        "latitude": -20.298849,
        "longitude": -40.329653
      },
      {
        "id": 967,
        "latitude": -20.298849,
        "longitude": -40.329653
      },
      {
        "id": 968,
        "latitude": -20.295879,
        "longitude": -40.304154
      },
      {
        "id": 973,
        "latitude": -14.978933,
        "longitude": -47.785516
      },
      {
        "id": 974,
        "latitude": -18.076342,
        "longitude": -48.764287
      },
      {
        "id": 975,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 976,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 977,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 6617,
        "latitude": -9.548357,
        "longitude": -35.723025
      },
      {
        "id": 984,
        "latitude": -8.280499,
        "longitude": -35.064683
      },
      {
        "id": 1000,
        "latitude": -16.081887,
        "longitude": -47.975378
      },
      {
        "id": 1007,
        "latitude": -11.17647,
        "longitude": -49.869698
      },
      {
        "id": 1015,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1021,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1055,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1057,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1065,
        "latitude": -16.810421,
        "longitude": -49.251094
      },
      {
        "id": 1066,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1069,
        "latitude": -16.068382,
        "longitude": -47.981973
      },
      {
        "id": 1071,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1073,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1077,
        "latitude": -15.01876,
        "longitude": -51.305037
      },
      {
        "id": 1080,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1084,
        "latitude": -14.970753,
        "longitude": -47.256153
      },
      {
        "id": 1088,
        "latitude": -22.925214,
        "longitude": -43.178064
      },
      {
        "id": 1089,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1090,
        "latitude": -15.761506,
        "longitude": -48.249718
      },
      {
        "id": 2736,
        "latitude": -5.543718,
        "longitude": -47.478195
      },
      {
        "id": 1105,
        "latitude": -16.076308,
        "longitude": -47.972107
      },
      {
        "id": 1109,
        "latitude": -16.745005,
        "longitude": -49.191489
      },
      {
        "id": 1110,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1111,
        "latitude": -22.849313,
        "longitude": -43.468479
      },
      {
        "id": 1112,
        "latitude": -17.813807,
        "longitude": -50.612789
      },
      {
        "id": 1113,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1114,
        "latitude": -8.280499,
        "longitude": -35.064683
      },
      {
        "id": 1115,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1117,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1118,
        "latitude": -22.849293,
        "longitude": -43.468434
      },
      {
        "id": 1119,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1120,
        "latitude": -8.280499,
        "longitude": -35.064683
      },
      {
        "id": 1121,
        "latitude": -15.74816,
        "longitude": -48.297049
      },
      {
        "id": 1122,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1123,
        "latitude": -15.738063,
        "longitude": -48.290469
      },
      {
        "id": 1124,
        "latitude": -15.704781,
        "longitude": -48.283379
      },
      {
        "id": 1125,
        "latitude": -8.280499,
        "longitude": -35.064683
      },
      {
        "id": 1126,
        "latitude": -15.738063,
        "longitude": -48.290469
      },
      {
        "id": 1127,
        "latitude": -27.000857,
        "longitude": -48.653329
      },
      {
        "id": 1128,
        "latitude": -22.927325,
        "longitude": -43.563007
      },
      {
        "id": 1129,
        "latitude": -15.740744,
        "longitude": -48.273176
      },
      {
        "id": 1130,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1131,
        "latitude": -15.769027,
        "longitude": -48.281541
      },
      {
        "id": 1132,
        "latitude": -16.070743,
        "longitude": -47.980841
      },
      {
        "id": 1133,
        "latitude": -15.945483,
        "longitude": -50.128711
      },
      {
        "id": 1134,
        "latitude": -29.315692,
        "longitude": -50.68295
      },
      {
        "id": 1135,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1136,
        "latitude": -23.039068,
        "longitude": -44.178015
      },
      {
        "id": 1138,
        "latitude": -14.612642,
        "longitude": -47.165422
      },
      {
        "id": 1139,
        "latitude": -15.705047,
        "longitude": -48.281512
      },
      {
        "id": 1140,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1142,
        "latitude": -23.039324,
        "longitude": -44.176816
      },
      {
        "id": 1143,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1144,
        "latitude": -15.441585,
        "longitude": -47.620297
      },
      {
        "id": 1146,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1147,
        "latitude": -15.761506,
        "longitude": -48.249718
      },
      {
        "id": 1150,
        "latitude": -22.849406,
        "longitude": -43.468701
      },
      {
        "id": 1151,
        "latitude": -15.738063,
        "longitude": -48.290469
      },
      {
        "id": 1153,
        "latitude": -16.039423,
        "longitude": -50.708072
      },
      {
        "id": 1154,
        "latitude": -13.265058,
        "longitude": -46.876
      },
      {
        "id": 1155,
        "latitude": -14.612642,
        "longitude": -47.165422
      },
      {
        "id": 1156,
        "latitude": -15.740564,
        "longitude": -48.255604
      },
      {
        "id": 1157,
        "latitude": -16.846728,
        "longitude": -49.757287
      },
      {
        "id": 1158,
        "latitude": -15.740564,
        "longitude": -48.255604
      },
      {
        "id": 2737,
        "latitude": -5.520559,
        "longitude": -47.475059
      },
      {
        "id": 1159,
        "latitude": -16.075754,
        "longitude": -47.971603
      },
      {
        "id": 1160,
        "latitude": -16.176168,
        "longitude": -51.367032
      },
      {
        "id": 1161,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 1162,
        "latitude": -15.739228,
        "longitude": -48.279336
      },
      {
        "id": 1163,
        "latitude": -15.002067,
        "longitude": -47.258678
      },
      {
        "id": 1164,
        "latitude": -14.562417,
        "longitude": -46.173636
      },
      {
        "id": 1165,
        "latitude": -17.878211,
        "longitude": -51.717648
      },
      {
        "id": 1166,
        "latitude": -16.084277,
        "longitude": -48.500508
      },
      {
        "id": 1167,
        "latitude": -15.935378,
        "longitude": -48.23205
      },
      {
        "id": 1169,
        "latitude": -16.333811,
        "longitude": -48.954798
      },
      {
        "id": 1171,
        "latitude": -16.304182,
        "longitude": -48.955896
      },
      {
        "id": 1172,
        "latitude": -16.334603,
        "longitude": -48.936638
      },
      {
        "id": 1173,
        "latitude": -16.421433,
        "longitude": -48.987898
      },
      {
        "id": 1174,
        "latitude": -16.333283,
        "longitude": -48.952576
      },
      {
        "id": 1175,
        "latitude": -16.334603,
        "longitude": -48.936638
      },
      {
        "id": 2959,
        "latitude": -22.220615,
        "longitude": -54.812208
      },
      {
        "id": 1179,
        "latitude": -16.829382,
        "longitude": -49.295346
      },
      {
        "id": 1180,
        "latitude": -16.829382,
        "longitude": -49.295346
      },
      {
        "id": 1181,
        "latitude": -15.460348,
        "longitude": -39.653481
      },
      {
        "id": 1182,
        "latitude": -16.386527,
        "longitude": -51.555023
      },
      {
        "id": 1183,
        "latitude": -16.987302,
        "longitude": -48.947761
      },
      {
        "id": 1184,
        "latitude": -16.783957,
        "longitude": -49.101964
      },
      {
        "id": 1185,
        "latitude": -18.089853,
        "longitude": -50.221095
      },
      {
        "id": 1186,
        "latitude": -18.220494,
        "longitude": -49.726571
      },
      {
        "id": 1187,
        "latitude": -16.617271,
        "longitude": -48.96157
      },
      {
        "id": 1188,
        "latitude": -16.617468,
        "longitude": -48.960485
      },
      {
        "id": 1189,
        "latitude": -16.612373,
        "longitude": -48.964227
      },
      {
        "id": 1190,
        "latitude": -16.617468,
        "longitude": -48.960485
      },
      {
        "id": 1191,
        "latitude": -21.732977,
        "longitude": -41.186473
      },
      {
        "id": 1192,
        "latitude": -16.429164,
        "longitude": -49.385822
      },
      {
        "id": 1193,
        "latitude": -16.420811,
        "longitude": -49.379044
      },
      {
        "id": 1194,
        "latitude": -16.429164,
        "longitude": -49.385822
      },
      {
        "id": 1195,
        "latitude": -16.752907,
        "longitude": -49.341397
      },
      {
        "id": 1196,
        "latitude": -16.429164,
        "longitude": -49.385822
      },
      {
        "id": 1197,
        "latitude": -18.136273,
        "longitude": -49.04415
      },
      {
        "id": 1198,
        "latitude": -15.807016,
        "longitude": -46.932828
      },
      {
        "id": 1199,
        "latitude": -15.807016,
        "longitude": -46.932828
      },
      {
        "id": 1200,
        "latitude": -17.744961,
        "longitude": -48.622478
      },
      {
        "id": 1201,
        "latitude": -17.766268,
        "longitude": -48.621046
      },
      {
        "id": 1202,
        "latitude": -17.763401,
        "longitude": -48.638448
      },
      {
        "id": 1203,
        "latitude": -17.745,
        "longitude": -48.625
      },
      {
        "id": 1204,
        "latitude": -17.744961,
        "longitude": -48.622478
      },
      {
        "id": 1205,
        "latitude": -9.616438,
        "longitude": -41.804642
      },
      {
        "id": 1206,
        "latitude": -17.744961,
        "longitude": -48.622478
      },
      {
        "id": 1207,
        "latitude": -17.744961,
        "longitude": -48.622478
      },
      {
        "id": 1208,
        "latitude": -17.744961,
        "longitude": -48.622478
      },
      {
        "id": 1209,
        "latitude": -17.744961,
        "longitude": -48.622478
      },
      {
        "id": 1210,
        "latitude": -14.317151,
        "longitude": -49.157044
      },
      {
        "id": 1211,
        "latitude": -14.317151,
        "longitude": -49.157044
      },
      {
        "id": 1212,
        "latitude": -14.317151,
        "longitude": -49.157044
      },
      {
        "id": 1213,
        "latitude": -14.317151,
        "longitude": -49.157044
      },
      {
        "id": 1214,
        "latitude": -14.317151,
        "longitude": -49.157044
      },
      {
        "id": 1216,
        "latitude": -13.034276,
        "longitude": -46.768215
      },
      {
        "id": 1217,
        "latitude": -13.034276,
        "longitude": -46.768215
      },
      {
        "id": 1220,
        "latitude": -13.034276,
        "longitude": -46.768215
      },
      {
        "id": 1223,
        "latitude": -13.034276,
        "longitude": -46.768215
      },
      {
        "id": 1227,
        "latitude": -13.031518,
        "longitude": -46.773638
      },
      {
        "id": 1228,
        "latitude": -13.034276,
        "longitude": -46.768215
      },
      {
        "id": 1229,
        "latitude": -13.034276,
        "longitude": -46.768215
      },
      {
        "id": 1231,
        "latitude": -15.351502,
        "longitude": -49.707694
      },
      {
        "id": 1232,
        "latitude": -18.173651,
        "longitude": -47.941292
      },
      {
        "id": 1233,
        "latitude": -13.800584,
        "longitude": -47.44112
      },
      {
        "id": 1234,
        "latitude": -13.800584,
        "longitude": -47.44112
      },
      {
        "id": 1235,
        "latitude": -18.397092,
        "longitude": -52.667637
      },
      {
        "id": 1239,
        "latitude": -16.746098,
        "longitude": -49.204703
      },
      {
        "id": 1270,
        "latitude": -16.099071,
        "longitude": -47.921291
      },
      {
        "id": 1271,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1276,
        "latitude": -16.068548,
        "longitude": -47.971484
      },
      {
        "id": 1279,
        "latitude": -9.539872,
        "longitude": -35.810565
      },
      {
        "id": 1280,
        "latitude": -16.109774,
        "longitude": -47.937194
      },
      {
        "id": 2961,
        "latitude": -23.683761,
        "longitude": -54.560824
      },
      {
        "id": 1283,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1290,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1292,
        "latitude": -17.725167,
        "longitude": -49.103945
      },
      {
        "id": 1294,
        "latitude": -17.725167,
        "longitude": -49.103945
      },
      {
        "id": 1295,
        "latitude": -16.031724,
        "longitude": -50.716161
      },
      {
        "id": 1296,
        "latitude": -16.099071,
        "longitude": -47.921291
      },
      {
        "id": 1306,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1308,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1309,
        "latitude": -17.725446,
        "longitude": -49.103307
      },
      {
        "id": 1312,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1313,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 2998,
        "latitude": -19.73333,
        "longitude": -45.75
      },
      {
        "id": 1317,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1318,
        "latitude": -16.296429,
        "longitude": -48.949594
      },
      {
        "id": 1325,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1331,
        "latitude": -15.853571,
        "longitude": -48.960909
      },
      {
        "id": 1332,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 1336,
        "latitude": -16.032193,
        "longitude": -50.716976
      },
      {
        "id": 1342,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1343,
        "latitude": -15.936596,
        "longitude": -50.144377
      },
      {
        "id": 1349,
        "latitude": -16.104004,
        "longitude": -47.924462
      },
      {
        "id": 1350,
        "latitude": -16.097129,
        "longitude": -47.960415
      },
      {
        "id": 1352,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1353,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1359,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1362,
        "latitude": -16.071604,
        "longitude": -47.975276
      },
      {
        "id": 1365,
        "latitude": -9.537637,
        "longitude": -35.811795
      },
      {
        "id": 1370,
        "latitude": -16.090441,
        "longitude": -47.955887
      },
      {
        "id": 1372,
        "latitude": -16.068548,
        "longitude": -47.971484
      },
      {
        "id": 1381,
        "latitude": -18.395802,
        "longitude": -49.216599
      },
      {
        "id": 1387,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1388,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1390,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1403,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 1405,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1406,
        "latitude": -17.725167,
        "longitude": -49.103945
      },
      {
        "id": 1408,
        "latitude": -23.513369,
        "longitude": -46.365562
      },
      {
        "id": 1409,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1411,
        "latitude": -16.099071,
        "longitude": -47.921291
      },
      {
        "id": 1412,
        "latitude": -16.110646,
        "longitude": -47.929876
      },
      {
        "id": 1413,
        "latitude": -18.138769,
        "longitude": -48.084623
      },
      {
        "id": 1416,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1418,
        "latitude": -16.076032,
        "longitude": -47.970458
      },
      {
        "id": 1420,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1421,
        "latitude": -15.563849,
        "longitude": -47.335518
      },
      {
        "id": 1422,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1423,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1424,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1425,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1426,
        "latitude": -23.737255,
        "longitude": -46.669971
      },
      {
        "id": 1427,
        "latitude": -23.737255,
        "longitude": -46.669971
      },
      {
        "id": 1428,
        "latitude": -16.747773,
        "longitude": -49.195573
      },
      {
        "id": 1429,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1430,
        "latitude": -17.725167,
        "longitude": -49.103945
      },
      {
        "id": 1431,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1432,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 1433,
        "latitude": -16.058048,
        "longitude": -47.975141
      },
      {
        "id": 1435,
        "latitude": -23.742552,
        "longitude": -46.678398
      },
      {
        "id": 1436,
        "latitude": -17.313198,
        "longitude": -48.279605
      },
      {
        "id": 1437,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 1438,
        "latitude": -16.110646,
        "longitude": -47.929876
      },
      {
        "id": 1439,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1440,
        "latitude": -17.725528,
        "longitude": -49.103346
      },
      {
        "id": 1441,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1442,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1444,
        "latitude": -16.090441,
        "longitude": -47.955887
      },
      {
        "id": 1445,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1456,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1537,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1457,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1459,
        "latitude": -23.746455,
        "longitude": -46.697373
      },
      {
        "id": 1460,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1462,
        "latitude": -16.769269,
        "longitude": -49.335518
      },
      {
        "id": 1463,
        "latitude": -16.107221,
        "longitude": -47.929152
      },
      {
        "id": 1464,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1465,
        "latitude": -14.154668,
        "longitude": -48.078243
      },
      {
        "id": 1466,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1467,
        "latitude": -15.023947,
        "longitude": -51.303965
      },
      {
        "id": 1468,
        "latitude": -16.058746,
        "longitude": -47.978151
      },
      {
        "id": 1469,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1470,
        "latitude": -17.725528,
        "longitude": -49.103346
      },
      {
        "id": 1471,
        "latitude": -17.725807,
        "longitude": -49.102708
      },
      {
        "id": 1472,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1474,
        "latitude": -9.537461,
        "longitude": -35.810166
      },
      {
        "id": 1475,
        "latitude": -16.80395,
        "longitude": -49.221025
      },
      {
        "id": 1476,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1477,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1478,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1479,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1480,
        "latitude": -16.115151,
        "longitude": -47.950312
      },
      {
        "id": 1481,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1482,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1483,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1484,
        "latitude": -16.111667,
        "longitude": -47.930731
      },
      {
        "id": 1485,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1486,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1487,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1488,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1489,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1490,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1491,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1492,
        "latitude": -15.936596,
        "longitude": -50.144377
      },
      {
        "id": 1493,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1494,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1495,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1496,
        "latitude": -16.077844,
        "longitude": -47.907299
      },
      {
        "id": 1497,
        "latitude": -17.725167,
        "longitude": -49.103945
      },
      {
        "id": 1498,
        "latitude": -16.070964,
        "longitude": -47.979458
      },
      {
        "id": 1499,
        "latitude": -16.097453,
        "longitude": -47.918385
      },
      {
        "id": 1500,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1501,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1502,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1503,
        "latitude": -16.101099,
        "longitude": -47.956039
      },
      {
        "id": 1504,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1505,
        "latitude": -16.059211,
        "longitude": -47.823815
      },
      {
        "id": 1506,
        "latitude": -16.337073,
        "longitude": -48.954723
      },
      {
        "id": 1507,
        "latitude": -16.891153,
        "longitude": -48.347135
      },
      {
        "id": 1508,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1509,
        "latitude": -16.61501,
        "longitude": -52.65869
      },
      {
        "id": 1510,
        "latitude": -16.053472,
        "longitude": -47.916397
      },
      {
        "id": 1511,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1513,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1514,
        "latitude": -16.099066,
        "longitude": -47.960323
      },
      {
        "id": 1515,
        "latitude": -16.65092,
        "longitude": -49.248813
      },
      {
        "id": 1516,
        "latitude": -15.558348,
        "longitude": -47.337568
      },
      {
        "id": 1517,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1518,
        "latitude": -16.097129,
        "longitude": -47.960415
      },
      {
        "id": 1519,
        "latitude": -16.090441,
        "longitude": -47.955887
      },
      {
        "id": 1520,
        "latitude": -16.086049,
        "longitude": -47.92124
      },
      {
        "id": 1536,
        "latitude": -16.090441,
        "longitude": -47.955887
      },
      {
        "id": 1521,
        "latitude": -16.071003,
        "longitude": -47.970536
      },
      {
        "id": 1522,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1523,
        "latitude": -15.09731,
        "longitude": -39.158743
      },
      {
        "id": 1524,
        "latitude": -16.090441,
        "longitude": -47.955887
      },
      {
        "id": 1525,
        "latitude": -16.106313,
        "longitude": -47.930447
      },
      {
        "id": 1526,
        "latitude": -15.936893,
        "longitude": -50.143672
      },
      {
        "id": 1527,
        "latitude": -16.678196,
        "longitude": -49.114797
      },
      {
        "id": 1528,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1529,
        "latitude": -3.31259,
        "longitude": -52.536907
      },
      {
        "id": 1530,
        "latitude": -16.097129,
        "longitude": -47.960415
      },
      {
        "id": 1531,
        "latitude": -16.110628,
        "longitude": -47.930524
      },
      {
        "id": 1532,
        "latitude": -16.097129,
        "longitude": -47.960415
      },
      {
        "id": 1533,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1534,
        "latitude": -16.102567,
        "longitude": -47.928039
      },
      {
        "id": 1535,
        "latitude": -16.032193,
        "longitude": -50.716976
      },
      {
        "id": 3016,
        "latitude": -19.939697,
        "longitude": -43.939432
      },
      {
        "id": 1540,
        "latitude": -15.936596,
        "longitude": -50.144377
      },
      {
        "id": 1541,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1542,
        "latitude": -16.7072,
        "longitude": -49.314549
      },
      {
        "id": 1543,
        "latitude": -16.099066,
        "longitude": -47.960323
      },
      {
        "id": 1544,
        "latitude": -16.033173,
        "longitude": -50.709646
      },
      {
        "id": 1545,
        "latitude": -16.052865,
        "longitude": -47.914606
      },
      {
        "id": 1547,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1548,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 1549,
        "latitude": -16.001705,
        "longitude": -47.988088
      },
      {
        "id": 1550,
        "latitude": -9.539872,
        "longitude": -35.810565
      },
      {
        "id": 1551,
        "latitude": -16.032193,
        "longitude": -50.716976
      },
      {
        "id": 1552,
        "latitude": -16.103118,
        "longitude": -47.931511
      },
      {
        "id": 1553,
        "latitude": -16.104354,
        "longitude": -47.929557
      },
      {
        "id": 1554,
        "latitude": -15.938274,
        "longitude": -50.145617
      },
      {
        "id": 1555,
        "latitude": -16.058746,
        "longitude": -47.978151
      },
      {
        "id": 1556,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1557,
        "latitude": -16.774102,
        "longitude": -49.360672
      },
      {
        "id": 1558,
        "latitude": -16.090428,
        "longitude": -47.955914
      },
      {
        "id": 1559,
        "latitude": -16.109826,
        "longitude": -47.930028
      },
      {
        "id": 1560,
        "latitude": -16.103118,
        "longitude": -47.931511
      },
      {
        "id": 1561,
        "latitude": -16.104133,
        "longitude": -47.944699
      },
      {
        "id": 1562,
        "latitude": -16.434724,
        "longitude": -51.120082
      },
      {
        "id": 1564,
        "latitude": -16.102478,
        "longitude": -47.950253
      },
      {
        "id": 1565,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1566,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1567,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1568,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1569,
        "latitude": -16.296429,
        "longitude": -48.949594
      },
      {
        "id": 1570,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1571,
        "latitude": -17.725446,
        "longitude": -49.103307
      },
      {
        "id": 1572,
        "latitude": -17.725807,
        "longitude": -49.102708
      },
      {
        "id": 1573,
        "latitude": -15.936596,
        "longitude": -50.144377
      },
      {
        "id": 1574,
        "latitude": -16.707153,
        "longitude": -49.311624
      },
      {
        "id": 1575,
        "latitude": -16.107221,
        "longitude": -47.929152
      },
      {
        "id": 1576,
        "latitude": -16.069984,
        "longitude": -47.974153
      },
      {
        "id": 1577,
        "latitude": -16.058048,
        "longitude": -47.975141
      },
      {
        "id": 1578,
        "latitude": -16.072476,
        "longitude": -47.974718
      },
      {
        "id": 1579,
        "latitude": -17.725446,
        "longitude": -49.103307
      },
      {
        "id": 1580,
        "latitude": -16.836187,
        "longitude": -49.533866
      },
      {
        "id": 1581,
        "latitude": -16.032193,
        "longitude": -50.716976
      },
      {
        "id": 1582,
        "latitude": -16.107221,
        "longitude": -47.929152
      },
      {
        "id": 1583,
        "latitude": -18.426631,
        "longitude": -49.223708
      },
      {
        "id": 1585,
        "latitude": -16.118507,
        "longitude": -47.95246
      },
      {
        "id": 1586,
        "latitude": -16.100076,
        "longitude": -47.944501
      },
      {
        "id": 1587,
        "latitude": -15.03396,
        "longitude": -51.297285
      },
      {
        "id": 1588,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1589,
        "latitude": -16.10944,
        "longitude": -47.94917
      },
      {
        "id": 1590,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1593,
        "latitude": -15.022185,
        "longitude": -51.303248
      },
      {
        "id": 1614,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1617,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1618,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1619,
        "latitude": -16.609005,
        "longitude": -49.318392
      },
      {
        "id": 1620,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1621,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1622,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1623,
        "latitude": -15.022185,
        "longitude": -51.303248
      },
      {
        "id": 1624,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 1625,
        "latitude": -15.023792,
        "longitude": -51.30162
      },
      {
        "id": 1626,
        "latitude": -15.776285,
        "longitude": -48.773043
      },
      {
        "id": 1627,
        "latitude": -15.92704,
        "longitude": -48.810692
      },
      {
        "id": 1629,
        "latitude": -17.197785,
        "longitude": -48.707471
      },
      {
        "id": 1631,
        "latitude": -14.542454,
        "longitude": -49.969481
      },
      {
        "id": 1632,
        "latitude": -14.542454,
        "longitude": -49.969481
      },
      {
        "id": 1633,
        "latitude": -14.53787,
        "longitude": -49.961203
      },
      {
        "id": 1638,
        "latitude": -14.458029,
        "longitude": -47.03954
      },
      {
        "id": 1639,
        "latitude": -15.550744,
        "longitude": -47.330686
      },
      {
        "id": 1640,
        "latitude": -15.549244,
        "longitude": -47.330121
      },
      {
        "id": 1641,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1642,
        "latitude": -16.601267,
        "longitude": -49.34762
      },
      {
        "id": 1643,
        "latitude": -16.678256,
        "longitude": -49.439409
      },
      {
        "id": 1644,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1645,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1646,
        "latitude": -16.700763,
        "longitude": -49.316604
      },
      {
        "id": 1647,
        "latitude": -16.611865,
        "longitude": -49.326405
      },
      {
        "id": 1648,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1649,
        "latitude": -16.685804,
        "longitude": -49.296621
      },
      {
        "id": 1650,
        "latitude": -16.685232,
        "longitude": -49.389694
      },
      {
        "id": 1651,
        "latitude": -16.689169,
        "longitude": -49.240466
      },
      {
        "id": 1652,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1654,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1655,
        "latitude": -16.77288,
        "longitude": -49.341397
      },
      {
        "id": 1656,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 3017,
        "latitude": -19.939697,
        "longitude": -43.939432
      },
      {
        "id": 1657,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1658,
        "latitude": -16.618619,
        "longitude": -49.219219
      },
      {
        "id": 1659,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1660,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1661,
        "latitude": -16.694011,
        "longitude": -49.329769
      },
      {
        "id": 1662,
        "latitude": -16.423081,
        "longitude": -49.465226
      },
      {
        "id": 1663,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1664,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1665,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1666,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1667,
        "latitude": -16.643581,
        "longitude": -49.327437
      },
      {
        "id": 1668,
        "latitude": -16.744239,
        "longitude": -49.198965
      },
      {
        "id": 1669,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1670,
        "latitude": -16.649524,
        "longitude": -49.302603
      },
      {
        "id": 1671,
        "latitude": -16.257246,
        "longitude": -49.986431
      },
      {
        "id": 1672,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1673,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1674,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1675,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1676,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1677,
        "latitude": -16.701919,
        "longitude": -49.272472
      },
      {
        "id": 1678,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1679,
        "latitude": -16.64455,
        "longitude": -49.258739
      },
      {
        "id": 1680,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1681,
        "latitude": -16.755578,
        "longitude": -49.239534
      },
      {
        "id": 1683,
        "latitude": -16.796107,
        "longitude": -49.375039
      },
      {
        "id": 1684,
        "latitude": -16.242545,
        "longitude": -47.903394
      },
      {
        "id": 1685,
        "latitude": -16.749011,
        "longitude": -49.383344
      },
      {
        "id": 1686,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1687,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1688,
        "latitude": -16.759057,
        "longitude": -49.437935
      },
      {
        "id": 1689,
        "latitude": -16.706083,
        "longitude": -49.358707
      },
      {
        "id": 1690,
        "latitude": -16.73495,
        "longitude": -49.345214
      },
      {
        "id": 1691,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1692,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1693,
        "latitude": -16.737173,
        "longitude": -49.340765
      },
      {
        "id": 1694,
        "latitude": -16.656523,
        "longitude": -49.35394
      },
      {
        "id": 1695,
        "latitude": -11.24112,
        "longitude": -49.103312
      },
      {
        "id": 1696,
        "latitude": -16.699899,
        "longitude": -49.302249
      },
      {
        "id": 1697,
        "latitude": -16.670199,
        "longitude": -49.437976
      },
      {
        "id": 1698,
        "latitude": -27.369597,
        "longitude": -53.334222
      },
      {
        "id": 1699,
        "latitude": -23.299362,
        "longitude": -48.054231
      },
      {
        "id": 1700,
        "latitude": -16.69127,
        "longitude": -49.265402
      },
      {
        "id": 1701,
        "latitude": -16.737173,
        "longitude": -49.340765
      },
      {
        "id": 1702,
        "latitude": -16.97419,
        "longitude": -47.256287
      },
      {
        "id": 1703,
        "latitude": -16.725499,
        "longitude": -49.349792
      },
      {
        "id": 1704,
        "latitude": -16.074617,
        "longitude": -47.997235
      },
      {
        "id": 1705,
        "latitude": -16.752036,
        "longitude": -49.34082
      },
      {
        "id": 1706,
        "latitude": -16.726312,
        "longitude": -49.329522
      },
      {
        "id": 1707,
        "latitude": -16.730235,
        "longitude": -49.32184
      },
      {
        "id": 1708,
        "latitude": -16.635335,
        "longitude": -49.253811
      },
      {
        "id": 1709,
        "latitude": -16.723498,
        "longitude": -49.397984
      },
      {
        "id": 1710,
        "latitude": -16.666521,
        "longitude": -49.24612
      },
      {
        "id": 1711,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1712,
        "latitude": -16.678945,
        "longitude": -49.319272
      },
      {
        "id": 1713,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1714,
        "latitude": -29.830862,
        "longitude": -50.057084
      },
      {
        "id": 1715,
        "latitude": -16.662364,
        "longitude": -49.212713
      },
      {
        "id": 1716,
        "latitude": -16.643023,
        "longitude": -49.497576
      },
      {
        "id": 1717,
        "latitude": -16.726312,
        "longitude": -49.329522
      },
      {
        "id": 1718,
        "latitude": -9.537461,
        "longitude": -35.810166
      },
      {
        "id": 1719,
        "latitude": -16.729896,
        "longitude": -49.336725
      },
      {
        "id": 1720,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1721,
        "latitude": -16.680882,
        "longitude": -49.253269
      },
      {
        "id": 1722,
        "latitude": -23.301655,
        "longitude": -48.05509
      },
      {
        "id": 1723,
        "latitude": -16.737173,
        "longitude": -49.340765
      },
      {
        "id": 1724,
        "latitude": -16.759057,
        "longitude": -49.437935
      },
      {
        "id": 1725,
        "latitude": -16.032526,
        "longitude": -50.710046
      },
      {
        "id": 1726,
        "latitude": -16.737009,
        "longitude": -49.287349
      },
      {
        "id": 1727,
        "latitude": -16.255927,
        "longitude": -49.978105
      },
      {
        "id": 1728,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1729,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1730,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 1731,
        "latitude": -9.533964,
        "longitude": -35.80144
      },
      {
        "id": 1732,
        "latitude": -16.679521,
        "longitude": -49.299059
      },
      {
        "id": 1734,
        "latitude": -17.39988,
        "longitude": -46.135317
      },
      {
        "id": 1735,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1736,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1737,
        "latitude": -16.500197,
        "longitude": -49.425479
      },
      {
        "id": 1738,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1739,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1740,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1741,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1742,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1743,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1744,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1745,
        "latitude": -16.521248,
        "longitude": -49.405637
      },
      {
        "id": 1746,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 1747,
        "latitude": -18.015458,
        "longitude": -49.364059
      },
      {
        "id": 1748,
        "latitude": -18.019755,
        "longitude": -49.36618
      },
      {
        "id": 1749,
        "latitude": -18.019755,
        "longitude": -49.36618
      },
      {
        "id": 1750,
        "latitude": -16.968429,
        "longitude": -49.221987
      },
      {
        "id": 1751,
        "latitude": -18.478559,
        "longitude": -49.996979
      },
      {
        "id": 1752,
        "latitude": -16.361057,
        "longitude": -49.500111
      },
      {
        "id": 1753,
        "latitude": -16.367252,
        "longitude": -49.492142
      },
      {
        "id": 1754,
        "latitude": -16.367252,
        "longitude": -49.492142
      },
      {
        "id": 1756,
        "latitude": -16.367252,
        "longitude": -49.492142
      },
      {
        "id": 1757,
        "latitude": -16.361057,
        "longitude": -49.500111
      },
      {
        "id": 1758,
        "latitude": -9.537507,
        "longitude": -35.810662
      },
      {
        "id": 1759,
        "latitude": -16.361057,
        "longitude": -49.500111
      },
      {
        "id": 1760,
        "latitude": -16.429844,
        "longitude": -51.110971
      },
      {
        "id": 1761,
        "latitude": -14.952235,
        "longitude": -49.551122
      },
      {
        "id": 1763,
        "latitude": -15.8205,
        "longitude": -50.609434
      },
      {
        "id": 1764,
        "latitude": -15.826346,
        "longitude": -50.608913
      },
      {
        "id": 1765,
        "latitude": -18.380759,
        "longitude": -49.215485
      },
      {
        "id": 1766,
        "latitude": -15.462047,
        "longitude": -47.608741
      },
      {
        "id": 1767,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1768,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1769,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1770,
        "latitude": -18.41275,
        "longitude": -49.222763
      },
      {
        "id": 1771,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1772,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1773,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1774,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 1775,
        "latitude": -26.740029,
        "longitude": -52.967607
      },
      {
        "id": 1776,
        "latitude": -15.745906,
        "longitude": -49.333904
      },
      {
        "id": 1777,
        "latitude": -15.745906,
        "longitude": -49.333904
      },
      {
        "id": 1779,
        "latitude": -15.765128,
        "longitude": -49.318451
      },
      {
        "id": 1781,
        "latitude": -15.745906,
        "longitude": -49.333904
      },
      {
        "id": 1782,
        "latitude": -15.745906,
        "longitude": -49.333904
      },
      {
        "id": 1783,
        "latitude": -15.745906,
        "longitude": -49.333904
      },
      {
        "id": 1784,
        "latitude": -17.892858,
        "longitude": -51.724822
      },
      {
        "id": 1785,
        "latitude": -17.892858,
        "longitude": -51.724822
      },
      {
        "id": 1786,
        "latitude": -15.880572,
        "longitude": -50.875747
      },
      {
        "id": 1787,
        "latitude": -16.743323,
        "longitude": -48.507506
      },
      {
        "id": 1788,
        "latitude": -15.869897,
        "longitude": -50.863986
      },
      {
        "id": 1789,
        "latitude": -15.869897,
        "longitude": -50.863986
      },
      {
        "id": 3541,
        "latitude": -20.026872,
        "longitude": -43.933938
      },
      {
        "id": 1797,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1803,
        "latitude": -13.062445,
        "longitude": -48.842643
      },
      {
        "id": 1817,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1819,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1821,
        "latitude": -16.142813,
        "longitude": -47.95561
      },
      {
        "id": 1823,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1824,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1825,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1826,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1827,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1828,
        "latitude": -16.391438,
        "longitude": -47.985055
      },
      {
        "id": 1829,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1830,
        "latitude": -29.509908,
        "longitude": -51.96535
      },
      {
        "id": 1831,
        "latitude": -16.179087,
        "longitude": -47.937827
      },
      {
        "id": 1832,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1833,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1834,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1835,
        "latitude": -16.133025,
        "longitude": -47.964066
      },
      {
        "id": 1836,
        "latitude": -16.2822,
        "longitude": -47.867285
      },
      {
        "id": 1837,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1838,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1839,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1840,
        "latitude": -24.69119,
        "longitude": -47.985368
      },
      {
        "id": 1841,
        "latitude": -22.849542,
        "longitude": -43.469032
      },
      {
        "id": 1842,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 5215,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 1843,
        "latitude": -16.333595,
        "longitude": -48.01894
      },
      {
        "id": 1844,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1845,
        "latitude": -22.849522,
        "longitude": -43.468988
      },
      {
        "id": 1846,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1847,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1848,
        "latitude": -16.133748,
        "longitude": -47.963109
      },
      {
        "id": 1849,
        "latitude": -16.133748,
        "longitude": -47.963109
      },
      {
        "id": 1850,
        "latitude": -16.251487,
        "longitude": -47.947095
      },
      {
        "id": 1851,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 1852,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1853,
        "latitude": -16.287741,
        "longitude": -47.869989
      },
      {
        "id": 1854,
        "latitude": -16.133303,
        "longitude": -47.962586
      },
      {
        "id": 1855,
        "latitude": -15.026135,
        "longitude": -49.12848
      },
      {
        "id": 1856,
        "latitude": -16.132631,
        "longitude": -47.961351
      },
      {
        "id": 1857,
        "latitude": -16.56792,
        "longitude": -49.26661
      },
      {
        "id": 1925,
        "latitude": -16.408317,
        "longitude": -49.223478
      },
      {
        "id": 1859,
        "latitude": -16.146889,
        "longitude": -47.96245
      },
      {
        "id": 1860,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1861,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1862,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1863,
        "latitude": -18.346259,
        "longitude": -47.80399
      },
      {
        "id": 1864,
        "latitude": -16.72383,
        "longitude": -49.242632
      },
      {
        "id": 1865,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1866,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1867,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1868,
        "latitude": -16.133303,
        "longitude": -47.962586
      },
      {
        "id": 1869,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 1870,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1871,
        "latitude": -16.242452,
        "longitude": -47.951207
      },
      {
        "id": 1872,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1873,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1874,
        "latitude": -15.805188,
        "longitude": -50.697518
      },
      {
        "id": 1926,
        "latitude": -16.408317,
        "longitude": -49.223478
      },
      {
        "id": 1875,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1876,
        "latitude": -16.133748,
        "longitude": -47.963109
      },
      {
        "id": 1877,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1878,
        "latitude": -16.131711,
        "longitude": -47.965315
      },
      {
        "id": 1879,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1880,
        "latitude": -16.149024,
        "longitude": -47.939891
      },
      {
        "id": 1881,
        "latitude": -21.739182,
        "longitude": -42.462779
      },
      {
        "id": 1882,
        "latitude": -16.127858,
        "longitude": -47.965889
      },
      {
        "id": 1883,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1884,
        "latitude": -16.133748,
        "longitude": -47.963109
      },
      {
        "id": 1885,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1886,
        "latitude": -19.078738,
        "longitude": -51.586125
      },
      {
        "id": 1887,
        "latitude": -9.045045,
        "longitude": -41.033683
      },
      {
        "id": 1888,
        "latitude": -16.137906,
        "longitude": -47.956322
      },
      {
        "id": 1889,
        "latitude": -19.955427,
        "longitude": -44.032876
      },
      {
        "id": 1890,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1891,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1892,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 1893,
        "latitude": -16.335148,
        "longitude": -48.014355
      },
      {
        "id": 1894,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1895,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1896,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1897,
        "latitude": -16.459602,
        "longitude": -49.986852
      },
      {
        "id": 1898,
        "latitude": -13.062445,
        "longitude": -48.842643
      },
      {
        "id": 1899,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1900,
        "latitude": -15.529301,
        "longitude": -47.306524
      },
      {
        "id": 1901,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1902,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1903,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1904,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1905,
        "latitude": -16.131712,
        "longitude": -47.965317
      },
      {
        "id": 1906,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1907,
        "latitude": -15.452293,
        "longitude": -47.592385
      },
      {
        "id": 1908,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1909,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1910,
        "latitude": -17.710347,
        "longitude": -49.840255
      },
      {
        "id": 1911,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1912,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1913,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 1914,
        "latitude": -16.253546,
        "longitude": -47.950028
      },
      {
        "id": 1915,
        "latitude": -17.541905,
        "longitude": -52.544511
      },
      {
        "id": 1916,
        "latitude": -17.56403,
        "longitude": -52.552528
      },
      {
        "id": 1920,
        "latitude": -13.255206,
        "longitude": -46.892813
      },
      {
        "id": 1921,
        "latitude": -13.257859,
        "longitude": -46.89078
      },
      {
        "id": 1922,
        "latitude": -13.234268,
        "longitude": -48.693108
      },
      {
        "id": 1923,
        "latitude": -17.732811,
        "longitude": -49.115006
      },
      {
        "id": 1924,
        "latitude": -17.732811,
        "longitude": -49.115006
      },
      {
        "id": 1927,
        "latitude": -16.408317,
        "longitude": -49.223478
      },
      {
        "id": 1928,
        "latitude": -16.408317,
        "longitude": -49.223478
      },
      {
        "id": 1929,
        "latitude": -14.4666,
        "longitude": -48.454097
      },
      {
        "id": 1947,
        "latitude": -16.090785,
        "longitude": -48.055975
      },
      {
        "id": 1951,
        "latitude": -15.947493,
        "longitude": -48.231543
      },
      {
        "id": 1952,
        "latitude": -16.091891,
        "longitude": -48.068868
      },
      {
        "id": 1953,
        "latitude": -16.049653,
        "longitude": -48.030462
      },
      {
        "id": 1954,
        "latitude": -16.107777,
        "longitude": -47.967546
      },
      {
        "id": 1955,
        "latitude": -16.080005,
        "longitude": -48.064078
      },
      {
        "id": 1956,
        "latitude": -16.090785,
        "longitude": -48.055975
      },
      {
        "id": 1957,
        "latitude": -16.080005,
        "longitude": -48.064078
      },
      {
        "id": 1962,
        "latitude": -15.163022,
        "longitude": -48.280943
      },
      {
        "id": 1964,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1965,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1966,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1967,
        "latitude": -15.452293,
        "longitude": -47.592385
      },
      {
        "id": 1968,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1969,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1970,
        "latitude": -16.690665,
        "longitude": -49.096782
      },
      {
        "id": 1972,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1977,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1983,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 1986,
        "latitude": -15.035,
        "longitude": -51.294665
      },
      {
        "id": 1988,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1991,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1993,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 1995,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2021,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2026,
        "latitude": -15.456644,
        "longitude": -47.619603
      },
      {
        "id": 2035,
        "latitude": -15.472629,
        "longitude": -47.603793
      },
      {
        "id": 2038,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2041,
        "latitude": -16.935783,
        "longitude": -48.513248
      },
      {
        "id": 2045,
        "latitude": -15.0278,
        "longitude": -51.301667
      },
      {
        "id": 2051,
        "latitude": -15.446338,
        "longitude": -47.609512
      },
      {
        "id": 2052,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2056,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2059,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 3308,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 2072,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2074,
        "latitude": -15.334579,
        "longitude": -49.108637
      },
      {
        "id": 2076,
        "latitude": -15.446338,
        "longitude": -47.609512
      },
      {
        "id": 2084,
        "latitude": -15.334579,
        "longitude": -49.108637
      },
      {
        "id": 2085,
        "latitude": -15.456063,
        "longitude": -47.609556
      },
      {
        "id": 2094,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2103,
        "latitude": -15.446338,
        "longitude": -47.609512
      },
      {
        "id": 2118,
        "latitude": -16.068945,
        "longitude": -48.006108
      },
      {
        "id": 2121,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2124,
        "latitude": -16.95477,
        "longitude": -51.808945
      },
      {
        "id": 2128,
        "latitude": -3.311362,
        "longitude": -52.534634
      },
      {
        "id": 2134,
        "latitude": -15.425033,
        "longitude": -47.519402
      },
      {
        "id": 2135,
        "latitude": -15.028458,
        "longitude": -51.300948
      },
      {
        "id": 2136,
        "latitude": -9.537461,
        "longitude": -35.810166
      },
      {
        "id": 2144,
        "latitude": -15.481324,
        "longitude": -47.601997
      },
      {
        "id": 2145,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2147,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2148,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2152,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2155,
        "latitude": -15.444518,
        "longitude": -47.610492
      },
      {
        "id": 2157,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2158,
        "latitude": -14.154668,
        "longitude": -48.078243
      },
      {
        "id": 2163,
        "latitude": -16.690665,
        "longitude": -49.096782
      },
      {
        "id": 2166,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2172,
        "latitude": -17.735947,
        "longitude": -49.09838
      },
      {
        "id": 2173,
        "latitude": -15.462047,
        "longitude": -47.608741
      },
      {
        "id": 2174,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2175,
        "latitude": -15.455925,
        "longitude": -47.609566
      },
      {
        "id": 2176,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2177,
        "latitude": -15.467542,
        "longitude": -47.60425
      },
      {
        "id": 2178,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2179,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2180,
        "latitude": -15.46142,
        "longitude": -47.609063
      },
      {
        "id": 2181,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2182,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2183,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2184,
        "latitude": -16.61501,
        "longitude": -52.65869
      },
      {
        "id": 2185,
        "latitude": -16.074131,
        "longitude": -47.99735
      },
      {
        "id": 2186,
        "latitude": -16.690665,
        "longitude": -49.096782
      },
      {
        "id": 2187,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2188,
        "latitude": -16.701919,
        "longitude": -49.272472
      },
      {
        "id": 2189,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2190,
        "latitude": -15.02045,
        "longitude": -50.320238
      },
      {
        "id": 2191,
        "latitude": -15.461642,
        "longitude": -47.61355
      },
      {
        "id": 2192,
        "latitude": -16.701919,
        "longitude": -49.272472
      },
      {
        "id": 2193,
        "latitude": -15.76917,
        "longitude": -49.32454
      },
      {
        "id": 2194,
        "latitude": -16.068373,
        "longitude": -47.98223
      },
      {
        "id": 2195,
        "latitude": -16.075767,
        "longitude": -47.971689
      },
      {
        "id": 2196,
        "latitude": -23.301246,
        "longitude": -48.054884
      },
      {
        "id": 2197,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2217,
        "latitude": -15.462047,
        "longitude": -47.608741
      },
      {
        "id": 2218,
        "latitude": -15.446338,
        "longitude": -47.609512
      },
      {
        "id": 2219,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2221,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2222,
        "latitude": -15.461671,
        "longitude": -47.607237
      },
      {
        "id": 2223,
        "latitude": -15.454266,
        "longitude": -47.599898
      },
      {
        "id": 2226,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2227,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2228,
        "latitude": -15.478977,
        "longitude": -47.527077
      },
      {
        "id": 2229,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2231,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2233,
        "latitude": -16.071182,
        "longitude": -47.981033
      },
      {
        "id": 2234,
        "latitude": -15.462047,
        "longitude": -47.608741
      },
      {
        "id": 2235,
        "latitude": -14.154668,
        "longitude": -48.078243
      },
      {
        "id": 2236,
        "latitude": -15.444518,
        "longitude": -47.610492
      },
      {
        "id": 2237,
        "latitude": -16.068212,
        "longitude": -47.982795
      },
      {
        "id": 2238,
        "latitude": -15.641838,
        "longitude": -47.629942
      },
      {
        "id": 2239,
        "latitude": -15.444518,
        "longitude": -47.610492
      },
      {
        "id": 2240,
        "latitude": -15.456843,
        "longitude": -47.60833
      },
      {
        "id": 2241,
        "latitude": -15.017627,
        "longitude": -51.30454
      },
      {
        "id": 2242,
        "latitude": -15.15391,
        "longitude": -47.623983
      },
      {
        "id": 2243,
        "latitude": -14.891313,
        "longitude": -46.830778
      },
      {
        "id": 2244,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2245,
        "latitude": -15.474518,
        "longitude": -47.595974
      },
      {
        "id": 2246,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2248,
        "latitude": -15.444518,
        "longitude": -47.610492
      },
      {
        "id": 2249,
        "latitude": -15.487175,
        "longitude": -47.600408
      },
      {
        "id": 2251,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2253,
        "latitude": -15.455733,
        "longitude": -47.623863
      },
      {
        "id": 2254,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2255,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2258,
        "latitude": -15.444518,
        "longitude": -47.610492
      },
      {
        "id": 2259,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2277,
        "latitude": -16.935783,
        "longitude": -48.513248
      },
      {
        "id": 2260,
        "latitude": -16.690465,
        "longitude": -49.26364
      },
      {
        "id": 2261,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2262,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2263,
        "latitude": -16.075767,
        "longitude": -47.971689
      },
      {
        "id": 2264,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2265,
        "latitude": -16.715688,
        "longitude": -49.276507
      },
      {
        "id": 2266,
        "latitude": -15.40166,
        "longitude": -47.53068
      },
      {
        "id": 2267,
        "latitude": -15.15391,
        "longitude": -47.623983
      },
      {
        "id": 2268,
        "latitude": -20.450756,
        "longitude": -53.758258
      },
      {
        "id": 2269,
        "latitude": -15.46142,
        "longitude": -47.609063
      },
      {
        "id": 2270,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2271,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2272,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2273,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2274,
        "latitude": -15.462708,
        "longitude": -47.608411
      },
      {
        "id": 2275,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2276,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2278,
        "latitude": -16.252387,
        "longitude": -40.144579
      },
      {
        "id": 2279,
        "latitude": -15.853215,
        "longitude": -50.918492
      },
      {
        "id": 2280,
        "latitude": -15.786019,
        "longitude": -47.934977
      },
      {
        "id": 2281,
        "latitude": -15.460221,
        "longitude": -47.608275
      },
      {
        "id": 2282,
        "latitude": -15.641867,
        "longitude": -47.630867
      },
      {
        "id": 2283,
        "latitude": -15.47835,
        "longitude": -47.603124
      },
      {
        "id": 2284,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2285,
        "latitude": -16.072593,
        "longitude": -47.98088
      },
      {
        "id": 2286,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2287,
        "latitude": -15.302315,
        "longitude": -47.458155
      },
      {
        "id": 2288,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2290,
        "latitude": -16.701919,
        "longitude": -49.272472
      },
      {
        "id": 2291,
        "latitude": -9.539872,
        "longitude": -35.810565
      },
      {
        "id": 2292,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2293,
        "latitude": -18.404583,
        "longitude": -49.212422
      },
      {
        "id": 2294,
        "latitude": -15.444518,
        "longitude": -47.610492
      },
      {
        "id": 2295,
        "latitude": -15.481324,
        "longitude": -47.601997
      },
      {
        "id": 2296,
        "latitude": -15.447822,
        "longitude": -47.609655
      },
      {
        "id": 2297,
        "latitude": -15.446338,
        "longitude": -47.609512
      },
      {
        "id": 2298,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2299,
        "latitude": -16.176168,
        "longitude": -51.367032
      },
      {
        "id": 2300,
        "latitude": -15.481324,
        "longitude": -47.601997
      },
      {
        "id": 2301,
        "latitude": -26.278866,
        "longitude": -49.383243
      },
      {
        "id": 2302,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2303,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2304,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2305,
        "latitude": -9.198135,
        "longitude": -63.212425
      },
      {
        "id": 2306,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2307,
        "latitude": -14.275945,
        "longitude": -46.809732
      },
      {
        "id": 2308,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2309,
        "latitude": -15.853215,
        "longitude": -50.918492
      },
      {
        "id": 2310,
        "latitude": -15.15391,
        "longitude": -47.623983
      },
      {
        "id": 2311,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2312,
        "latitude": -15.334579,
        "longitude": -49.108637
      },
      {
        "id": 2313,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2314,
        "latitude": -15.641838,
        "longitude": -47.629942
      },
      {
        "id": 2315,
        "latitude": -16.935783,
        "longitude": -48.513248
      },
      {
        "id": 2316,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2317,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2318,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2319,
        "latitude": -15.457154,
        "longitude": -47.608902
      },
      {
        "id": 2320,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 2322,
        "latitude": -13.438023,
        "longitude": -49.149054
      },
      {
        "id": 2324,
        "latitude": -14.083585,
        "longitude": -46.356319
      },
      {
        "id": 2325,
        "latitude": -17.792126,
        "longitude": -50.919122
      },
      {
        "id": 2327,
        "latitude": -17.792126,
        "longitude": -50.919122
      },
      {
        "id": 2328,
        "latitude": -22.86119,
        "longitude": -43.09487
      },
      {
        "id": 2329,
        "latitude": -17.729159,
        "longitude": -48.173328
      },
      {
        "id": 2330,
        "latitude": -17.793405,
        "longitude": -50.932871
      },
      {
        "id": 2332,
        "latitude": -17.328144,
        "longitude": -53.199821
      },
      {
        "id": 2333,
        "latitude": -17.318968,
        "longitude": -53.208859
      },
      {
        "id": 2334,
        "latitude": -17.328144,
        "longitude": -53.199821
      },
      {
        "id": 2335,
        "latitude": -16.486172,
        "longitude": -49.309389
      },
      {
        "id": 2336,
        "latitude": -16.096387,
        "longitude": -48.26438
      },
      {
        "id": 2337,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2391,
        "latitude": -15.0278,
        "longitude": -51.301667
      },
      {
        "id": 2429,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2437,
        "latitude": -16.050371,
        "longitude": -47.984394
      },
      {
        "id": 2445,
        "latitude": -16.052237,
        "longitude": -48.105803
      },
      {
        "id": 2446,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2447,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2451,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2452,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2453,
        "latitude": -15.946542,
        "longitude": -48.261653
      },
      {
        "id": 2454,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 2457,
        "latitude": -15.933428,
        "longitude": -49.260833
      },
      {
        "id": 2458,
        "latitude": -14.704345,
        "longitude": -47.520687
      },
      {
        "id": 2459,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 2460,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 2461,
        "latitude": -14.704345,
        "longitude": -47.520687
      },
      {
        "id": 2462,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 2463,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 2464,
        "latitude": -14.704345,
        "longitude": -47.520687
      },
      {
        "id": 2466,
        "latitude": -16.654992,
        "longitude": -49.48762
      },
      {
        "id": 2467,
        "latitude": -16.636536,
        "longitude": -49.500653
      },
      {
        "id": 2468,
        "latitude": -16.650155,
        "longitude": -49.495697
      },
      {
        "id": 2469,
        "latitude": -15.035,
        "longitude": -51.294665
      },
      {
        "id": 2470,
        "latitude": -16.63024,
        "longitude": -49.403736
      },
      {
        "id": 2471,
        "latitude": -16.650155,
        "longitude": -49.495697
      },
      {
        "id": 2472,
        "latitude": -16.650155,
        "longitude": -49.495697
      },
      {
        "id": 2473,
        "latitude": -14.524416,
        "longitude": -49.151002
      },
      {
        "id": 2479,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2482,
        "latitude": -16.071211,
        "longitude": -47.980804
      },
      {
        "id": 2490,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2491,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2494,
        "latitude": -16.081843,
        "longitude": -47.996252
      },
      {
        "id": 2495,
        "latitude": -22.906803,
        "longitude": -43.265628
      },
      {
        "id": 2496,
        "latitude": -16.078213,
        "longitude": -47.984417
      },
      {
        "id": 2497,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2498,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2501,
        "latitude": -16.078641,
        "longitude": -47.970357
      },
      {
        "id": 2503,
        "latitude": -29.694076,
        "longitude": -53.815593
      },
      {
        "id": 2504,
        "latitude": -16.071806,
        "longitude": -47.975816
      },
      {
        "id": 2509,
        "latitude": -21.27472,
        "longitude": -50.469971
      },
      {
        "id": 2511,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2512,
        "latitude": -16.066783,
        "longitude": -48.017416
      },
      {
        "id": 2516,
        "latitude": -16.063211,
        "longitude": -47.998361
      },
      {
        "id": 2519,
        "latitude": -19.779593,
        "longitude": -43.900551
      },
      {
        "id": 2525,
        "latitude": -22.901406,
        "longitude": -43.189358
      },
      {
        "id": 2528,
        "latitude": -15.0194,
        "longitude": -51.304632
      },
      {
        "id": 2537,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2538,
        "latitude": -16.053618,
        "longitude": -47.966076
      },
      {
        "id": 2541,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2547,
        "latitude": -16.071668,
        "longitude": -47.980756
      },
      {
        "id": 2548,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2549,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2550,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2551,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2552,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2553,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2554,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2556,
        "latitude": -17.90212,
        "longitude": -51.730451
      },
      {
        "id": 2557,
        "latitude": -16.060443,
        "longitude": -48.001427
      },
      {
        "id": 2559,
        "latitude": -15.038168,
        "longitude": -51.290815
      },
      {
        "id": 2560,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2561,
        "latitude": -16.072856,
        "longitude": -48.012988
      },
      {
        "id": 2733,
        "latitude": -5.81789,
        "longitude": -46.159338
      },
      {
        "id": 2571,
        "latitude": -16.071668,
        "longitude": -47.980756
      },
      {
        "id": 2572,
        "latitude": -16.118444,
        "longitude": -47.966983
      },
      {
        "id": 2573,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2574,
        "latitude": -16.744957,
        "longitude": -49.192607
      },
      {
        "id": 2576,
        "latitude": -16.074431,
        "longitude": -47.990725
      },
      {
        "id": 2577,
        "latitude": -16.10839,
        "longitude": -47.974346
      },
      {
        "id": 2578,
        "latitude": -17.727651,
        "longitude": -48.169627
      },
      {
        "id": 2579,
        "latitude": -22.700745,
        "longitude": -43.273027
      },
      {
        "id": 2582,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2583,
        "latitude": -23.539118,
        "longitude": -46.635594
      },
      {
        "id": 2584,
        "latitude": -16.074617,
        "longitude": -47.997235
      },
      {
        "id": 2585,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2586,
        "latitude": -15.448771,
        "longitude": -49.516586
      },
      {
        "id": 2587,
        "latitude": -16.073132,
        "longitude": -47.998723
      },
      {
        "id": 2588,
        "latitude": -15.858721,
        "longitude": -50.867966
      },
      {
        "id": 2590,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2591,
        "latitude": -16.068382,
        "longitude": -47.981973
      },
      {
        "id": 2592,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2593,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2594,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2595,
        "latitude": -16.065933,
        "longitude": -48.020442
      },
      {
        "id": 2596,
        "latitude": -22.901406,
        "longitude": -43.189358
      },
      {
        "id": 2597,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2598,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2599,
        "latitude": -18.007274,
        "longitude": -49.363695
      },
      {
        "id": 2600,
        "latitude": -16.066757,
        "longitude": -47.995523
      },
      {
        "id": 2601,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2602,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2603,
        "latitude": -16.659741,
        "longitude": -49.289827
      },
      {
        "id": 2605,
        "latitude": -16.618619,
        "longitude": -49.219219
      },
      {
        "id": 2606,
        "latitude": -17.381758,
        "longitude": -50.375036
      },
      {
        "id": 2607,
        "latitude": -27.548819,
        "longitude": -48.494515
      },
      {
        "id": 2608,
        "latitude": -22.86022,
        "longitude": -43.998917
      },
      {
        "id": 2609,
        "latitude": -16.10839,
        "longitude": -47.974346
      },
      {
        "id": 2611,
        "latitude": -16.069441,
        "longitude": -47.983301
      },
      {
        "id": 2612,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2613,
        "latitude": -16.074617,
        "longitude": -47.997235
      },
      {
        "id": 2614,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2616,
        "latitude": -16.070201,
        "longitude": -47.975608
      },
      {
        "id": 2617,
        "latitude": -16.619272,
        "longitude": -48.746778
      },
      {
        "id": 2618,
        "latitude": -16.069357,
        "longitude": -47.982793
      },
      {
        "id": 2619,
        "latitude": -16.074617,
        "longitude": -47.997235
      },
      {
        "id": 2620,
        "latitude": -16.069357,
        "longitude": -47.982793
      },
      {
        "id": 2621,
        "latitude": -16.081903,
        "longitude": -47.974247
      },
      {
        "id": 2622,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2623,
        "latitude": -16.083094,
        "longitude": -47.972893
      },
      {
        "id": 2624,
        "latitude": -16.066785,
        "longitude": -48.01236
      },
      {
        "id": 2625,
        "latitude": -16.083579,
        "longitude": -47.975385
      },
      {
        "id": 2627,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2628,
        "latitude": -16.054064,
        "longitude": -47.979337
      },
      {
        "id": 2629,
        "latitude": -16.071211,
        "longitude": -47.980804
      },
      {
        "id": 2631,
        "latitude": -16.069191,
        "longitude": -47.973091
      },
      {
        "id": 2632,
        "latitude": -16.081903,
        "longitude": -47.974247
      },
      {
        "id": 2633,
        "latitude": -16.071211,
        "longitude": -47.980804
      },
      {
        "id": 2634,
        "latitude": -16.07098,
        "longitude": -47.98238
      },
      {
        "id": 2635,
        "latitude": -16.071211,
        "longitude": -47.980804
      },
      {
        "id": 2636,
        "latitude": -22.906803,
        "longitude": -43.265628
      },
      {
        "id": 2637,
        "latitude": -16.073653,
        "longitude": -47.988256
      },
      {
        "id": 2638,
        "latitude": -12.239498,
        "longitude": -38.958677
      },
      {
        "id": 2640,
        "latitude": -15.88575,
        "longitude": -50.758045
      },
      {
        "id": 2641,
        "latitude": -16.073927,
        "longitude": -47.988435
      },
      {
        "id": 2645,
        "latitude": -17.906298,
        "longitude": -51.716757
      },
      {
        "id": 2646,
        "latitude": -16.055357,
        "longitude": -47.967586
      },
      {
        "id": 2647,
        "latitude": -16.067046,
        "longitude": -47.998511
      },
      {
        "id": 2648,
        "latitude": -16.074617,
        "longitude": -47.997235
      },
      {
        "id": 2649,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2650,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2651,
        "latitude": -16.332532,
        "longitude": -48.0194
      },
      {
        "id": 2652,
        "latitude": -16.069191,
        "longitude": -47.973091
      },
      {
        "id": 2653,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2655,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2656,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2657,
        "latitude": -16.619362,
        "longitude": -48.74349
      },
      {
        "id": 2658,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2660,
        "latitude": -16.068249,
        "longitude": -47.983021
      },
      {
        "id": 2661,
        "latitude": -18.006421,
        "longitude": -49.369036
      },
      {
        "id": 2663,
        "latitude": -15.870435,
        "longitude": -47.813071
      },
      {
        "id": 2664,
        "latitude": -16.059385,
        "longitude": -47.972923
      },
      {
        "id": 2665,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2666,
        "latitude": -16.060443,
        "longitude": -48.001427
      },
      {
        "id": 2667,
        "latitude": -16.073365,
        "longitude": -47.974278
      },
      {
        "id": 2668,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2669,
        "latitude": -16.690665,
        "longitude": -49.096782
      },
      {
        "id": 2670,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2672,
        "latitude": -16.081197,
        "longitude": -47.975973
      },
      {
        "id": 2673,
        "latitude": -16.07098,
        "longitude": -47.98238
      },
      {
        "id": 2674,
        "latitude": -16.086952,
        "longitude": -47.985185
      },
      {
        "id": 2675,
        "latitude": -17.709834,
        "longitude": -48.157397
      },
      {
        "id": 2676,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2677,
        "latitude": -18.180546,
        "longitude": -47.949452
      },
      {
        "id": 2678,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2679,
        "latitude": -16.069441,
        "longitude": -47.983301
      },
      {
        "id": 2681,
        "latitude": -16.052486,
        "longitude": -47.971108
      },
      {
        "id": 2683,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2684,
        "latitude": -16.10839,
        "longitude": -47.974346
      },
      {
        "id": 2685,
        "latitude": -16.086952,
        "longitude": -47.985185
      },
      {
        "id": 2686,
        "latitude": -16.071617,
        "longitude": -47.990402
      },
      {
        "id": 2689,
        "latitude": -18.006887,
        "longitude": -49.364728
      },
      {
        "id": 2690,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2692,
        "latitude": -18.163702,
        "longitude": -47.955127
      },
      {
        "id": 2695,
        "latitude": -16.747638,
        "longitude": -49.274089
      },
      {
        "id": 2696,
        "latitude": -18.006421,
        "longitude": -49.369036
      },
      {
        "id": 2697,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2700,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2701,
        "latitude": -16.069984,
        "longitude": -47.974153
      },
      {
        "id": 2702,
        "latitude": -16.072898,
        "longitude": -48.009333
      },
      {
        "id": 2703,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2704,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2706,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2707,
        "latitude": -16.068249,
        "longitude": -47.983021
      },
      {
        "id": 2708,
        "latitude": -21.748686,
        "longitude": -43.392551
      },
      {
        "id": 2709,
        "latitude": -16.055357,
        "longitude": -47.967586
      },
      {
        "id": 2710,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2711,
        "latitude": -29.694076,
        "longitude": -53.815593
      },
      {
        "id": 2712,
        "latitude": -16.068639,
        "longitude": -47.976491
      },
      {
        "id": 2713,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 2714,
        "latitude": -4.944691,
        "longitude": -47.491238
      },
      {
        "id": 2715,
        "latitude": -4.944691,
        "longitude": -47.491238
      },
      {
        "id": 2716,
        "latitude": -4.944691,
        "longitude": -47.491238
      },
      {
        "id": 2717,
        "latitude": -4.9119,
        "longitude": -47.404009
      },
      {
        "id": 2718,
        "latitude": -7.519798,
        "longitude": -46.04508
      },
      {
        "id": 2719,
        "latitude": -7.519798,
        "longitude": -46.04508
      },
      {
        "id": 2720,
        "latitude": -7.519798,
        "longitude": -46.04508
      },
      {
        "id": 2721,
        "latitude": -4.323079,
        "longitude": -46.452867
      },
      {
        "id": 2722,
        "latitude": -4.318925,
        "longitude": -46.454652
      },
      {
        "id": 2723,
        "latitude": -4.730401,
        "longitude": -44.324906
      },
      {
        "id": 2724,
        "latitude": -4.258894,
        "longitude": -43.014215
      },
      {
        "id": 2725,
        "latitude": -6.025539,
        "longitude": -44.241833
      },
      {
        "id": 2726,
        "latitude": -6.559119,
        "longitude": -47.44248
      },
      {
        "id": 2727,
        "latitude": -6.558302,
        "longitude": -47.443221
      },
      {
        "id": 2728,
        "latitude": -5.018321,
        "longitude": -44.264544
      },
      {
        "id": 2729,
        "latitude": -5.018321,
        "longitude": -44.264544
      },
      {
        "id": 2730,
        "latitude": -5.018321,
        "longitude": -44.264544
      },
      {
        "id": 2731,
        "latitude": -5.018321,
        "longitude": -44.264544
      },
      {
        "id": 2732,
        "latitude": -2.133416,
        "longitude": -45.890983
      },
      {
        "id": 2738,
        "latitude": -3.485992,
        "longitude": -42.56292
      },
      {
        "id": 2739,
        "latitude": -2.045859,
        "longitude": -45.959949
      },
      {
        "id": 2740,
        "latitude": -2.045859,
        "longitude": -45.959949
      },
      {
        "id": 2741,
        "latitude": -3.485948,
        "longitude": -45.25197
      },
      {
        "id": 2742,
        "latitude": -3.649875,
        "longitude": -45.369664
      },
      {
        "id": 2743,
        "latitude": -3.484204,
        "longitude": -45.251547
      },
      {
        "id": 2744,
        "latitude": -2.532029,
        "longitude": -44.103348
      },
      {
        "id": 2745,
        "latitude": -2.532029,
        "longitude": -44.103348
      },
      {
        "id": 2746,
        "latitude": -2.532029,
        "longitude": -44.103348
      },
      {
        "id": 2747,
        "latitude": -2.540325,
        "longitude": -44.130871
      },
      {
        "id": 2748,
        "latitude": -2.540325,
        "longitude": -44.130871
      },
      {
        "id": 2749,
        "latitude": -6.345266,
        "longitude": -47.389614
      },
      {
        "id": 2750,
        "latitude": -2.542888,
        "longitude": -45.776463
      },
      {
        "id": 2751,
        "latitude": -2.542888,
        "longitude": -45.776463
      },
      {
        "id": 2753,
        "latitude": -5.579974,
        "longitude": -44.384162
      },
      {
        "id": 2754,
        "latitude": -2.557655,
        "longitude": -44.180275
      },
      {
        "id": 2755,
        "latitude": -14.775287,
        "longitude": -44.171828
      },
      {
        "id": 2756,
        "latitude": -2.560912,
        "longitude": -44.055951
      },
      {
        "id": 2757,
        "latitude": -2.491985,
        "longitude": -44.303925
      },
      {
        "id": 2758,
        "latitude": -2.479529,
        "longitude": -44.162631
      },
      {
        "id": 2760,
        "latitude": -2.51339,
        "longitude": -44.217123
      },
      {
        "id": 2761,
        "latitude": -2.560912,
        "longitude": -44.055951
      },
      {
        "id": 2762,
        "latitude": -2.506785,
        "longitude": -44.226341
      },
      {
        "id": 2763,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 2764,
        "latitude": -2.560912,
        "longitude": -44.055951
      },
      {
        "id": 2765,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 2766,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 2767,
        "latitude": -2.560912,
        "longitude": -44.055951
      },
      {
        "id": 2769,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 2771,
        "latitude": -2.548218,
        "longitude": -44.201127
      },
      {
        "id": 3362,
        "latitude": -20.079459,
        "longitude": -44.588078
      },
      {
        "id": 2772,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 2773,
        "latitude": -2.51667,
        "longitude": -44.26667
      },
      {
        "id": 2774,
        "latitude": -2.548535,
        "longitude": -44.11028
      },
      {
        "id": 2775,
        "latitude": -2.559682,
        "longitude": -44.205831
      },
      {
        "id": 2776,
        "latitude": -2.562236,
        "longitude": -44.203224
      },
      {
        "id": 2777,
        "latitude": -2.51339,
        "longitude": -44.217123
      },
      {
        "id": 2778,
        "latitude": -22.766661,
        "longitude": -43.330834
      },
      {
        "id": 2779,
        "latitude": -2.557905,
        "longitude": -44.200885
      },
      {
        "id": 2780,
        "latitude": -2.51339,
        "longitude": -44.217123
      },
      {
        "id": 2782,
        "latitude": -2.508292,
        "longitude": -44.295096
      },
      {
        "id": 2783,
        "latitude": -2.527392,
        "longitude": -44.277191
      },
      {
        "id": 2784,
        "latitude": -2.518457,
        "longitude": -44.26509
      },
      {
        "id": 2785,
        "latitude": -2.540681,
        "longitude": -44.220781
      },
      {
        "id": 2786,
        "latitude": -2.596203,
        "longitude": -44.217395
      },
      {
        "id": 2787,
        "latitude": -2.754062,
        "longitude": -44.349649
      },
      {
        "id": 2788,
        "latitude": -2.527392,
        "longitude": -44.277191
      },
      {
        "id": 2789,
        "latitude": -4.034833,
        "longitude": -44.468636
      },
      {
        "id": 2790,
        "latitude": -5.261845,
        "longitude": -44.05802
      },
      {
        "id": 2791,
        "latitude": -1.666047,
        "longitude": -45.375551
      },
      {
        "id": 2792,
        "latitude": -1.666047,
        "longitude": -45.375551
      },
      {
        "id": 2793,
        "latitude": -1.666047,
        "longitude": -45.375551
      },
      {
        "id": 2794,
        "latitude": -3.201888,
        "longitude": -44.994528
      },
      {
        "id": 2796,
        "latitude": -14.194553,
        "longitude": -49.345059
      },
      {
        "id": 2797,
        "latitude": -15.068371,
        "longitude": -57.185369
      },
      {
        "id": 2798,
        "latitude": -15.068371,
        "longitude": -57.185369
      },
      {
        "id": 2799,
        "latitude": -15.891603,
        "longitude": -52.261541
      },
      {
        "id": 2800,
        "latitude": -16.104217,
        "longitude": -57.692721
      },
      {
        "id": 2801,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 2802,
        "latitude": -15.598669,
        "longitude": -56.09913
      },
      {
        "id": 2803,
        "latitude": -15.598669,
        "longitude": -56.09913
      },
      {
        "id": 2804,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 2805,
        "latitude": -15.598669,
        "longitude": -56.09913
      },
      {
        "id": 2806,
        "latitude": -15.598669,
        "longitude": -56.09913
      },
      {
        "id": 2807,
        "latitude": -15.647883,
        "longitude": -56.030009
      },
      {
        "id": 2808,
        "latitude": -15.598669,
        "longitude": -56.09913
      },
      {
        "id": 2809,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 2810,
        "latitude": -15.631001,
        "longitude": -56.055236
      },
      {
        "id": 2811,
        "latitude": -15.654131,
        "longitude": -56.068964
      },
      {
        "id": 2812,
        "latitude": -16.013263,
        "longitude": -54.924617
      },
      {
        "id": 2813,
        "latitude": -15.624996,
        "longitude": -56.050101
      },
      {
        "id": 2814,
        "latitude": -15.631001,
        "longitude": -56.055236
      },
      {
        "id": 2815,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 2816,
        "latitude": -15.631001,
        "longitude": -56.055236
      },
      {
        "id": 2817,
        "latitude": -15.663831,
        "longitude": -56.135602
      },
      {
        "id": 2818,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 2819,
        "latitude": -15.598669,
        "longitude": -56.09913
      },
      {
        "id": 2820,
        "latitude": -15.617354,
        "longitude": -56.111784
      },
      {
        "id": 2821,
        "latitude": -15.6289,
        "longitude": -56.047067
      },
      {
        "id": 2822,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 2823,
        "latitude": -14.728987,
        "longitude": -57.049077
      },
      {
        "id": 2824,
        "latitude": -14.387458,
        "longitude": -56.41757
      },
      {
        "id": 2825,
        "latitude": -11.418977,
        "longitude": -58.755334
      },
      {
        "id": 2826,
        "latitude": -13.086422,
        "longitude": -55.928748
      },
      {
        "id": 2827,
        "latitude": -15.693565,
        "longitude": -58.098704
      },
      {
        "id": 2828,
        "latitude": -14.452591,
        "longitude": -56.797655
      },
      {
        "id": 2830,
        "latitude": -14.904375,
        "longitude": -53.011741
      },
      {
        "id": 2831,
        "latitude": -16.616886,
        "longitude": -54.480073
      },
      {
        "id": 2833,
        "latitude": -16.623002,
        "longitude": -54.476123
      },
      {
        "id": 2834,
        "latitude": -16.262369,
        "longitude": -56.629626
      },
      {
        "id": 2835,
        "latitude": -17.593428,
        "longitude": -46.277615
      },
      {
        "id": 2836,
        "latitude": -16.262369,
        "longitude": -56.629626
      },
      {
        "id": 2837,
        "latitude": -16.262369,
        "longitude": -56.629626
      },
      {
        "id": 2838,
        "latitude": -15.857789,
        "longitude": -58.462821
      },
      {
        "id": 2839,
        "latitude": -15.857789,
        "longitude": -58.462821
      },
      {
        "id": 2840,
        "latitude": -13.446893,
        "longitude": -56.731498
      },
      {
        "id": 2841,
        "latitude": -11.887781,
        "longitude": -55.513002
      },
      {
        "id": 2842,
        "latitude": -15.64762,
        "longitude": -56.157313
      },
      {
        "id": 2843,
        "latitude": -15.663965,
        "longitude": -56.125287
      },
      {
        "id": 2844,
        "latitude": -15.645816,
        "longitude": -56.132218
      },
      {
        "id": 2846,
        "latitude": -15.64762,
        "longitude": -56.157313
      },
      {
        "id": 2847,
        "latitude": -15.64762,
        "longitude": -56.157313
      },
      {
        "id": 2848,
        "latitude": -20.438576,
        "longitude": -52.87975
      },
      {
        "id": 2849,
        "latitude": -20.487993,
        "longitude": -55.798232
      },
      {
        "id": 2850,
        "latitude": -20.494961,
        "longitude": -55.821297
      },
      {
        "id": 2851,
        "latitude": -21.70692,
        "longitude": -52.42428
      },
      {
        "id": 2852,
        "latitude": -19.52961,
        "longitude": -54.044419
      },
      {
        "id": 2853,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2854,
        "latitude": -20.552805,
        "longitude": -54.645014
      },
      {
        "id": 2855,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2856,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2857,
        "latitude": -20.510702,
        "longitude": -54.653595
      },
      {
        "id": 2858,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2859,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2860,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2861,
        "latitude": -20.496578,
        "longitude": -54.689683
      },
      {
        "id": 2862,
        "latitude": -20.557054,
        "longitude": -54.621811
      },
      {
        "id": 2863,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2864,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2865,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2866,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2867,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2868,
        "latitude": -20.542573,
        "longitude": -54.652823
      },
      {
        "id": 2869,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 2870,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 2871,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2872,
        "latitude": -20.494392,
        "longitude": -54.676915
      },
      {
        "id": 2873,
        "latitude": -20.542355,
        "longitude": -54.630839
      },
      {
        "id": 2874,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2875,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2960,
        "latitude": -22.378434,
        "longitude": -54.512567
      },
      {
        "id": 2876,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 2877,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 2878,
        "latitude": -20.487954,
        "longitude": -54.643942
      },
      {
        "id": 2879,
        "latitude": -20.542573,
        "longitude": -54.652823
      },
      {
        "id": 2880,
        "latitude": -20.5375,
        "longitude": -54.657025
      },
      {
        "id": 2881,
        "latitude": -20.503094,
        "longitude": -54.664102
      },
      {
        "id": 2882,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2883,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2884,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2885,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2886,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2887,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2888,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2889,
        "latitude": -20.5103,
        "longitude": -54.66362
      },
      {
        "id": 2890,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2891,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2892,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2893,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2894,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2895,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2896,
        "latitude": -20.543024,
        "longitude": -54.673301
      },
      {
        "id": 2897,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2898,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2899,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2900,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2901,
        "latitude": -29.657884,
        "longitude": -51.001423
      },
      {
        "id": 2903,
        "latitude": -20.556643,
        "longitude": -54.602366
      },
      {
        "id": 2904,
        "latitude": -20.556643,
        "longitude": -54.602366
      },
      {
        "id": 2906,
        "latitude": -20.473106,
        "longitude": -54.615076
      },
      {
        "id": 2907,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2908,
        "latitude": -20.559281,
        "longitude": -54.604627
      },
      {
        "id": 2909,
        "latitude": -20.521642,
        "longitude": -54.677976
      },
      {
        "id": 2910,
        "latitude": -20.55745,
        "longitude": -54.608785
      },
      {
        "id": 2911,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2912,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2913,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2914,
        "latitude": -20.544191,
        "longitude": -54.653581
      },
      {
        "id": 2915,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2916,
        "latitude": -20.413208,
        "longitude": -54.595403
      },
      {
        "id": 2917,
        "latitude": -20.435005,
        "longitude": -54.61933
      },
      {
        "id": 2918,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2919,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 2920,
        "latitude": -20.983647,
        "longitude": -54.494569
      },
      {
        "id": 2921,
        "latitude": -13.651651,
        "longitude": -57.886183
      },
      {
        "id": 2922,
        "latitude": -20.460104,
        "longitude": -54.540187
      },
      {
        "id": 2923,
        "latitude": -20.522143,
        "longitude": -54.668561
      },
      {
        "id": 2924,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2925,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 2926,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2927,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2928,
        "latitude": -20.493761,
        "longitude": -54.666751
      },
      {
        "id": 2929,
        "latitude": -20.551536,
        "longitude": -54.628653
      },
      {
        "id": 2930,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2931,
        "latitude": -20.523515,
        "longitude": -54.672245
      },
      {
        "id": 2932,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 2933,
        "latitude": -20.464017,
        "longitude": -54.616295
      },
      {
        "id": 2934,
        "latitude": -19.113791,
        "longitude": -51.737434
      },
      {
        "id": 2935,
        "latitude": -18.793879,
        "longitude": -52.62224
      },
      {
        "id": 2936,
        "latitude": -18.793879,
        "longitude": -52.62224
      },
      {
        "id": 2938,
        "latitude": -19.026277,
        "longitude": -57.652974
      },
      {
        "id": 2939,
        "latitude": -18.533386,
        "longitude": -53.139429
      },
      {
        "id": 2940,
        "latitude": -23.41667,
        "longitude": -54.65
      },
      {
        "id": 2941,
        "latitude": -18.501585,
        "longitude": -54.722716
      },
      {
        "id": 2942,
        "latitude": -18.501585,
        "longitude": -54.722716
      },
      {
        "id": 2943,
        "latitude": -22.205668,
        "longitude": -54.841197
      },
      {
        "id": 2944,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2945,
        "latitude": -15.537902,
        "longitude": -54.308208
      },
      {
        "id": 2946,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2947,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2948,
        "latitude": -22.204256,
        "longitude": -54.654231
      },
      {
        "id": 2950,
        "latitude": -22.220615,
        "longitude": -54.812208
      },
      {
        "id": 2952,
        "latitude": -22.220615,
        "longitude": -54.812208
      },
      {
        "id": 2953,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2954,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2955,
        "latitude": -22.248014,
        "longitude": -54.837893
      },
      {
        "id": 2956,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2957,
        "latitude": -22.228108,
        "longitude": -54.796928
      },
      {
        "id": 2958,
        "latitude": -22.237277,
        "longitude": -54.845752
      },
      {
        "id": 2962,
        "latitude": -23.480146,
        "longitude": -54.182968
      },
      {
        "id": 2963,
        "latitude": -23.056886,
        "longitude": -54.188131
      },
      {
        "id": 2964,
        "latitude": -23.062215,
        "longitude": -54.201832
      },
      {
        "id": 2965,
        "latitude": -22.240024,
        "longitude": -53.352453
      },
      {
        "id": 2966,
        "latitude": -21.458727,
        "longitude": -54.376063
      },
      {
        "id": 2967,
        "latitude": -19.673601,
        "longitude": -51.176628
      },
      {
        "id": 2968,
        "latitude": -21.804892,
        "longitude": -54.541423
      },
      {
        "id": 2969,
        "latitude": -20.360518,
        "longitude": -51.427147
      },
      {
        "id": 2970,
        "latitude": -20.78668,
        "longitude": -51.706125
      },
      {
        "id": 2971,
        "latitude": -20.790108,
        "longitude": -51.704595
      },
      {
        "id": 2972,
        "latitude": -20.790108,
        "longitude": -51.704595
      },
      {
        "id": 2973,
        "latitude": -21.44545,
        "longitude": -45.95769
      },
      {
        "id": 2974,
        "latitude": -21.434746,
        "longitude": -45.944298
      },
      {
        "id": 2975,
        "latitude": -16.1732,
        "longitude": -40.691715
      },
      {
        "id": 2976,
        "latitude": -22.069341,
        "longitude": -46.570758
      },
      {
        "id": 2978,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2979,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2980,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2981,
        "latitude": -18.659136,
        "longitude": -48.214801
      },
      {
        "id": 2982,
        "latitude": -18.661374,
        "longitude": -48.220679
      },
      {
        "id": 2983,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2984,
        "latitude": -18.651126,
        "longitude": -48.193376
      },
      {
        "id": 2985,
        "latitude": -18.623358,
        "longitude": -48.216732
      },
      {
        "id": 2986,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2987,
        "latitude": -18.651126,
        "longitude": -48.193376
      },
      {
        "id": 2988,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2989,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 2990,
        "latitude": -17.880228,
        "longitude": -41.308878
      },
      {
        "id": 2991,
        "latitude": -20.280107,
        "longitude": -45.542885
      },
      {
        "id": 2992,
        "latitude": -19.591535,
        "longitude": -43.861509
      },
      {
        "id": 2993,
        "latitude": -19.293488,
        "longitude": -43.953638
      },
      {
        "id": 2997,
        "latitude": -19.597694,
        "longitude": -43.904833
      },
      {
        "id": 2999,
        "latitude": -20.015762,
        "longitude": -45.98403
      },
      {
        "id": 3000,
        "latitude": -21.222236,
        "longitude": -43.77206
      },
      {
        "id": 3001,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3003,
        "latitude": -19.803583,
        "longitude": -43.960099
      },
      {
        "id": 3004,
        "latitude": -19.834226,
        "longitude": -43.9146
      },
      {
        "id": 3005,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3006,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3007,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3008,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3010,
        "latitude": -19.870433,
        "longitude": -43.979739
      },
      {
        "id": 3011,
        "latitude": -19.836477,
        "longitude": -43.932536
      },
      {
        "id": 3012,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3013,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3014,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3015,
        "latitude": -19.877155,
        "longitude": -43.994265
      },
      {
        "id": 3018,
        "latitude": -19.989628,
        "longitude": -43.990614
      },
      {
        "id": 3019,
        "latitude": -19.877279,
        "longitude": -44.009028
      },
      {
        "id": 3020,
        "latitude": -19.858205,
        "longitude": -43.890508
      },
      {
        "id": 3021,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3022,
        "latitude": -19.883019,
        "longitude": -44.005321
      },
      {
        "id": 3023,
        "latitude": -20.003335,
        "longitude": -44.005607
      },
      {
        "id": 3026,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3027,
        "latitude": -19.806429,
        "longitude": -43.987233
      },
      {
        "id": 3028,
        "latitude": -19.888171,
        "longitude": -44.000769
      },
      {
        "id": 3029,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3030,
        "latitude": -19.710245,
        "longitude": -43.991268
      },
      {
        "id": 3031,
        "latitude": -19.845171,
        "longitude": -43.906767
      },
      {
        "id": 3032,
        "latitude": -19.873248,
        "longitude": -43.991126
      },
      {
        "id": 3033,
        "latitude": -19.971183,
        "longitude": -43.969446
      },
      {
        "id": 3034,
        "latitude": -19.97112,
        "longitude": -43.973972
      },
      {
        "id": 3035,
        "latitude": -19.838243,
        "longitude": -43.958743
      },
      {
        "id": 3036,
        "latitude": -19.832004,
        "longitude": -43.945525
      },
      {
        "id": 3037,
        "latitude": -19.918929,
        "longitude": -43.965754
      },
      {
        "id": 3038,
        "latitude": -19.874988,
        "longitude": -43.993564
      },
      {
        "id": 3039,
        "latitude": -19.810292,
        "longitude": -43.975438
      },
      {
        "id": 3040,
        "latitude": -20.003335,
        "longitude": -44.005607
      },
      {
        "id": 3041,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3042,
        "latitude": -21.285503,
        "longitude": -44.763438
      },
      {
        "id": 3043,
        "latitude": -19.934989,
        "longitude": -44.00634
      },
      {
        "id": 3044,
        "latitude": -19.874988,
        "longitude": -43.993564
      },
      {
        "id": 3045,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3047,
        "latitude": -19.932418,
        "longitude": -44.023375
      },
      {
        "id": 3048,
        "latitude": -19.838147,
        "longitude": -43.891991
      },
      {
        "id": 3049,
        "latitude": -19.930175,
        "longitude": -44.018605
      },
      {
        "id": 3050,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3051,
        "latitude": -19.931469,
        "longitude": -44.015982
      },
      {
        "id": 3052,
        "latitude": -19.845437,
        "longitude": -43.97231
      },
      {
        "id": 3053,
        "latitude": -19.829615,
        "longitude": -43.993112
      },
      {
        "id": 3054,
        "latitude": -19.932418,
        "longitude": -44.023375
      },
      {
        "id": 3055,
        "latitude": -19.859885,
        "longitude": -43.921112
      },
      {
        "id": 3056,
        "latitude": -19.931469,
        "longitude": -44.015982
      },
      {
        "id": 3057,
        "latitude": -19.882403,
        "longitude": -43.951465
      },
      {
        "id": 3058,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3059,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3060,
        "latitude": -19.922912,
        "longitude": -44.008454
      },
      {
        "id": 3061,
        "latitude": -19.922578,
        "longitude": -43.945136
      },
      {
        "id": 3062,
        "latitude": -19.982043,
        "longitude": -44.005452
      },
      {
        "id": 3063,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 3064,
        "latitude": -19.88023,
        "longitude": -43.919815
      },
      {
        "id": 3065,
        "latitude": -19.846223,
        "longitude": -43.916263
      },
      {
        "id": 3066,
        "latitude": -19.949922,
        "longitude": -44.146234
      },
      {
        "id": 5216,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 3067,
        "latitude": -19.944885,
        "longitude": -44.164594
      },
      {
        "id": 3068,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3069,
        "latitude": -19.955908,
        "longitude": -44.158924
      },
      {
        "id": 3070,
        "latitude": -19.993195,
        "longitude": -44.15323
      },
      {
        "id": 3071,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3072,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3073,
        "latitude": -19.948205,
        "longitude": -44.195391
      },
      {
        "id": 3074,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3075,
        "latitude": -20.019096,
        "longitude": -44.231963
      },
      {
        "id": 3076,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3077,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3078,
        "latitude": -19.949593,
        "longitude": -44.161662
      },
      {
        "id": 3079,
        "latitude": -19.930108,
        "longitude": -44.202406
      },
      {
        "id": 3080,
        "latitude": -19.949922,
        "longitude": -44.146234
      },
      {
        "id": 3081,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3082,
        "latitude": -19.832756,
        "longitude": -43.974051
      },
      {
        "id": 3083,
        "latitude": -19.949922,
        "longitude": -44.146234
      },
      {
        "id": 3084,
        "latitude": -19.947602,
        "longitude": -44.129572
      },
      {
        "id": 3085,
        "latitude": -20.0483,
        "longitude": -44.27258
      },
      {
        "id": 3086,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3087,
        "latitude": -19.949922,
        "longitude": -44.146234
      },
      {
        "id": 3088,
        "latitude": -19.931583,
        "longitude": -44.132631
      },
      {
        "id": 3089,
        "latitude": -19.950673,
        "longitude": -43.960006
      },
      {
        "id": 3090,
        "latitude": -15.546705,
        "longitude": -55.175012
      },
      {
        "id": 3091,
        "latitude": -20.025536,
        "longitude": -44.222046
      },
      {
        "id": 3092,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3093,
        "latitude": -19.93599,
        "longitude": -44.102616
      },
      {
        "id": 3094,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3095,
        "latitude": -19.50089,
        "longitude": -42.575252
      },
      {
        "id": 3096,
        "latitude": -19.832628,
        "longitude": -43.916314
      },
      {
        "id": 3097,
        "latitude": -17.119138,
        "longitude": -43.852308
      },
      {
        "id": 3098,
        "latitude": -19.859554,
        "longitude": -44.158731
      },
      {
        "id": 3099,
        "latitude": -19.859554,
        "longitude": -44.158731
      },
      {
        "id": 3100,
        "latitude": -19.935795,
        "longitude": -44.114741
      },
      {
        "id": 3101,
        "latitude": -19.975859,
        "longitude": -44.228419
      },
      {
        "id": 3102,
        "latitude": -19.934435,
        "longitude": -44.099759
      },
      {
        "id": 3103,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3104,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3105,
        "latitude": -19.956679,
        "longitude": -44.178225
      },
      {
        "id": 3106,
        "latitude": -19.946147,
        "longitude": -44.135528
      },
      {
        "id": 3107,
        "latitude": -19.931583,
        "longitude": -44.132631
      },
      {
        "id": 3108,
        "latitude": -19.998386,
        "longitude": -44.133685
      },
      {
        "id": 3109,
        "latitude": -19.953901,
        "longitude": -44.140089
      },
      {
        "id": 3110,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3111,
        "latitude": -19.948205,
        "longitude": -44.195391
      },
      {
        "id": 3112,
        "latitude": -19.961062,
        "longitude": -44.175732
      },
      {
        "id": 3113,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3114,
        "latitude": -19.956679,
        "longitude": -44.178225
      },
      {
        "id": 3115,
        "latitude": -19.940612,
        "longitude": -44.104681
      },
      {
        "id": 3116,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 3117,
        "latitude": -19.968056,
        "longitude": -44.198333
      },
      {
        "id": 3118,
        "latitude": -19.934435,
        "longitude": -44.099759
      },
      {
        "id": 3119,
        "latitude": -19.949922,
        "longitude": -44.146234
      },
      {
        "id": 3120,
        "latitude": -19.944885,
        "longitude": -44.164594
      },
      {
        "id": 3122,
        "latitude": -19.931583,
        "longitude": -44.132631
      },
      {
        "id": 3123,
        "latitude": -19.963388,
        "longitude": -44.175876
      },
      {
        "id": 3124,
        "latitude": -19.962486,
        "longitude": -44.131078
      },
      {
        "id": 3125,
        "latitude": -19.981428,
        "longitude": -44.202341
      },
      {
        "id": 3126,
        "latitude": -21.094955,
        "longitude": -45.557001
      },
      {
        "id": 3127,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3128,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3129,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3130,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3131,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3133,
        "latitude": -19.74309,
        "longitude": -45.253657
      },
      {
        "id": 3134,
        "latitude": -19.732439,
        "longitude": -45.276763
      },
      {
        "id": 3135,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3136,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3137,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3138,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3139,
        "latitude": -19.754248,
        "longitude": -45.257672
      },
      {
        "id": 3140,
        "latitude": -19.748406,
        "longitude": -45.281721
      },
      {
        "id": 3141,
        "latitude": -17.011811,
        "longitude": -46.010573
      },
      {
        "id": 3142,
        "latitude": -19.427188,
        "longitude": -42.257847
      },
      {
        "id": 3143,
        "latitude": -19.425769,
        "longitude": -42.2556
      },
      {
        "id": 3144,
        "latitude": -15.621389,
        "longitude": -46.423889
      },
      {
        "id": 3145,
        "latitude": -22.61225,
        "longitude": -46.057214
      },
      {
        "id": 3146,
        "latitude": -19.534626,
        "longitude": -49.488474
      },
      {
        "id": 3147,
        "latitude": -19.535277,
        "longitude": -49.486389
      },
      {
        "id": 3148,
        "latitude": -19.535277,
        "longitude": -49.486389
      },
      {
        "id": 3149,
        "latitude": -19.767167,
        "longitude": -48.571878
      },
      {
        "id": 3150,
        "latitude": -19.767167,
        "longitude": -48.571878
      },
      {
        "id": 3151,
        "latitude": -20.772327,
        "longitude": -45.278193
      },
      {
        "id": 3153,
        "latitude": -20.952313,
        "longitude": -43.775522
      },
      {
        "id": 3154,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3155,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3156,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3157,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3158,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3159,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3160,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3161,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3162,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3163,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3164,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3165,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 3166,
        "latitude": -18.996622,
        "longitude": -46.309897
      },
      {
        "id": 3167,
        "latitude": -21.105454,
        "longitude": -46.204318
      },
      {
        "id": 3168,
        "latitude": -18.59422,
        "longitude": -49.200319
      },
      {
        "id": 3169,
        "latitude": -18.59422,
        "longitude": -49.200319
      },
      {
        "id": 3170,
        "latitude": -20.431405,
        "longitude": -44.780327
      },
      {
        "id": 3171,
        "latitude": -22.151669,
        "longitude": -46.040545
      },
      {
        "id": 3172,
        "latitude": -18.815539,
        "longitude": -43.678869
      },
      {
        "id": 3173,
        "latitude": -20.659594,
        "longitude": -43.796776
      },
      {
        "id": 3174,
        "latitude": -19.901941,
        "longitude": -44.070015
      },
      {
        "id": 3175,
        "latitude": -19.913275,
        "longitude": -44.084095
      },
      {
        "id": 3176,
        "latitude": -19.913275,
        "longitude": -44.084095
      },
      {
        "id": 3177,
        "latitude": -19.950673,
        "longitude": -43.960006
      },
      {
        "id": 3179,
        "latitude": -19.851778,
        "longitude": -44.151746
      },
      {
        "id": 3180,
        "latitude": -19.893001,
        "longitude": -44.085547
      },
      {
        "id": 3181,
        "latitude": -19.901941,
        "longitude": -44.070015
      },
      {
        "id": 3182,
        "latitude": -21.528638,
        "longitude": -42.6418
      },
      {
        "id": 3183,
        "latitude": -19.886012,
        "longitude": -44.020998
      },
      {
        "id": 3184,
        "latitude": -19.868794,
        "longitude": -44.051022
      },
      {
        "id": 3185,
        "latitude": -19.950859,
        "longitude": -43.9598
      },
      {
        "id": 3186,
        "latitude": -19.843642,
        "longitude": -44.033191
      },
      {
        "id": 3187,
        "latitude": -16.824309,
        "longitude": -40.624106
      },
      {
        "id": 3188,
        "latitude": -19.878758,
        "longitude": -43.918546
      },
      {
        "id": 3189,
        "latitude": -19.853419,
        "longitude": -44.146126
      },
      {
        "id": 3190,
        "latitude": -21.185427,
        "longitude": -45.445779
      },
      {
        "id": 3191,
        "latitude": -20.012375,
        "longitude": -44.035797
      },
      {
        "id": 3192,
        "latitude": -19.874159,
        "longitude": -44.04537
      },
      {
        "id": 3193,
        "latitude": -18.579484,
        "longitude": -46.517321
      },
      {
        "id": 3194,
        "latitude": -19.842574,
        "longitude": -44.028166
      },
      {
        "id": 3195,
        "latitude": -19.969794,
        "longitude": -44.046065
      },
      {
        "id": 3196,
        "latitude": -19.868794,
        "longitude": -44.051022
      },
      {
        "id": 3197,
        "latitude": -19.913275,
        "longitude": -44.084095
      },
      {
        "id": 3198,
        "latitude": -19.874602,
        "longitude": -44.047175
      },
      {
        "id": 3199,
        "latitude": -19.887386,
        "longitude": -44.020851
      },
      {
        "id": 3200,
        "latitude": -19.889216,
        "longitude": -44.111299
      },
      {
        "id": 3201,
        "latitude": -19.843642,
        "longitude": -44.033191
      },
      {
        "id": 3203,
        "latitude": -19.936937,
        "longitude": -44.046672
      },
      {
        "id": 3204,
        "latitude": -19.838793,
        "longitude": -44.034006
      },
      {
        "id": 3205,
        "latitude": -19.929437,
        "longitude": -44.045791
      },
      {
        "id": 3206,
        "latitude": -19.877816,
        "longitude": -44.040997
      },
      {
        "id": 3207,
        "latitude": -19.932254,
        "longitude": -44.054915
      },
      {
        "id": 3208,
        "latitude": -19.950376,
        "longitude": -44.066523
      },
      {
        "id": 3209,
        "latitude": -19.843642,
        "longitude": -44.033191
      },
      {
        "id": 3210,
        "latitude": -19.919166,
        "longitude": -44.125364
      },
      {
        "id": 3211,
        "latitude": -19.91682,
        "longitude": -44.055736
      },
      {
        "id": 3213,
        "latitude": -19.813229,
        "longitude": -44.144307
      },
      {
        "id": 3214,
        "latitude": -19.939071,
        "longitude": -43.979271
      },
      {
        "id": 3215,
        "latitude": -19.895367,
        "longitude": -44.085648
      },
      {
        "id": 3216,
        "latitude": -19.874159,
        "longitude": -44.04537
      },
      {
        "id": 3217,
        "latitude": -19.795172,
        "longitude": -43.997553
      },
      {
        "id": 3218,
        "latitude": -18.479955,
        "longitude": -47.197209
      },
      {
        "id": 3219,
        "latitude": -19.498191,
        "longitude": -42.5995
      },
      {
        "id": 3220,
        "latitude": -19.523307,
        "longitude": -42.61447
      },
      {
        "id": 3221,
        "latitude": -19.51783,
        "longitude": -42.60675
      },
      {
        "id": 3222,
        "latitude": -18.743168,
        "longitude": -44.409097
      },
      {
        "id": 3223,
        "latitude": -19.975216,
        "longitude": -47.777891
      },
      {
        "id": 3224,
        "latitude": -19.975216,
        "longitude": -47.777891
      },
      {
        "id": 3225,
        "latitude": -18.238688,
        "longitude": -43.60572
      },
      {
        "id": 3226,
        "latitude": -20.608492,
        "longitude": -42.145827
      },
      {
        "id": 3227,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3228,
        "latitude": -20.188802,
        "longitude": -44.930328
      },
      {
        "id": 3229,
        "latitude": -20.136731,
        "longitude": -44.892391
      },
      {
        "id": 3230,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3231,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3232,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3233,
        "latitude": -20.260171,
        "longitude": -44.765145
      },
      {
        "id": 3234,
        "latitude": -19.990083,
        "longitude": -44.851721
      },
      {
        "id": 3235,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3236,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3237,
        "latitude": -19.952949,
        "longitude": -43.970848
      },
      {
        "id": 3238,
        "latitude": -17.824356,
        "longitude": -40.373443
      },
      {
        "id": 3239,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3242,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3243,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3244,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3245,
        "latitude": -22.887723,
        "longitude": -46.357425
      },
      {
        "id": 3246,
        "latitude": -20.130924,
        "longitude": -44.839948
      },
      {
        "id": 3247,
        "latitude": -16.612889,
        "longitude": -46.577516
      },
      {
        "id": 3248,
        "latitude": -20.19838,
        "longitude": -44.944666
      },
      {
        "id": 3249,
        "latitude": -19.186262,
        "longitude": -41.465141
      },
      {
        "id": 3250,
        "latitude": -20.195749,
        "longitude": -44.94185
      },
      {
        "id": 3252,
        "latitude": -20.045512,
        "longitude": -44.840564
      },
      {
        "id": 3253,
        "latitude": -18.569264,
        "longitude": -46.48005
      },
      {
        "id": 3254,
        "latitude": -20.159792,
        "longitude": -44.873934
      },
      {
        "id": 3255,
        "latitude": -20.148313,
        "longitude": -44.908978
      },
      {
        "id": 3256,
        "latitude": -20.144657,
        "longitude": -44.878966
      },
      {
        "id": 3257,
        "latitude": -20.158425,
        "longitude": -44.856902
      },
      {
        "id": 3258,
        "latitude": -20.144657,
        "longitude": -44.878966
      },
      {
        "id": 3259,
        "latitude": -20.144657,
        "longitude": -44.878966
      },
      {
        "id": 3260,
        "latitude": -16.701103,
        "longitude": -43.847457
      },
      {
        "id": 3261,
        "latitude": -16.701103,
        "longitude": -43.847457
      },
      {
        "id": 3262,
        "latitude": -19.603295,
        "longitude": -44.239428
      },
      {
        "id": 3263,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3264,
        "latitude": -19.726272,
        "longitude": -44.1829
      },
      {
        "id": 3265,
        "latitude": -19.702556,
        "longitude": -44.176004
      },
      {
        "id": 3266,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3267,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3268,
        "latitude": -19.819975,
        "longitude": -43.971402
      },
      {
        "id": 3269,
        "latitude": -17.880228,
        "longitude": -41.308878
      },
      {
        "id": 3271,
        "latitude": -19.815859,
        "longitude": -44.318472
      },
      {
        "id": 3272,
        "latitude": -18.384588,
        "longitude": -48.293292
      },
      {
        "id": 3273,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3274,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3275,
        "latitude": -19.728679,
        "longitude": -44.492055
      },
      {
        "id": 3276,
        "latitude": -19.731332,
        "longitude": -44.180622
      },
      {
        "id": 3277,
        "latitude": -18.765092,
        "longitude": -44.442416
      },
      {
        "id": 3278,
        "latitude": -19.704839,
        "longitude": -44.199079
      },
      {
        "id": 3279,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3280,
        "latitude": -19.931602,
        "longitude": -44.158824
      },
      {
        "id": 3282,
        "latitude": -19.912996,
        "longitude": -43.993011
      },
      {
        "id": 3283,
        "latitude": -19.605568,
        "longitude": -44.239561
      },
      {
        "id": 3284,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3285,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3286,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3287,
        "latitude": -19.708453,
        "longitude": -44.195168
      },
      {
        "id": 3288,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3289,
        "latitude": -19.715135,
        "longitude": -44.177535
      },
      {
        "id": 3290,
        "latitude": -19.731332,
        "longitude": -44.180622
      },
      {
        "id": 3291,
        "latitude": -17.564375,
        "longitude": -47.2441
      },
      {
        "id": 3292,
        "latitude": -19.715135,
        "longitude": -44.177535
      },
      {
        "id": 3293,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3294,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3295,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 3296,
        "latitude": -19.331915,
        "longitude": -42.879124
      },
      {
        "id": 3297,
        "latitude": -20.652475,
        "longitude": -41.909676
      },
      {
        "id": 3298,
        "latitude": -18.757641,
        "longitude": -44.898466
      },
      {
        "id": 3299,
        "latitude": -20.727879,
        "longitude": -42.278316
      },
      {
        "id": 3301,
        "latitude": -20.031561,
        "longitude": -48.938741
      },
      {
        "id": 3303,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 3304,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 3305,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 3306,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 3307,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 3309,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 3310,
        "latitude": -18.876989,
        "longitude": -41.928484
      },
      {
        "id": 3311,
        "latitude": -18.876989,
        "longitude": -41.928484
      },
      {
        "id": 3312,
        "latitude": -18.876989,
        "longitude": -41.928484
      },
      {
        "id": 3313,
        "latitude": -18.835134,
        "longitude": -41.948945
      },
      {
        "id": 3314,
        "latitude": -18.878126,
        "longitude": -41.969423
      },
      {
        "id": 3315,
        "latitude": -18.834486,
        "longitude": -41.998416
      },
      {
        "id": 3316,
        "latitude": -18.883256,
        "longitude": -41.965447
      },
      {
        "id": 3317,
        "latitude": -16.566042,
        "longitude": -42.889332
      },
      {
        "id": 3318,
        "latitude": -16.538492,
        "longitude": -43.000642
      },
      {
        "id": 3319,
        "latitude": -16.566042,
        "longitude": -42.889332
      },
      {
        "id": 3320,
        "latitude": -20.694055,
        "longitude": -45.913708
      },
      {
        "id": 3322,
        "latitude": -19.482814,
        "longitude": -46.54561
      },
      {
        "id": 3324,
        "latitude": -22.402815,
        "longitude": -47.547233
      },
      {
        "id": 3326,
        "latitude": -17.33816,
        "longitude": -44.906907
      },
      {
        "id": 3327,
        "latitude": -20.633607,
        "longitude": -44.081403
      },
      {
        "id": 3328,
        "latitude": -20.012521,
        "longitude": -44.090111
      },
      {
        "id": 3329,
        "latitude": -20.683694,
        "longitude": -43.786849
      },
      {
        "id": 3330,
        "latitude": -20.683694,
        "longitude": -43.786849
      },
      {
        "id": 3332,
        "latitude": -19.997409,
        "longitude": -44.070989
      },
      {
        "id": 3333,
        "latitude": -20.683694,
        "longitude": -43.786849
      },
      {
        "id": 3334,
        "latitude": -20.683694,
        "longitude": -43.786849
      },
      {
        "id": 3335,
        "latitude": -20.114997,
        "longitude": -44.046414
      },
      {
        "id": 3336,
        "latitude": -19.999141,
        "longitude": -44.082394
      },
      {
        "id": 3338,
        "latitude": -20.060948,
        "longitude": -44.312344
      },
      {
        "id": 3339,
        "latitude": -19.991994,
        "longitude": -44.021929
      },
      {
        "id": 3340,
        "latitude": -20.068789,
        "longitude": -44.301744
      },
      {
        "id": 3341,
        "latitude": -20.318819,
        "longitude": -44.189616
      },
      {
        "id": 3342,
        "latitude": -20.060948,
        "longitude": -44.312344
      },
      {
        "id": 3343,
        "latitude": -20.068789,
        "longitude": -44.301744
      },
      {
        "id": 3363,
        "latitude": -20.079459,
        "longitude": -44.588078
      },
      {
        "id": 3344,
        "latitude": -19.807132,
        "longitude": -43.925119
      },
      {
        "id": 3346,
        "latitude": -20.942234,
        "longitude": -45.825886
      },
      {
        "id": 3347,
        "latitude": -18.884548,
        "longitude": -48.069492
      },
      {
        "id": 3348,
        "latitude": -19.62265,
        "longitude": -43.226246
      },
      {
        "id": 3349,
        "latitude": -22.283662,
        "longitude": -44.870605
      },
      {
        "id": 3350,
        "latitude": -22.302697,
        "longitude": -44.935672
      },
      {
        "id": 3351,
        "latitude": -20.198208,
        "longitude": -44.438775
      },
      {
        "id": 3352,
        "latitude": -20.198208,
        "longitude": -44.438775
      },
      {
        "id": 3353,
        "latitude": -20.198208,
        "longitude": -44.438775
      },
      {
        "id": 3354,
        "latitude": -20.198208,
        "longitude": -44.438775
      },
      {
        "id": 3355,
        "latitude": -20.175209,
        "longitude": -44.373176
      },
      {
        "id": 3356,
        "latitude": -20.175209,
        "longitude": -44.373176
      },
      {
        "id": 3357,
        "latitude": -20.198208,
        "longitude": -44.438775
      },
      {
        "id": 3358,
        "latitude": -20.199969,
        "longitude": -44.434195
      },
      {
        "id": 3359,
        "latitude": -20.094682,
        "longitude": -44.614268
      },
      {
        "id": 3360,
        "latitude": -20.075556,
        "longitude": -44.576389
      },
      {
        "id": 3361,
        "latitude": -20.075556,
        "longitude": -44.576389
      },
      {
        "id": 3365,
        "latitude": -20.079459,
        "longitude": -44.588078
      },
      {
        "id": 3367,
        "latitude": -20.094682,
        "longitude": -44.614268
      },
      {
        "id": 3368,
        "latitude": -20.079459,
        "longitude": -44.588078
      },
      {
        "id": 3369,
        "latitude": -20.093652,
        "longitude": -44.593086
      },
      {
        "id": 3370,
        "latitude": -20.072328,
        "longitude": -44.570898
      },
      {
        "id": 3371,
        "latitude": -20.093652,
        "longitude": -44.593086
      },
      {
        "id": 3372,
        "latitude": -20.09357,
        "longitude": -44.614214
      },
      {
        "id": 3374,
        "latitude": -18.979024,
        "longitude": -49.463928
      },
      {
        "id": 3375,
        "latitude": -18.979024,
        "longitude": -49.463928
      },
      {
        "id": 3376,
        "latitude": -18.979024,
        "longitude": -49.463928
      },
      {
        "id": 3377,
        "latitude": -18.979024,
        "longitude": -49.463928
      },
      {
        "id": 3378,
        "latitude": -18.979024,
        "longitude": -49.463928
      },
      {
        "id": 3379,
        "latitude": -19.697218,
        "longitude": -50.456835
      },
      {
        "id": 3382,
        "latitude": -19.888383,
        "longitude": -43.664571
      },
      {
        "id": 3383,
        "latitude": -19.868043,
        "longitude": -43.92095
      },
      {
        "id": 3384,
        "latitude": -19.516746,
        "longitude": -43.752258
      },
      {
        "id": 3385,
        "latitude": -19.516746,
        "longitude": -43.752258
      },
      {
        "id": 3386,
        "latitude": -19.521667,
        "longitude": -43.745277
      },
      {
        "id": 3387,
        "latitude": -16.148078,
        "longitude": -40.295163
      },
      {
        "id": 3388,
        "latitude": -15.337374,
        "longitude": -43.673444
      },
      {
        "id": 3389,
        "latitude": -15.330546,
        "longitude": -43.67672
      },
      {
        "id": 3390,
        "latitude": -17.228654,
        "longitude": -44.442867
      },
      {
        "id": 3392,
        "latitude": -16.3,
        "longitude": -41.18333
      },
      {
        "id": 3393,
        "latitude": -16.439438,
        "longitude": -41.00861
      },
      {
        "id": 3394,
        "latitude": -16.439438,
        "longitude": -41.00861
      },
      {
        "id": 3395,
        "latitude": -19.848412,
        "longitude": -43.906683
      },
      {
        "id": 3396,
        "latitude": -17.742777,
        "longitude": -46.175278
      },
      {
        "id": 3397,
        "latitude": -17.742777,
        "longitude": -46.175278
      },
      {
        "id": 3398,
        "latitude": -17.739991,
        "longitude": -46.181197
      },
      {
        "id": 3399,
        "latitude": -21.153385,
        "longitude": -44.280946
      },
      {
        "id": 3401,
        "latitude": -21.794045,
        "longitude": -43.347266
      },
      {
        "id": 3402,
        "latitude": -21.790008,
        "longitude": -43.390263
      },
      {
        "id": 3405,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3406,
        "latitude": -21.790008,
        "longitude": -43.390263
      },
      {
        "id": 3407,
        "latitude": -21.68633,
        "longitude": -43.439774
      },
      {
        "id": 3408,
        "latitude": -21.751477,
        "longitude": -43.372096
      },
      {
        "id": 3409,
        "latitude": -21.814411,
        "longitude": -43.360008
      },
      {
        "id": 3411,
        "latitude": -21.74388,
        "longitude": -43.407874
      },
      {
        "id": 3412,
        "latitude": -21.78889,
        "longitude": -43.34389
      },
      {
        "id": 3413,
        "latitude": -21.79001,
        "longitude": -43.367178
      },
      {
        "id": 3414,
        "latitude": -21.79001,
        "longitude": -43.367178
      },
      {
        "id": 3416,
        "latitude": -21.780276,
        "longitude": -43.39251
      },
      {
        "id": 3418,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3419,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3420,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3421,
        "latitude": -21.797118,
        "longitude": -43.401968
      },
      {
        "id": 3423,
        "latitude": -21.791958,
        "longitude": -43.351836
      },
      {
        "id": 3424,
        "latitude": -21.790008,
        "longitude": -43.390263
      },
      {
        "id": 3425,
        "latitude": -21.790008,
        "longitude": -43.390263
      },
      {
        "id": 3427,
        "latitude": -21.77222,
        "longitude": -43.37111
      },
      {
        "id": 3428,
        "latitude": -21.688578,
        "longitude": -43.435782
      },
      {
        "id": 3429,
        "latitude": -21.727587,
        "longitude": -43.35203
      },
      {
        "id": 3430,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3431,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3432,
        "latitude": -21.788513,
        "longitude": -43.391269
      },
      {
        "id": 3433,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 3434,
        "latitude": -21.814411,
        "longitude": -43.360008
      },
      {
        "id": 3435,
        "latitude": -21.688578,
        "longitude": -43.435782
      },
      {
        "id": 3436,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3437,
        "latitude": -21.763726,
        "longitude": -43.344113
      },
      {
        "id": 3438,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3439,
        "latitude": -21.77222,
        "longitude": -43.37111
      },
      {
        "id": 3440,
        "latitude": -21.706926,
        "longitude": -43.425071
      },
      {
        "id": 3441,
        "latitude": -21.763081,
        "longitude": -43.379041
      },
      {
        "id": 3442,
        "latitude": -21.77222,
        "longitude": -43.37111
      },
      {
        "id": 3443,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3445,
        "latitude": -21.786825,
        "longitude": -43.389406
      },
      {
        "id": 3446,
        "latitude": -21.78278,
        "longitude": -43.38389
      },
      {
        "id": 3447,
        "latitude": -21.77222,
        "longitude": -43.37111
      },
      {
        "id": 3448,
        "latitude": -21.75589,
        "longitude": -43.35711
      },
      {
        "id": 3449,
        "latitude": -21.790008,
        "longitude": -43.390263
      },
      {
        "id": 3450,
        "latitude": -21.71966,
        "longitude": -43.394607
      },
      {
        "id": 3451,
        "latitude": -21.73267,
        "longitude": -43.361467
      },
      {
        "id": 3452,
        "latitude": -21.453113,
        "longitude": -43.552572
      },
      {
        "id": 3453,
        "latitude": -21.453113,
        "longitude": -43.552572
      },
      {
        "id": 3454,
        "latitude": -21.752962,
        "longitude": -43.338504
      },
      {
        "id": 3455,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 3456,
        "latitude": -21.814411,
        "longitude": -43.360008
      },
      {
        "id": 3457,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3459,
        "latitude": -21.814411,
        "longitude": -43.360008
      },
      {
        "id": 3460,
        "latitude": -21.814411,
        "longitude": -43.360008
      },
      {
        "id": 3461,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3462,
        "latitude": -21.690713,
        "longitude": -43.34944
      },
      {
        "id": 3463,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3464,
        "latitude": -21.79001,
        "longitude": -43.367178
      },
      {
        "id": 3465,
        "latitude": -21.709968,
        "longitude": -43.356277
      },
      {
        "id": 3466,
        "latitude": -21.776235,
        "longitude": -43.36211
      },
      {
        "id": 3467,
        "latitude": -21.755153,
        "longitude": -43.378194
      },
      {
        "id": 3468,
        "latitude": -21.767685,
        "longitude": -43.324332
      },
      {
        "id": 3469,
        "latitude": -21.777355,
        "longitude": -43.343811
      },
      {
        "id": 3470,
        "latitude": -21.786825,
        "longitude": -43.389406
      },
      {
        "id": 3471,
        "latitude": -21.769656,
        "longitude": -43.392614
      },
      {
        "id": 3472,
        "latitude": -21.804538,
        "longitude": -43.343433
      },
      {
        "id": 3473,
        "latitude": -21.71966,
        "longitude": -43.394607
      },
      {
        "id": 3475,
        "latitude": -21.709968,
        "longitude": -43.356277
      },
      {
        "id": 3476,
        "latitude": -21.746887,
        "longitude": -43.426527
      },
      {
        "id": 3477,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3478,
        "latitude": -20.465532,
        "longitude": -45.953764
      },
      {
        "id": 3479,
        "latitude": -21.790008,
        "longitude": -43.390263
      },
      {
        "id": 3480,
        "latitude": -21.785818,
        "longitude": -43.356911
      },
      {
        "id": 3481,
        "latitude": -21.742079,
        "longitude": -43.365164
      },
      {
        "id": 3482,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3483,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3484,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3485,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 3486,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 3487,
        "latitude": -21.730168,
        "longitude": -43.403501
      },
      {
        "id": 3488,
        "latitude": -21.78278,
        "longitude": -43.38389
      },
      {
        "id": 3489,
        "latitude": -18.044392,
        "longitude": -46.694898
      },
      {
        "id": 3490,
        "latitude": -18.791197,
        "longitude": -46.410547
      },
      {
        "id": 3491,
        "latitude": -18.572116,
        "longitude": -46.50602
      },
      {
        "id": 3492,
        "latitude": -19.494545,
        "longitude": -43.814266
      },
      {
        "id": 3493,
        "latitude": -19.599815,
        "longitude": -43.898736
      },
      {
        "id": 3494,
        "latitude": -21.974501,
        "longitude": -45.347058
      },
      {
        "id": 3495,
        "latitude": -21.243911,
        "longitude": -44.993262
      },
      {
        "id": 3496,
        "latitude": -19.793678,
        "longitude": -45.68086
      },
      {
        "id": 3497,
        "latitude": -19.793678,
        "longitude": -45.68086
      },
      {
        "id": 3498,
        "latitude": -19.787117,
        "longitude": -45.684674
      },
      {
        "id": 3499,
        "latitude": -19.793678,
        "longitude": -45.68086
      },
      {
        "id": 3500,
        "latitude": -18.781678,
        "longitude": -40.982331
      },
      {
        "id": 3501,
        "latitude": -22.308832,
        "longitude": -45.368134
      },
      {
        "id": 3502,
        "latitude": -19.419836,
        "longitude": -45.155474
      },
      {
        "id": 3503,
        "latitude": -19.348544,
        "longitude": -45.242581
      },
      {
        "id": 3505,
        "latitude": -19.984537,
        "longitude": -44.411219
      },
      {
        "id": 3506,
        "latitude": -20.004605,
        "longitude": -44.446421
      },
      {
        "id": 3830,
        "latitude": -18.976916,
        "longitude": -48.3932
      },
      {
        "id": 3507,
        "latitude": -19.997632,
        "longitude": -44.42483
      },
      {
        "id": 3508,
        "latitude": -19.997632,
        "longitude": -44.42483
      },
      {
        "id": 3509,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3510,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3511,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3512,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3513,
        "latitude": -19.679905,
        "longitude": -44.179068
      },
      {
        "id": 3514,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3516,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3517,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 3518,
        "latitude": -19.577878,
        "longitude": -44.066477
      },
      {
        "id": 3519,
        "latitude": -19.998221,
        "longitude": -46.219233
      },
      {
        "id": 3520,
        "latitude": -18.657641,
        "longitude": -41.400575
      },
      {
        "id": 3521,
        "latitude": -14.419915,
        "longitude": -44.368235
      },
      {
        "id": 3522,
        "latitude": -18.870278,
        "longitude": -48.880556
      },
      {
        "id": 3523,
        "latitude": -18.729602,
        "longitude": -47.497151
      },
      {
        "id": 5217,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 3524,
        "latitude": -18.722235,
        "longitude": -47.698249
      },
      {
        "id": 3525,
        "latitude": -20.732303,
        "longitude": -47.049448
      },
      {
        "id": 3526,
        "latitude": -16.770277,
        "longitude": -43.848743
      },
      {
        "id": 3527,
        "latitude": -16.685348,
        "longitude": -43.84783
      },
      {
        "id": 3528,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 3530,
        "latitude": -22.26179,
        "longitude": -45.711653
      },
      {
        "id": 3531,
        "latitude": -16.727354,
        "longitude": -43.871768
      },
      {
        "id": 3532,
        "latitude": -16.730207,
        "longitude": -43.835034
      },
      {
        "id": 3533,
        "latitude": -16.7131,
        "longitude": -43.842878
      },
      {
        "id": 3534,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 3535,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 3536,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 3537,
        "latitude": -17.007785,
        "longitude": -46.006489
      },
      {
        "id": 3538,
        "latitude": -17.83899,
        "longitude": -40.350934
      },
      {
        "id": 3539,
        "latitude": -21.241502,
        "longitude": -45.231104
      },
      {
        "id": 3540,
        "latitude": -19.985409,
        "longitude": -43.847069
      },
      {
        "id": 3542,
        "latitude": -19.874953,
        "longitude": -44.983925
      },
      {
        "id": 3544,
        "latitude": -19.86553,
        "longitude": -44.941119
      },
      {
        "id": 3545,
        "latitude": -19.874953,
        "longitude": -44.983925
      },
      {
        "id": 3546,
        "latitude": -19.874953,
        "longitude": -44.983925
      },
      {
        "id": 3547,
        "latitude": -19.875834,
        "longitude": -44.984166
      },
      {
        "id": 3548,
        "latitude": -20.523026,
        "longitude": -43.691733
      },
      {
        "id": 3549,
        "latitude": -20.523026,
        "longitude": -43.691733
      },
      {
        "id": 3550,
        "latitude": -19.62488,
        "longitude": -42.230415
      },
      {
        "id": 3551,
        "latitude": -20.525946,
        "longitude": -43.688445
      },
      {
        "id": 3552,
        "latitude": -20.343598,
        "longitude": -43.757608
      },
      {
        "id": 3553,
        "latitude": -22.288365,
        "longitude": -46.379433
      },
      {
        "id": 3554,
        "latitude": -19.853601,
        "longitude": -44.60198
      },
      {
        "id": 3555,
        "latitude": -19.859575,
        "longitude": -44.63036
      },
      {
        "id": 3556,
        "latitude": -19.853601,
        "longitude": -44.60198
      },
      {
        "id": 3557,
        "latitude": -1.068708,
        "longitude": -48.354851
      },
      {
        "id": 3559,
        "latitude": -19.839936,
        "longitude": -44.608141
      },
      {
        "id": 3560,
        "latitude": -19.281415,
        "longitude": -44.402093
      },
      {
        "id": 3561,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 3562,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 3563,
        "latitude": -20.71678,
        "longitude": -46.615686
      },
      {
        "id": 3564,
        "latitude": -20.71678,
        "longitude": -46.615686
      },
      {
        "id": 3565,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 3566,
        "latitude": -20.717091,
        "longitude": -46.31807
      },
      {
        "id": 3567,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 3568,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 3569,
        "latitude": -18.61716,
        "longitude": -46.499098
      },
      {
        "id": 3570,
        "latitude": -18.580727,
        "longitude": -46.511488
      },
      {
        "id": 3571,
        "latitude": -18.599047,
        "longitude": -46.527623
      },
      {
        "id": 3572,
        "latitude": -18.580727,
        "longitude": -46.511488
      },
      {
        "id": 3573,
        "latitude": -18.580727,
        "longitude": -46.511488
      },
      {
        "id": 3575,
        "latitude": -18.9175,
        "longitude": -46.956051
      },
      {
        "id": 3576,
        "latitude": -15.992942,
        "longitude": -41.278009
      },
      {
        "id": 3747,
        "latitude": -16.297046,
        "longitude": -44.783787
      },
      {
        "id": 3577,
        "latitude": -19.61293,
        "longitude": -44.037655
      },
      {
        "id": 3578,
        "latitude": -19.69529,
        "longitude": -44.054234
      },
      {
        "id": 3579,
        "latitude": -19.729133,
        "longitude": -42.410858
      },
      {
        "id": 3580,
        "latitude": -16.05513,
        "longitude": -45.147211
      },
      {
        "id": 3581,
        "latitude": -16.05513,
        "longitude": -45.147211
      },
      {
        "id": 3582,
        "latitude": -16.05513,
        "longitude": -45.147211
      },
      {
        "id": 3583,
        "latitude": -19.904932,
        "longitude": -48.699141
      },
      {
        "id": 3584,
        "latitude": -19.79039,
        "longitude": -42.400317
      },
      {
        "id": 3585,
        "latitude": -19.682304,
        "longitude": -44.892047
      },
      {
        "id": 3586,
        "latitude": -19.682223,
        "longitude": -44.890555
      },
      {
        "id": 3587,
        "latitude": -21.820581,
        "longitude": -46.552014
      },
      {
        "id": 3588,
        "latitude": -21.808696,
        "longitude": -46.576769
      },
      {
        "id": 3589,
        "latitude": -19.225155,
        "longitude": -45.002647
      },
      {
        "id": 3590,
        "latitude": -19.218611,
        "longitude": -45.002778
      },
      {
        "id": 3591,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3592,
        "latitude": -22.223582,
        "longitude": -45.934907
      },
      {
        "id": 3593,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3594,
        "latitude": -22.203611,
        "longitude": -45.905073
      },
      {
        "id": 3595,
        "latitude": -22.234386,
        "longitude": -45.932724
      },
      {
        "id": 3596,
        "latitude": -22.234386,
        "longitude": -45.932724
      },
      {
        "id": 3597,
        "latitude": -22.223582,
        "longitude": -45.934907
      },
      {
        "id": 3598,
        "latitude": -22.229308,
        "longitude": -45.899476
      },
      {
        "id": 3599,
        "latitude": -22.250046,
        "longitude": -45.922587
      },
      {
        "id": 3600,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3601,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3602,
        "latitude": -22.232724,
        "longitude": -45.924856
      },
      {
        "id": 3603,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3604,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3605,
        "latitude": -22.238936,
        "longitude": -45.927365
      },
      {
        "id": 3606,
        "latitude": -22.213079,
        "longitude": -45.951896
      },
      {
        "id": 3607,
        "latitude": -22.213079,
        "longitude": -45.951896
      },
      {
        "id": 3608,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3609,
        "latitude": -22.213079,
        "longitude": -45.951896
      },
      {
        "id": 3610,
        "latitude": -22.223582,
        "longitude": -45.934907
      },
      {
        "id": 3611,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3612,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 3613,
        "latitude": -19.311749,
        "longitude": -48.928396
      },
      {
        "id": 3614,
        "latitude": -19.307222,
        "longitude": -48.923889
      },
      {
        "id": 3615,
        "latitude": -19.311749,
        "longitude": -48.928396
      },
      {
        "id": 3616,
        "latitude": -19.589412,
        "longitude": -44.037635
      },
      {
        "id": 3617,
        "latitude": -19.898322,
        "longitude": -44.097319
      },
      {
        "id": 3618,
        "latitude": -19.824866,
        "longitude": -44.016236
      },
      {
        "id": 3619,
        "latitude": -19.578672,
        "longitude": -44.024596
      },
      {
        "id": 3620,
        "latitude": -19.76729,
        "longitude": -44.087544
      },
      {
        "id": 3621,
        "latitude": -19.763395,
        "longitude": -44.075502
      },
      {
        "id": 3622,
        "latitude": -19.952949,
        "longitude": -43.970848
      },
      {
        "id": 3623,
        "latitude": -19.76729,
        "longitude": -44.087544
      },
      {
        "id": 3624,
        "latitude": -19.780165,
        "longitude": -44.090739
      },
      {
        "id": 3625,
        "latitude": -19.803477,
        "longitude": -44.015928
      },
      {
        "id": 3626,
        "latitude": -19.780165,
        "longitude": -44.090739
      },
      {
        "id": 3627,
        "latitude": -19.76729,
        "longitude": -44.087544
      },
      {
        "id": 3628,
        "latitude": -19.780165,
        "longitude": -44.090739
      },
      {
        "id": 3629,
        "latitude": -19.780165,
        "longitude": -44.090739
      },
      {
        "id": 3630,
        "latitude": -19.804032,
        "longitude": -44.024646
      },
      {
        "id": 3631,
        "latitude": -19.76729,
        "longitude": -44.087544
      },
      {
        "id": 3632,
        "latitude": -19.804032,
        "longitude": -44.024646
      },
      {
        "id": 3633,
        "latitude": -20.223954,
        "longitude": -42.656107
      },
      {
        "id": 3634,
        "latitude": -21.267626,
        "longitude": -43.173259
      },
      {
        "id": 3635,
        "latitude": -21.302082,
        "longitude": -43.245085
      },
      {
        "id": 3636,
        "latitude": -21.275,
        "longitude": -43.178889
      },
      {
        "id": 3637,
        "latitude": -19.897127,
        "longitude": -43.819085
      },
      {
        "id": 3638,
        "latitude": -19.890037,
        "longitude": -43.810792
      },
      {
        "id": 3639,
        "latitude": -16.158316,
        "longitude": -42.285937
      },
      {
        "id": 3640,
        "latitude": -21.120789,
        "longitude": -44.229958
      },
      {
        "id": 3642,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3643,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3644,
        "latitude": -16.567066,
        "longitude": -41.508366
      },
      {
        "id": 3645,
        "latitude": -19.658892,
        "longitude": -43.7731
      },
      {
        "id": 3647,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3648,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3649,
        "latitude": -19.790683,
        "longitude": -43.931139
      },
      {
        "id": 3651,
        "latitude": -19.77021,
        "longitude": -43.850529
      },
      {
        "id": 3652,
        "latitude": -19.77021,
        "longitude": -43.850529
      },
      {
        "id": 3653,
        "latitude": -19.763111,
        "longitude": -43.881034
      },
      {
        "id": 3655,
        "latitude": -19.779297,
        "longitude": -43.910916
      },
      {
        "id": 3656,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3657,
        "latitude": -19.781409,
        "longitude": -43.908099
      },
      {
        "id": 3658,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3659,
        "latitude": -19.775636,
        "longitude": -43.891233
      },
      {
        "id": 3660,
        "latitude": -19.788195,
        "longitude": -43.915768
      },
      {
        "id": 3661,
        "latitude": -19.789395,
        "longitude": -43.915249
      },
      {
        "id": 3663,
        "latitude": -19.658892,
        "longitude": -43.7731
      },
      {
        "id": 3664,
        "latitude": -19.658892,
        "longitude": -43.7731
      },
      {
        "id": 3665,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 3666,
        "latitude": -19.791295,
        "longitude": -43.900279
      },
      {
        "id": 3667,
        "latitude": -19.778363,
        "longitude": -43.914395
      },
      {
        "id": 3669,
        "latitude": -18.848268,
        "longitude": -50.12692
      },
      {
        "id": 3670,
        "latitude": -18.833919,
        "longitude": -50.143705
      },
      {
        "id": 3671,
        "latitude": -19.365291,
        "longitude": -42.549483
      },
      {
        "id": 3673,
        "latitude": -19.456084,
        "longitude": -42.526166
      },
      {
        "id": 3674,
        "latitude": -29.830862,
        "longitude": -50.057084
      },
      {
        "id": 3675,
        "latitude": -20.094089,
        "longitude": -45.2875
      },
      {
        "id": 3676,
        "latitude": -20.086944,
        "longitude": -45.290555
      },
      {
        "id": 3677,
        "latitude": -22.271864,
        "longitude": -42.612815
      },
      {
        "id": 3678,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3679,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3680,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3681,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3682,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3683,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3684,
        "latitude": -20.625,
        "longitude": -43.948889
      },
      {
        "id": 3685,
        "latitude": -18.591048,
        "longitude": -41.486837
      },
      {
        "id": 3686,
        "latitude": -18.590197,
        "longitude": -41.487597
      },
      {
        "id": 3687,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3688,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3689,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3690,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3691,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3692,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3694,
        "latitude": -15.959871,
        "longitude": -44.854371
      },
      {
        "id": 3748,
        "latitude": -16.297046,
        "longitude": -44.783787
      },
      {
        "id": 3695,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3696,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3697,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 3698,
        "latitude": -19.861227,
        "longitude": -49.771576
      },
      {
        "id": 3699,
        "latitude": -19.854206,
        "longitude": -49.774842
      },
      {
        "id": 3701,
        "latitude": -19.826529,
        "longitude": -43.359561
      },
      {
        "id": 3702,
        "latitude": -21.13447,
        "longitude": -44.252062
      },
      {
        "id": 3703,
        "latitude": -21.13447,
        "longitude": -44.252062
      },
      {
        "id": 3704,
        "latitude": -21.146379,
        "longitude": -44.253086
      },
      {
        "id": 3705,
        "latitude": -21.135982,
        "longitude": -44.229613
      },
      {
        "id": 3706,
        "latitude": -21.101162,
        "longitude": -44.22053
      },
      {
        "id": 3707,
        "latitude": -19.339859,
        "longitude": -42.158415
      },
      {
        "id": 3708,
        "latitude": -22.325801,
        "longitude": -45.527585
      },
      {
        "id": 3709,
        "latitude": -21.537722,
        "longitude": -43.014969
      },
      {
        "id": 3710,
        "latitude": -20.057222,
        "longitude": -44.260673
      },
      {
        "id": 3711,
        "latitude": -20.059812,
        "longitude": -44.270691
      },
      {
        "id": 3712,
        "latitude": -19.716166,
        "longitude": -43.977138
      },
      {
        "id": 3713,
        "latitude": -8.535093,
        "longitude": -39.018475
      },
      {
        "id": 3714,
        "latitude": -22.11634,
        "longitude": -45.038655
      },
      {
        "id": 3715,
        "latitude": -20.181599,
        "longitude": -45.047244
      },
      {
        "id": 3716,
        "latitude": -20.032662,
        "longitude": -44.156059
      },
      {
        "id": 3717,
        "latitude": -20.040583,
        "longitude": -44.15601
      },
      {
        "id": 3718,
        "latitude": -19.479312,
        "longitude": -44.230265
      },
      {
        "id": 3719,
        "latitude": -19.477634,
        "longitude": -44.247162
      },
      {
        "id": 3720,
        "latitude": -19.466111,
        "longitude": -44.246944
      },
      {
        "id": 3721,
        "latitude": -19.43413,
        "longitude": -44.239602
      },
      {
        "id": 3722,
        "latitude": -19.484834,
        "longitude": -44.155017
      },
      {
        "id": 3723,
        "latitude": -19.447335,
        "longitude": -44.237536
      },
      {
        "id": 3724,
        "latitude": -19.836477,
        "longitude": -43.180601
      },
      {
        "id": 3725,
        "latitude": -19.447335,
        "longitude": -44.237536
      },
      {
        "id": 3726,
        "latitude": -19.466111,
        "longitude": -44.246944
      },
      {
        "id": 3727,
        "latitude": -19.416522,
        "longitude": -44.234719
      },
      {
        "id": 3728,
        "latitude": -19.482546,
        "longitude": -44.239296
      },
      {
        "id": 3729,
        "latitude": -19.482546,
        "longitude": -44.239296
      },
      {
        "id": 3730,
        "latitude": -19.482546,
        "longitude": -44.239296
      },
      {
        "id": 3731,
        "latitude": -19.487346,
        "longitude": -44.254342
      },
      {
        "id": 3732,
        "latitude": -19.479312,
        "longitude": -44.230265
      },
      {
        "id": 3733,
        "latitude": -19.477634,
        "longitude": -44.247162
      },
      {
        "id": 3734,
        "latitude": -19.416522,
        "longitude": -44.234719
      },
      {
        "id": 3735,
        "latitude": -19.433941,
        "longitude": -44.227196
      },
      {
        "id": 3736,
        "latitude": -19.416522,
        "longitude": -44.234719
      },
      {
        "id": 3737,
        "latitude": -19.466111,
        "longitude": -44.246944
      },
      {
        "id": 3738,
        "latitude": -19.234575,
        "longitude": -42.097803
      },
      {
        "id": 3739,
        "latitude": -22.059699,
        "longitude": -45.045207
      },
      {
        "id": 3740,
        "latitude": -15.804747,
        "longitude": -42.240431
      },
      {
        "id": 3741,
        "latitude": -19.561686,
        "longitude": -42.642776
      },
      {
        "id": 3742,
        "latitude": -18.509838,
        "longitude": -44.291828
      },
      {
        "id": 3743,
        "latitude": -21.702539,
        "longitude": -45.236274
      },
      {
        "id": 3744,
        "latitude": -21.693993,
        "longitude": -45.252564
      },
      {
        "id": 3745,
        "latitude": -21.693993,
        "longitude": -45.252564
      },
      {
        "id": 3746,
        "latitude": -21.123426,
        "longitude": -42.937161
      },
      {
        "id": 3751,
        "latitude": -19.756867,
        "longitude": -47.886716
      },
      {
        "id": 3752,
        "latitude": -19.774842,
        "longitude": -47.949624
      },
      {
        "id": 3753,
        "latitude": -19.774842,
        "longitude": -47.949624
      },
      {
        "id": 3755,
        "latitude": -19.756541,
        "longitude": -47.976698
      },
      {
        "id": 3756,
        "latitude": -19.794265,
        "longitude": -47.925069
      },
      {
        "id": 3757,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3758,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3759,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3761,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3762,
        "latitude": -19.781388,
        "longitude": -47.940253
      },
      {
        "id": 3763,
        "latitude": -19.751011,
        "longitude": -47.928498
      },
      {
        "id": 3764,
        "latitude": -19.79299,
        "longitude": -47.933177
      },
      {
        "id": 3765,
        "latitude": -19.774842,
        "longitude": -47.949624
      },
      {
        "id": 3766,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3767,
        "latitude": -19.756867,
        "longitude": -47.886716
      },
      {
        "id": 3768,
        "latitude": -19.779865,
        "longitude": -47.922417
      },
      {
        "id": 3769,
        "latitude": -19.783592,
        "longitude": -47.939238
      },
      {
        "id": 3770,
        "latitude": -19.723489,
        "longitude": -47.913151
      },
      {
        "id": 3771,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3772,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3773,
        "latitude": -19.755439,
        "longitude": -47.974824
      },
      {
        "id": 3774,
        "latitude": -19.750833,
        "longitude": -47.936666
      },
      {
        "id": 3775,
        "latitude": -22.257951,
        "longitude": -45.930997
      },
      {
        "id": 3776,
        "latitude": -18.926003,
        "longitude": -48.315035
      },
      {
        "id": 3777,
        "latitude": -18.92677,
        "longitude": -48.305274
      },
      {
        "id": 3778,
        "latitude": -19,
        "longitude": -48.48333
      },
      {
        "id": 3780,
        "latitude": -18.965776,
        "longitude": -48.338374
      },
      {
        "id": 3781,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3783,
        "latitude": -18.909619,
        "longitude": -48.328029
      },
      {
        "id": 3784,
        "latitude": -18.934912,
        "longitude": -48.334327
      },
      {
        "id": 3785,
        "latitude": -18.894719,
        "longitude": -48.268292
      },
      {
        "id": 3786,
        "latitude": -18.926405,
        "longitude": -48.269273
      },
      {
        "id": 3787,
        "latitude": -18.907462,
        "longitude": -48.215694
      },
      {
        "id": 3788,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3789,
        "latitude": -19.500882,
        "longitude": -42.576588
      },
      {
        "id": 3790,
        "latitude": -18.963259,
        "longitude": -48.247353
      },
      {
        "id": 3791,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3792,
        "latitude": -18.913321,
        "longitude": -48.213151
      },
      {
        "id": 3793,
        "latitude": -18.964869,
        "longitude": -48.258865
      },
      {
        "id": 3794,
        "latitude": -18.965776,
        "longitude": -48.338374
      },
      {
        "id": 3795,
        "latitude": -19.50089,
        "longitude": -42.575252
      },
      {
        "id": 3796,
        "latitude": -18.964543,
        "longitude": -48.329127
      },
      {
        "id": 3797,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3798,
        "latitude": -19.950859,
        "longitude": -43.9598
      },
      {
        "id": 3799,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3800,
        "latitude": -19.952949,
        "longitude": -43.970848
      },
      {
        "id": 3801,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3802,
        "latitude": -19.501524,
        "longitude": -42.575543
      },
      {
        "id": 3803,
        "latitude": -18.909619,
        "longitude": -48.328029
      },
      {
        "id": 3804,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3805,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3806,
        "latitude": -18.909619,
        "longitude": -48.328029
      },
      {
        "id": 3807,
        "latitude": -18.934052,
        "longitude": -48.32425
      },
      {
        "id": 3809,
        "latitude": -18.933755,
        "longitude": -48.322667
      },
      {
        "id": 3811,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3813,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 5218,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 3814,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3815,
        "latitude": -18.970048,
        "longitude": -48.272837
      },
      {
        "id": 3816,
        "latitude": -18.940384,
        "longitude": -48.321158
      },
      {
        "id": 3817,
        "latitude": -18.910669,
        "longitude": -48.323975
      },
      {
        "id": 3818,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3819,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3820,
        "latitude": -18.955599,
        "longitude": -48.313181
      },
      {
        "id": 3821,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3822,
        "latitude": -18.921491,
        "longitude": -48.313441
      },
      {
        "id": 3823,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3824,
        "latitude": -18.943165,
        "longitude": -48.338149
      },
      {
        "id": 3825,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3826,
        "latitude": -18.913598,
        "longitude": -48.330158
      },
      {
        "id": 3827,
        "latitude": -18.919938,
        "longitude": -48.335724
      },
      {
        "id": 3828,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3829,
        "latitude": -18.921491,
        "longitude": -48.313441
      },
      {
        "id": 3831,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3832,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3833,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3834,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3835,
        "latitude": -18.978924,
        "longitude": -48.277956
      },
      {
        "id": 3836,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3837,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3838,
        "latitude": -18.894511,
        "longitude": -48.320634
      },
      {
        "id": 3839,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3840,
        "latitude": -18.958044,
        "longitude": -48.315424
      },
      {
        "id": 3841,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3842,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3843,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3844,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3845,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3846,
        "latitude": -18.924708,
        "longitude": -48.319765
      },
      {
        "id": 3847,
        "latitude": -18.92677,
        "longitude": -48.305274
      },
      {
        "id": 3848,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3849,
        "latitude": -18.909983,
        "longitude": -48.270646
      },
      {
        "id": 3850,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3851,
        "latitude": -18.924528,
        "longitude": -48.19304
      },
      {
        "id": 3853,
        "latitude": -18.934052,
        "longitude": -48.32425
      },
      {
        "id": 3854,
        "latitude": -18.914818,
        "longitude": -48.297854
      },
      {
        "id": 3855,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3856,
        "latitude": -19.952949,
        "longitude": -43.970848
      },
      {
        "id": 3857,
        "latitude": -21.370369,
        "longitude": -45.533491
      },
      {
        "id": 3858,
        "latitude": -18.859937,
        "longitude": -48.272329
      },
      {
        "id": 3859,
        "latitude": -19.878758,
        "longitude": -43.918546
      },
      {
        "id": 3860,
        "latitude": -18.913781,
        "longitude": -48.213729
      },
      {
        "id": 3861,
        "latitude": -18.951604,
        "longitude": -48.346095
      },
      {
        "id": 3862,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3863,
        "latitude": -19.802935,
        "longitude": -43.964811
      },
      {
        "id": 3864,
        "latitude": -18.909591,
        "longitude": -48.209483
      },
      {
        "id": 3865,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3866,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3867,
        "latitude": -18.973156,
        "longitude": -48.360513
      },
      {
        "id": 3868,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3869,
        "latitude": -18.579484,
        "longitude": -46.517321
      },
      {
        "id": 3870,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3871,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3872,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3873,
        "latitude": -20.773705,
        "longitude": -43.791688
      },
      {
        "id": 3874,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3875,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3876,
        "latitude": -18.973156,
        "longitude": -48.360513
      },
      {
        "id": 3877,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3878,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3879,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3880,
        "latitude": -18.909619,
        "longitude": -48.328029
      },
      {
        "id": 3881,
        "latitude": -18.894719,
        "longitude": -48.268292
      },
      {
        "id": 3882,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3883,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3884,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3885,
        "latitude": -18.859937,
        "longitude": -48.272329
      },
      {
        "id": 3886,
        "latitude": -18.951604,
        "longitude": -48.346095
      },
      {
        "id": 3887,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3888,
        "latitude": -15.958286,
        "longitude": -44.84456
      },
      {
        "id": 3889,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3890,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3891,
        "latitude": -18.908408,
        "longitude": -48.259962
      },
      {
        "id": 3892,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3893,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3894,
        "latitude": -18.934912,
        "longitude": -48.334327
      },
      {
        "id": 3895,
        "latitude": -19.50089,
        "longitude": -42.575252
      },
      {
        "id": 3896,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3897,
        "latitude": -18.910661,
        "longitude": -48.214934
      },
      {
        "id": 3898,
        "latitude": -18.909658,
        "longitude": -48.245281
      },
      {
        "id": 3899,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3900,
        "latitude": -18.970048,
        "longitude": -48.272837
      },
      {
        "id": 3901,
        "latitude": -18.959096,
        "longitude": -48.307426
      },
      {
        "id": 3902,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3903,
        "latitude": -18.921067,
        "longitude": -48.311142
      },
      {
        "id": 3904,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3905,
        "latitude": -19.819281,
        "longitude": -43.885617
      },
      {
        "id": 3906,
        "latitude": -18.926003,
        "longitude": -48.315035
      },
      {
        "id": 3907,
        "latitude": -18.971157,
        "longitude": -48.274809
      },
      {
        "id": 3908,
        "latitude": -18.953341,
        "longitude": -48.318502
      },
      {
        "id": 3909,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 3910,
        "latitude": -18.968515,
        "longitude": -48.325608
      },
      {
        "id": 3911,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 3912,
        "latitude": -18.918804,
        "longitude": -48.276784
      },
      {
        "id": 3913,
        "latitude": -18.92903,
        "longitude": -48.320162
      },
      {
        "id": 3914,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 3915,
        "latitude": -21.58402,
        "longitude": -45.453447
      },
      {
        "id": 3916,
        "latitude": -21.543205,
        "longitude": -45.407153
      },
      {
        "id": 3917,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3918,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3919,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3920,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 3921,
        "latitude": -19.50089,
        "longitude": -42.575252
      },
      {
        "id": 3922,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3923,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3924,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3925,
        "latitude": -21.593819,
        "longitude": -45.443208
      },
      {
        "id": 3926,
        "latitude": -21.556591,
        "longitude": -45.434067
      },
      {
        "id": 3927,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 3928,
        "latitude": -21.567937,
        "longitude": -45.43416
      },
      {
        "id": 3929,
        "latitude": -21.556591,
        "longitude": -45.434067
      },
      {
        "id": 3930,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3931,
        "latitude": -21.568348,
        "longitude": -45.439591
      },
      {
        "id": 3932,
        "latitude": -21.568348,
        "longitude": -45.439591
      },
      {
        "id": 3933,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 3934,
        "latitude": -19.50048,
        "longitude": -42.576295
      },
      {
        "id": 3935,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 3936,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 3937,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 3938,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 3940,
        "latitude": -17.596985,
        "longitude": -44.737352
      },
      {
        "id": 3941,
        "latitude": -17.986433,
        "longitude": -46.901124
      },
      {
        "id": 3942,
        "latitude": -19.731245,
        "longitude": -43.909806
      },
      {
        "id": 3943,
        "latitude": -19.731245,
        "longitude": -43.909806
      },
      {
        "id": 3944,
        "latitude": -19.732781,
        "longitude": -43.940524
      },
      {
        "id": 3945,
        "latitude": -19.757126,
        "longitude": -43.94911
      },
      {
        "id": 3946,
        "latitude": -19.731245,
        "longitude": -43.909806
      },
      {
        "id": 3947,
        "latitude": -19.732781,
        "longitude": -43.940524
      },
      {
        "id": 3949,
        "latitude": -19.687074,
        "longitude": -43.918551
      },
      {
        "id": 3951,
        "latitude": -20.907072,
        "longitude": -44.506325
      },
      {
        "id": 3954,
        "latitude": -19.601274,
        "longitude": -43.896935
      },
      {
        "id": 3955,
        "latitude": -19.823746,
        "longitude": -43.897846
      },
      {
        "id": 3956,
        "latitude": -20.236734,
        "longitude": -42.816363
      },
      {
        "id": 3957,
        "latitude": -19.762551,
        "longitude": -43.991825
      },
      {
        "id": 3958,
        "latitude": -19.00573,
        "longitude": -46.320347
      },
      {
        "id": 3959,
        "latitude": -19.731245,
        "longitude": -43.909806
      },
      {
        "id": 3960,
        "latitude": -19.732781,
        "longitude": -43.940524
      },
      {
        "id": 3961,
        "latitude": -20.732922,
        "longitude": -42.863654
      },
      {
        "id": 3962,
        "latitude": -21.009911,
        "longitude": -42.836006
      },
      {
        "id": 3963,
        "latitude": -21.009911,
        "longitude": -42.836006
      },
      {
        "id": 3964,
        "latitude": -21.009911,
        "longitude": -42.836006
      },
      {
        "id": 3965,
        "latitude": -3.184712,
        "longitude": -52.217963
      },
      {
        "id": 3966,
        "latitude": -3.204065,
        "longitude": -52.209961
      },
      {
        "id": 3967,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3968,
        "latitude": -1.39837,
        "longitude": -48.389096
      },
      {
        "id": 3969,
        "latitude": -1.374035,
        "longitude": -48.401662
      },
      {
        "id": 3970,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3971,
        "latitude": -1.345365,
        "longitude": -48.41565
      },
      {
        "id": 3972,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3973,
        "latitude": -1.356673,
        "longitude": -48.40585
      },
      {
        "id": 3974,
        "latitude": -1.338142,
        "longitude": -48.42108
      },
      {
        "id": 3975,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 3976,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3977,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3978,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 4015,
        "latitude": -1.448755,
        "longitude": -48.487867
      },
      {
        "id": 3979,
        "latitude": -1.376679,
        "longitude": -48.424051
      },
      {
        "id": 3980,
        "latitude": -1.32593,
        "longitude": -48.401496
      },
      {
        "id": 3981,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3982,
        "latitude": -1.34485,
        "longitude": -48.395965
      },
      {
        "id": 3983,
        "latitude": -3.310377,
        "longitude": -52.536862
      },
      {
        "id": 3984,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 3985,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 3986,
        "latitude": -3.474202,
        "longitude": -44.874597
      },
      {
        "id": 3987,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 3988,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 3989,
        "latitude": -2.510436,
        "longitude": -45.797996
      },
      {
        "id": 3990,
        "latitude": -0.864687,
        "longitude": -47.374678
      },
      {
        "id": 3991,
        "latitude": -2.130781,
        "longitude": -47.566034
      },
      {
        "id": 3992,
        "latitude": -3.29326,
        "longitude": -52.544177
      },
      {
        "id": 3993,
        "latitude": -1.821274,
        "longitude": -47.757983
      },
      {
        "id": 3994,
        "latitude": -2.134937,
        "longitude": -47.55579
      },
      {
        "id": 3995,
        "latitude": -2.13816,
        "longitude": -47.55402
      },
      {
        "id": 3996,
        "latitude": -2.013587,
        "longitude": -47.678979
      },
      {
        "id": 3997,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 3998,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 3999,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 4000,
        "latitude": -29.443714,
        "longitude": -51.511636
      },
      {
        "id": 4001,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 4002,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 4003,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 4004,
        "latitude": -2.129248,
        "longitude": -47.563404
      },
      {
        "id": 4005,
        "latitude": -2.13232,
        "longitude": -47.558436
      },
      {
        "id": 4006,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 4007,
        "latitude": -2.135607,
        "longitude": -47.569494
      },
      {
        "id": 4008,
        "latitude": -27.219949,
        "longitude": -49.636752
      },
      {
        "id": 4009,
        "latitude": -2.1352,
        "longitude": -47.554068
      },
      {
        "id": 4010,
        "latitude": -1.51891,
        "longitude": -48.700428
      },
      {
        "id": 4012,
        "latitude": -1.401908,
        "longitude": -48.435631
      },
      {
        "id": 4013,
        "latitude": -1.45056,
        "longitude": -48.468245
      },
      {
        "id": 4014,
        "latitude": -1.457152,
        "longitude": -48.496657
      },
      {
        "id": 4016,
        "latitude": -1.45056,
        "longitude": -48.468245
      },
      {
        "id": 4017,
        "latitude": -1.454908,
        "longitude": -48.465727
      },
      {
        "id": 4018,
        "latitude": -1.45056,
        "longitude": -48.468245
      },
      {
        "id": 4019,
        "latitude": -1.360818,
        "longitude": -48.435665
      },
      {
        "id": 4020,
        "latitude": -1.406484,
        "longitude": -48.444732
      },
      {
        "id": 4021,
        "latitude": -1.402945,
        "longitude": -48.452565
      },
      {
        "id": 4022,
        "latitude": -1.299825,
        "longitude": -48.474268
      },
      {
        "id": 4023,
        "latitude": -1.448755,
        "longitude": -48.487867
      },
      {
        "id": 4024,
        "latitude": -1.448755,
        "longitude": -48.487867
      },
      {
        "id": 4025,
        "latitude": -1.40813,
        "longitude": -48.434115
      },
      {
        "id": 4026,
        "latitude": -1.335302,
        "longitude": -48.28742
      },
      {
        "id": 4027,
        "latitude": -1.335302,
        "longitude": -48.28742
      },
      {
        "id": 4028,
        "latitude": -1.308814,
        "longitude": -48.302457
      },
      {
        "id": 4029,
        "latitude": -1.355558,
        "longitude": -48.252235
      },
      {
        "id": 4031,
        "latitude": -3.77468,
        "longitude": -49.570512
      },
      {
        "id": 4032,
        "latitude": -2.998365,
        "longitude": -47.356397
      },
      {
        "id": 4033,
        "latitude": -6.53106,
        "longitude": -49.854664
      },
      {
        "id": 4034,
        "latitude": -6.53106,
        "longitude": -49.854664
      },
      {
        "id": 4035,
        "latitude": -6.529684,
        "longitude": -49.851659
      },
      {
        "id": 4037,
        "latitude": -1.747552,
        "longitude": -47.065495
      },
      {
        "id": 4038,
        "latitude": -1.747552,
        "longitude": -47.065495
      },
      {
        "id": 4039,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4040,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4041,
        "latitude": -1.30771,
        "longitude": -47.91942
      },
      {
        "id": 4042,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4043,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4044,
        "latitude": -1.294419,
        "longitude": -47.91302
      },
      {
        "id": 4045,
        "latitude": -1.292703,
        "longitude": -47.92239
      },
      {
        "id": 4046,
        "latitude": -1.281536,
        "longitude": -47.938856
      },
      {
        "id": 4047,
        "latitude": -1.301735,
        "longitude": -47.920863
      },
      {
        "id": 4048,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4049,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4050,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4051,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4052,
        "latitude": -1.275899,
        "longitude": -47.932467
      },
      {
        "id": 4053,
        "latitude": -1.316404,
        "longitude": -48.159095
      },
      {
        "id": 4054,
        "latitude": -1.292703,
        "longitude": -47.92239
      },
      {
        "id": 4055,
        "latitude": -1.285242,
        "longitude": -47.954423
      },
      {
        "id": 4056,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4057,
        "latitude": -1.313715,
        "longitude": -48.155401
      },
      {
        "id": 4058,
        "latitude": -1.292703,
        "longitude": -47.92239
      },
      {
        "id": 4059,
        "latitude": -6.565612,
        "longitude": -49.945583
      },
      {
        "id": 4060,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4061,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 4062,
        "latitude": -20.528929,
        "longitude": -54.584041
      },
      {
        "id": 4063,
        "latitude": -8.123147,
        "longitude": -49.122305
      },
      {
        "id": 4064,
        "latitude": -8.255801,
        "longitude": -49.270607
      },
      {
        "id": 4065,
        "latitude": -8.255801,
        "longitude": -49.270607
      },
      {
        "id": 4066,
        "latitude": -8.259535,
        "longitude": -49.285192
      },
      {
        "id": 4067,
        "latitude": -8.255801,
        "longitude": -49.270607
      },
      {
        "id": 4068,
        "latitude": -3.830035,
        "longitude": -50.629526
      },
      {
        "id": 5219,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 4069,
        "latitude": -8.256288,
        "longitude": -49.291681
      },
      {
        "id": 4070,
        "latitude": -1.994825,
        "longitude": -47.941602
      },
      {
        "id": 4071,
        "latitude": -1.226762,
        "longitude": -48.297241
      },
      {
        "id": 4072,
        "latitude": -1.958096,
        "longitude": -48.193094
      },
      {
        "id": 4073,
        "latitude": -1.994825,
        "longitude": -47.941602
      },
      {
        "id": 4074,
        "latitude": -0.728608,
        "longitude": -47.860596
      },
      {
        "id": 4075,
        "latitude": -0.649774,
        "longitude": -47.3294
      },
      {
        "id": 4076,
        "latitude": -4.286511,
        "longitude": -47.544249
      },
      {
        "id": 4077,
        "latitude": -4.29491,
        "longitude": -47.554459
      },
      {
        "id": 4078,
        "latitude": -4.292811,
        "longitude": -47.555908
      },
      {
        "id": 4079,
        "latitude": -1.895102,
        "longitude": -47.037376
      },
      {
        "id": 4080,
        "latitude": -1.934893,
        "longitude": -47.048591
      },
      {
        "id": 4081,
        "latitude": -1.929247,
        "longitude": -47.047407
      },
      {
        "id": 4082,
        "latitude": -1.926366,
        "longitude": -47.050521
      },
      {
        "id": 4083,
        "latitude": -1.929127,
        "longitude": -47.045155
      },
      {
        "id": 4084,
        "latitude": -1.930363,
        "longitude": -47.0502
      },
      {
        "id": 4085,
        "latitude": -3.844616,
        "longitude": -49.098099
      },
      {
        "id": 4086,
        "latitude": -3.848511,
        "longitude": -49.098039
      },
      {
        "id": 4087,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4088,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4089,
        "latitude": -2.559214,
        "longitude": -47.496546
      },
      {
        "id": 4090,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4091,
        "latitude": -2.391383,
        "longitude": -47.542167
      },
      {
        "id": 4092,
        "latitude": -2.554411,
        "longitude": -47.499027
      },
      {
        "id": 4093,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4094,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4095,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4096,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4097,
        "latitude": -2.559214,
        "longitude": -47.496546
      },
      {
        "id": 4098,
        "latitude": -2.555855,
        "longitude": -47.507071
      },
      {
        "id": 4099,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4100,
        "latitude": -1.128245,
        "longitude": -48.422358
      },
      {
        "id": 4101,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4102,
        "latitude": -2.56198,
        "longitude": -47.494703
      },
      {
        "id": 4103,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4104,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4105,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4106,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4107,
        "latitude": -2.559214,
        "longitude": -47.496546
      },
      {
        "id": 4108,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4109,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 4110,
        "latitude": -4.254161,
        "longitude": -55.992342
      },
      {
        "id": 4111,
        "latitude": -10.939522,
        "longitude": -69.556809
      },
      {
        "id": 4112,
        "latitude": -4.278685,
        "longitude": -56.025059
      },
      {
        "id": 4113,
        "latitude": -4.262522,
        "longitude": -55.987814
      },
      {
        "id": 4114,
        "latitude": -9.537637,
        "longitude": -35.811795
      },
      {
        "id": 4115,
        "latitude": -1.353798,
        "longitude": -48.363203
      },
      {
        "id": 4116,
        "latitude": -4.43853,
        "longitude": -49.11862
      },
      {
        "id": 4118,
        "latitude": -5.368623,
        "longitude": -49.103778
      },
      {
        "id": 4120,
        "latitude": -5.346282,
        "longitude": -49.10074
      },
      {
        "id": 4121,
        "latitude": -5.346282,
        "longitude": -49.10074
      },
      {
        "id": 4122,
        "latitude": -5.368623,
        "longitude": -49.103778
      },
      {
        "id": 4123,
        "latitude": -5.368623,
        "longitude": -49.103778
      },
      {
        "id": 4124,
        "latitude": -1.359531,
        "longitude": -48.33615
      },
      {
        "id": 4125,
        "latitude": -1.359531,
        "longitude": -48.33615
      },
      {
        "id": 4126,
        "latitude": -1.359531,
        "longitude": -48.33615
      },
      {
        "id": 4128,
        "latitude": -6.122379,
        "longitude": -49.904642
      },
      {
        "id": 4129,
        "latitude": -1.359531,
        "longitude": -48.33615
      },
      {
        "id": 4130,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 4131,
        "latitude": -1.369103,
        "longitude": -48.333922
      },
      {
        "id": 4132,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 4133,
        "latitude": -20.015563,
        "longitude": -44.028814
      },
      {
        "id": 4134,
        "latitude": -19.923587,
        "longitude": -43.932226
      },
      {
        "id": 4135,
        "latitude": -6.122379,
        "longitude": -49.904642
      },
      {
        "id": 4136,
        "latitude": -23.569362,
        "longitude": -47.264981
      },
      {
        "id": 4137,
        "latitude": -6.122379,
        "longitude": -49.904642
      },
      {
        "id": 4139,
        "latitude": -8.492327,
        "longitude": -50.702528
      },
      {
        "id": 4140,
        "latitude": -1.359531,
        "longitude": -48.33615
      },
      {
        "id": 4141,
        "latitude": -20.015563,
        "longitude": -44.028814
      },
      {
        "id": 4142,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 4143,
        "latitude": -1.348826,
        "longitude": -48.42311
      },
      {
        "id": 4144,
        "latitude": -6.122379,
        "longitude": -49.904642
      },
      {
        "id": 4145,
        "latitude": -6.122379,
        "longitude": -49.904642
      },
      {
        "id": 4146,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 4147,
        "latitude": -1.359531,
        "longitude": -48.33615
      },
      {
        "id": 4148,
        "latitude": -6.122379,
        "longitude": -49.904642
      },
      {
        "id": 4150,
        "latitude": -3.845418,
        "longitude": -49.103659
      },
      {
        "id": 4151,
        "latitude": -6.748813,
        "longitude": -51.07811
      },
      {
        "id": 4152,
        "latitude": -6.748813,
        "longitude": -51.07811
      },
      {
        "id": 4153,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 4154,
        "latitude": -1.382683,
        "longitude": -48.435665
      },
      {
        "id": 4155,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 5220,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 4156,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 4157,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 4158,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 4160,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 4161,
        "latitude": -8.030178,
        "longitude": -50.029925
      },
      {
        "id": 4162,
        "latitude": -8.025294,
        "longitude": -50.031687
      },
      {
        "id": 4163,
        "latitude": -0.625287,
        "longitude": -47.362632
      },
      {
        "id": 4165,
        "latitude": -0.625928,
        "longitude": -47.344282
      },
      {
        "id": 4166,
        "latitude": -1.226232,
        "longitude": -48.291652
      },
      {
        "id": 4167,
        "latitude": -1.36518,
        "longitude": -47.305863
      },
      {
        "id": 4168,
        "latitude": -2.454951,
        "longitude": -54.711952
      },
      {
        "id": 4169,
        "latitude": -1.677582,
        "longitude": -47.773423
      },
      {
        "id": 4170,
        "latitude": -1.677582,
        "longitude": -47.773423
      },
      {
        "id": 4171,
        "latitude": -5.586904,
        "longitude": -44.379704
      },
      {
        "id": 4172,
        "latitude": -5.586904,
        "longitude": -44.379704
      },
      {
        "id": 4173,
        "latitude": -1.431718,
        "longitude": -47.585147
      },
      {
        "id": 4174,
        "latitude": -1.677582,
        "longitude": -47.773423
      },
      {
        "id": 4175,
        "latitude": -1.677582,
        "longitude": -47.773423
      },
      {
        "id": 4176,
        "latitude": -1.677582,
        "longitude": -47.773423
      },
      {
        "id": 4177,
        "latitude": -1.677582,
        "longitude": -47.773423
      },
      {
        "id": 4178,
        "latitude": -1.616034,
        "longitude": -47.492205
      },
      {
        "id": 4179,
        "latitude": -1.606514,
        "longitude": -47.482729
      },
      {
        "id": 4180,
        "latitude": -1.601989,
        "longitude": -47.471973
      },
      {
        "id": 4181,
        "latitude": -1.61168,
        "longitude": -47.475755
      },
      {
        "id": 4182,
        "latitude": -1.608408,
        "longitude": -47.48642
      },
      {
        "id": 4183,
        "latitude": -1.608408,
        "longitude": -47.48642
      },
      {
        "id": 4184,
        "latitude": -1.608408,
        "longitude": -47.48642
      },
      {
        "id": 4185,
        "latitude": -1.608408,
        "longitude": -47.48642
      },
      {
        "id": 4186,
        "latitude": -1.615962,
        "longitude": -47.463971
      },
      {
        "id": 4187,
        "latitude": -1.608408,
        "longitude": -47.48642
      },
      {
        "id": 4188,
        "latitude": -1.075664,
        "longitude": -46.90111
      },
      {
        "id": 4189,
        "latitude": -6.759867,
        "longitude": -51.171474
      },
      {
        "id": 4190,
        "latitude": -6.754428,
        "longitude": -51.157583
      },
      {
        "id": 4191,
        "latitude": -3.736454,
        "longitude": -47.488427
      },
      {
        "id": 4192,
        "latitude": -7.430181,
        "longitude": -34.912217
      },
      {
        "id": 4193,
        "latitude": -7.171748,
        "longitude": -34.85686
      },
      {
        "id": 4195,
        "latitude": -7.15186,
        "longitude": -34.918893
      },
      {
        "id": 4196,
        "latitude": -7.144146,
        "longitude": -34.923626
      },
      {
        "id": 4197,
        "latitude": -7.15186,
        "longitude": -34.918893
      },
      {
        "id": 4198,
        "latitude": -7.147804,
        "longitude": -34.919913
      },
      {
        "id": 4199,
        "latitude": -7.151314,
        "longitude": -34.918702
      },
      {
        "id": 4200,
        "latitude": -7.148046,
        "longitude": -34.91822
      },
      {
        "id": 4201,
        "latitude": -7.15186,
        "longitude": -34.918893
      },
      {
        "id": 4202,
        "latitude": -7.330617,
        "longitude": -35.181904
      },
      {
        "id": 4203,
        "latitude": -7.15186,
        "longitude": -34.918893
      },
      {
        "id": 4204,
        "latitude": -7.143124,
        "longitude": -34.931726
      },
      {
        "id": 4205,
        "latitude": -6.188711,
        "longitude": -37.536192
      },
      {
        "id": 4206,
        "latitude": -6.343669,
        "longitude": -37.4993
      },
      {
        "id": 4207,
        "latitude": -6.380427,
        "longitude": -37.340717
      },
      {
        "id": 4224,
        "latitude": -7.230798,
        "longitude": -35.898981
      },
      {
        "id": 4208,
        "latitude": -7.510234,
        "longitude": -34.915411
      },
      {
        "id": 4209,
        "latitude": -6.97324,
        "longitude": -34.835161
      },
      {
        "id": 4211,
        "latitude": -7.025228,
        "longitude": -34.842046
      },
      {
        "id": 4212,
        "latitude": -7.052512,
        "longitude": -34.846359
      },
      {
        "id": 4213,
        "latitude": -7.03825,
        "longitude": -34.840795
      },
      {
        "id": 4214,
        "latitude": -6.966797,
        "longitude": -34.833143
      },
      {
        "id": 4215,
        "latitude": -6.999763,
        "longitude": -34.827612
      },
      {
        "id": 4216,
        "latitude": -6.97324,
        "longitude": -34.835161
      },
      {
        "id": 4217,
        "latitude": -6.889155,
        "longitude": -38.56129
      },
      {
        "id": 4218,
        "latitude": -6.889155,
        "longitude": -38.56129
      },
      {
        "id": 4221,
        "latitude": -7.230798,
        "longitude": -35.898981
      },
      {
        "id": 4222,
        "latitude": -7.102101,
        "longitude": -35.868576
      },
      {
        "id": 4223,
        "latitude": -7.183755,
        "longitude": -35.984048
      },
      {
        "id": 4226,
        "latitude": -7.224674,
        "longitude": -35.877129
      },
      {
        "id": 4227,
        "latitude": -7.224674,
        "longitude": -35.877129
      },
      {
        "id": 4228,
        "latitude": -7.230798,
        "longitude": -35.898981
      },
      {
        "id": 4229,
        "latitude": -7.224674,
        "longitude": -35.877129
      },
      {
        "id": 4230,
        "latitude": -7.224674,
        "longitude": -35.877129
      },
      {
        "id": 4231,
        "latitude": -7.213414,
        "longitude": -35.887165
      },
      {
        "id": 4232,
        "latitude": -7.270665,
        "longitude": -36.099336
      },
      {
        "id": 4233,
        "latitude": -7.245273,
        "longitude": -35.895541
      },
      {
        "id": 4234,
        "latitude": -7.230798,
        "longitude": -35.898981
      },
      {
        "id": 4235,
        "latitude": -7.224674,
        "longitude": -35.877129
      },
      {
        "id": 4236,
        "latitude": -7.273801,
        "longitude": -35.931063
      },
      {
        "id": 4237,
        "latitude": -6.335007,
        "longitude": -37.739358
      },
      {
        "id": 4238,
        "latitude": -7.251652,
        "longitude": -34.917821
      },
      {
        "id": 4239,
        "latitude": -7.256209,
        "longitude": -34.906204
      },
      {
        "id": 4240,
        "latitude": -7.256209,
        "longitude": -34.906204
      },
      {
        "id": 4241,
        "latitude": -7.260036,
        "longitude": -34.905352
      },
      {
        "id": 4242,
        "latitude": -6.487614,
        "longitude": -36.155027
      },
      {
        "id": 4243,
        "latitude": -7.027032,
        "longitude": -37.286285
      },
      {
        "id": 4244,
        "latitude": -7.121598,
        "longitude": -34.882028
      },
      {
        "id": 4245,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4246,
        "latitude": -7.15065,
        "longitude": -34.906618
      },
      {
        "id": 4247,
        "latitude": -7.125513,
        "longitude": -34.939528
      },
      {
        "id": 4248,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4249,
        "latitude": -6.96078,
        "longitude": -34.863206
      },
      {
        "id": 4250,
        "latitude": -7.177191,
        "longitude": -34.929467
      },
      {
        "id": 4251,
        "latitude": -7.168363,
        "longitude": -34.848406
      },
      {
        "id": 4252,
        "latitude": -7.21667,
        "longitude": -34.88333
      },
      {
        "id": 4253,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4254,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4255,
        "latitude": -7.179435,
        "longitude": -34.925379
      },
      {
        "id": 4408,
        "latitude": -24.794072,
        "longitude": -49.997291
      },
      {
        "id": 4258,
        "latitude": -7.183692,
        "longitude": -34.897936
      },
      {
        "id": 4259,
        "latitude": -7.171201,
        "longitude": -34.889252
      },
      {
        "id": 4260,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4261,
        "latitude": -7.144629,
        "longitude": -34.9099
      },
      {
        "id": 4262,
        "latitude": -7.160388,
        "longitude": -34.850128
      },
      {
        "id": 4263,
        "latitude": -7.121598,
        "longitude": -34.882028
      },
      {
        "id": 4264,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4265,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4267,
        "latitude": -7.011348,
        "longitude": -35.853527
      },
      {
        "id": 4268,
        "latitude": -7.125582,
        "longitude": -34.825637
      },
      {
        "id": 4269,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4270,
        "latitude": -7.150742,
        "longitude": -34.882861
      },
      {
        "id": 4271,
        "latitude": -7.121598,
        "longitude": -34.882028
      },
      {
        "id": 4272,
        "latitude": -7.095072,
        "longitude": -34.841952
      },
      {
        "id": 4273,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 4427,
        "latitude": -25.369594,
        "longitude": -49.237095
      },
      {
        "id": 4274,
        "latitude": -7.207452,
        "longitude": -34.835696
      },
      {
        "id": 4275,
        "latitude": -7.212395,
        "longitude": -34.834205
      },
      {
        "id": 4276,
        "latitude": -7.153883,
        "longitude": -34.832145
      },
      {
        "id": 4277,
        "latitude": -7.173975,
        "longitude": -34.895034
      },
      {
        "id": 4278,
        "latitude": -7.171936,
        "longitude": -34.893804
      },
      {
        "id": 4279,
        "latitude": -6.690148,
        "longitude": -34.931885
      },
      {
        "id": 4280,
        "latitude": -6.898402,
        "longitude": -34.871192
      },
      {
        "id": 4281,
        "latitude": -7.892018,
        "longitude": -37.123205
      },
      {
        "id": 4282,
        "latitude": -7.017985,
        "longitude": -37.277773
      },
      {
        "id": 4283,
        "latitude": -7.025829,
        "longitude": -37.276682
      },
      {
        "id": 4284,
        "latitude": -7.017985,
        "longitude": -37.277773
      },
      {
        "id": 4285,
        "latitude": -7.017985,
        "longitude": -37.277773
      },
      {
        "id": 4286,
        "latitude": -6.598036,
        "longitude": -37.621577
      },
      {
        "id": 4287,
        "latitude": -6.51271,
        "longitude": -36.343883
      },
      {
        "id": 4288,
        "latitude": -7.387501,
        "longitude": -34.811482
      },
      {
        "id": 4289,
        "latitude": -23.530432,
        "longitude": -46.421638
      },
      {
        "id": 4290,
        "latitude": -7.076283,
        "longitude": -36.062993
      },
      {
        "id": 4291,
        "latitude": -7.076283,
        "longitude": -36.062993
      },
      {
        "id": 4292,
        "latitude": -7.188051,
        "longitude": -35.992329
      },
      {
        "id": 4293,
        "latitude": -7.076283,
        "longitude": -36.062993
      },
      {
        "id": 4294,
        "latitude": -7.076283,
        "longitude": -36.062993
      },
      {
        "id": 4295,
        "latitude": -7.076283,
        "longitude": -36.062993
      },
      {
        "id": 4297,
        "latitude": -6.771132,
        "longitude": -37.798865
      },
      {
        "id": 4298,
        "latitude": -6.772486,
        "longitude": -37.804894
      },
      {
        "id": 4299,
        "latitude": -6.778805,
        "longitude": -37.801289
      },
      {
        "id": 4300,
        "latitude": -6.803828,
        "longitude": -35.077569
      },
      {
        "id": 4301,
        "latitude": -6.801754,
        "longitude": -35.073342
      },
      {
        "id": 4302,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4303,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4304,
        "latitude": -7.15051,
        "longitude": -34.971853
      },
      {
        "id": 4305,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4306,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4307,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4308,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4309,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4310,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4311,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4312,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4313,
        "latitude": -7.128465,
        "longitude": -34.984619
      },
      {
        "id": 4314,
        "latitude": -7.401373,
        "longitude": -35.101655
      },
      {
        "id": 4315,
        "latitude": -7.163191,
        "longitude": -34.974306
      },
      {
        "id": 4317,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4318,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4319,
        "latitude": -7.152508,
        "longitude": -34.963037
      },
      {
        "id": 4320,
        "latitude": -7.144122,
        "longitude": -34.968465
      },
      {
        "id": 4321,
        "latitude": -7.123858,
        "longitude": -34.943365
      },
      {
        "id": 4322,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4323,
        "latitude": -7.132406,
        "longitude": -34.966756
      },
      {
        "id": 4324,
        "latitude": -7.131644,
        "longitude": -34.95044
      },
      {
        "id": 4326,
        "latitude": -6.28333,
        "longitude": -37.75
      },
      {
        "id": 4327,
        "latitude": -6.28333,
        "longitude": -37.75
      },
      {
        "id": 4328,
        "latitude": -6.28333,
        "longitude": -37.75
      },
      {
        "id": 4329,
        "latitude": -6.28333,
        "longitude": -37.75
      },
      {
        "id": 4330,
        "latitude": -7.676741,
        "longitude": -36.894534
      },
      {
        "id": 4331,
        "latitude": -7.676741,
        "longitude": -36.894534
      },
      {
        "id": 4332,
        "latitude": -7.672015,
        "longitude": -36.886134
      },
      {
        "id": 4333,
        "latitude": -7.672015,
        "longitude": -36.886134
      },
      {
        "id": 4334,
        "latitude": -25.339846,
        "longitude": -49.255092
      },
      {
        "id": 4335,
        "latitude": -25.339846,
        "longitude": -49.255092
      },
      {
        "id": 4336,
        "latitude": -25.339846,
        "longitude": -49.255092
      },
      {
        "id": 4337,
        "latitude": -25.36819,
        "longitude": -49.315435
      },
      {
        "id": 4338,
        "latitude": -25.313305,
        "longitude": -49.299784
      },
      {
        "id": 4339,
        "latitude": -25.339846,
        "longitude": -49.255092
      },
      {
        "id": 4340,
        "latitude": -25.343709,
        "longitude": -49.260314
      },
      {
        "id": 4481,
        "latitude": -25.381879,
        "longitude": -51.477205
      },
      {
        "id": 4341,
        "latitude": -24.025064,
        "longitude": -53.443122
      },
      {
        "id": 4343,
        "latitude": -24.023902,
        "longitude": -53.441745
      },
      {
        "id": 4344,
        "latitude": -23.051859,
        "longitude": -50.245578
      },
      {
        "id": 4345,
        "latitude": -22.963742,
        "longitude": -50.26834
      },
      {
        "id": 4346,
        "latitude": -23.557787,
        "longitude": -51.473632
      },
      {
        "id": 4347,
        "latitude": -23.420736,
        "longitude": -51.442753
      },
      {
        "id": 4348,
        "latitude": -23.414273,
        "longitude": -51.419135
      },
      {
        "id": 4349,
        "latitude": -23.414273,
        "longitude": -51.419135
      },
      {
        "id": 4350,
        "latitude": -23.932342,
        "longitude": -52.497401
      },
      {
        "id": 4352,
        "latitude": -23.379219,
        "longitude": -50.846303
      },
      {
        "id": 4353,
        "latitude": -23.379219,
        "longitude": -50.846303
      },
      {
        "id": 4354,
        "latitude": -24.405072,
        "longitude": -53.523829
      },
      {
        "id": 4355,
        "latitude": -23.542646,
        "longitude": -51.425426
      },
      {
        "id": 4356,
        "latitude": -23.278204,
        "longitude": -51.277958
      },
      {
        "id": 4357,
        "latitude": -23.284261,
        "longitude": -51.262166
      },
      {
        "id": 4358,
        "latitude": -23.278204,
        "longitude": -51.277958
      },
      {
        "id": 4359,
        "latitude": -25.400176,
        "longitude": -49.340501
      },
      {
        "id": 4360,
        "latitude": -25.501136,
        "longitude": -49.541626
      },
      {
        "id": 4361,
        "latitude": -25.459773,
        "longitude": -49.527085
      },
      {
        "id": 4362,
        "latitude": -25.430274,
        "longitude": -49.23916
      },
      {
        "id": 4363,
        "latitude": -25.351167,
        "longitude": -49.258541
      },
      {
        "id": 4364,
        "latitude": -25.459773,
        "longitude": -49.527085
      },
      {
        "id": 4365,
        "latitude": -25.472622,
        "longitude": -49.528768
      },
      {
        "id": 4366,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4367,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4368,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4369,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4370,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4371,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4372,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4373,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4374,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 5103,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 4375,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4376,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4377,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4378,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4379,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4380,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4381,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4382,
        "latitude": -25.459773,
        "longitude": -49.527085
      },
      {
        "id": 4383,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4384,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4385,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4386,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4387,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4388,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4389,
        "latitude": -25.465422,
        "longitude": -49.515991
      },
      {
        "id": 4390,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 5188,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 4391,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4392,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4393,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4394,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4395,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4396,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4397,
        "latitude": -25.485982,
        "longitude": -49.57608
      },
      {
        "id": 4398,
        "latitude": -24.916555,
        "longitude": -53.413828
      },
      {
        "id": 4399,
        "latitude": -24.914231,
        "longitude": -53.412559
      },
      {
        "id": 4400,
        "latitude": -9.929696,
        "longitude": -56.065403
      },
      {
        "id": 4401,
        "latitude": -9.929696,
        "longitude": -56.065403
      },
      {
        "id": 4402,
        "latitude": -24.96001,
        "longitude": -53.455156
      },
      {
        "id": 4403,
        "latitude": -24.96001,
        "longitude": -53.455156
      },
      {
        "id": 4404,
        "latitude": -24.96001,
        "longitude": -53.455156
      },
      {
        "id": 4405,
        "latitude": -24.96001,
        "longitude": -53.455156
      },
      {
        "id": 4406,
        "latitude": -24.914231,
        "longitude": -53.412559
      },
      {
        "id": 4407,
        "latitude": -26.525887,
        "longitude": -50.72332
      },
      {
        "id": 4409,
        "latitude": -23.662457,
        "longitude": -52.612471
      },
      {
        "id": 4410,
        "latitude": -23.662036,
        "longitude": -52.610399
      },
      {
        "id": 4411,
        "latitude": -23.674886,
        "longitude": -52.626508
      },
      {
        "id": 4412,
        "latitude": -25.390085,
        "longitude": -49.181674
      },
      {
        "id": 4413,
        "latitude": -25.292544,
        "longitude": -49.224347
      },
      {
        "id": 4414,
        "latitude": -25.43581,
        "longitude": -54.595816
      },
      {
        "id": 4415,
        "latitude": -25.292544,
        "longitude": -49.224347
      },
      {
        "id": 4416,
        "latitude": -25.434665,
        "longitude": -49.25105
      },
      {
        "id": 4417,
        "latitude": -25.615358,
        "longitude": -53.134035
      },
      {
        "id": 4418,
        "latitude": -22.962368,
        "longitude": -52.162569
      },
      {
        "id": 4419,
        "latitude": -25.873187,
        "longitude": -50.404766
      },
      {
        "id": 4420,
        "latitude": -25.584117,
        "longitude": -49.321017
      },
      {
        "id": 4421,
        "latitude": -25.7926,
        "longitude": -49.4376
      },
      {
        "id": 4422,
        "latitude": -25.429596,
        "longitude": -49.271272
      },
      {
        "id": 4423,
        "latitude": -25.601504,
        "longitude": -49.27729
      },
      {
        "id": 4424,
        "latitude": -25.429596,
        "longitude": -49.271272
      },
      {
        "id": 4425,
        "latitude": -25.413766,
        "longitude": -49.267701
      },
      {
        "id": 4426,
        "latitude": -25.503349,
        "longitude": -49.301921
      },
      {
        "id": 4428,
        "latitude": -25.528389,
        "longitude": -49.210913
      },
      {
        "id": 4429,
        "latitude": -25.507834,
        "longitude": -49.289177
      },
      {
        "id": 4430,
        "latitude": -25.529282,
        "longitude": -49.247906
      },
      {
        "id": 4431,
        "latitude": -25.429596,
        "longitude": -49.271272
      },
      {
        "id": 4432,
        "latitude": -25.406769,
        "longitude": -49.211535
      },
      {
        "id": 4433,
        "latitude": -25.592698,
        "longitude": -49.343867
      },
      {
        "id": 4434,
        "latitude": -25.515666,
        "longitude": -49.284409
      },
      {
        "id": 4435,
        "latitude": -25.424049,
        "longitude": -49.251711
      },
      {
        "id": 4436,
        "latitude": -25.507834,
        "longitude": -49.289177
      },
      {
        "id": 4437,
        "latitude": -25.429596,
        "longitude": -49.271272
      },
      {
        "id": 4438,
        "latitude": -25.55361,
        "longitude": -49.30972
      },
      {
        "id": 4439,
        "latitude": -25.48373,
        "longitude": -49.25016
      },
      {
        "id": 4440,
        "latitude": -25.461486,
        "longitude": -49.241833
      },
      {
        "id": 4441,
        "latitude": -25.415841,
        "longitude": -49.243627
      },
      {
        "id": 4442,
        "latitude": -25.383549,
        "longitude": -49.261617
      },
      {
        "id": 4443,
        "latitude": -25.513117,
        "longitude": -49.297246
      },
      {
        "id": 4444,
        "latitude": -25.389034,
        "longitude": -49.198971
      },
      {
        "id": 4445,
        "latitude": -25.459935,
        "longitude": -49.280018
      },
      {
        "id": 4446,
        "latitude": -25.459935,
        "longitude": -49.280018
      },
      {
        "id": 4447,
        "latitude": -25.429596,
        "longitude": -49.271272
      },
      {
        "id": 4448,
        "latitude": -25.459935,
        "longitude": -49.280018
      },
      {
        "id": 4449,
        "latitude": -25.533073,
        "longitude": -49.261215
      },
      {
        "id": 4450,
        "latitude": -25.459935,
        "longitude": -49.280018
      },
      {
        "id": 4451,
        "latitude": -25.749611,
        "longitude": -53.065036
      },
      {
        "id": 4452,
        "latitude": -9.539015,
        "longitude": -35.809139
      },
      {
        "id": 4453,
        "latitude": -25.395349,
        "longitude": -49.245762
      },
      {
        "id": 4454,
        "latitude": -25.668177,
        "longitude": -49.288581
      },
      {
        "id": 4455,
        "latitude": -25.659345,
        "longitude": -49.313107
      },
      {
        "id": 4456,
        "latitude": -25.659766,
        "longitude": -49.333896
      },
      {
        "id": 4459,
        "latitude": -25.068738,
        "longitude": -50.128914
      },
      {
        "id": 4460,
        "latitude": -23.61241,
        "longitude": -52.083464
      },
      {
        "id": 4461,
        "latitude": -23.61241,
        "longitude": -52.083464
      },
      {
        "id": 4462,
        "latitude": -23.61241,
        "longitude": -52.083464
      },
      {
        "id": 4463,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 4464,
        "latitude": -22.861461,
        "longitude": -51.398532
      },
      {
        "id": 4465,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 4466,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 4467,
        "latitude": -22.866394,
        "longitude": -51.390512
      },
      {
        "id": 4468,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 4469,
        "latitude": -22.866394,
        "longitude": -51.390512
      },
      {
        "id": 4470,
        "latitude": -22.866394,
        "longitude": -51.390512
      },
      {
        "id": 4471,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 4472,
        "latitude": -25.540148,
        "longitude": -54.585814
      },
      {
        "id": 4473,
        "latitude": -25.49166,
        "longitude": -54.536772
      },
      {
        "id": 4474,
        "latitude": -25.511383,
        "longitude": -54.550203
      },
      {
        "id": 4475,
        "latitude": -24.070076,
        "longitude": -53.847873
      },
      {
        "id": 4476,
        "latitude": -24.070076,
        "longitude": -53.847873
      },
      {
        "id": 4477,
        "latitude": -9.537461,
        "longitude": -35.810166
      },
      {
        "id": 4478,
        "latitude": -24.070076,
        "longitude": -53.847873
      },
      {
        "id": 4479,
        "latitude": -24.06944,
        "longitude": -53.844348
      },
      {
        "id": 4482,
        "latitude": -25.410165,
        "longitude": -51.477627
      },
      {
        "id": 4483,
        "latitude": -25.411798,
        "longitude": -51.476539
      },
      {
        "id": 4484,
        "latitude": -23.272089,
        "longitude": -51.057229
      },
      {
        "id": 4485,
        "latitude": -25.474089,
        "longitude": -50.663251
      },
      {
        "id": 4486,
        "latitude": -24.423791,
        "longitude": -52.104061
      },
      {
        "id": 4487,
        "latitude": -23.408713,
        "longitude": -53.369879
      },
      {
        "id": 4488,
        "latitude": -23.151824,
        "longitude": -49.971368
      },
      {
        "id": 4489,
        "latitude": -23.151824,
        "longitude": -49.971368
      },
      {
        "id": 4490,
        "latitude": -23.114305,
        "longitude": -51.542054
      },
      {
        "id": 4491,
        "latitude": -23.110045,
        "longitude": -51.533792
      },
      {
        "id": 4492,
        "latitude": -23.110045,
        "longitude": -51.533792
      },
      {
        "id": 4493,
        "latitude": -23.110045,
        "longitude": -51.533792
      },
      {
        "id": 4494,
        "latitude": -23.110045,
        "longitude": -51.533792
      },
      {
        "id": 4495,
        "latitude": -23.600391,
        "longitude": -51.645674
      },
      {
        "id": 4496,
        "latitude": -23.258807,
        "longitude": -50.999369
      },
      {
        "id": 4497,
        "latitude": -23.607398,
        "longitude": -49.625948
      },
      {
        "id": 4498,
        "latitude": -25.400002,
        "longitude": -52.404595
      },
      {
        "id": 4499,
        "latitude": -23.311288,
        "longitude": -51.159502
      },
      {
        "id": 4500,
        "latitude": -23.25,
        "longitude": -51.15
      },
      {
        "id": 4501,
        "latitude": -23.325554,
        "longitude": -51.157232
      },
      {
        "id": 4502,
        "latitude": -23.269067,
        "longitude": -51.165242
      },
      {
        "id": 4504,
        "latitude": -23.281499,
        "longitude": -51.145043
      },
      {
        "id": 4505,
        "latitude": -23.26258,
        "longitude": -51.193202
      },
      {
        "id": 4506,
        "latitude": -23.325554,
        "longitude": -51.157232
      },
      {
        "id": 4507,
        "latitude": -23.325554,
        "longitude": -51.157232
      },
      {
        "id": 4508,
        "latitude": -23.311288,
        "longitude": -51.159502
      },
      {
        "id": 4509,
        "latitude": -23.348434,
        "longitude": -52.102536
      },
      {
        "id": 4510,
        "latitude": -23.348508,
        "longitude": -52.096622
      },
      {
        "id": 4511,
        "latitude": -23.348434,
        "longitude": -52.102536
      },
      {
        "id": 4512,
        "latitude": -23.348434,
        "longitude": -52.102536
      },
      {
        "id": 4513,
        "latitude": -23.78311,
        "longitude": -53.328975
      },
      {
        "id": 4514,
        "latitude": -23.513232,
        "longitude": -51.686458
      },
      {
        "id": 4515,
        "latitude": -23.522485,
        "longitude": -51.678751
      },
      {
        "id": 4516,
        "latitude": -25.775141,
        "longitude": -49.320781
      },
      {
        "id": 4517,
        "latitude": -24.557421,
        "longitude": -54.054126
      },
      {
        "id": 4518,
        "latitude": -24.008564,
        "longitude": -53.148496
      },
      {
        "id": 4519,
        "latitude": -24.008564,
        "longitude": -53.148496
      },
      {
        "id": 4520,
        "latitude": -23.41946,
        "longitude": -51.934664
      },
      {
        "id": 4521,
        "latitude": -29.990782,
        "longitude": -51.041172
      },
      {
        "id": 4522,
        "latitude": -23.387874,
        "longitude": -51.907581
      },
      {
        "id": 4523,
        "latitude": -23.390225,
        "longitude": -51.911297
      },
      {
        "id": 4524,
        "latitude": -23.423318,
        "longitude": -51.894753
      },
      {
        "id": 4526,
        "latitude": -23.425269,
        "longitude": -51.938208
      },
      {
        "id": 4527,
        "latitude": -23.422098,
        "longitude": -51.938731
      },
      {
        "id": 4528,
        "latitude": -23.425269,
        "longitude": -51.938208
      },
      {
        "id": 4529,
        "latitude": -23.425269,
        "longitude": -51.938208
      },
      {
        "id": 4530,
        "latitude": -23.454038,
        "longitude": -51.983793
      },
      {
        "id": 4531,
        "latitude": -23.431453,
        "longitude": -51.886737
      },
      {
        "id": 4532,
        "latitude": -23.441732,
        "longitude": -51.925617
      },
      {
        "id": 4533,
        "latitude": -23.422098,
        "longitude": -51.938731
      },
      {
        "id": 4534,
        "latitude": -23.431453,
        "longitude": -51.886737
      },
      {
        "id": 4535,
        "latitude": -23.399234,
        "longitude": -51.877534
      },
      {
        "id": 4536,
        "latitude": -23.422098,
        "longitude": -51.938731
      },
      {
        "id": 4537,
        "latitude": -23.387874,
        "longitude": -51.907581
      },
      {
        "id": 4538,
        "latitude": -23.390225,
        "longitude": -51.911297
      },
      {
        "id": 4539,
        "latitude": -23.425269,
        "longitude": -51.938208
      },
      {
        "id": 4540,
        "latitude": -25.240418,
        "longitude": -53.9835
      },
      {
        "id": 4541,
        "latitude": -25.876846,
        "longitude": -48.5701
      },
      {
        "id": 4542,
        "latitude": -25.772114,
        "longitude": -48.526277
      },
      {
        "id": 4543,
        "latitude": -23.901483,
        "longitude": -51.234679
      },
      {
        "id": 4544,
        "latitude": -23.904552,
        "longitude": -51.226117
      },
      {
        "id": 4545,
        "latitude": -23.901483,
        "longitude": -51.234679
      },
      {
        "id": 4546,
        "latitude": -23.901483,
        "longitude": -51.234679
      },
      {
        "id": 4547,
        "latitude": -23.901483,
        "longitude": -51.234679
      },
      {
        "id": 4548,
        "latitude": -23.901483,
        "longitude": -51.234679
      },
      {
        "id": 4549,
        "latitude": -23.901483,
        "longitude": -51.234679
      },
      {
        "id": 4550,
        "latitude": -22.930753,
        "longitude": -52.149991
      },
      {
        "id": 4551,
        "latitude": -23.175971,
        "longitude": -52.216158
      },
      {
        "id": 4552,
        "latitude": -23.476778,
        "longitude": -53.085974
      },
      {
        "id": 4553,
        "latitude": -23.476778,
        "longitude": -53.085974
      },
      {
        "id": 4554,
        "latitude": -24.465831,
        "longitude": -53.954256
      },
      {
        "id": 4555,
        "latitude": -24.438498,
        "longitude": -51.946099
      },
      {
        "id": 4556,
        "latitude": -23.457588,
        "longitude": -52.048988
      },
      {
        "id": 4557,
        "latitude": -23.452811,
        "longitude": -52.025273
      },
      {
        "id": 4558,
        "latitude": -23.454179,
        "longitude": -52.040976
      },
      {
        "id": 4560,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 4561,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 4562,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 4563,
        "latitude": -15.50792,
        "longitude": -39.645077
      },
      {
        "id": 4564,
        "latitude": -25.593642,
        "longitude": -48.571703
      },
      {
        "id": 4565,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 4566,
        "latitude": -25.514882,
        "longitude": -48.52267
      },
      {
        "id": 4567,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 4568,
        "latitude": -25.516297,
        "longitude": -48.517131
      },
      {
        "id": 4569,
        "latitude": -15.333763,
        "longitude": -39.639742
      },
      {
        "id": 4570,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 4571,
        "latitude": -25.565836,
        "longitude": -48.57374
      },
      {
        "id": 4572,
        "latitude": -25.580929,
        "longitude": -48.577854
      },
      {
        "id": 4573,
        "latitude": -25.565857,
        "longitude": -48.555047
      },
      {
        "id": 4574,
        "latitude": -23.08165,
        "longitude": -52.461724
      },
      {
        "id": 4575,
        "latitude": -20.606159,
        "longitude": -44.201767
      },
      {
        "id": 4576,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4577,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4578,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4579,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4580,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4581,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4582,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4583,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4584,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4585,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4586,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4587,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4588,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4589,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4591,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4592,
        "latitude": -25.455151,
        "longitude": -49.351363
      },
      {
        "id": 4593,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4594,
        "latitude": -22.72127,
        "longitude": -53.177036
      },
      {
        "id": 4595,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4596,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4597,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4598,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4599,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4600,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4601,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4602,
        "latitude": -23.506207,
        "longitude": -53.730308
      },
      {
        "id": 4603,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4604,
        "latitude": -23.793009,
        "longitude": -53.67674
      },
      {
        "id": 4605,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4606,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4607,
        "latitude": -25.455151,
        "longitude": -49.351363
      },
      {
        "id": 4608,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4609,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4610,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4611,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4612,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4613,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4614,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4615,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4616,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4617,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4618,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4619,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4620,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4621,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4622,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4623,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4624,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4626,
        "latitude": -23.803167,
        "longitude": -53.682497
      },
      {
        "id": 4627,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 4628,
        "latitude": -25.4253,
        "longitude": -49.276193
      },
      {
        "id": 4629,
        "latitude": -25.446418,
        "longitude": -49.068935
      },
      {
        "id": 4630,
        "latitude": -25.446418,
        "longitude": -49.068935
      },
      {
        "id": 4631,
        "latitude": -25.446418,
        "longitude": -49.068935
      },
      {
        "id": 4632,
        "latitude": -25.4246,
        "longitude": -49.050491
      },
      {
        "id": 4633,
        "latitude": -25.423955,
        "longitude": -49.049606
      },
      {
        "id": 4634,
        "latitude": -25.455762,
        "longitude": -49.292282
      },
      {
        "id": 4635,
        "latitude": -25.446418,
        "longitude": -49.068935
      },
      {
        "id": 4636,
        "latitude": -25.446418,
        "longitude": -49.068935
      },
      {
        "id": 4637,
        "latitude": -25.442327,
        "longitude": -49.060447
      },
      {
        "id": 4638,
        "latitude": -25.462694,
        "longitude": -49.292166
      },
      {
        "id": 4639,
        "latitude": -25.442988,
        "longitude": -49.230452
      },
      {
        "id": 4640,
        "latitude": -25.107222,
        "longitude": -50.140055
      },
      {
        "id": 4641,
        "latitude": -24.991283,
        "longitude": -50.283759
      },
      {
        "id": 4642,
        "latitude": -25.107222,
        "longitude": -50.140055
      },
      {
        "id": 4644,
        "latitude": -25.70248,
        "longitude": -48.48123
      },
      {
        "id": 4645,
        "latitude": -22.753259,
        "longitude": -51.378418
      },
      {
        "id": 4646,
        "latitude": -22.753259,
        "longitude": -51.378418
      },
      {
        "id": 4648,
        "latitude": -25.450156,
        "longitude": -52.910261
      },
      {
        "id": 4649,
        "latitude": -23.076028,
        "longitude": -50.915497
      },
      {
        "id": 4650,
        "latitude": -25.770138,
        "longitude": -53.528955
      },
      {
        "id": 4651,
        "latitude": -25.770138,
        "longitude": -53.528955
      },
      {
        "id": 4652,
        "latitude": -25.622552,
        "longitude": -50.698107
      },
      {
        "id": 4653,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4654,
        "latitude": -23.31199,
        "longitude": -51.367415
      },
      {
        "id": 4655,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4656,
        "latitude": -23.318846,
        "longitude": -51.360924
      },
      {
        "id": 4657,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4658,
        "latitude": -23.318846,
        "longitude": -51.360924
      },
      {
        "id": 4659,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4660,
        "latitude": -25.44677,
        "longitude": -49.215653
      },
      {
        "id": 4661,
        "latitude": -23.614984,
        "longitude": -51.253328
      },
      {
        "id": 4662,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4663,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4664,
        "latitude": -25.44677,
        "longitude": -49.215653
      },
      {
        "id": 4665,
        "latitude": -25.44677,
        "longitude": -49.215653
      },
      {
        "id": 4666,
        "latitude": -23.326034,
        "longitude": -51.385947
      },
      {
        "id": 4667,
        "latitude": -23.318846,
        "longitude": -51.360924
      },
      {
        "id": 4668,
        "latitude": -23.770353,
        "longitude": -53.338324
      },
      {
        "id": 4671,
        "latitude": -23.329204,
        "longitude": -51.384378
      },
      {
        "id": 4672,
        "latitude": -23.318846,
        "longitude": -51.360924
      },
      {
        "id": 4673,
        "latitude": -25.44677,
        "longitude": -49.215653
      },
      {
        "id": 4674,
        "latitude": -22.817265,
        "longitude": -51.595302
      },
      {
        "id": 4675,
        "latitude": -23.004539,
        "longitude": -53.19501
      },
      {
        "id": 4676,
        "latitude": -25.819843,
        "longitude": -53.48157
      },
      {
        "id": 4677,
        "latitude": -24.936629,
        "longitude": -51.87038
      },
      {
        "id": 4678,
        "latitude": -22.736127,
        "longitude": -52.344377
      },
      {
        "id": 4679,
        "latitude": -26.075601,
        "longitude": -53.723811
      },
      {
        "id": 4680,
        "latitude": -23.991549,
        "longitude": -51.818965
      },
      {
        "id": 4681,
        "latitude": -23.76017,
        "longitude": -53.881703
      },
      {
        "id": 4682,
        "latitude": -25.564304,
        "longitude": -49.208697
      },
      {
        "id": 4683,
        "latitude": -25.516411,
        "longitude": -49.123947
      },
      {
        "id": 4684,
        "latitude": -25.559001,
        "longitude": -49.170947
      },
      {
        "id": 4689,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4690,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4691,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4692,
        "latitude": -25.493707,
        "longitude": -49.171729
      },
      {
        "id": 4693,
        "latitude": -23.471105,
        "longitude": -46.616546
      },
      {
        "id": 4694,
        "latitude": -25.522708,
        "longitude": -49.1313
      },
      {
        "id": 4695,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4696,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4697,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4698,
        "latitude": -25.536753,
        "longitude": -48.643535
      },
      {
        "id": 4699,
        "latitude": -25.436177,
        "longitude": -49.324134
      },
      {
        "id": 4700,
        "latitude": -25.651149,
        "longitude": -49.185591
      },
      {
        "id": 4701,
        "latitude": -25.515452,
        "longitude": -49.190199
      },
      {
        "id": 4702,
        "latitude": -25.527962,
        "longitude": -49.180008
      },
      {
        "id": 4703,
        "latitude": -25.555898,
        "longitude": -49.180965
      },
      {
        "id": 4704,
        "latitude": -23.866607,
        "longitude": -51.856079
      },
      {
        "id": 4705,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4706,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4707,
        "latitude": -23.442501,
        "longitude": -51.859356
      },
      {
        "id": 4708,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4709,
        "latitude": -23.444117,
        "longitude": -51.876016
      },
      {
        "id": 4710,
        "latitude": -23.444117,
        "longitude": -51.876016
      },
      {
        "id": 4711,
        "latitude": -23.444117,
        "longitude": -51.876016
      },
      {
        "id": 4712,
        "latitude": -23.456553,
        "longitude": -51.872803
      },
      {
        "id": 4713,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4714,
        "latitude": -9.534824,
        "longitude": -35.801257
      },
      {
        "id": 4715,
        "latitude": -23.431379,
        "longitude": -51.852972
      },
      {
        "id": 4716,
        "latitude": -23.433693,
        "longitude": -51.8534
      },
      {
        "id": 4717,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4718,
        "latitude": -23.398178,
        "longitude": -52.18855
      },
      {
        "id": 4719,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4720,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4721,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4722,
        "latitude": -23.444117,
        "longitude": -51.876016
      },
      {
        "id": 4723,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4724,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4725,
        "latitude": -23.453538,
        "longitude": -51.882752
      },
      {
        "id": 4726,
        "latitude": -23.456553,
        "longitude": -51.872803
      },
      {
        "id": 4727,
        "latitude": -23.456553,
        "longitude": -51.872803
      },
      {
        "id": 4728,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4729,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4730,
        "latitude": -23.453538,
        "longitude": -51.882752
      },
      {
        "id": 4731,
        "latitude": -23.444117,
        "longitude": -51.876016
      },
      {
        "id": 4732,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 4733,
        "latitude": -22.850122,
        "longitude": -50.87403
      },
      {
        "id": 4734,
        "latitude": -23.281683,
        "longitude": -51.128379
      },
      {
        "id": 4735,
        "latitude": -23.728403,
        "longitude": -52.872609
      },
      {
        "id": 4736,
        "latitude": -23.768577,
        "longitude": -52.449798
      },
      {
        "id": 4737,
        "latitude": -24.168473,
        "longitude": -54.093496
      },
      {
        "id": 4738,
        "latitude": -24.168473,
        "longitude": -54.093496
      },
      {
        "id": 4739,
        "latitude": -24.168473,
        "longitude": -54.093496
      },
      {
        "id": 4740,
        "latitude": -24.168473,
        "longitude": -54.093496
      },
      {
        "id": 4741,
        "latitude": -24.168473,
        "longitude": -54.093496
      },
      {
        "id": 4742,
        "latitude": -24.726759,
        "longitude": -53.739696
      },
      {
        "id": 4743,
        "latitude": -24.726759,
        "longitude": -53.739696
      },
      {
        "id": 4744,
        "latitude": -24.726759,
        "longitude": -53.739696
      },
      {
        "id": 4745,
        "latitude": -25.517298,
        "longitude": -49.231103
      },
      {
        "id": 4746,
        "latitude": -25.096475,
        "longitude": -50.148187
      },
      {
        "id": 4747,
        "latitude": -23.765073,
        "longitude": -53.303886
      },
      {
        "id": 4748,
        "latitude": -23.765073,
        "longitude": -53.303886
      },
      {
        "id": 4749,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4750,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4751,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4752,
        "latitude": -8.011066,
        "longitude": -35.035724
      },
      {
        "id": 4753,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4754,
        "latitude": -7.756964,
        "longitude": -34.828511
      },
      {
        "id": 4755,
        "latitude": -7.905213,
        "longitude": -34.897231
      },
      {
        "id": 4756,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4757,
        "latitude": -7.900719,
        "longitude": -34.898389
      },
      {
        "id": 4758,
        "latitude": -7.91895,
        "longitude": -34.950611
      },
      {
        "id": 4759,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4760,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4761,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4762,
        "latitude": -7.906278,
        "longitude": -34.904592
      },
      {
        "id": 4763,
        "latitude": -7.75271,
        "longitude": -37.639246
      },
      {
        "id": 4764,
        "latitude": -7.75271,
        "longitude": -37.639246
      },
      {
        "id": 4765,
        "latitude": -7.75271,
        "longitude": -37.639246
      },
      {
        "id": 4766,
        "latitude": -7.55405,
        "longitude": -37.707804
      },
      {
        "id": 4767,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4768,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4769,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4770,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4771,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4772,
        "latitude": -7.605351,
        "longitude": -35.22215
      },
      {
        "id": 4773,
        "latitude": -7.402225,
        "longitude": -35.106261
      },
      {
        "id": 4774,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4775,
        "latitude": -7.604893,
        "longitude": -35.232247
      },
      {
        "id": 4776,
        "latitude": -7.604707,
        "longitude": -35.224817
      },
      {
        "id": 4777,
        "latitude": -7.576858,
        "longitude": -40.503851
      },
      {
        "id": 4778,
        "latitude": -21.635673,
        "longitude": -41.763086
      },
      {
        "id": 4779,
        "latitude": -8.423,
        "longitude": -37.068604
      },
      {
        "id": 4780,
        "latitude": -8.41069,
        "longitude": -37.079733
      },
      {
        "id": 4781,
        "latitude": -8.152971,
        "longitude": -37.00728
      },
      {
        "id": 4782,
        "latitude": -8.418519,
        "longitude": -37.042932
      },
      {
        "id": 4783,
        "latitude": -8.40981,
        "longitude": -37.075937
      },
      {
        "id": 4784,
        "latitude": -8.412744,
        "longitude": -37.073317
      },
      {
        "id": 4785,
        "latitude": -22.380222,
        "longitude": -47.589392
      },
      {
        "id": 4786,
        "latitude": -8.423,
        "longitude": -37.068604
      },
      {
        "id": 4787,
        "latitude": -8.424405,
        "longitude": -37.071161
      },
      {
        "id": 4788,
        "latitude": -22.378889,
        "longitude": -47.590321
      },
      {
        "id": 4791,
        "latitude": -8.415192,
        "longitude": -37.057748
      },
      {
        "id": 4792,
        "latitude": -8.423,
        "longitude": -37.068604
      },
      {
        "id": 4793,
        "latitude": -8.423,
        "longitude": -37.068604
      },
      {
        "id": 4794,
        "latitude": -10.935745,
        "longitude": -37.102934
      },
      {
        "id": 4795,
        "latitude": -8.403914,
        "longitude": -37.0731
      },
      {
        "id": 4796,
        "latitude": -21.803495,
        "longitude": -49.62184
      },
      {
        "id": 4797,
        "latitude": -8.423,
        "longitude": -37.068604
      },
      {
        "id": 4798,
        "latitude": -8.410919,
        "longitude": -37.079642
      },
      {
        "id": 4799,
        "latitude": -8.423,
        "longitude": -37.068604
      },
      {
        "id": 4800,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4801,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4802,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4803,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4804,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4805,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4807,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4808,
        "latitude": -8.173709,
        "longitude": -36.201019
      },
      {
        "id": 4809,
        "latitude": -8.212514,
        "longitude": -35.558158
      },
      {
        "id": 4810,
        "latitude": -8.143208,
        "longitude": -36.369529
      },
      {
        "id": 4811,
        "latitude": -8.333829,
        "longitude": -36.417642
      },
      {
        "id": 4812,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4813,
        "latitude": -8.322845,
        "longitude": -36.411333
      },
      {
        "id": 4814,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4815,
        "latitude": -8.148193,
        "longitude": -36.36931
      },
      {
        "id": 4816,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4817,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4818,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4819,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4820,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4821,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4822,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4823,
        "latitude": -8.144222,
        "longitude": -36.375608
      },
      {
        "id": 4824,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4825,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4826,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4827,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4829,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4830,
        "latitude": -8.333696,
        "longitude": -36.430027
      },
      {
        "id": 4831,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4832,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4833,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4834,
        "latitude": -7.560711,
        "longitude": -35.016346
      },
      {
        "id": 4835,
        "latitude": -8.144155,
        "longitude": -36.369364
      },
      {
        "id": 4836,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4837,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4838,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4839,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4840,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4841,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4842,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4843,
        "latitude": -8.334499,
        "longitude": -36.409552
      },
      {
        "id": 4844,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4845,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4846,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4847,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4848,
        "latitude": -8.333829,
        "longitude": -36.417642
      },
      {
        "id": 4849,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4850,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4851,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4852,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4853,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4854,
        "latitude": -8.006133,
        "longitude": -34.852722
      },
      {
        "id": 4855,
        "latitude": -8.212514,
        "longitude": -35.558158
      },
      {
        "id": 4856,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4857,
        "latitude": -8.174542,
        "longitude": -36.200514
      },
      {
        "id": 4858,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4859,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4860,
        "latitude": -8.145204,
        "longitude": -36.372844
      },
      {
        "id": 4861,
        "latitude": -8.335905,
        "longitude": -36.413665
      },
      {
        "id": 4862,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4863,
        "latitude": -8.150352,
        "longitude": -36.367838
      },
      {
        "id": 4864,
        "latitude": -8.361637,
        "longitude": -35.761839
      },
      {
        "id": 4865,
        "latitude": -8.143464,
        "longitude": -36.369104
      },
      {
        "id": 4866,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 4867,
        "latitude": -8.093483,
        "longitude": -36.224806
      },
      {
        "id": 5189,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 4868,
        "latitude": -8.236898,
        "longitude": -35.747351
      },
      {
        "id": 4869,
        "latitude": -8.107622,
        "longitude": -35.305074
      },
      {
        "id": 4870,
        "latitude": -8.236898,
        "longitude": -35.747351
      },
      {
        "id": 4871,
        "latitude": -8.107622,
        "longitude": -35.305074
      },
      {
        "id": 4872,
        "latitude": -8.236898,
        "longitude": -35.747351
      },
      {
        "id": 4873,
        "latitude": -8.432093,
        "longitude": -35.807556
      },
      {
        "id": 4874,
        "latitude": -7.785658,
        "longitude": -39.933843
      },
      {
        "id": 4875,
        "latitude": -7.785658,
        "longitude": -39.933843
      },
      {
        "id": 4876,
        "latitude": -9.171644,
        "longitude": -36.682224
      },
      {
        "id": 4877,
        "latitude": -9.168817,
        "longitude": -36.684246
      },
      {
        "id": 4878,
        "latitude": -9.169191,
        "longitude": -36.685683
      },
      {
        "id": 4879,
        "latitude": -9.178805,
        "longitude": -36.681211
      },
      {
        "id": 4880,
        "latitude": -9.168817,
        "longitude": -36.684246
      },
      {
        "id": 4881,
        "latitude": -9.168817,
        "longitude": -36.684246
      },
      {
        "id": 4882,
        "latitude": -8.28709,
        "longitude": -35.033825
      },
      {
        "id": 4883,
        "latitude": -8.245603,
        "longitude": -34.98031
      },
      {
        "id": 4884,
        "latitude": -8.245603,
        "longitude": -34.98031
      },
      {
        "id": 5212,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 4886,
        "latitude": -8.292779,
        "longitude": -34.955372
      },
      {
        "id": 4887,
        "latitude": -8.286812,
        "longitude": -35.022404
      },
      {
        "id": 4888,
        "latitude": -12.25598,
        "longitude": -38.984484
      },
      {
        "id": 4889,
        "latitude": -8.50837,
        "longitude": -39.310696
      },
      {
        "id": 4890,
        "latitude": -8.332038,
        "longitude": -36.129651
      },
      {
        "id": 4891,
        "latitude": -8.02078,
        "longitude": -35.006924
      },
      {
        "id": 4892,
        "latitude": -8.02078,
        "longitude": -35.006924
      },
      {
        "id": 4893,
        "latitude": -8.02078,
        "longitude": -35.006924
      },
      {
        "id": 4894,
        "latitude": -8.02078,
        "longitude": -35.006924
      },
      {
        "id": 4895,
        "latitude": -8.027211,
        "longitude": -35.001631
      },
      {
        "id": 4896,
        "latitude": -8.027211,
        "longitude": -35.001631
      },
      {
        "id": 4897,
        "latitude": -8.7,
        "longitude": -37.66667
      },
      {
        "id": 4898,
        "latitude": -8.7,
        "longitude": -37.66667
      },
      {
        "id": 4899,
        "latitude": -8.7,
        "longitude": -37.66667
      },
      {
        "id": 4900,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4901,
        "latitude": -8.103399,
        "longitude": -35.271815
      },
      {
        "id": 4902,
        "latitude": -7.844237,
        "longitude": -35.23007
      },
      {
        "id": 4903,
        "latitude": -8.105459,
        "longitude": -35.27334
      },
      {
        "id": 4904,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4905,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4906,
        "latitude": -7.667844,
        "longitude": -35.413469
      },
      {
        "id": 4907,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4908,
        "latitude": -8.111308,
        "longitude": -35.283713
      },
      {
        "id": 4909,
        "latitude": -7.840641,
        "longitude": -35.236051
      },
      {
        "id": 4910,
        "latitude": -7.845911,
        "longitude": -35.253886
      },
      {
        "id": 4911,
        "latitude": -7.416582,
        "longitude": -35.105441
      },
      {
        "id": 4912,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4913,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4914,
        "latitude": -8.103971,
        "longitude": -35.271233
      },
      {
        "id": 4916,
        "latitude": -7.846289,
        "longitude": -35.227071
      },
      {
        "id": 4917,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4918,
        "latitude": -8.107752,
        "longitude": -35.30396
      },
      {
        "id": 4919,
        "latitude": -11.425412,
        "longitude": -60.740238
      },
      {
        "id": 4920,
        "latitude": -7.845911,
        "longitude": -35.253886
      },
      {
        "id": 4921,
        "latitude": -7.85072,
        "longitude": -35.232379
      },
      {
        "id": 4922,
        "latitude": -7.823345,
        "longitude": -35.256844
      },
      {
        "id": 4923,
        "latitude": -8.875504,
        "longitude": -36.203731
      },
      {
        "id": 4924,
        "latitude": -2.557935,
        "longitude": -44.241043
      },
      {
        "id": 4925,
        "latitude": -8.104432,
        "longitude": -35.272827
      },
      {
        "id": 4926,
        "latitude": -7.979623,
        "longitude": -35.063998
      },
      {
        "id": 4927,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4928,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4929,
        "latitude": -7.856314,
        "longitude": -35.251177
      },
      {
        "id": 4930,
        "latitude": -8.407509,
        "longitude": -36.001959
      },
      {
        "id": 4932,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 4933,
        "latitude": -8.08403,
        "longitude": -34.963939
      },
      {
        "id": 4934,
        "latitude": -8.28662,
        "longitude": -35.99264
      },
      {
        "id": 4935,
        "latitude": -8.28297,
        "longitude": -35.972285
      },
      {
        "id": 4936,
        "latitude": -23.117402,
        "longitude": -52.286942
      },
      {
        "id": 4937,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 4938,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 4939,
        "latitude": -8.08403,
        "longitude": -34.963939
      },
      {
        "id": 4940,
        "latitude": -8.08403,
        "longitude": -34.963939
      },
      {
        "id": 4941,
        "latitude": -8.28297,
        "longitude": -35.972285
      },
      {
        "id": 4942,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 4943,
        "latitude": -8.614003,
        "longitude": -35.695703
      },
      {
        "id": 4944,
        "latitude": -7.887173,
        "longitude": -35.03047
      },
      {
        "id": 4945,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4946,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4947,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4948,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4949,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4950,
        "latitude": -8.098017,
        "longitude": -35.288042
      },
      {
        "id": 4951,
        "latitude": -5.608932,
        "longitude": -36.906897
      },
      {
        "id": 4952,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4953,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4954,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4955,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4956,
        "latitude": -7.992778,
        "longitude": -35.213662
      },
      {
        "id": 4957,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 4958,
        "latitude": -8.000343,
        "longitude": -35.290852
      },
      {
        "id": 4959,
        "latitude": -8.229346,
        "longitude": -35.482065
      },
      {
        "id": 4960,
        "latitude": -8.61508,
        "longitude": -35.94875
      },
      {
        "id": 4961,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4962,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4963,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4964,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4965,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4966,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4967,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4968,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4969,
        "latitude": -7.944777,
        "longitude": -35.386279
      },
      {
        "id": 4970,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4973,
        "latitude": -8.890589,
        "longitude": -36.49309
      },
      {
        "id": 4974,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4975,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4976,
        "latitude": -7.414459,
        "longitude": -35.106816
      },
      {
        "id": 4977,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4978,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4979,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4980,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4981,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4982,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 4983,
        "latitude": -8.890589,
        "longitude": -36.49309
      },
      {
        "id": 4984,
        "latitude": -7.999959,
        "longitude": -35.286612
      },
      {
        "id": 4985,
        "latitude": -8.004652,
        "longitude": -36.052951
      },
      {
        "id": 4986,
        "latitude": -8.000013,
        "longitude": -35.285207
      },
      {
        "id": 4987,
        "latitude": -8.000013,
        "longitude": -35.285207
      },
      {
        "id": 4988,
        "latitude": -7.996367,
        "longitude": -35.2924
      },
      {
        "id": 4989,
        "latitude": -7.998088,
        "longitude": -35.296087
      },
      {
        "id": 4990,
        "latitude": -8.015859,
        "longitude": -35.013461
      },
      {
        "id": 4991,
        "latitude": -8.199305,
        "longitude": -35.563114
      },
      {
        "id": 4992,
        "latitude": -8.195733,
        "longitude": -35.566964
      },
      {
        "id": 4993,
        "latitude": -8.195733,
        "longitude": -35.566964
      },
      {
        "id": 4994,
        "latitude": -8.212664,
        "longitude": -35.575638
      },
      {
        "id": 4995,
        "latitude": -8.195733,
        "longitude": -35.566964
      },
      {
        "id": 4996,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 4998,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 4999,
        "latitude": -8.326414,
        "longitude": -35.711549
      },
      {
        "id": 5000,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5001,
        "latitude": -7.953795,
        "longitude": -34.902178
      },
      {
        "id": 5002,
        "latitude": -23.324081,
        "longitude": -46.616802
      },
      {
        "id": 5003,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5004,
        "latitude": -7.810472,
        "longitude": -34.911185
      },
      {
        "id": 5005,
        "latitude": -7.830392,
        "longitude": -34.927132
      },
      {
        "id": 5006,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5213,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5007,
        "latitude": -7.857973,
        "longitude": -34.89623
      },
      {
        "id": 5008,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5009,
        "latitude": -22.959371,
        "longitude": -42.825085
      },
      {
        "id": 5010,
        "latitude": -7.55818,
        "longitude": -35.013726
      },
      {
        "id": 5011,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5012,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5013,
        "latitude": -7.836527,
        "longitude": -34.917846
      },
      {
        "id": 5014,
        "latitude": -7.826073,
        "longitude": -34.923377
      },
      {
        "id": 5015,
        "latitude": -8.042187,
        "longitude": -35.103183
      },
      {
        "id": 5016,
        "latitude": -7.833308,
        "longitude": -34.901254
      },
      {
        "id": 5017,
        "latitude": -8.011651,
        "longitude": -34.857155
      },
      {
        "id": 5018,
        "latitude": -7.560711,
        "longitude": -35.016346
      },
      {
        "id": 5019,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5020,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5021,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5022,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5023,
        "latitude": -7.835489,
        "longitude": -34.921523
      },
      {
        "id": 5024,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5025,
        "latitude": -7.825096,
        "longitude": -34.919777
      },
      {
        "id": 5026,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5027,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5028,
        "latitude": -7.823421,
        "longitude": -34.921297
      },
      {
        "id": 5029,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5030,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5031,
        "latitude": -7.863538,
        "longitude": -34.902001
      },
      {
        "id": 5032,
        "latitude": -7.86738,
        "longitude": -34.899359
      },
      {
        "id": 5033,
        "latitude": -7.863538,
        "longitude": -34.902001
      },
      {
        "id": 5034,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5035,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5036,
        "latitude": -7.830474,
        "longitude": -34.927155
      },
      {
        "id": 5037,
        "latitude": -21.764904,
        "longitude": -47.077659
      },
      {
        "id": 5038,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5039,
        "latitude": -8.070643,
        "longitude": -34.99802
      },
      {
        "id": 5040,
        "latitude": -7.942232,
        "longitude": -36.20383
      },
      {
        "id": 5041,
        "latitude": -7.997748,
        "longitude": -35.040022
      },
      {
        "id": 5042,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5044,
        "latitude": -7.859172,
        "longitude": -34.905957
      },
      {
        "id": 5046,
        "latitude": -7.811696,
        "longitude": -34.911031
      },
      {
        "id": 5047,
        "latitude": -7.830392,
        "longitude": -34.927132
      },
      {
        "id": 5048,
        "latitude": -7.856276,
        "longitude": -34.894557
      },
      {
        "id": 5049,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5050,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5051,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5052,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5053,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5054,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5055,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5056,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5057,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5058,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5059,
        "latitude": -7.830474,
        "longitude": -34.927155
      },
      {
        "id": 5060,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5061,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5062,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5063,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5064,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5065,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5067,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5068,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5069,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5070,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5071,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5072,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5073,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5074,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5075,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5076,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5077,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5078,
        "latitude": -8.045774,
        "longitude": -34.951865
      },
      {
        "id": 5079,
        "latitude": -7.897043,
        "longitude": -34.89877
      },
      {
        "id": 5080,
        "latitude": -7.560711,
        "longitude": -35.016346
      },
      {
        "id": 5081,
        "latitude": -7.560711,
        "longitude": -35.016346
      },
      {
        "id": 5082,
        "latitude": -8.075812,
        "longitude": -35.006262
      },
      {
        "id": 5083,
        "latitude": -8.002227,
        "longitude": -36.06478
      },
      {
        "id": 5084,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5085,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5086,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5087,
        "latitude": -7.829154,
        "longitude": -34.922881
      },
      {
        "id": 5088,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5089,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5090,
        "latitude": -7.874545,
        "longitude": -34.903644
      },
      {
        "id": 5091,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5092,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 5093,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5094,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5095,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5096,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5097,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5098,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5100,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5101,
        "latitude": -7.808067,
        "longitude": -34.924069
      },
      {
        "id": 5102,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5104,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5105,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5106,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5107,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5108,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5109,
        "latitude": -7.830474,
        "longitude": -34.927155
      },
      {
        "id": 5110,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5111,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5112,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5113,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5114,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5115,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5116,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5117,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5118,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5119,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5120,
        "latitude": -7.835442,
        "longitude": -34.906113
      },
      {
        "id": 5121,
        "latitude": -7.835225,
        "longitude": -37.519007
      },
      {
        "id": 5122,
        "latitude": -7.835225,
        "longitude": -37.519007
      },
      {
        "id": 5123,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5124,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5125,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5126,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5127,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5128,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5129,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5130,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5131,
        "latitude": -7.747664,
        "longitude": -34.830319
      },
      {
        "id": 5132,
        "latitude": -7.677311,
        "longitude": -37.45989
      },
      {
        "id": 5133,
        "latitude": -7.677311,
        "longitude": -37.45989
      },
      {
        "id": 5134,
        "latitude": -7.410851,
        "longitude": -35.10967
      },
      {
        "id": 5135,
        "latitude": -7.410851,
        "longitude": -35.10967
      },
      {
        "id": 5136,
        "latitude": -7.410851,
        "longitude": -35.10967
      },
      {
        "id": 5137,
        "latitude": -7.767983,
        "longitude": -34.897136
      },
      {
        "id": 5138,
        "latitude": -7.767983,
        "longitude": -34.897136
      },
      {
        "id": 5139,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5143,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5144,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5145,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5147,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5148,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5149,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5150,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5151,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5152,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5153,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5154,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5155,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5156,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5157,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5158,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5159,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5160,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5161,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5162,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5163,
        "latitude": -8.175248,
        "longitude": -34.946872
      },
      {
        "id": 5164,
        "latitude": -7.986055,
        "longitude": -36.498012
      },
      {
        "id": 5165,
        "latitude": -7.986055,
        "longitude": -36.498012
      },
      {
        "id": 5166,
        "latitude": -8.711468,
        "longitude": -36.417448
      },
      {
        "id": 5167,
        "latitude": -8.71891,
        "longitude": -36.137365
      },
      {
        "id": 5168,
        "latitude": -7.843834,
        "longitude": -35.310783
      },
      {
        "id": 5214,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5169,
        "latitude": -7.843834,
        "longitude": -35.310783
      },
      {
        "id": 5170,
        "latitude": -7.843834,
        "longitude": -35.310783
      },
      {
        "id": 5171,
        "latitude": -7.843834,
        "longitude": -35.310783
      },
      {
        "id": 5172,
        "latitude": -7.843834,
        "longitude": -35.310783
      },
      {
        "id": 5173,
        "latitude": -8.993065,
        "longitude": -40.271645
      },
      {
        "id": 5174,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5175,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5176,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5177,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5178,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5179,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5180,
        "latitude": -8.657914,
        "longitude": -36.329336
      },
      {
        "id": 5181,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5182,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5183,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5184,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5185,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5186,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5187,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5190,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5191,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5192,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5193,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5194,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5195,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5196,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5197,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5198,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5199,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5200,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5201,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5202,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5203,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5204,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5205,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5206,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5207,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5208,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5209,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5210,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5211,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5221,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5222,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5223,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5224,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5225,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5226,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5227,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5228,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5229,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5230,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5231,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5232,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5233,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5234,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5235,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5236,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5237,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5238,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5239,
        "latitude": -7.872599,
        "longitude": -35.440154
      },
      {
        "id": 5240,
        "latitude": -8.108706,
        "longitude": -35.08354
      },
      {
        "id": 5241,
        "latitude": -8.108706,
        "longitude": -35.08354
      },
      {
        "id": 5242,
        "latitude": -7.99864,
        "longitude": -34.845955
      },
      {
        "id": 5244,
        "latitude": -8.91981,
        "longitude": -36.657351
      },
      {
        "id": 5245,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5246,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5247,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5248,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5249,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5250,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5251,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5252,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5253,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5254,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5255,
        "latitude": -7.892721,
        "longitude": -34.831461
      },
      {
        "id": 5256,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5257,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5258,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5259,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5260,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5261,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5262,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5263,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5264,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5266,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5267,
        "latitude": -7.901662,
        "longitude": -34.837129
      },
      {
        "id": 5268,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5269,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5270,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5271,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5272,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5289,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5273,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5274,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5275,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5276,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5277,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5278,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5279,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5280,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5281,
        "latitude": -7.904916,
        "longitude": -34.83597
      },
      {
        "id": 5282,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5283,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5284,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5285,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5286,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5287,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5288,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5290,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5291,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5292,
        "latitude": -7.885962,
        "longitude": -34.836596
      },
      {
        "id": 5293,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5294,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5295,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5296,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5297,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5298,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5299,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5300,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5301,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5302,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5303,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5304,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5305,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5306,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5307,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5309,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5310,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5311,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5312,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5313,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5314,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5315,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5316,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5317,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5318,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5319,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5320,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5321,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5322,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5323,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5324,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5325,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5326,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5327,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5328,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5329,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5330,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5331,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5332,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5333,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5334,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5335,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5336,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5337,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5338,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5339,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5340,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5341,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5342,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5343,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5344,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5345,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5346,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5347,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5348,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5349,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5350,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5351,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5352,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5353,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5549,
        "latitude": -7.987764,
        "longitude": -38.28921
      },
      {
        "id": 5354,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5355,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5356,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5357,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5358,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5359,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5360,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5361,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5362,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5363,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5364,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5365,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5366,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5367,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5368,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5369,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5370,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5371,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5372,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5373,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5374,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5375,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5376,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5377,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5378,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5379,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5380,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5381,
        "latitude": -7.934007,
        "longitude": -34.868407
      },
      {
        "id": 5382,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5383,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5384,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5385,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5386,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5387,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5388,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5389,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5390,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5391,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5392,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5393,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5394,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5395,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5396,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5397,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5398,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5399,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5400,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5401,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5402,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5403,
        "latitude": -8.357447,
        "longitude": -36.696824
      },
      {
        "id": 5404,
        "latitude": -9.381733,
        "longitude": -40.496888
      },
      {
        "id": 5405,
        "latitude": -9.381733,
        "longitude": -40.496888
      },
      {
        "id": 5406,
        "latitude": -9.381733,
        "longitude": -40.496888
      },
      {
        "id": 5408,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5409,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5410,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5411,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5412,
        "latitude": -8.051567,
        "longitude": -34.908574
      },
      {
        "id": 5413,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5414,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5415,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5416,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5417,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5418,
        "latitude": -8.085327,
        "longitude": -34.930175
      },
      {
        "id": 5419,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5420,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5421,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5422,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5423,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5424,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5425,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5426,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5427,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5428,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5429,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5430,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5431,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5432,
        "latitude": -8.010782,
        "longitude": -34.932114
      },
      {
        "id": 5433,
        "latitude": -8.058493,
        "longitude": -34.884819
      },
      {
        "id": 5434,
        "latitude": -8.072673,
        "longitude": -39.126972
      },
      {
        "id": 5435,
        "latitude": -7.818545,
        "longitude": -38.152351
      },
      {
        "id": 5550,
        "latitude": -7.974739,
        "longitude": -38.276767
      },
      {
        "id": 5436,
        "latitude": -7.818545,
        "longitude": -38.152351
      },
      {
        "id": 5437,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5438,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5439,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5440,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5441,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5442,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5443,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5444,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5445,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5446,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5447,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5449,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5450,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5451,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5452,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5453,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5454,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5455,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5456,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5457,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5458,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5459,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5460,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5461,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5462,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5463,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5464,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5465,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5466,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5467,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5551,
        "latitude": -7.953002,
        "longitude": -39.294802
      },
      {
        "id": 5468,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5469,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5470,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5471,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5472,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5473,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5474,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5475,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5476,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5477,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5478,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5479,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5480,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5481,
        "latitude": -7.887788,
        "longitude": -36.338531
      },
      {
        "id": 5482,
        "latitude": -7.37696,
        "longitude": -37.47875
      },
      {
        "id": 5483,
        "latitude": -8.520709,
        "longitude": -36.444555
      },
      {
        "id": 5484,
        "latitude": -8.330449,
        "longitude": -36.135359
      },
      {
        "id": 5485,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5486,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5488,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5489,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5490,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5491,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5492,
        "latitude": -7.995819,
        "longitude": -38.622941
      },
      {
        "id": 5493,
        "latitude": -7.995819,
        "longitude": -38.622941
      },
      {
        "id": 5494,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5495,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5496,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5497,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5498,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5499,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5590,
        "latitude": -5.081351,
        "longitude": -42.809811
      },
      {
        "id": 5500,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5501,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5502,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5503,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5504,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5505,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5506,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5507,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5508,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5509,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5510,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5511,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5512,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5513,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5514,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5515,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5571,
        "latitude": -6.782453,
        "longitude": -43.003986
      },
      {
        "id": 5516,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5517,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5518,
        "latitude": -7.864126,
        "longitude": -38.759955
      },
      {
        "id": 5519,
        "latitude": -8.269842,
        "longitude": -38.558752
      },
      {
        "id": 5520,
        "latitude": -7.872647,
        "longitude": -38.756422
      },
      {
        "id": 5521,
        "latitude": -7.872647,
        "longitude": -38.756422
      },
      {
        "id": 5522,
        "latitude": -7.99266,
        "longitude": -38.624124
      },
      {
        "id": 5524,
        "latitude": -7.981875,
        "longitude": -35.077702
      },
      {
        "id": 5525,
        "latitude": -7.884167,
        "longitude": -35.439918
      },
      {
        "id": 5526,
        "latitude": -7.990644,
        "longitude": -35.050418
      },
      {
        "id": 5527,
        "latitude": -7.994158,
        "longitude": -35.032829
      },
      {
        "id": 5528,
        "latitude": -8.487208,
        "longitude": -36.238335
      },
      {
        "id": 5529,
        "latitude": -8.062951,
        "longitude": -34.891964
      },
      {
        "id": 5530,
        "latitude": -7.994158,
        "longitude": -35.032829
      },
      {
        "id": 5531,
        "latitude": -7.846152,
        "longitude": -35.265088
      },
      {
        "id": 5532,
        "latitude": -7.994158,
        "longitude": -35.032829
      },
      {
        "id": 5533,
        "latitude": -7.846152,
        "longitude": -35.265088
      },
      {
        "id": 5534,
        "latitude": -7.797362,
        "longitude": -34.837786
      },
      {
        "id": 5535,
        "latitude": -8.019705,
        "longitude": -34.994706
      },
      {
        "id": 5536,
        "latitude": -7.995961,
        "longitude": -35.039335
      },
      {
        "id": 5537,
        "latitude": -8.200263,
        "longitude": -34.92788
      },
      {
        "id": 5538,
        "latitude": -7.884167,
        "longitude": -35.439918
      },
      {
        "id": 5539,
        "latitude": -7.994158,
        "longitude": -35.032829
      },
      {
        "id": 5540,
        "latitude": -7.890866,
        "longitude": -34.908397
      },
      {
        "id": 5541,
        "latitude": -7.904373,
        "longitude": -35.184902
      },
      {
        "id": 5542,
        "latitude": -7.975775,
        "longitude": -38.287594
      },
      {
        "id": 5543,
        "latitude": -7.987764,
        "longitude": -38.28921
      },
      {
        "id": 5544,
        "latitude": -7.978929,
        "longitude": -38.294169
      },
      {
        "id": 5546,
        "latitude": -7.987764,
        "longitude": -38.28921
      },
      {
        "id": 5547,
        "latitude": -7.98069,
        "longitude": -38.2784
      },
      {
        "id": 5548,
        "latitude": -7.987764,
        "longitude": -38.28921
      },
      {
        "id": 5552,
        "latitude": -7.943346,
        "longitude": -39.297899
      },
      {
        "id": 5553,
        "latitude": -7.943346,
        "longitude": -39.297899
      },
      {
        "id": 5554,
        "latitude": -8.070376,
        "longitude": -37.271852
      },
      {
        "id": 5555,
        "latitude": -7.848887,
        "longitude": -35.752269
      },
      {
        "id": 5556,
        "latitude": -30.851873,
        "longitude": -51.806457
      },
      {
        "id": 5557,
        "latitude": -7.847654,
        "longitude": -35.763512
      },
      {
        "id": 5558,
        "latitude": -7.510645,
        "longitude": -35.315332
      },
      {
        "id": 5559,
        "latitude": -7.510645,
        "longitude": -35.315332
      },
      {
        "id": 5560,
        "latitude": -7.510645,
        "longitude": -35.315332
      },
      {
        "id": 5561,
        "latitude": -8.579733,
        "longitude": -36.879306
      },
      {
        "id": 5562,
        "latitude": -7.655339,
        "longitude": -35.32179
      },
      {
        "id": 5563,
        "latitude": -8.119708,
        "longitude": -35.295462
      },
      {
        "id": 5564,
        "latitude": -7.241407,
        "longitude": -40.859546
      },
      {
        "id": 5565,
        "latitude": -5.046605,
        "longitude": -42.462071
      },
      {
        "id": 5566,
        "latitude": -5.817419,
        "longitude": -42.511731
      },
      {
        "id": 5567,
        "latitude": -9.071845,
        "longitude": -44.357992
      },
      {
        "id": 5568,
        "latitude": -5.030952,
        "longitude": -42.811709
      },
      {
        "id": 5569,
        "latitude": -22.943277,
        "longitude": -46.543059
      },
      {
        "id": 5570,
        "latitude": -4.83434,
        "longitude": -42.176421
      },
      {
        "id": 5572,
        "latitude": -6.783176,
        "longitude": -43.021734
      },
      {
        "id": 5573,
        "latitude": -6.76386,
        "longitude": -43.004697
      },
      {
        "id": 5574,
        "latitude": -4.755249,
        "longitude": -42.572409
      },
      {
        "id": 5575,
        "latitude": -5.041901,
        "longitude": -42.4465
      },
      {
        "id": 5576,
        "latitude": -4.7566,
        "longitude": -42.578222
      },
      {
        "id": 5577,
        "latitude": -4.755249,
        "longitude": -42.572409
      },
      {
        "id": 5578,
        "latitude": -4.755249,
        "longitude": -42.572409
      },
      {
        "id": 5579,
        "latitude": -4.74328,
        "longitude": -42.57989
      },
      {
        "id": 5580,
        "latitude": -5.559953,
        "longitude": -42.612011
      },
      {
        "id": 5581,
        "latitude": -5.690858,
        "longitude": -42.602708
      },
      {
        "id": 5582,
        "latitude": -5.559953,
        "longitude": -42.612011
      },
      {
        "id": 5583,
        "latitude": -7.001284,
        "longitude": -41.027326
      },
      {
        "id": 5584,
        "latitude": -7.096295,
        "longitude": -41.473535
      },
      {
        "id": 5585,
        "latitude": -7.085309,
        "longitude": -41.4349
      },
      {
        "id": 5586,
        "latitude": -6.9,
        "longitude": -41.28333
      },
      {
        "id": 5587,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5589,
        "latitude": -5.0855,
        "longitude": -42.810113
      },
      {
        "id": 5591,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5592,
        "latitude": -5.067466,
        "longitude": -42.751875
      },
      {
        "id": 5593,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5594,
        "latitude": -5.066274,
        "longitude": -42.794429
      },
      {
        "id": 5595,
        "latitude": -5.099992,
        "longitude": -42.752904
      },
      {
        "id": 5596,
        "latitude": -5.080367,
        "longitude": -42.738567
      },
      {
        "id": 5597,
        "latitude": -5.078779,
        "longitude": -42.780751
      },
      {
        "id": 5598,
        "latitude": -5.089563,
        "longitude": -42.774962
      },
      {
        "id": 5599,
        "latitude": -5.089563,
        "longitude": -42.774962
      },
      {
        "id": 5600,
        "latitude": -5.041088,
        "longitude": -42.768549
      },
      {
        "id": 5601,
        "latitude": -5.075886,
        "longitude": -42.753545
      },
      {
        "id": 5602,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5603,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5604,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5605,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5606,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5607,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5608,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5609,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5610,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5611,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5612,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5613,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5614,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5615,
        "latitude": -5.041442,
        "longitude": -42.748735
      },
      {
        "id": 5616,
        "latitude": -4.993661,
        "longitude": -42.836644
      },
      {
        "id": 5618,
        "latitude": -5.045743,
        "longitude": -42.749674
      },
      {
        "id": 5619,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5620,
        "latitude": -4.938202,
        "longitude": -43.048127
      },
      {
        "id": 5621,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5622,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5623,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5624,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5625,
        "latitude": -5.067466,
        "longitude": -42.751875
      },
      {
        "id": 5626,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5627,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5628,
        "latitude": -5.09476,
        "longitude": -42.834058
      },
      {
        "id": 5629,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5630,
        "latitude": -5.060029,
        "longitude": -42.772045
      },
      {
        "id": 5631,
        "latitude": -5.038384,
        "longitude": -42.743355
      },
      {
        "id": 5632,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5633,
        "latitude": -22.796679,
        "longitude": -43.579165
      },
      {
        "id": 5634,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5635,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5636,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5637,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 5639,
        "latitude": -5.03333,
        "longitude": -42.83333
      },
      {
        "id": 5676,
        "latitude": -22.728868,
        "longitude": -43.376864
      },
      {
        "id": 5641,
        "latitude": -5.090831,
        "longitude": -42.764107
      },
      {
        "id": 5642,
        "latitude": -9.616845,
        "longitude": -35.705694
      },
      {
        "id": 5643,
        "latitude": -5.085375,
        "longitude": -42.810627
      },
      {
        "id": 5644,
        "latitude": -25.378565,
        "longitude": -51.441935
      },
      {
        "id": 5645,
        "latitude": -30.099685,
        "longitude": -51.171792
      },
      {
        "id": 5646,
        "latitude": -22.927927,
        "longitude": -44.418813
      },
      {
        "id": 5648,
        "latitude": -22.852881,
        "longitude": -42.278491
      },
      {
        "id": 5649,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5651,
        "latitude": -22.848966,
        "longitude": -42.098811
      },
      {
        "id": 5653,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5654,
        "latitude": -22.868482,
        "longitude": -42.320777
      },
      {
        "id": 5655,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5656,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5657,
        "latitude": -22.936286,
        "longitude": -42.474071
      },
      {
        "id": 5658,
        "latitude": -13.923875,
        "longitude": -39.484792
      },
      {
        "id": 5659,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5660,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5661,
        "latitude": -22.862636,
        "longitude": -42.325718
      },
      {
        "id": 5662,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5663,
        "latitude": -22.850695,
        "longitude": -42.253639
      },
      {
        "id": 5664,
        "latitude": -22.881977,
        "longitude": -42.311395
      },
      {
        "id": 5666,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5667,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5668,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5669,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5670,
        "latitude": -9.664938,
        "longitude": -35.761418
      },
      {
        "id": 5671,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 5672,
        "latitude": -29.960088,
        "longitude": -51.38452
      },
      {
        "id": 5673,
        "latitude": -22.728868,
        "longitude": -43.376864
      },
      {
        "id": 5678,
        "latitude": -21.143399,
        "longitude": -41.675496
      },
      {
        "id": 5679,
        "latitude": -21.143399,
        "longitude": -41.675496
      },
      {
        "id": 5680,
        "latitude": -22.672686,
        "longitude": -42.001845
      },
      {
        "id": 5681,
        "latitude": -22.900085,
        "longitude": -42.065583
      },
      {
        "id": 5682,
        "latitude": -22.8787,
        "longitude": -42.043864
      },
      {
        "id": 5684,
        "latitude": -22.900085,
        "longitude": -42.065583
      },
      {
        "id": 5685,
        "latitude": -22.604305,
        "longitude": -42.00027
      },
      {
        "id": 5686,
        "latitude": -22.900085,
        "longitude": -42.065583
      },
      {
        "id": 5687,
        "latitude": -22.884211,
        "longitude": -42.047566
      },
      {
        "id": 5688,
        "latitude": -22.829841,
        "longitude": -41.993071
      },
      {
        "id": 5689,
        "latitude": -22.864238,
        "longitude": -42.008815
      },
      {
        "id": 5690,
        "latitude": -22.900085,
        "longitude": -42.065583
      },
      {
        "id": 5691,
        "latitude": -22.609386,
        "longitude": -42.770072
      },
      {
        "id": 5692,
        "latitude": -21.988676,
        "longitude": -42.374561
      },
      {
        "id": 5693,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5694,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5695,
        "latitude": -22.599806,
        "longitude": -42.746062
      },
      {
        "id": 5696,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5697,
        "latitude": -5.328319,
        "longitude": -49.096734
      },
      {
        "id": 5698,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5699,
        "latitude": -27.576458,
        "longitude": -48.671454
      },
      {
        "id": 5700,
        "latitude": -5.329227,
        "longitude": -49.094514
      },
      {
        "id": 5701,
        "latitude": -5.328319,
        "longitude": -49.096734
      },
      {
        "id": 5702,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5703,
        "latitude": -22.971857,
        "longitude": -44.288593
      },
      {
        "id": 5705,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5706,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5707,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5708,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5709,
        "latitude": -22.706197,
        "longitude": -42.865097
      },
      {
        "id": 5710,
        "latitude": -22.927357,
        "longitude": -43.386678
      },
      {
        "id": 5712,
        "latitude": -22.28609,
        "longitude": -42.497153
      },
      {
        "id": 5713,
        "latitude": -22.434309,
        "longitude": -42.74934
      },
      {
        "id": 5714,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5715,
        "latitude": -22.88079,
        "longitude": -43.366841
      },
      {
        "id": 5716,
        "latitude": -27.576458,
        "longitude": -48.671454
      },
      {
        "id": 5717,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5718,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 5719,
        "latitude": -22.906707,
        "longitude": -42.812638
      },
      {
        "id": 5720,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5721,
        "latitude": -21.754605,
        "longitude": -41.346261
      },
      {
        "id": 5722,
        "latitude": -30.042084,
        "longitude": -51.18256
      },
      {
        "id": 5723,
        "latitude": -21.758204,
        "longitude": -41.321114
      },
      {
        "id": 5724,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 5725,
        "latitude": -21.71257,
        "longitude": -41.299476
      },
      {
        "id": 5726,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5727,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5729,
        "latitude": -21.744085,
        "longitude": -41.323522
      },
      {
        "id": 5730,
        "latitude": -15.8814,
        "longitude": -47.814874
      },
      {
        "id": 5731,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5732,
        "latitude": -21.750529,
        "longitude": -41.318128
      },
      {
        "id": 5733,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5735,
        "latitude": -21.641334,
        "longitude": -41.020906
      },
      {
        "id": 5736,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5737,
        "latitude": -21.747894,
        "longitude": -41.345353
      },
      {
        "id": 5738,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5739,
        "latitude": -21.640116,
        "longitude": -41.017274
      },
      {
        "id": 5740,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5741,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5742,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5743,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5744,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5745,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5778,
        "latitude": -21.832438,
        "longitude": -41.302911
      },
      {
        "id": 5746,
        "latitude": -21.740744,
        "longitude": -41.34923
      },
      {
        "id": 5747,
        "latitude": -21.721926,
        "longitude": -41.326055
      },
      {
        "id": 5749,
        "latitude": -21.7546,
        "longitude": -41.3242
      },
      {
        "id": 5750,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5751,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5752,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 5753,
        "latitude": -30.042084,
        "longitude": -51.18256
      },
      {
        "id": 5754,
        "latitude": -21.641334,
        "longitude": -41.020906
      },
      {
        "id": 5755,
        "latitude": -21.641334,
        "longitude": -41.020906
      },
      {
        "id": 5756,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 5757,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 5758,
        "latitude": -21.641334,
        "longitude": -41.020906
      },
      {
        "id": 5759,
        "latitude": -21.721926,
        "longitude": -41.326055
      },
      {
        "id": 5760,
        "latitude": -22.726346,
        "longitude": -43.383576
      },
      {
        "id": 5761,
        "latitude": -21.779688,
        "longitude": -41.300605
      },
      {
        "id": 6283,
        "latitude": -22.856274,
        "longitude": -43.779427
      },
      {
        "id": 5762,
        "latitude": -21.779688,
        "longitude": -41.300605
      },
      {
        "id": 5763,
        "latitude": -17.801302,
        "longitude": -54.741035
      },
      {
        "id": 5764,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 5765,
        "latitude": -21.740744,
        "longitude": -41.34923
      },
      {
        "id": 5766,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5767,
        "latitude": -21.711783,
        "longitude": -41.545038
      },
      {
        "id": 5768,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5769,
        "latitude": -21.747894,
        "longitude": -41.345353
      },
      {
        "id": 5770,
        "latitude": -21.747894,
        "longitude": -41.345353
      },
      {
        "id": 5771,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5772,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5773,
        "latitude": -21.834134,
        "longitude": -41.303169
      },
      {
        "id": 5774,
        "latitude": -21.832438,
        "longitude": -41.302911
      },
      {
        "id": 5775,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5776,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5777,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5779,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5780,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5781,
        "latitude": -21.814445,
        "longitude": -41.294854
      },
      {
        "id": 5782,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 5784,
        "latitude": -21.761725,
        "longitude": -41.346269
      },
      {
        "id": 5785,
        "latitude": -21.770615,
        "longitude": -41.31057
      },
      {
        "id": 5786,
        "latitude": -21.776603,
        "longitude": -41.325614
      },
      {
        "id": 5787,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5788,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5789,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5790,
        "latitude": -21.755768,
        "longitude": -41.330244
      },
      {
        "id": 5791,
        "latitude": -21.819356,
        "longitude": -41.296119
      },
      {
        "id": 5792,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5793,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5794,
        "latitude": -21.749672,
        "longitude": -41.340824
      },
      {
        "id": 5795,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5952,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5796,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5797,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5798,
        "latitude": -21.834134,
        "longitude": -41.303169
      },
      {
        "id": 5799,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 5801,
        "latitude": -21.760626,
        "longitude": -41.306822
      },
      {
        "id": 5802,
        "latitude": -21.760626,
        "longitude": -41.306822
      },
      {
        "id": 5803,
        "latitude": -21.760626,
        "longitude": -41.306822
      },
      {
        "id": 5804,
        "latitude": -21.760626,
        "longitude": -41.306822
      },
      {
        "id": 5805,
        "latitude": -21.797138,
        "longitude": -41.326468
      },
      {
        "id": 5806,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5807,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5808,
        "latitude": -21.753439,
        "longitude": -41.33374
      },
      {
        "id": 5809,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5810,
        "latitude": -21.799068,
        "longitude": -41.293463
      },
      {
        "id": 5811,
        "latitude": -30.042084,
        "longitude": -51.18256
      },
      {
        "id": 5812,
        "latitude": -21.733945,
        "longitude": -41.529488
      },
      {
        "id": 5813,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5814,
        "latitude": -22.726346,
        "longitude": -43.383576
      },
      {
        "id": 5815,
        "latitude": -21.754605,
        "longitude": -41.346261
      },
      {
        "id": 5816,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5817,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5818,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5819,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5820,
        "latitude": -21.726944,
        "longitude": -41.316992
      },
      {
        "id": 5821,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5822,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5823,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5824,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 5825,
        "latitude": -21.764934,
        "longitude": -41.337843
      },
      {
        "id": 5826,
        "latitude": -12.882843,
        "longitude": -38.438117
      },
      {
        "id": 5827,
        "latitude": -30.042084,
        "longitude": -51.18256
      },
      {
        "id": 5828,
        "latitude": -22.726346,
        "longitude": -43.383576
      },
      {
        "id": 5829,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5830,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5831,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 5832,
        "latitude": -21.782665,
        "longitude": -41.232652
      },
      {
        "id": 5833,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5834,
        "latitude": -8.134957,
        "longitude": -34.9614
      },
      {
        "id": 5835,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 5836,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5837,
        "latitude": -21.763336,
        "longitude": -41.324694
      },
      {
        "id": 5838,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5839,
        "latitude": -21.743795,
        "longitude": -41.360394
      },
      {
        "id": 5840,
        "latitude": -21.718876,
        "longitude": -41.321625
      },
      {
        "id": 5841,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5842,
        "latitude": -21.760626,
        "longitude": -41.306822
      },
      {
        "id": 5843,
        "latitude": -21.778891,
        "longitude": -41.308354
      },
      {
        "id": 5844,
        "latitude": -22.726346,
        "longitude": -43.383576
      },
      {
        "id": 5845,
        "latitude": -22.726346,
        "longitude": -43.383576
      },
      {
        "id": 5846,
        "latitude": -21.760626,
        "longitude": -41.306822
      },
      {
        "id": 5847,
        "latitude": -21.7546,
        "longitude": -41.3242
      },
      {
        "id": 5848,
        "latitude": -12.125687,
        "longitude": -38.440576
      },
      {
        "id": 5849,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5850,
        "latitude": -30.042084,
        "longitude": -51.18256
      },
      {
        "id": 5851,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5852,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5853,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 5855,
        "latitude": -22.481079,
        "longitude": -42.200763
      },
      {
        "id": 5856,
        "latitude": -22.562024,
        "longitude": -41.987265
      },
      {
        "id": 5858,
        "latitude": -22.479045,
        "longitude": -42.195816
      },
      {
        "id": 5859,
        "latitude": -22.56362,
        "longitude": -41.983403
      },
      {
        "id": 5860,
        "latitude": -22.48369,
        "longitude": -42.015673
      },
      {
        "id": 5861,
        "latitude": -22.481079,
        "longitude": -42.200763
      },
      {
        "id": 5953,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5862,
        "latitude": -22.010502,
        "longitude": -42.364682
      },
      {
        "id": 5863,
        "latitude": -22.030782,
        "longitude": -42.369689
      },
      {
        "id": 5864,
        "latitude": -22.034448,
        "longitude": -42.379308
      },
      {
        "id": 5865,
        "latitude": -22.635026,
        "longitude": -43.265659
      },
      {
        "id": 5866,
        "latitude": -22.774839,
        "longitude": -43.317225
      },
      {
        "id": 5867,
        "latitude": -22.768429,
        "longitude": -43.324497
      },
      {
        "id": 5868,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5869,
        "latitude": -22.755286,
        "longitude": -43.520895
      },
      {
        "id": 5870,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5871,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5872,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5873,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5874,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 5875,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5876,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5877,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5878,
        "latitude": -21.490259,
        "longitude": -56.167101
      },
      {
        "id": 6636,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 5879,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5881,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 5882,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5883,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5884,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5886,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5887,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5888,
        "latitude": -22.79197,
        "longitude": -43.317307
      },
      {
        "id": 5889,
        "latitude": -22.776821,
        "longitude": -43.310966
      },
      {
        "id": 5890,
        "latitude": -22.728711,
        "longitude": -43.526324
      },
      {
        "id": 5891,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5892,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5893,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5894,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5896,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5897,
        "latitude": -22.795435,
        "longitude": -43.321523
      },
      {
        "id": 5898,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5899,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5900,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5901,
        "latitude": -22.887672,
        "longitude": -43.526241
      },
      {
        "id": 5902,
        "latitude": -21.803495,
        "longitude": -49.62184
      },
      {
        "id": 5903,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 5904,
        "latitude": -22.786607,
        "longitude": -43.3061
      },
      {
        "id": 5905,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5906,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5907,
        "latitude": -22.785713,
        "longitude": -43.31335
      },
      {
        "id": 5908,
        "latitude": -22.792778,
        "longitude": -43.31832
      },
      {
        "id": 5909,
        "latitude": -22.650927,
        "longitude": -43.279338
      },
      {
        "id": 5910,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5911,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 5912,
        "latitude": -22.795193,
        "longitude": -43.326102
      },
      {
        "id": 5913,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5914,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5915,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5917,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 5918,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 5919,
        "latitude": -22.521865,
        "longitude": -42.981579
      },
      {
        "id": 5920,
        "latitude": -22.669246,
        "longitude": -43.026733
      },
      {
        "id": 5921,
        "latitude": -22.37177,
        "longitude": -43.004562
      },
      {
        "id": 5922,
        "latitude": -22.521865,
        "longitude": -42.981579
      },
      {
        "id": 5923,
        "latitude": -22.521865,
        "longitude": -42.981579
      },
      {
        "id": 5924,
        "latitude": -22.521865,
        "longitude": -42.981579
      },
      {
        "id": 5925,
        "latitude": -22.521865,
        "longitude": -42.981579
      },
      {
        "id": 5926,
        "latitude": -22.4859,
        "longitude": -42.95902
      },
      {
        "id": 5928,
        "latitude": -22.521865,
        "longitude": -42.981579
      },
      {
        "id": 5929,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5930,
        "latitude": -22.822038,
        "longitude": -42.239469
      },
      {
        "id": 5931,
        "latitude": -31.096462,
        "longitude": -50.761088
      },
      {
        "id": 5932,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5933,
        "latitude": -22.869097,
        "longitude": -42.034597
      },
      {
        "id": 5934,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5935,
        "latitude": -22.841174,
        "longitude": -42.209265
      },
      {
        "id": 5936,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5937,
        "latitude": -22.942286,
        "longitude": -43.370111
      },
      {
        "id": 5938,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5939,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5940,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5941,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5942,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5943,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5944,
        "latitude": -22.839841,
        "longitude": -42.205119
      },
      {
        "id": 5945,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 5946,
        "latitude": -22.844565,
        "longitude": -42.234075
      },
      {
        "id": 5947,
        "latitude": -16.051558,
        "longitude": -47.971185
      },
      {
        "id": 5948,
        "latitude": -22.865232,
        "longitude": -42.260191
      },
      {
        "id": 5949,
        "latitude": -22.834243,
        "longitude": -42.22739
      },
      {
        "id": 5951,
        "latitude": -22.789477,
        "longitude": -42.89845
      },
      {
        "id": 5954,
        "latitude": -22.720928,
        "longitude": -42.942471
      },
      {
        "id": 5955,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5956,
        "latitude": -22.755813,
        "longitude": -42.882956
      },
      {
        "id": 5957,
        "latitude": -22.811757,
        "longitude": -43.223945
      },
      {
        "id": 5958,
        "latitude": -22.750719,
        "longitude": -42.937075
      },
      {
        "id": 5959,
        "latitude": -22.793125,
        "longitude": -42.930125
      },
      {
        "id": 5960,
        "latitude": -22.762792,
        "longitude": -42.867598
      },
      {
        "id": 5961,
        "latitude": -22.736995,
        "longitude": -42.852093
      },
      {
        "id": 5962,
        "latitude": -22.780153,
        "longitude": -42.924934
      },
      {
        "id": 5964,
        "latitude": -22.763491,
        "longitude": -42.856969
      },
      {
        "id": 5965,
        "latitude": -22.743107,
        "longitude": -42.947035
      },
      {
        "id": 5966,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5967,
        "latitude": -22.785793,
        "longitude": -42.923362
      },
      {
        "id": 5968,
        "latitude": -22.68863,
        "longitude": -42.800301
      },
      {
        "id": 5969,
        "latitude": -22.731599,
        "longitude": -42.94059
      },
      {
        "id": 5970,
        "latitude": -15.018755,
        "longitude": -51.30612
      },
      {
        "id": 5971,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5972,
        "latitude": -22.68863,
        "longitude": -42.800301
      },
      {
        "id": 5973,
        "latitude": -22.757522,
        "longitude": -42.86688
      },
      {
        "id": 5974,
        "latitude": -22.785793,
        "longitude": -42.923362
      },
      {
        "id": 5975,
        "latitude": -22.757989,
        "longitude": -42.845117
      },
      {
        "id": 5976,
        "latitude": -22.277535,
        "longitude": -42.558905
      },
      {
        "id": 5977,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5978,
        "latitude": -22.695749,
        "longitude": -42.798055
      },
      {
        "id": 5979,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5980,
        "latitude": -21.47091,
        "longitude": -41.055266
      },
      {
        "id": 5981,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 5982,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 5983,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 5984,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 5985,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 5986,
        "latitude": -22.791258,
        "longitude": -42.931177
      },
      {
        "id": 5987,
        "latitude": -22.604019,
        "longitude": -43.038602
      },
      {
        "id": 5988,
        "latitude": -25.492275,
        "longitude": -49.348572
      },
      {
        "id": 5989,
        "latitude": -22.791171,
        "longitude": -43.182141
      },
      {
        "id": 5990,
        "latitude": -22.755813,
        "longitude": -42.882956
      },
      {
        "id": 5992,
        "latitude": -22.783718,
        "longitude": -42.926171
      },
      {
        "id": 5993,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5994,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5995,
        "latitude": -22.785793,
        "longitude": -42.923362
      },
      {
        "id": 5996,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5997,
        "latitude": -22.764575,
        "longitude": -42.931105
      },
      {
        "id": 5998,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 5999,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6001,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6002,
        "latitude": -21.47091,
        "longitude": -41.055266
      },
      {
        "id": 6003,
        "latitude": -21.47091,
        "longitude": -41.055266
      },
      {
        "id": 6004,
        "latitude": -22.76667,
        "longitude": -42.93333
      },
      {
        "id": 6005,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6006,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6008,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6009,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6010,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6011,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6012,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6013,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6015,
        "latitude": -22.404567,
        "longitude": -41.80617
      },
      {
        "id": 6016,
        "latitude": -22.927118,
        "longitude": -42.595141
      },
      {
        "id": 6017,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6018,
        "latitude": -22.90572,
        "longitude": -43.22472
      },
      {
        "id": 6019,
        "latitude": -22.90572,
        "longitude": -43.22472
      },
      {
        "id": 6020,
        "latitude": -22.768203,
        "longitude": -42.534284
      },
      {
        "id": 6021,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6022,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6132,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6023,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6024,
        "latitude": -22.768203,
        "longitude": -42.534284
      },
      {
        "id": 6025,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6026,
        "latitude": -22.877366,
        "longitude": -43.799439
      },
      {
        "id": 6027,
        "latitude": -22.777615,
        "longitude": -42.910715
      },
      {
        "id": 6028,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6029,
        "latitude": -11.832115,
        "longitude": -46.269253
      },
      {
        "id": 6030,
        "latitude": -22.763491,
        "longitude": -42.856969
      },
      {
        "id": 6031,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6032,
        "latitude": -18.688327,
        "longitude": -49.573136
      },
      {
        "id": 6033,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6034,
        "latitude": -22.762792,
        "longitude": -42.867598
      },
      {
        "id": 6036,
        "latitude": -22.721635,
        "longitude": -42.859809
      },
      {
        "id": 6037,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6038,
        "latitude": -22.715443,
        "longitude": -42.896646
      },
      {
        "id": 6039,
        "latitude": -6.469897,
        "longitude": -37.091672
      },
      {
        "id": 6040,
        "latitude": -21.47091,
        "longitude": -41.055266
      },
      {
        "id": 6041,
        "latitude": -27.142151,
        "longitude": -48.593926
      },
      {
        "id": 6042,
        "latitude": -22.744176,
        "longitude": -42.850927
      },
      {
        "id": 6043,
        "latitude": -22.744176,
        "longitude": -42.850927
      },
      {
        "id": 6044,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6045,
        "latitude": -22.755813,
        "longitude": -42.882956
      },
      {
        "id": 6046,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6047,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6048,
        "latitude": -22.757133,
        "longitude": -42.89289
      },
      {
        "id": 6049,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6050,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6051,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6052,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6053,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6054,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6055,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6056,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6057,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6058,
        "latitude": -22.790348,
        "longitude": -42.933348
      },
      {
        "id": 6059,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6060,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6061,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6062,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6063,
        "latitude": -22.768975,
        "longitude": -42.927789
      },
      {
        "id": 6064,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6065,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6066,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6067,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6068,
        "latitude": -22.739731,
        "longitude": -42.949402
      },
      {
        "id": 6069,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6070,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6071,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6072,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6073,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6075,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6076,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6077,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6078,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6079,
        "latitude": -22.754247,
        "longitude": -42.83761
      },
      {
        "id": 6080,
        "latitude": -22.75644,
        "longitude": -42.850967
      },
      {
        "id": 6081,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6082,
        "latitude": -22.718824,
        "longitude": -42.898546
      },
      {
        "id": 6084,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6085,
        "latitude": -11.835287,
        "longitude": -38.364274
      },
      {
        "id": 6086,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6087,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6088,
        "latitude": -29.706235,
        "longitude": -53.82168
      },
      {
        "id": 6089,
        "latitude": -22.68863,
        "longitude": -42.800301
      },
      {
        "id": 6090,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6091,
        "latitude": -22.752692,
        "longitude": -42.895999
      },
      {
        "id": 6092,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6655,
        "latitude": -22.290914,
        "longitude": -42.531688
      },
      {
        "id": 6093,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6094,
        "latitude": -22.740634,
        "longitude": -42.85314
      },
      {
        "id": 6095,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6096,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6097,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6098,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6099,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6100,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6101,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6102,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6103,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6104,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6105,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6106,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6107,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6108,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6109,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6110,
        "latitude": -22.601798,
        "longitude": -43.034762
      },
      {
        "id": 6111,
        "latitude": -22.750719,
        "longitude": -42.937075
      },
      {
        "id": 6133,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6112,
        "latitude": -22.720928,
        "longitude": -42.942471
      },
      {
        "id": 6113,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6114,
        "latitude": -22.777036,
        "longitude": -42.910213
      },
      {
        "id": 6115,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6116,
        "latitude": -22.726599,
        "longitude": -42.851261
      },
      {
        "id": 6117,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6118,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6119,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6120,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6121,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6122,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6123,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6124,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6125,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6126,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6127,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6128,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6129,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6130,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6131,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6134,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6135,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6136,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6137,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6138,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6139,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6144,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6145,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6146,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6147,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6148,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6149,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6150,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6151,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6152,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6153,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6154,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6155,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6156,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6157,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6158,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6159,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6160,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6161,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6162,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6163,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6164,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6165,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6166,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6167,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6168,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6169,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6170,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6171,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6172,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6173,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6174,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6175,
        "latitude": -22.925104,
        "longitude": -43.17299
      },
      {
        "id": 6181,
        "latitude": -22.70685,
        "longitude": -42.906092
      },
      {
        "id": 6182,
        "latitude": -22.841129,
        "longitude": -43.357406
      },
      {
        "id": 6183,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6184,
        "latitude": -22.77725,
        "longitude": -42.909733
      },
      {
        "id": 6185,
        "latitude": -22.777036,
        "longitude": -42.910213
      },
      {
        "id": 6186,
        "latitude": -22.68863,
        "longitude": -42.800301
      },
      {
        "id": 6187,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6188,
        "latitude": -22.764711,
        "longitude": -42.83877
      },
      {
        "id": 6189,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6190,
        "latitude": -22.75525,
        "longitude": -42.88334
      },
      {
        "id": 6191,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6192,
        "latitude": -22.605704,
        "longitude": -44.039318
      },
      {
        "id": 6193,
        "latitude": -22.750719,
        "longitude": -42.937075
      },
      {
        "id": 6194,
        "latitude": -22.266671,
        "longitude": -43.108343
      },
      {
        "id": 6195,
        "latitude": -22.88899,
        "longitude": -43.12314
      },
      {
        "id": 6196,
        "latitude": -22.705489,
        "longitude": -42.885755
      },
      {
        "id": 6197,
        "latitude": -22.777791,
        "longitude": -42.93494
      },
      {
        "id": 6198,
        "latitude": -22.737878,
        "longitude": -42.838866
      },
      {
        "id": 6199,
        "latitude": -22.731088,
        "longitude": -42.84973
      },
      {
        "id": 6200,
        "latitude": -22.730498,
        "longitude": -42.850455
      },
      {
        "id": 6201,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6202,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6203,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6204,
        "latitude": -22.755813,
        "longitude": -42.882956
      },
      {
        "id": 6205,
        "latitude": -22.779079,
        "longitude": -42.92166
      },
      {
        "id": 6206,
        "latitude": -22.78886,
        "longitude": -42.995815
      },
      {
        "id": 6207,
        "latitude": -22.790816,
        "longitude": -42.940112
      },
      {
        "id": 6208,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6209,
        "latitude": -22.85654,
        "longitude": -43.057852
      },
      {
        "id": 6210,
        "latitude": -22.704685,
        "longitude": -42.602439
      },
      {
        "id": 6211,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 6212,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6213,
        "latitude": -22.88899,
        "longitude": -43.12314
      },
      {
        "id": 6214,
        "latitude": -22.85177,
        "longitude": -43.393935
      },
      {
        "id": 6215,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 6216,
        "latitude": -22.754275,
        "longitude": -42.861095
      },
      {
        "id": 6217,
        "latitude": -13.006665,
        "longitude": -38.497022
      },
      {
        "id": 6218,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6219,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6220,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6221,
        "latitude": -22.941263,
        "longitude": -43.347846
      },
      {
        "id": 6222,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6223,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6224,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6225,
        "latitude": -3.723228,
        "longitude": -38.555582
      },
      {
        "id": 6226,
        "latitude": -22.861586,
        "longitude": -43.888927
      },
      {
        "id": 6227,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6228,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6229,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6230,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6231,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6232,
        "latitude": -22.843581,
        "longitude": -44.062513
      },
      {
        "id": 6233,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6234,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6235,
        "latitude": -22.856429,
        "longitude": -43.767951
      },
      {
        "id": 6236,
        "latitude": -13.006665,
        "longitude": -38.497022
      },
      {
        "id": 6237,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6238,
        "latitude": -22.860054,
        "longitude": -43.765867
      },
      {
        "id": 6239,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6240,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6241,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6242,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6243,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6244,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6245,
        "latitude": -22.873183,
        "longitude": -43.815016
      },
      {
        "id": 6246,
        "latitude": -22.91806,
        "longitude": -43.83444
      },
      {
        "id": 6247,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6248,
        "latitude": -22.861586,
        "longitude": -43.888927
      },
      {
        "id": 6249,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6250,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6251,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6252,
        "latitude": -22.864684,
        "longitude": -43.765116
      },
      {
        "id": 6253,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6254,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6255,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6256,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6257,
        "latitude": -22.833669,
        "longitude": -43.764867
      },
      {
        "id": 6258,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6259,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6260,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6261,
        "latitude": -22.86296,
        "longitude": -43.775322
      },
      {
        "id": 6262,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6263,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6264,
        "latitude": -22.893951,
        "longitude": -43.589482
      },
      {
        "id": 6265,
        "latitude": -22.861586,
        "longitude": -43.888927
      },
      {
        "id": 6266,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6267,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6268,
        "latitude": -22.864881,
        "longitude": -43.774882
      },
      {
        "id": 6269,
        "latitude": -22.860054,
        "longitude": -43.765867
      },
      {
        "id": 6270,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6272,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6273,
        "latitude": -22.91806,
        "longitude": -43.83444
      },
      {
        "id": 6274,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6275,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6276,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6277,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6278,
        "latitude": -22.865923,
        "longitude": -43.796262
      },
      {
        "id": 6279,
        "latitude": -22.945398,
        "longitude": -43.591396
      },
      {
        "id": 6280,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6281,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6282,
        "latitude": -22.79635,
        "longitude": -43.583039
      },
      {
        "id": 6284,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6285,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6286,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6287,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 6288,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 6289,
        "latitude": -21.670677,
        "longitude": -42.073579
      },
      {
        "id": 6290,
        "latitude": -22.459985,
        "longitude": -44.480092
      },
      {
        "id": 6291,
        "latitude": -22.433629,
        "longitude": -44.52313
      },
      {
        "id": 6292,
        "latitude": -22.983614,
        "longitude": -43.221612
      },
      {
        "id": 6293,
        "latitude": -22.305963,
        "longitude": -41.75279
      },
      {
        "id": 6294,
        "latitude": -22.925706,
        "longitude": -43.631585
      },
      {
        "id": 6295,
        "latitude": -22.305963,
        "longitude": -41.75279
      },
      {
        "id": 6296,
        "latitude": -22.983614,
        "longitude": -43.221612
      },
      {
        "id": 6297,
        "latitude": -22.925706,
        "longitude": -43.631585
      },
      {
        "id": 6298,
        "latitude": -22.90277,
        "longitude": -43.179097
      },
      {
        "id": 6300,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6302,
        "latitude": -22.983614,
        "longitude": -43.221612
      },
      {
        "id": 6303,
        "latitude": -22.90277,
        "longitude": -43.179097
      },
      {
        "id": 6305,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6307,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6308,
        "latitude": -22.870844,
        "longitude": -43.630306
      },
      {
        "id": 6309,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6310,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6311,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6312,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6313,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6314,
        "latitude": -15.964506,
        "longitude": -41.496823
      },
      {
        "id": 6315,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6316,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6490,
        "latitude": -22.482748,
        "longitude": -41.906589
      },
      {
        "id": 6318,
        "latitude": -22.126113,
        "longitude": -41.8785
      },
      {
        "id": 6319,
        "latitude": -22.340256,
        "longitude": -41.750624
      },
      {
        "id": 6320,
        "latitude": -22.53068,
        "longitude": -41.990093
      },
      {
        "id": 6321,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6322,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6323,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6324,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6325,
        "latitude": -22.340256,
        "longitude": -41.750624
      },
      {
        "id": 6326,
        "latitude": -22.340624,
        "longitude": -41.750439
      },
      {
        "id": 6327,
        "latitude": -22.337707,
        "longitude": -41.752782
      },
      {
        "id": 6328,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6329,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6330,
        "latitude": -22.346308,
        "longitude": -41.791621
      },
      {
        "id": 6331,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6332,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6333,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6334,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6335,
        "latitude": -22.052163,
        "longitude": -41.875069
      },
      {
        "id": 6336,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6337,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6338,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6339,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6341,
        "latitude": -22.282831,
        "longitude": -41.964909
      },
      {
        "id": 6343,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6344,
        "latitude": -22.401082,
        "longitude": -41.802076
      },
      {
        "id": 6345,
        "latitude": -22.340624,
        "longitude": -41.750439
      },
      {
        "id": 6347,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6348,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6349,
        "latitude": -22.330395,
        "longitude": -41.775649
      },
      {
        "id": 6350,
        "latitude": -22.330147,
        "longitude": -41.730098
      },
      {
        "id": 6351,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6352,
        "latitude": -22.394014,
        "longitude": -41.790757
      },
      {
        "id": 6353,
        "latitude": -22.514878,
        "longitude": -41.922345
      },
      {
        "id": 6354,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6356,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6357,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6358,
        "latitude": -22.340624,
        "longitude": -41.750439
      },
      {
        "id": 6359,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6360,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6361,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6362,
        "latitude": -22.90277,
        "longitude": -43.179097
      },
      {
        "id": 6363,
        "latitude": -22.282831,
        "longitude": -41.964909
      },
      {
        "id": 6364,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6365,
        "latitude": -22.373861,
        "longitude": -41.778972
      },
      {
        "id": 6366,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6367,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6368,
        "latitude": -22.337707,
        "longitude": -41.752782
      },
      {
        "id": 6369,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6370,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6371,
        "latitude": -15.964506,
        "longitude": -41.496823
      },
      {
        "id": 6372,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6373,
        "latitude": -22.340624,
        "longitude": -41.750439
      },
      {
        "id": 6374,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6375,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6376,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6377,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6378,
        "latitude": -22.340624,
        "longitude": -41.750439
      },
      {
        "id": 6379,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6380,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6381,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6382,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6383,
        "latitude": -22.43333,
        "longitude": -41.86667
      },
      {
        "id": 6384,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6385,
        "latitude": -22.908336,
        "longitude": -43.528438
      },
      {
        "id": 6386,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6387,
        "latitude": -22.361843,
        "longitude": -41.818649
      },
      {
        "id": 6388,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 6389,
        "latitude": -22.529328,
        "longitude": -41.931798
      },
      {
        "id": 6390,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 6391,
        "latitude": -22.3945,
        "longitude": -41.78509
      },
      {
        "id": 6392,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6393,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6394,
        "latitude": -22.709829,
        "longitude": -43.173635
      },
      {
        "id": 6395,
        "latitude": -22.680439,
        "longitude": -43.169911
      },
      {
        "id": 6396,
        "latitude": -22.641383,
        "longitude": -43.201875
      },
      {
        "id": 6397,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6398,
        "latitude": -22.70553,
        "longitude": -43.174312
      },
      {
        "id": 6399,
        "latitude": -22.680439,
        "longitude": -43.169911
      },
      {
        "id": 6400,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6401,
        "latitude": -22.79306,
        "longitude": -43.30036
      },
      {
        "id": 6402,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6403,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6404,
        "latitude": -22.818709,
        "longitude": -43.199413
      },
      {
        "id": 6405,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6406,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6407,
        "latitude": -22.927061,
        "longitude": -43.601037
      },
      {
        "id": 6408,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6409,
        "latitude": -22.657832,
        "longitude": -43.128557
      },
      {
        "id": 6410,
        "latitude": -22.709829,
        "longitude": -43.173635
      },
      {
        "id": 6411,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6412,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6413,
        "latitude": -22.657832,
        "longitude": -43.128557
      },
      {
        "id": 6414,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6415,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6416,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6417,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6418,
        "latitude": -22.815292,
        "longitude": -43.224961
      },
      {
        "id": 6419,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6420,
        "latitude": -22.707684,
        "longitude": -43.169132
      },
      {
        "id": 6597,
        "latitude": -22.955821,
        "longitude": -42.996896
      },
      {
        "id": 6421,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6422,
        "latitude": -22.271864,
        "longitude": -42.612815
      },
      {
        "id": 6423,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6424,
        "latitude": -22.697585,
        "longitude": -43.164952
      },
      {
        "id": 6425,
        "latitude": -22.295476,
        "longitude": -42.510411
      },
      {
        "id": 6427,
        "latitude": -22.709245,
        "longitude": -43.169563
      },
      {
        "id": 6429,
        "latitude": -22.697585,
        "longitude": -43.164952
      },
      {
        "id": 6430,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6431,
        "latitude": -22.841986,
        "longitude": -43.330655
      },
      {
        "id": 6432,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6433,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6434,
        "latitude": -22.61779,
        "longitude": -43.17913
      },
      {
        "id": 6435,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6436,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6437,
        "latitude": -22.702665,
        "longitude": -43.149983
      },
      {
        "id": 6438,
        "latitude": -22.70693,
        "longitude": -43.151124
      },
      {
        "id": 6440,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6441,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6442,
        "latitude": -22.777615,
        "longitude": -42.910715
      },
      {
        "id": 6443,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6444,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6445,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6446,
        "latitude": -22.717248,
        "longitude": -43.183998
      },
      {
        "id": 6447,
        "latitude": -22.61779,
        "longitude": -43.17913
      },
      {
        "id": 6448,
        "latitude": -22.687541,
        "longitude": -43.168726
      },
      {
        "id": 6449,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6450,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6451,
        "latitude": -22.295476,
        "longitude": -42.510411
      },
      {
        "id": 6452,
        "latitude": -22.697585,
        "longitude": -43.164952
      },
      {
        "id": 6453,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6454,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6455,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6456,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6457,
        "latitude": -22.715064,
        "longitude": -43.171391
      },
      {
        "id": 6458,
        "latitude": -22.61779,
        "longitude": -43.17913
      },
      {
        "id": 6459,
        "latitude": -22.705702,
        "longitude": -43.132364
      },
      {
        "id": 6460,
        "latitude": -22.680439,
        "longitude": -43.169911
      },
      {
        "id": 6461,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6462,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6463,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6464,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6465,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6466,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6467,
        "latitude": -22.707198,
        "longitude": -43.173458
      },
      {
        "id": 6468,
        "latitude": -22.957229,
        "longitude": -43.684154
      },
      {
        "id": 6469,
        "latitude": -22.703999,
        "longitude": -43.144916
      },
      {
        "id": 6470,
        "latitude": -22.874259,
        "longitude": -43.239658
      },
      {
        "id": 6471,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6472,
        "latitude": -22.61472,
        "longitude": -43.16861
      },
      {
        "id": 6473,
        "latitude": -22.70557,
        "longitude": -43.162772
      },
      {
        "id": 6474,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 6475,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 6476,
        "latitude": -22.295476,
        "longitude": -42.510411
      },
      {
        "id": 6477,
        "latitude": -22.61472,
        "longitude": -43.16861
      },
      {
        "id": 6478,
        "latitude": -22.939238,
        "longitude": -44.092453
      },
      {
        "id": 6479,
        "latitude": -22.939238,
        "longitude": -44.092453
      },
      {
        "id": 6480,
        "latitude": -22.9414,
        "longitude": -44.0419
      },
      {
        "id": 6481,
        "latitude": -22.873864,
        "longitude": -43.627317
      },
      {
        "id": 6482,
        "latitude": -22.873864,
        "longitude": -43.627317
      },
      {
        "id": 6483,
        "latitude": -22.939238,
        "longitude": -44.092453
      },
      {
        "id": 6484,
        "latitude": -23.030836,
        "longitude": -44.132559
      },
      {
        "id": 6485,
        "latitude": -22.939238,
        "longitude": -44.092453
      },
      {
        "id": 6486,
        "latitude": -22.93754,
        "longitude": -44.039016
      },
      {
        "id": 6488,
        "latitude": -22.92229,
        "longitude": -43.946238
      },
      {
        "id": 6489,
        "latitude": -22.828812,
        "longitude": -42.2267
      },
      {
        "id": 6491,
        "latitude": -22.921845,
        "longitude": -43.940518
      },
      {
        "id": 6492,
        "latitude": -22.923564,
        "longitude": -43.9351
      },
      {
        "id": 6493,
        "latitude": -22.939238,
        "longitude": -44.092453
      },
      {
        "id": 6494,
        "latitude": -22.922446,
        "longitude": -43.944074
      },
      {
        "id": 6495,
        "latitude": -22.960254,
        "longitude": -44.028545
      },
      {
        "id": 6496,
        "latitude": -22.939238,
        "longitude": -44.092453
      },
      {
        "id": 6497,
        "latitude": -22.915721,
        "longitude": -42.816966
      },
      {
        "id": 6498,
        "latitude": -22.904893,
        "longitude": -43.124356
      },
      {
        "id": 6499,
        "latitude": -19.59295,
        "longitude": -46.946016
      },
      {
        "id": 6500,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6501,
        "latitude": -22.95617,
        "longitude": -42.7951
      },
      {
        "id": 6502,
        "latitude": -22.936981,
        "longitude": -42.881385
      },
      {
        "id": 6503,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6504,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6505,
        "latitude": -22.80812,
        "longitude": -43.223218
      },
      {
        "id": 6506,
        "latitude": -10.990134,
        "longitude": -37.074047
      },
      {
        "id": 6507,
        "latitude": -22.943648,
        "longitude": -42.926587
      },
      {
        "id": 6508,
        "latitude": -22.936941,
        "longitude": -42.661214
      },
      {
        "id": 6511,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6512,
        "latitude": -5.326824,
        "longitude": -49.09948
      },
      {
        "id": 6513,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6514,
        "latitude": -22.88026,
        "longitude": -42.80133
      },
      {
        "id": 6515,
        "latitude": -22.912622,
        "longitude": -42.809056
      },
      {
        "id": 6516,
        "latitude": -22.96667,
        "longitude": -43.01667
      },
      {
        "id": 6517,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6518,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6519,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6520,
        "latitude": -22.805395,
        "longitude": -43.004625
      },
      {
        "id": 6522,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6523,
        "latitude": -19.75871,
        "longitude": -44.163347
      },
      {
        "id": 6616,
        "latitude": -22.885068,
        "longitude": -43.123188
      },
      {
        "id": 6525,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6526,
        "latitude": -22.934638,
        "longitude": -43.174589
      },
      {
        "id": 6527,
        "latitude": -3.728891,
        "longitude": -38.571315
      },
      {
        "id": 6528,
        "latitude": -22.939402,
        "longitude": -42.949386
      },
      {
        "id": 6529,
        "latitude": -22.933075,
        "longitude": -43.072008
      },
      {
        "id": 6530,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6531,
        "latitude": -21.288142,
        "longitude": -50.324752
      },
      {
        "id": 6532,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6533,
        "latitude": -22.716364,
        "longitude": -42.923423
      },
      {
        "id": 6534,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6535,
        "latitude": -22.777036,
        "longitude": -42.910213
      },
      {
        "id": 6536,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6537,
        "latitude": -22.963426,
        "longitude": -42.995103
      },
      {
        "id": 6538,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6539,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6540,
        "latitude": -22.698664,
        "longitude": -42.874653
      },
      {
        "id": 6541,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6542,
        "latitude": -22.950738,
        "longitude": -42.944004
      },
      {
        "id": 6543,
        "latitude": -22.956648,
        "longitude": -42.948255
      },
      {
        "id": 6544,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6545,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6546,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6547,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6548,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6549,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6550,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6551,
        "latitude": -22.902887,
        "longitude": -43.187973
      },
      {
        "id": 6552,
        "latitude": -22.94702,
        "longitude": -42.960467
      },
      {
        "id": 6553,
        "latitude": -22.954381,
        "longitude": -42.957275
      },
      {
        "id": 6554,
        "latitude": -22.916596,
        "longitude": -42.850014
      },
      {
        "id": 6555,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6556,
        "latitude": -22.955129,
        "longitude": -42.70084
      },
      {
        "id": 6557,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 6558,
        "latitude": -22.732507,
        "longitude": -42.857192
      },
      {
        "id": 6559,
        "latitude": -22.896094,
        "longitude": -43.283297
      },
      {
        "id": 6560,
        "latitude": -22.7994,
        "longitude": -43.453724
      },
      {
        "id": 6561,
        "latitude": -22.905192,
        "longitude": -43.202316
      },
      {
        "id": 6562,
        "latitude": -22.915738,
        "longitude": -43.199338
      },
      {
        "id": 6564,
        "latitude": -22.454312,
        "longitude": -43.470438
      },
      {
        "id": 6565,
        "latitude": -22.643648,
        "longitude": -43.300941
      },
      {
        "id": 6567,
        "latitude": -22.830381,
        "longitude": -43.431671
      },
      {
        "id": 6569,
        "latitude": -22.896182,
        "longitude": -43.192551
      },
      {
        "id": 6570,
        "latitude": -22.801162,
        "longitude": -43.416781
      },
      {
        "id": 6571,
        "latitude": -22.807621,
        "longitude": -43.421045
      },
      {
        "id": 6573,
        "latitude": -22.830381,
        "longitude": -43.431671
      },
      {
        "id": 6574,
        "latitude": -22.81578,
        "longitude": -43.416396
      },
      {
        "id": 6575,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 6576,
        "latitude": -22.815277,
        "longitude": -43.400898
      },
      {
        "id": 6577,
        "latitude": -22.812179,
        "longitude": -43.398493
      },
      {
        "id": 6578,
        "latitude": -22.830381,
        "longitude": -43.431671
      },
      {
        "id": 6579,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 6580,
        "latitude": -22.907115,
        "longitude": -43.182146
      },
      {
        "id": 6581,
        "latitude": -22.796864,
        "longitude": -43.414288
      },
      {
        "id": 6582,
        "latitude": -22.807621,
        "longitude": -43.421045
      },
      {
        "id": 6583,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 6584,
        "latitude": -22.807621,
        "longitude": -43.421045
      },
      {
        "id": 6585,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 6586,
        "latitude": -22.612236,
        "longitude": -46.078559
      },
      {
        "id": 6587,
        "latitude": -22.807621,
        "longitude": -43.421045
      },
      {
        "id": 6588,
        "latitude": -22.804367,
        "longitude": -43.44052
      },
      {
        "id": 6589,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 6590,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 6591,
        "latitude": -22.807621,
        "longitude": -43.421045
      },
      {
        "id": 6592,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 6593,
        "latitude": -22.80808,
        "longitude": -43.41257
      },
      {
        "id": 6594,
        "latitude": -22.867545,
        "longitude": -43.10478
      },
      {
        "id": 6595,
        "latitude": -22.873027,
        "longitude": -43.0689
      },
      {
        "id": 6596,
        "latitude": -22.892333,
        "longitude": -42.987243
      },
      {
        "id": 6598,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6599,
        "latitude": -22.940581,
        "longitude": -43.014879
      },
      {
        "id": 6600,
        "latitude": -22.882225,
        "longitude": -43.089047
      },
      {
        "id": 6601,
        "latitude": -22.87312,
        "longitude": -43.105142
      },
      {
        "id": 6602,
        "latitude": -22.901543,
        "longitude": -43.084889
      },
      {
        "id": 6603,
        "latitude": -22.882225,
        "longitude": -43.089047
      },
      {
        "id": 6604,
        "latitude": -22.878433,
        "longitude": -43.083505
      },
      {
        "id": 6605,
        "latitude": -22.8775,
        "longitude": -43.096278
      },
      {
        "id": 6606,
        "latitude": -22.88476,
        "longitude": -43.087531
      },
      {
        "id": 6607,
        "latitude": -22.909366,
        "longitude": -43.101511
      },
      {
        "id": 6609,
        "latitude": -22.8775,
        "longitude": -43.096278
      },
      {
        "id": 6610,
        "latitude": -22.873182,
        "longitude": -43.078005
      },
      {
        "id": 6611,
        "latitude": -22.941125,
        "longitude": -43.024082
      },
      {
        "id": 6613,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6614,
        "latitude": -22.882055,
        "longitude": -43.101107
      },
      {
        "id": 6615,
        "latitude": -22.764666,
        "longitude": -43.33349
      },
      {
        "id": 6618,
        "latitude": -22.897044,
        "longitude": -43.119805
      },
      {
        "id": 6619,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6620,
        "latitude": -23.675973,
        "longitude": -46.786541
      },
      {
        "id": 6621,
        "latitude": -22.883444,
        "longitude": -43.10699
      },
      {
        "id": 6622,
        "latitude": -22.888321,
        "longitude": -43.110206
      },
      {
        "id": 6623,
        "latitude": -22.90448,
        "longitude": -43.108585
      },
      {
        "id": 6624,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6625,
        "latitude": -22.945787,
        "longitude": -43.033103
      },
      {
        "id": 6626,
        "latitude": -22.869601,
        "longitude": -43.093857
      },
      {
        "id": 6627,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6628,
        "latitude": -22.862117,
        "longitude": -43.090137
      },
      {
        "id": 6629,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6630,
        "latitude": -29.830338,
        "longitude": -50.057782
      },
      {
        "id": 6631,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 6632,
        "latitude": -22.897762,
        "longitude": -43.121309
      },
      {
        "id": 6633,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 6634,
        "latitude": -22.879585,
        "longitude": -43.08638
      },
      {
        "id": 6635,
        "latitude": -22.901469,
        "longitude": -43.068052
      },
      {
        "id": 6637,
        "latitude": -22.882225,
        "longitude": -43.089047
      },
      {
        "id": 6638,
        "latitude": -22.896338,
        "longitude": -43.121903
      },
      {
        "id": 6639,
        "latitude": -23.396614,
        "longitude": -46.520171
      },
      {
        "id": 6640,
        "latitude": -22.940581,
        "longitude": -43.014879
      },
      {
        "id": 6641,
        "latitude": -22.862378,
        "longitude": -43.095623
      },
      {
        "id": 6642,
        "latitude": -22.68662,
        "longitude": -43.241608
      },
      {
        "id": 6644,
        "latitude": -22.293312,
        "longitude": -42.544383
      },
      {
        "id": 6645,
        "latitude": -22.28,
        "longitude": -42.53253
      },
      {
        "id": 6647,
        "latitude": -22.19936,
        "longitude": -42.505035
      },
      {
        "id": 6648,
        "latitude": -22.290449,
        "longitude": -42.531849
      },
      {
        "id": 6650,
        "latitude": -22.261533,
        "longitude": -42.46003
      },
      {
        "id": 6652,
        "latitude": -22.293312,
        "longitude": -42.544383
      },
      {
        "id": 6653,
        "latitude": -22.225036,
        "longitude": -42.513758
      },
      {
        "id": 6654,
        "latitude": -22.270282,
        "longitude": -42.61239
      },
      {
        "id": 6656,
        "latitude": -22.270282,
        "longitude": -42.61239
      },
      {
        "id": 6657,
        "latitude": -22.248992,
        "longitude": -42.527642
      },
      {
        "id": 6658,
        "latitude": -22.28,
        "longitude": -42.53253
      },
      {
        "id": 6659,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6660,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6662,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6663,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6664,
        "latitude": -22.756832,
        "longitude": -43.531685
      },
      {
        "id": 6665,
        "latitude": -22.766218,
        "longitude": -43.559428
      },
      {
        "id": 6666,
        "latitude": -22.76348,
        "longitude": -43.559098
      },
      {
        "id": 6667,
        "latitude": -22.765459,
        "longitude": -43.557737
      },
      {
        "id": 6668,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6669,
        "latitude": -22.778535,
        "longitude": -43.546454
      },
      {
        "id": 6670,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6671,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6672,
        "latitude": -22.756832,
        "longitude": -43.531685
      },
      {
        "id": 6673,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6674,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6675,
        "latitude": -22.749155,
        "longitude": -43.533202
      },
      {
        "id": 6676,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6677,
        "latitude": -22.766218,
        "longitude": -43.559428
      },
      {
        "id": 6678,
        "latitude": -22.765459,
        "longitude": -43.557737
      },
      {
        "id": 6679,
        "latitude": -22.76348,
        "longitude": -43.559098
      },
      {
        "id": 6680,
        "latitude": -22.784375,
        "longitude": -43.557603
      },
      {
        "id": 6681,
        "latitude": -22.770195,
        "longitude": -43.554849
      },
      {
        "id": 6682,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 6683,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 6684,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 6685,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6687,
        "latitude": -22.796224,
        "longitude": -43.574445
      },
      {
        "id": 6688,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6689,
        "latitude": -22.744096,
        "longitude": -43.504246
      },
      {
        "id": 6690,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6691,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6692,
        "latitude": -22.998668,
        "longitude": -43.39825
      },
      {
        "id": 6693,
        "latitude": -22.765698,
        "longitude": -43.514625
      },
      {
        "id": 6694,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6695,
        "latitude": -22.998668,
        "longitude": -43.39825
      },
      {
        "id": 6696,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6697,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6698,
        "latitude": -22.795288,
        "longitude": -43.575295
      },
      {
        "id": 6699,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6700,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6701,
        "latitude": -22.780799,
        "longitude": -43.548704
      },
      {
        "id": 6702,
        "latitude": -19.009945,
        "longitude": -48.233603
      },
      {
        "id": 6703,
        "latitude": -22.765698,
        "longitude": -43.514625
      },
      {
        "id": 6704,
        "latitude": -22.709886,
        "longitude": -43.535684
      },
      {
        "id": 6705,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6706,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6707,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6708,
        "latitude": -22.765159,
        "longitude": -43.530599
      },
      {
        "id": 6709,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6710,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6711,
        "latitude": -22.709886,
        "longitude": -43.535684
      },
      {
        "id": 6712,
        "latitude": -22.999106,
        "longitude": -43.397665
      },
      {
        "id": 6713,
        "latitude": -22.765698,
        "longitude": -43.514625
      },
      {
        "id": 6714,
        "latitude": -22.765698,
        "longitude": -43.514625
      },
      {
        "id": 6715,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6716,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6717,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6718,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6719,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6720,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6721,
        "latitude": -22.776202,
        "longitude": -43.526787
      },
      {
        "id": 6722,
        "latitude": -22.795288,
        "longitude": -43.575295
      },
      {
        "id": 6723,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6724,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6725,
        "latitude": -22.772428,
        "longitude": -43.536464
      },
      {
        "id": 6726,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6728,
        "latitude": -22.775359,
        "longitude": -43.540601
      },
      {
        "id": 6729,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6730,
        "latitude": -22.780799,
        "longitude": -43.548704
      },
      {
        "id": 6732,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6733,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6734,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6735,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6736,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6737,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 6738,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 6739,
        "latitude": -22.765698,
        "longitude": -43.514625
      },
      {
        "id": 6741,
        "latitude": -22.810448,
        "longitude": -43.382756
      },
      {
        "id": 6742,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6743,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6744,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6745,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6746,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6747,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6748,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6749,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6750,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6751,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6752,
        "latitude": -22.796568,
        "longitude": -43.574456
      },
      {
        "id": 6753,
        "latitude": -22.776475,
        "longitude": -43.53843
      },
      {
        "id": 6754,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6755,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6756,
        "latitude": -22.765159,
        "longitude": -43.530599
      },
      {
        "id": 6757,
        "latitude": -22.864489,
        "longitude": -43.370003
      },
      {
        "id": 6758,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6759,
        "latitude": -22.765459,
        "longitude": -43.557737
      },
      {
        "id": 6760,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6761,
        "latitude": -22.700673,
        "longitude": -43.517887
      },
      {
        "id": 6762,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6763,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6764,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6765,
        "latitude": -22.904849,
        "longitude": -43.542124
      },
      {
        "id": 6766,
        "latitude": -22.761594,
        "longitude": -43.450652
      },
      {
        "id": 6767,
        "latitude": -22.772411,
        "longitude": -43.522553
      },
      {
        "id": 6768,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6769,
        "latitude": -22.765459,
        "longitude": -43.557737
      },
      {
        "id": 6770,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6771,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6772,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 6773,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6775,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6776,
        "latitude": -22.74117,
        "longitude": -43.507737
      },
      {
        "id": 6777,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6778,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 6779,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6780,
        "latitude": -22.916137,
        "longitude": -43.246679
      },
      {
        "id": 6781,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6782,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6783,
        "latitude": -22.765159,
        "longitude": -43.530599
      },
      {
        "id": 6784,
        "latitude": -21.480143,
        "longitude": -41.650247
      },
      {
        "id": 6785,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 6786,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 6787,
        "latitude": -22.585934,
        "longitude": -43.714307
      },
      {
        "id": 6788,
        "latitude": -22.189278,
        "longitude": -43.252252
      },
      {
        "id": 6789,
        "latitude": -22.164032,
        "longitude": -43.298348
      },
      {
        "id": 6790,
        "latitude": -22.159538,
        "longitude": -43.30127
      },
      {
        "id": 6791,
        "latitude": -22.419618,
        "longitude": -43.416946
      },
      {
        "id": 6792,
        "latitude": -22.3847,
        "longitude": -43.115119
      },
      {
        "id": 6793,
        "latitude": -22.510777,
        "longitude": -43.186622
      },
      {
        "id": 6795,
        "latitude": -22.510777,
        "longitude": -43.186622
      },
      {
        "id": 6796,
        "latitude": -22.507574,
        "longitude": -43.178536
      },
      {
        "id": 6797,
        "latitude": -22.288134,
        "longitude": -43.110868
      },
      {
        "id": 6798,
        "latitude": -22.510777,
        "longitude": -43.186622
      },
      {
        "id": 6799,
        "latitude": -22.510777,
        "longitude": -43.186622
      },
      {
        "id": 6800,
        "latitude": -22.380794,
        "longitude": -43.114138
      },
      {
        "id": 6801,
        "latitude": -22.626935,
        "longitude": -43.904771
      },
      {
        "id": 6802,
        "latitude": -22.47513,
        "longitude": -44.047399
      },
      {
        "id": 6803,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6804,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6805,
        "latitude": -22.891408,
        "longitude": -43.369278
      },
      {
        "id": 6806,
        "latitude": -22.692373,
        "longitude": -43.587652
      },
      {
        "id": 6807,
        "latitude": -25.540955,
        "longitude": -49.247813
      },
      {
        "id": 6808,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6809,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6810,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6811,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6813,
        "latitude": -22.70994,
        "longitude": -43.559543
      },
      {
        "id": 6814,
        "latitude": -22.70709,
        "longitude": -43.572885
      },
      {
        "id": 6815,
        "latitude": -22.768102,
        "longitude": -43.342001
      },
      {
        "id": 6816,
        "latitude": -22.840498,
        "longitude": -43.30124
      },
      {
        "id": 6817,
        "latitude": -22.7008,
        "longitude": -43.563262
      },
      {
        "id": 6818,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6820,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6821,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6822,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6823,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6824,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6825,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6826,
        "latitude": -22.84433,
        "longitude": -42.876015
      },
      {
        "id": 6827,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6828,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6829,
        "latitude": -22.759789,
        "longitude": -43.546248
      },
      {
        "id": 6830,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6831,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6832,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6833,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6834,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6835,
        "latitude": -22.694391,
        "longitude": -43.566829
      },
      {
        "id": 6836,
        "latitude": -22.70976,
        "longitude": -43.559798
      },
      {
        "id": 6837,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6838,
        "latitude": -22.759789,
        "longitude": -43.546248
      },
      {
        "id": 6839,
        "latitude": -6.258283,
        "longitude": -36.521366
      },
      {
        "id": 6840,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6841,
        "latitude": -22.742847,
        "longitude": -43.555421
      },
      {
        "id": 6842,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6843,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6844,
        "latitude": -22.826974,
        "longitude": -43.274043
      },
      {
        "id": 6845,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6846,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6847,
        "latitude": -6.258283,
        "longitude": -36.521366
      },
      {
        "id": 6848,
        "latitude": -22.439384,
        "longitude": -43.438519
      },
      {
        "id": 6849,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6850,
        "latitude": -22.759789,
        "longitude": -43.546248
      },
      {
        "id": 6851,
        "latitude": -22.715698,
        "longitude": -43.555754
      },
      {
        "id": 6853,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6854,
        "latitude": -22.79791,
        "longitude": -43.385764
      },
      {
        "id": 6855,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 6856,
        "latitude": -22.73858,
        "longitude": -43.562616
      },
      {
        "id": 6857,
        "latitude": -22.481445,
        "longitude": -44.452341
      },
      {
        "id": 6858,
        "latitude": -22.48132,
        "longitude": -44.479897
      },
      {
        "id": 6859,
        "latitude": -22.483013,
        "longitude": -44.489212
      },
      {
        "id": 6860,
        "latitude": -22.48132,
        "longitude": -44.479897
      },
      {
        "id": 6861,
        "latitude": -22.490808,
        "longitude": -44.501032
      },
      {
        "id": 6862,
        "latitude": -22.518529,
        "longitude": -44.194003
      },
      {
        "id": 6863,
        "latitude": -22.48132,
        "longitude": -44.479897
      },
      {
        "id": 6865,
        "latitude": -23.591561,
        "longitude": -47.035977
      },
      {
        "id": 6866,
        "latitude": -22.713992,
        "longitude": -42.623406
      },
      {
        "id": 6867,
        "latitude": -22.88,
        "longitude": -42.453987
      },
      {
        "id": 6868,
        "latitude": -22.73333,
        "longitude": -42.55
      },
      {
        "id": 6869,
        "latitude": -22.700916,
        "longitude": -42.607428
      },
      {
        "id": 6870,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6871,
        "latitude": -22.554756,
        "longitude": -41.976865
      },
      {
        "id": 6872,
        "latitude": -22.526189,
        "longitude": -41.966241
      },
      {
        "id": 6873,
        "latitude": -22.43333,
        "longitude": -41.86667
      },
      {
        "id": 6874,
        "latitude": -22.52533,
        "longitude": -41.983329
      },
      {
        "id": 6875,
        "latitude": -22.478124,
        "longitude": -41.913322
      },
      {
        "id": 6876,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6877,
        "latitude": -22.43333,
        "longitude": -41.86667
      },
      {
        "id": 6878,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6879,
        "latitude": -22.477371,
        "longitude": -41.919414
      },
      {
        "id": 6880,
        "latitude": -22.500868,
        "longitude": -41.914828
      },
      {
        "id": 6881,
        "latitude": -22.487686,
        "longitude": -41.922793
      },
      {
        "id": 6883,
        "latitude": -22.485679,
        "longitude": -41.926073
      },
      {
        "id": 6884,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6885,
        "latitude": -22.495741,
        "longitude": -41.915765
      },
      {
        "id": 6886,
        "latitude": -22.333517,
        "longitude": -41.752558
      },
      {
        "id": 6888,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6889,
        "latitude": -22.492316,
        "longitude": -41.919793
      },
      {
        "id": 6890,
        "latitude": -29.230584,
        "longitude": -51.156162
      },
      {
        "id": 6891,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6892,
        "latitude": -22.550454,
        "longitude": -41.985168
      },
      {
        "id": 6893,
        "latitude": -22.488362,
        "longitude": -41.908503
      },
      {
        "id": 6894,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6895,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6896,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6897,
        "latitude": -22.494462,
        "longitude": -41.908618
      },
      {
        "id": 6899,
        "latitude": -22.494738,
        "longitude": -41.92503
      },
      {
        "id": 6900,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6901,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6902,
        "latitude": -22.443422,
        "longitude": -41.853166
      },
      {
        "id": 6903,
        "latitude": -22.496083,
        "longitude": -41.910578
      },
      {
        "id": 6904,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6905,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6907,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6908,
        "latitude": -22.443422,
        "longitude": -41.853166
      },
      {
        "id": 6909,
        "latitude": -22.358415,
        "longitude": -41.772732
      },
      {
        "id": 6911,
        "latitude": -22.496083,
        "longitude": -41.910578
      },
      {
        "id": 6913,
        "latitude": -22.476592,
        "longitude": -41.91129
      },
      {
        "id": 6914,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6915,
        "latitude": -22.537267,
        "longitude": -41.967956
      },
      {
        "id": 6916,
        "latitude": -22.49613,
        "longitude": -41.905932
      },
      {
        "id": 6917,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6918,
        "latitude": -22.510058,
        "longitude": -41.950715
      },
      {
        "id": 6919,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6920,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6921,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6922,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6923,
        "latitude": -22.493407,
        "longitude": -41.930307
      },
      {
        "id": 6924,
        "latitude": -22.537267,
        "longitude": -41.967956
      },
      {
        "id": 6925,
        "latitude": -22.492822,
        "longitude": -41.907927
      },
      {
        "id": 6926,
        "latitude": -22.520504,
        "longitude": -41.944437
      },
      {
        "id": 6927,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6928,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6929,
        "latitude": -22.443422,
        "longitude": -41.853166
      },
      {
        "id": 6930,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6932,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6933,
        "latitude": -22.499075,
        "longitude": -41.914995
      },
      {
        "id": 6934,
        "latitude": -22.54878,
        "longitude": -41.980691
      },
      {
        "id": 6935,
        "latitude": -22.502063,
        "longitude": -41.935304
      },
      {
        "id": 6936,
        "latitude": -22.443422,
        "longitude": -41.853166
      },
      {
        "id": 6937,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6938,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6939,
        "latitude": -22.49763,
        "longitude": -41.905365
      },
      {
        "id": 6940,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6941,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6942,
        "latitude": -22.543026,
        "longitude": -41.979215
      },
      {
        "id": 6943,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6944,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6945,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6946,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6947,
        "latitude": -22.488362,
        "longitude": -41.908503
      },
      {
        "id": 6948,
        "latitude": -22.521981,
        "longitude": -41.953343
      },
      {
        "id": 6949,
        "latitude": -22.879681,
        "longitude": -43.224564
      },
      {
        "id": 6950,
        "latitude": -22.494195,
        "longitude": -41.919967
      },
      {
        "id": 6951,
        "latitude": -25.893569,
        "longitude": -50.832922
      },
      {
        "id": 6952,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 6953,
        "latitude": -22.4895,
        "longitude": -41.909937
      },
      {
        "id": 6954,
        "latitude": -22.493407,
        "longitude": -41.930307
      },
      {
        "id": 6955,
        "latitude": -22.493407,
        "longitude": -41.930307
      },
      {
        "id": 6957,
        "latitude": -22.875332,
        "longitude": -43.251269
      },
      {
        "id": 6958,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 6959,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 6960,
        "latitude": -22.983754,
        "longitude": -43.210773
      },
      {
        "id": 6962,
        "latitude": -22.823186,
        "longitude": -43.351211
      },
      {
        "id": 6963,
        "latitude": -22.816197,
        "longitude": -43.37797
      },
      {
        "id": 6964,
        "latitude": -22.852043,
        "longitude": -43.341656
      },
      {
        "id": 6965,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 6966,
        "latitude": -22.897346,
        "longitude": -43.193165
      },
      {
        "id": 6967,
        "latitude": -22.842828,
        "longitude": -43.2802
      },
      {
        "id": 6968,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 6969,
        "latitude": -22.848355,
        "longitude": -43.290395
      },
      {
        "id": 6970,
        "latitude": -22.84531,
        "longitude": -43.298173
      },
      {
        "id": 6971,
        "latitude": -22.81811,
        "longitude": -43.294752
      },
      {
        "id": 6972,
        "latitude": -22.869619,
        "longitude": -43.317025
      },
      {
        "id": 6973,
        "latitude": -22.81667,
        "longitude": -43.2
      },
      {
        "id": 6974,
        "latitude": -22.89556,
        "longitude": -43.53195
      },
      {
        "id": 6975,
        "latitude": -22.840409,
        "longitude": -43.356729
      },
      {
        "id": 6976,
        "latitude": -22.867723,
        "longitude": -43.440369
      },
      {
        "id": 6977,
        "latitude": -22.823304,
        "longitude": -43.289589
      },
      {
        "id": 6978,
        "latitude": -22.843576,
        "longitude": -43.369435
      },
      {
        "id": 6979,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 6980,
        "latitude": -22.816777,
        "longitude": -43.378516
      },
      {
        "id": 6981,
        "latitude": -22.933615,
        "longitude": -43.669369
      },
      {
        "id": 6982,
        "latitude": -22.870767,
        "longitude": -43.257078
      },
      {
        "id": 6984,
        "latitude": -22.913878,
        "longitude": -43.254113
      },
      {
        "id": 6985,
        "latitude": -22.965517,
        "longitude": -43.409303
      },
      {
        "id": 6986,
        "latitude": -22.904053,
        "longitude": -43.243552
      },
      {
        "id": 6987,
        "latitude": -22.80292,
        "longitude": -43.19249
      },
      {
        "id": 6988,
        "latitude": -22.897866,
        "longitude": -43.342523
      },
      {
        "id": 6989,
        "latitude": -22.903813,
        "longitude": -43.350197
      },
      {
        "id": 6990,
        "latitude": -22.90064,
        "longitude": -43.26713
      },
      {
        "id": 6991,
        "latitude": -22.885167,
        "longitude": -43.317159
      },
      {
        "id": 6992,
        "latitude": -22.917911,
        "longitude": -43.211841
      },
      {
        "id": 6994,
        "latitude": -22.899083,
        "longitude": -43.312753
      },
      {
        "id": 6995,
        "latitude": -22.903987,
        "longitude": -43.267888
      },
      {
        "id": 6996,
        "latitude": -22.850305,
        "longitude": -43.493296
      },
      {
        "id": 6998,
        "latitude": -22.913861,
        "longitude": -43.252621
      },
      {
        "id": 6999,
        "latitude": -22.727404,
        "longitude": -43.399075
      },
      {
        "id": 7000,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 7001,
        "latitude": -22.870009,
        "longitude": -43.309239
      },
      {
        "id": 7002,
        "latitude": -22.790182,
        "longitude": -43.172169
      },
      {
        "id": 7003,
        "latitude": -22.789821,
        "longitude": -43.187944
      },
      {
        "id": 7004,
        "latitude": -23.00515,
        "longitude": -43.372192
      },
      {
        "id": 7005,
        "latitude": -22.87744,
        "longitude": -43.32283
      },
      {
        "id": 9423,
        "latitude": -5.162565,
        "longitude": -36.598078
      },
      {
        "id": 7006,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 7007,
        "latitude": -22.897413,
        "longitude": -43.307041
      },
      {
        "id": 7008,
        "latitude": -22.926113,
        "longitude": -43.359458
      },
      {
        "id": 7009,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7010,
        "latitude": -22.895694,
        "longitude": -43.263448
      },
      {
        "id": 7011,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7012,
        "latitude": -23.027588,
        "longitude": -43.561303
      },
      {
        "id": 7013,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7014,
        "latitude": -22.86493,
        "longitude": -43.37014
      },
      {
        "id": 7015,
        "latitude": -30.05414,
        "longitude": -51.168269
      },
      {
        "id": 7016,
        "latitude": -22.8882,
        "longitude": -43.30398
      },
      {
        "id": 7017,
        "latitude": -22.917967,
        "longitude": -43.351887
      },
      {
        "id": 7018,
        "latitude": -22.87986,
        "longitude": -43.302271
      },
      {
        "id": 7019,
        "latitude": -22.878345,
        "longitude": -43.290196
      },
      {
        "id": 7020,
        "latitude": -22.86493,
        "longitude": -43.37014
      },
      {
        "id": 7022,
        "latitude": -22.869619,
        "longitude": -43.317025
      },
      {
        "id": 7059,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 7024,
        "latitude": -22.865359,
        "longitude": -43.295031
      },
      {
        "id": 7025,
        "latitude": -22.892004,
        "longitude": -43.353246
      },
      {
        "id": 7026,
        "latitude": -22.81811,
        "longitude": -43.294752
      },
      {
        "id": 7027,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7028,
        "latitude": -22.85284,
        "longitude": -43.493516
      },
      {
        "id": 7031,
        "latitude": -22.892004,
        "longitude": -43.353246
      },
      {
        "id": 7032,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7033,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7034,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7035,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7036,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7038,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7039,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7040,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7429,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7042,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7043,
        "latitude": -22.92175,
        "longitude": -43.619067
      },
      {
        "id": 7044,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7045,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7046,
        "latitude": -22.911955,
        "longitude": -43.582625
      },
      {
        "id": 7047,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7048,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7049,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7050,
        "latitude": -22.857263,
        "longitude": -43.447411
      },
      {
        "id": 7051,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7052,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7053,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7054,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7055,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7056,
        "latitude": -22.947075,
        "longitude": -43.645635
      },
      {
        "id": 7058,
        "latitude": -23.003036,
        "longitude": -43.316344
      },
      {
        "id": 7060,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 7061,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7062,
        "latitude": -22.860996,
        "longitude": -43.515456
      },
      {
        "id": 7063,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7064,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7065,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7066,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7067,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7068,
        "latitude": -22.673272,
        "longitude": -43.444927
      },
      {
        "id": 7069,
        "latitude": -22.91323,
        "longitude": -43.694813
      },
      {
        "id": 7070,
        "latitude": -22.530271,
        "longitude": -43.202505
      },
      {
        "id": 7071,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 7072,
        "latitude": -22.530271,
        "longitude": -43.202505
      },
      {
        "id": 7073,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7074,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 7075,
        "latitude": -22.914096,
        "longitude": -43.623557
      },
      {
        "id": 7076,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7469,
        "latitude": -22.93789,
        "longitude": -43.663422
      },
      {
        "id": 7078,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7079,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7080,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7081,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 7082,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7083,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7084,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7085,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7086,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7087,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7088,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7089,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7091,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7092,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7093,
        "latitude": -22.867508,
        "longitude": -43.587815
      },
      {
        "id": 7094,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7095,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7096,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7098,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7099,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7100,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7101,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 7102,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7105,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7106,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7107,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7108,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7109,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7110,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7111,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7112,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7113,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7114,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7115,
        "latitude": -22.911925,
        "longitude": -43.582982
      },
      {
        "id": 7116,
        "latitude": -22.867508,
        "longitude": -43.587815
      },
      {
        "id": 7117,
        "latitude": -22.91214,
        "longitude": -43.583269
      },
      {
        "id": 7118,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7119,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7120,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7121,
        "latitude": -22.88374,
        "longitude": -43.440114
      },
      {
        "id": 7122,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7123,
        "latitude": -22.842717,
        "longitude": -43.334885
      },
      {
        "id": 7124,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7125,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7126,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7127,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7128,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7129,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7130,
        "latitude": -22.859825,
        "longitude": -43.446354
      },
      {
        "id": 7131,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7133,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7134,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7135,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7136,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7137,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7138,
        "latitude": -22.856997,
        "longitude": -43.44749
      },
      {
        "id": 7139,
        "latitude": -22.846176,
        "longitude": -43.47003
      },
      {
        "id": 7140,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7141,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7142,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7143,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7144,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7145,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7146,
        "latitude": -22.911925,
        "longitude": -43.582982
      },
      {
        "id": 7147,
        "latitude": -22.898867,
        "longitude": -43.573719
      },
      {
        "id": 7148,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7149,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7150,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7151,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7152,
        "latitude": -22.911979,
        "longitude": -43.582227
      },
      {
        "id": 7153,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7154,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7155,
        "latitude": -22.92175,
        "longitude": -43.619067
      },
      {
        "id": 7156,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7158,
        "latitude": -22.859922,
        "longitude": -43.441269
      },
      {
        "id": 7159,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7160,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7161,
        "latitude": -22.883848,
        "longitude": -43.440435
      },
      {
        "id": 7162,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7163,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7470,
        "latitude": -23.003348,
        "longitude": -43.424385
      },
      {
        "id": 7164,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7165,
        "latitude": -22.857263,
        "longitude": -43.447411
      },
      {
        "id": 7166,
        "latitude": -22.891653,
        "longitude": -43.246889
      },
      {
        "id": 7167,
        "latitude": -22.755357,
        "longitude": -43.33302
      },
      {
        "id": 7168,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7169,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7170,
        "latitude": -22.857263,
        "longitude": -43.447411
      },
      {
        "id": 7171,
        "latitude": -22.856997,
        "longitude": -43.44749
      },
      {
        "id": 7172,
        "latitude": -22.891279,
        "longitude": -43.24703
      },
      {
        "id": 7173,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7174,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7175,
        "latitude": -22.856997,
        "longitude": -43.44749
      },
      {
        "id": 7176,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7177,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7178,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7179,
        "latitude": -22.916532,
        "longitude": -43.244497
      },
      {
        "id": 7180,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 9883,
        "latitude": -29.150132,
        "longitude": -51.210023
      },
      {
        "id": 7181,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7182,
        "latitude": -22.883848,
        "longitude": -43.440435
      },
      {
        "id": 7183,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7185,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7186,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7187,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7189,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 7190,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7191,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7192,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7193,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7194,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7195,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7196,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7197,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7198,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7200,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 7202,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7203,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7204,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7205,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7206,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 7207,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7208,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7209,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7210,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7211,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 7212,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7213,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7214,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7215,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7216,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7217,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7218,
        "latitude": -22.953993,
        "longitude": -43.175977
      },
      {
        "id": 7219,
        "latitude": -22.868315,
        "longitude": -43.545391
      },
      {
        "id": 7220,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7221,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7222,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7223,
        "latitude": -22.868315,
        "longitude": -43.545391
      },
      {
        "id": 7224,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7225,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7226,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7228,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7229,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7230,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7231,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7232,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 12578,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 7233,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7234,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7235,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7236,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7237,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7238,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7239,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7240,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7241,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7242,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7243,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7244,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7245,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7246,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7247,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7248,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7249,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 12596,
        "latitude": -23.59358,
        "longitude": -46.740256
      },
      {
        "id": 7251,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7252,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7253,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 7254,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7255,
        "latitude": -22.890225,
        "longitude": -43.311368
      },
      {
        "id": 7256,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7257,
        "latitude": -22.966746,
        "longitude": -43.348398
      },
      {
        "id": 7258,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 7259,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 7260,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7261,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7262,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 7263,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7264,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7265,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7266,
        "latitude": -22.77961,
        "longitude": -43.372747
      },
      {
        "id": 7267,
        "latitude": -22.860996,
        "longitude": -43.515456
      },
      {
        "id": 7268,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7269,
        "latitude": -22.961527,
        "longitude": -43.344481
      },
      {
        "id": 7270,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7271,
        "latitude": -25.454588,
        "longitude": -49.245463
      },
      {
        "id": 7272,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7273,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7274,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7276,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7277,
        "latitude": -22.9978,
        "longitude": -43.397834
      },
      {
        "id": 7278,
        "latitude": -22.673272,
        "longitude": -43.444927
      },
      {
        "id": 7279,
        "latitude": -22.673272,
        "longitude": -43.444927
      },
      {
        "id": 7280,
        "latitude": -22.866866,
        "longitude": -43.307632
      },
      {
        "id": 7281,
        "latitude": -22.673272,
        "longitude": -43.444927
      },
      {
        "id": 7282,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7283,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7284,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7285,
        "latitude": -22.914096,
        "longitude": -43.623557
      },
      {
        "id": 7286,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7287,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7288,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7289,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7290,
        "latitude": -22.890225,
        "longitude": -43.311368
      },
      {
        "id": 7291,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7292,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7293,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7294,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7295,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7296,
        "latitude": -22.802628,
        "longitude": -43.366453
      },
      {
        "id": 7297,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7298,
        "latitude": -22.914096,
        "longitude": -43.623557
      },
      {
        "id": 7300,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 7301,
        "latitude": -22.88374,
        "longitude": -43.440114
      },
      {
        "id": 7302,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7558,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7303,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7304,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7305,
        "latitude": -22.91323,
        "longitude": -43.694813
      },
      {
        "id": 7306,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7307,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7308,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7309,
        "latitude": -22.970516,
        "longitude": -43.700755
      },
      {
        "id": 7310,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7311,
        "latitude": -22.91323,
        "longitude": -43.694813
      },
      {
        "id": 7312,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7313,
        "latitude": -22.857822,
        "longitude": -43.466618
      },
      {
        "id": 7314,
        "latitude": -22.933588,
        "longitude": -43.636043
      },
      {
        "id": 7315,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7316,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7317,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7318,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7319,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7320,
        "latitude": -22.91323,
        "longitude": -43.694813
      },
      {
        "id": 7321,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7322,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7323,
        "latitude": -22.923203,
        "longitude": -43.361179
      },
      {
        "id": 7324,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 7325,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 7326,
        "latitude": -22.845952,
        "longitude": -43.319017
      },
      {
        "id": 7327,
        "latitude": -22.950023,
        "longitude": -43.183302
      },
      {
        "id": 7328,
        "latitude": -22.908526,
        "longitude": -43.17724
      },
      {
        "id": 7329,
        "latitude": -22.841423,
        "longitude": -43.311677
      },
      {
        "id": 7330,
        "latitude": -22.908526,
        "longitude": -43.17724
      },
      {
        "id": 7331,
        "latitude": -22.965707,
        "longitude": -43.177758
      },
      {
        "id": 7332,
        "latitude": -22.903626,
        "longitude": -43.313967
      },
      {
        "id": 7333,
        "latitude": -22.89729,
        "longitude": -43.536304
      },
      {
        "id": 7335,
        "latitude": -22.963994,
        "longitude": -43.409796
      },
      {
        "id": 7336,
        "latitude": -22.903489,
        "longitude": -43.345783
      },
      {
        "id": 7337,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7338,
        "latitude": -22.904447,
        "longitude": -43.346372
      },
      {
        "id": 7339,
        "latitude": -22.846953,
        "longitude": -43.297455
      },
      {
        "id": 7341,
        "latitude": -22.89302,
        "longitude": -43.587393
      },
      {
        "id": 7342,
        "latitude": -22.952486,
        "longitude": -43.384396
      },
      {
        "id": 7343,
        "latitude": -22.836813,
        "longitude": -43.38395
      },
      {
        "id": 7344,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7345,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7346,
        "latitude": -22.867025,
        "longitude": -43.33295
      },
      {
        "id": 7347,
        "latitude": -22.92175,
        "longitude": -43.619067
      },
      {
        "id": 7348,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7349,
        "latitude": -22.897974,
        "longitude": -43.591161
      },
      {
        "id": 7350,
        "latitude": -22.883393,
        "longitude": -43.440059
      },
      {
        "id": 7351,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7352,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7353,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7354,
        "latitude": -22.899435,
        "longitude": -43.344071
      },
      {
        "id": 7355,
        "latitude": -22.892004,
        "longitude": -43.353246
      },
      {
        "id": 7356,
        "latitude": -22.820918,
        "longitude": -43.291101
      },
      {
        "id": 7357,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7358,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7359,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7360,
        "latitude": -21.563327,
        "longitude": -42.291724
      },
      {
        "id": 7361,
        "latitude": -22.91323,
        "longitude": -43.694813
      },
      {
        "id": 7362,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7363,
        "latitude": -22.888624,
        "longitude": -43.355344
      },
      {
        "id": 7364,
        "latitude": -22.92712,
        "longitude": -43.23058
      },
      {
        "id": 7365,
        "latitude": -22.886972,
        "longitude": -43.307217
      },
      {
        "id": 7366,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7367,
        "latitude": -22.904447,
        "longitude": -43.346372
      },
      {
        "id": 7368,
        "latitude": -22.904447,
        "longitude": -43.346372
      },
      {
        "id": 7369,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 7371,
        "latitude": -22.861449,
        "longitude": -43.34494
      },
      {
        "id": 7372,
        "latitude": -22.949378,
        "longitude": -43.355291
      },
      {
        "id": 7373,
        "latitude": -23.006831,
        "longitude": -44.296118
      },
      {
        "id": 7374,
        "latitude": -22.903365,
        "longitude": -43.344742
      },
      {
        "id": 7375,
        "latitude": -22.91504,
        "longitude": -43.606002
      },
      {
        "id": 7376,
        "latitude": -22.896935,
        "longitude": -43.352664
      },
      {
        "id": 7377,
        "latitude": -22.904447,
        "longitude": -43.346372
      },
      {
        "id": 7378,
        "latitude": -22.84116,
        "longitude": -43.37228
      },
      {
        "id": 7379,
        "latitude": -22.869619,
        "longitude": -43.317025
      },
      {
        "id": 7380,
        "latitude": -19.951571,
        "longitude": -43.958703
      },
      {
        "id": 7381,
        "latitude": -22.945658,
        "longitude": -43.363236
      },
      {
        "id": 7382,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 7383,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7384,
        "latitude": -9.478008,
        "longitude": -40.8122
      },
      {
        "id": 7385,
        "latitude": -22.730964,
        "longitude": -43.325579
      },
      {
        "id": 7386,
        "latitude": -22.933615,
        "longitude": -43.669369
      },
      {
        "id": 7387,
        "latitude": -22.860996,
        "longitude": -43.515456
      },
      {
        "id": 7388,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 7389,
        "latitude": -22.788541,
        "longitude": -43.17864
      },
      {
        "id": 7390,
        "latitude": -22.903489,
        "longitude": -43.345783
      },
      {
        "id": 7391,
        "latitude": -22.779154,
        "longitude": -43.370886
      },
      {
        "id": 7392,
        "latitude": -22.861551,
        "longitude": -43.339354
      },
      {
        "id": 7393,
        "latitude": -22.916442,
        "longitude": -43.244062
      },
      {
        "id": 7394,
        "latitude": -22.879613,
        "longitude": -43.656468
      },
      {
        "id": 7395,
        "latitude": -22.827325,
        "longitude": -43.353655
      },
      {
        "id": 7396,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7397,
        "latitude": -22.849275,
        "longitude": -43.54192
      },
      {
        "id": 7398,
        "latitude": -22.817184,
        "longitude": -43.384632
      },
      {
        "id": 7399,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7400,
        "latitude": -22.836584,
        "longitude": -43.302986
      },
      {
        "id": 7401,
        "latitude": -22.83569,
        "longitude": -43.300835
      },
      {
        "id": 7402,
        "latitude": -22.878463,
        "longitude": -43.427833
      },
      {
        "id": 7403,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7404,
        "latitude": -22.897974,
        "longitude": -43.591161
      },
      {
        "id": 7405,
        "latitude": -22.897192,
        "longitude": -43.352609
      },
      {
        "id": 7406,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7407,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 7408,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7409,
        "latitude": -22.933615,
        "longitude": -43.669369
      },
      {
        "id": 7410,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7411,
        "latitude": -22.887,
        "longitude": -43.345583
      },
      {
        "id": 7412,
        "latitude": -22.840723,
        "longitude": -43.26457
      },
      {
        "id": 7413,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 7414,
        "latitude": -23.002721,
        "longitude": -43.628518
      },
      {
        "id": 7415,
        "latitude": -22.89385,
        "longitude": -43.598343
      },
      {
        "id": 7416,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7417,
        "latitude": -22.897974,
        "longitude": -43.591161
      },
      {
        "id": 7419,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7420,
        "latitude": -22.882208,
        "longitude": -43.280491
      },
      {
        "id": 7421,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7422,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7424,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7425,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7426,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7427,
        "latitude": -22.86417,
        "longitude": -43.477665
      },
      {
        "id": 7428,
        "latitude": -22.86417,
        "longitude": -43.477665
      },
      {
        "id": 7430,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7431,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7432,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7433,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7434,
        "latitude": -22.85623,
        "longitude": -43.3162
      },
      {
        "id": 7435,
        "latitude": -22.869619,
        "longitude": -43.317025
      },
      {
        "id": 7436,
        "latitude": -22.9107,
        "longitude": -43.241878
      },
      {
        "id": 7437,
        "latitude": -22.913059,
        "longitude": -43.248343
      },
      {
        "id": 7438,
        "latitude": -22.845779,
        "longitude": -43.287242
      },
      {
        "id": 7439,
        "latitude": -22.850507,
        "longitude": -43.355919
      },
      {
        "id": 7440,
        "latitude": -22.8882,
        "longitude": -43.30398
      },
      {
        "id": 7441,
        "latitude": -22.880526,
        "longitude": -43.315319
      },
      {
        "id": 7442,
        "latitude": -22.816578,
        "longitude": -43.364818
      },
      {
        "id": 7443,
        "latitude": -23.00515,
        "longitude": -43.372192
      },
      {
        "id": 7445,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7447,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7448,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 7449,
        "latitude": -25.366526,
        "longitude": -49.265194
      },
      {
        "id": 7450,
        "latitude": -23.005,
        "longitude": -43.628724
      },
      {
        "id": 7451,
        "latitude": -22.837019,
        "longitude": -43.309579
      },
      {
        "id": 7452,
        "latitude": -22.837019,
        "longitude": -43.309579
      },
      {
        "id": 7453,
        "latitude": -22.886753,
        "longitude": -43.24635
      },
      {
        "id": 7454,
        "latitude": -20.430481,
        "longitude": -54.599607
      },
      {
        "id": 7455,
        "latitude": -22.983116,
        "longitude": -43.428522
      },
      {
        "id": 7456,
        "latitude": -22.993641,
        "longitude": -43.361025
      },
      {
        "id": 7457,
        "latitude": -22.882335,
        "longitude": -43.515532
      },
      {
        "id": 7458,
        "latitude": -22.908792,
        "longitude": -43.170818
      },
      {
        "id": 7459,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 7460,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 7461,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 7462,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 7463,
        "latitude": -22.916949,
        "longitude": -43.211435
      },
      {
        "id": 7464,
        "latitude": -22.893818,
        "longitude": -43.252777
      },
      {
        "id": 7465,
        "latitude": -22.837019,
        "longitude": -43.309579
      },
      {
        "id": 7466,
        "latitude": -22.853482,
        "longitude": -43.307883
      },
      {
        "id": 7467,
        "latitude": -22.837019,
        "longitude": -43.309579
      },
      {
        "id": 7468,
        "latitude": -22.920363,
        "longitude": -43.249587
      },
      {
        "id": 7471,
        "latitude": -22.947704,
        "longitude": -43.188942
      },
      {
        "id": 7472,
        "latitude": -22.81408,
        "longitude": -43.196432
      },
      {
        "id": 7473,
        "latitude": -22.878463,
        "longitude": -43.427833
      },
      {
        "id": 7474,
        "latitude": -22.999737,
        "longitude": -43.380199
      },
      {
        "id": 7475,
        "latitude": -22.83497,
        "longitude": -43.29188
      },
      {
        "id": 7476,
        "latitude": -22.916999,
        "longitude": -43.366386
      },
      {
        "id": 7477,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7478,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7479,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7480,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7481,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7482,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7483,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7484,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7485,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7486,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 7487,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7488,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7489,
        "latitude": -22.789466,
        "longitude": -43.171941
      },
      {
        "id": 7490,
        "latitude": -22.794043,
        "longitude": -43.337762
      },
      {
        "id": 7491,
        "latitude": -9.388205,
        "longitude": -40.539045
      },
      {
        "id": 7492,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7493,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7494,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 7495,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7496,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7497,
        "latitude": -22.899459,
        "longitude": -43.296436
      },
      {
        "id": 7498,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 7499,
        "latitude": -22.891971,
        "longitude": -43.590046
      },
      {
        "id": 7501,
        "latitude": -22.849457,
        "longitude": -43.347195
      },
      {
        "id": 7502,
        "latitude": -22.861449,
        "longitude": -43.34494
      },
      {
        "id": 7503,
        "latitude": -22.817323,
        "longitude": -43.37136
      },
      {
        "id": 7504,
        "latitude": -22.87023,
        "longitude": -43.299333
      },
      {
        "id": 7505,
        "latitude": -22.817323,
        "longitude": -43.37136
      },
      {
        "id": 7506,
        "latitude": -22.896599,
        "longitude": -43.61141
      },
      {
        "id": 7507,
        "latitude": -22.814742,
        "longitude": -43.293278
      },
      {
        "id": 7508,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 7509,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7510,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7511,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7512,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7513,
        "latitude": -22.858136,
        "longitude": -43.447079
      },
      {
        "id": 7514,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7515,
        "latitude": -22.867508,
        "longitude": -43.587815
      },
      {
        "id": 7517,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7518,
        "latitude": -22.911955,
        "longitude": -43.582625
      },
      {
        "id": 7519,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7520,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7521,
        "latitude": -22.87134,
        "longitude": -43.30454
      },
      {
        "id": 7522,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 7841,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7523,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7524,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7525,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7526,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7527,
        "latitude": -22.706873,
        "longitude": -43.160808
      },
      {
        "id": 7528,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7529,
        "latitude": -22.916442,
        "longitude": -43.244062
      },
      {
        "id": 7530,
        "latitude": -22.883288,
        "longitude": -43.440445
      },
      {
        "id": 7531,
        "latitude": -23.006976,
        "longitude": -44.295838
      },
      {
        "id": 7532,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7534,
        "latitude": -22.883848,
        "longitude": -43.440435
      },
      {
        "id": 7535,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7536,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7537,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 7538,
        "latitude": -22.872778,
        "longitude": -43.526439
      },
      {
        "id": 7539,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7648,
        "latitude": -22.908635,
        "longitude": -43.177867
      },
      {
        "id": 7540,
        "latitude": -22.96104,
        "longitude": -43.649365
      },
      {
        "id": 7541,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7542,
        "latitude": -25.454588,
        "longitude": -49.245463
      },
      {
        "id": 7543,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7544,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7545,
        "latitude": -22.927652,
        "longitude": -43.199471
      },
      {
        "id": 7546,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7547,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 7548,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7549,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7550,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7551,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7552,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7553,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7554,
        "latitude": -22.77961,
        "longitude": -43.372747
      },
      {
        "id": 7555,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7556,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7557,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7559,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7560,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 7561,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7562,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7563,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 7564,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7565,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 7566,
        "latitude": -22.605,
        "longitude": -43.283316
      },
      {
        "id": 7567,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7568,
        "latitude": -23.00515,
        "longitude": -43.372192
      },
      {
        "id": 7569,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 7570,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7571,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7572,
        "latitude": -22.833758,
        "longitude": -43.329239
      },
      {
        "id": 7573,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7574,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7575,
        "latitude": -25.454588,
        "longitude": -49.245463
      },
      {
        "id": 7576,
        "latitude": -22.817824,
        "longitude": -43.372255
      },
      {
        "id": 7577,
        "latitude": -22.897747,
        "longitude": -43.346351
      },
      {
        "id": 7578,
        "latitude": -22.912377,
        "longitude": -43.56427
      },
      {
        "id": 7579,
        "latitude": -22.897974,
        "longitude": -43.591161
      },
      {
        "id": 7581,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7582,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7583,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7584,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7586,
        "latitude": -22.92175,
        "longitude": -43.619067
      },
      {
        "id": 7587,
        "latitude": -22.918238,
        "longitude": -43.394117
      },
      {
        "id": 7588,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7589,
        "latitude": -22.916442,
        "longitude": -43.244062
      },
      {
        "id": 7590,
        "latitude": -23.006689,
        "longitude": -44.296567
      },
      {
        "id": 7591,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7592,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7593,
        "latitude": -22.925469,
        "longitude": -43.348667
      },
      {
        "id": 7594,
        "latitude": -22.839888,
        "longitude": -43.566125
      },
      {
        "id": 7595,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7596,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7597,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7599,
        "latitude": -22.859825,
        "longitude": -43.446354
      },
      {
        "id": 7600,
        "latitude": -22.858136,
        "longitude": -43.447079
      },
      {
        "id": 7601,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7602,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7603,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7604,
        "latitude": -22.883068,
        "longitude": -43.549362
      },
      {
        "id": 7605,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 7606,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 7607,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7608,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 7610,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7702,
        "latitude": -22.882789,
        "longitude": -43.340374
      },
      {
        "id": 7611,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7612,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7613,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7614,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7615,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7616,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7617,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7618,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7619,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 7620,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7621,
        "latitude": -22.77961,
        "longitude": -43.372747
      },
      {
        "id": 7622,
        "latitude": -23.00515,
        "longitude": -43.372192
      },
      {
        "id": 7623,
        "latitude": -22.966746,
        "longitude": -43.348398
      },
      {
        "id": 7624,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 7625,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7626,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7627,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 7628,
        "latitude": -22.925469,
        "longitude": -43.348667
      },
      {
        "id": 7629,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7630,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 7631,
        "latitude": -22.970516,
        "longitude": -43.700755
      },
      {
        "id": 7632,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7633,
        "latitude": -21.753507,
        "longitude": -41.1892
      },
      {
        "id": 7634,
        "latitude": -23.003348,
        "longitude": -43.424385
      },
      {
        "id": 7635,
        "latitude": -22.843249,
        "longitude": -43.29913
      },
      {
        "id": 7636,
        "latitude": -22.883642,
        "longitude": -43.422755
      },
      {
        "id": 7638,
        "latitude": -22.819593,
        "longitude": -43.333888
      },
      {
        "id": 7639,
        "latitude": -22.821603,
        "longitude": -43.385148
      },
      {
        "id": 7640,
        "latitude": -22.950422,
        "longitude": -43.354108
      },
      {
        "id": 7641,
        "latitude": -22.801791,
        "longitude": -43.193672
      },
      {
        "id": 7642,
        "latitude": -22.965991,
        "longitude": -43.338276
      },
      {
        "id": 7643,
        "latitude": -22.9133,
        "longitude": -43.249571
      },
      {
        "id": 7644,
        "latitude": -22.88953,
        "longitude": -43.225272
      },
      {
        "id": 7645,
        "latitude": -22.856268,
        "longitude": -43.316793
      },
      {
        "id": 7646,
        "latitude": -22.917201,
        "longitude": -43.177832
      },
      {
        "id": 7647,
        "latitude": -22.828711,
        "longitude": -43.29341
      },
      {
        "id": 7649,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7650,
        "latitude": -22.937295,
        "longitude": -43.3603
      },
      {
        "id": 7651,
        "latitude": -22.946787,
        "longitude": -43.358458
      },
      {
        "id": 7652,
        "latitude": -22.862648,
        "longitude": -43.544331
      },
      {
        "id": 7653,
        "latitude": -22.975988,
        "longitude": -43.657563
      },
      {
        "id": 7654,
        "latitude": -22.900179,
        "longitude": -43.346028
      },
      {
        "id": 7655,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7656,
        "latitude": -22.876357,
        "longitude": -43.284129
      },
      {
        "id": 7657,
        "latitude": -22.789776,
        "longitude": -43.170896
      },
      {
        "id": 7659,
        "latitude": -22.870009,
        "longitude": -43.309239
      },
      {
        "id": 7661,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 7662,
        "latitude": -22.810503,
        "longitude": -43.201748
      },
      {
        "id": 7663,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7664,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7666,
        "latitude": -22.92198,
        "longitude": -43.209878
      },
      {
        "id": 7667,
        "latitude": -22.880017,
        "longitude": -43.348805
      },
      {
        "id": 7668,
        "latitude": -22.869426,
        "longitude": -43.347011
      },
      {
        "id": 7669,
        "latitude": -22.973548,
        "longitude": -43.329633
      },
      {
        "id": 7671,
        "latitude": -22.890561,
        "longitude": -43.347273
      },
      {
        "id": 7672,
        "latitude": -22.894595,
        "longitude": -43.306946
      },
      {
        "id": 7673,
        "latitude": -22.91476,
        "longitude": -43.35758
      },
      {
        "id": 7674,
        "latitude": -22.876008,
        "longitude": -43.313585
      },
      {
        "id": 7675,
        "latitude": -22.858726,
        "longitude": -43.323945
      },
      {
        "id": 7676,
        "latitude": -22.804953,
        "longitude": -43.185237
      },
      {
        "id": 7677,
        "latitude": -22.954508,
        "longitude": -43.38381
      },
      {
        "id": 7678,
        "latitude": -22.914498,
        "longitude": -43.239541
      },
      {
        "id": 7679,
        "latitude": -22.861551,
        "longitude": -43.339354
      },
      {
        "id": 7680,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7681,
        "latitude": -22.938788,
        "longitude": -43.25065
      },
      {
        "id": 7682,
        "latitude": -22.82763,
        "longitude": -43.306416
      },
      {
        "id": 7683,
        "latitude": -22.790915,
        "longitude": -43.170561
      },
      {
        "id": 7684,
        "latitude": -22.971942,
        "longitude": -43.411428
      },
      {
        "id": 7685,
        "latitude": -22.908956,
        "longitude": -43.267875
      },
      {
        "id": 7686,
        "latitude": -22.789821,
        "longitude": -43.187944
      },
      {
        "id": 7687,
        "latitude": -22.925204,
        "longitude": -43.201493
      },
      {
        "id": 7688,
        "latitude": -22.90972,
        "longitude": -43.28259
      },
      {
        "id": 7689,
        "latitude": -22.787764,
        "longitude": -43.175709
      },
      {
        "id": 7690,
        "latitude": -22.902365,
        "longitude": -43.224888
      },
      {
        "id": 7691,
        "latitude": -22.896828,
        "longitude": -43.352296
      },
      {
        "id": 7692,
        "latitude": -22.94829,
        "longitude": -43.364381
      },
      {
        "id": 7693,
        "latitude": -22.869739,
        "longitude": -43.252744
      },
      {
        "id": 7695,
        "latitude": -22.907949,
        "longitude": -43.297581
      },
      {
        "id": 7696,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 7697,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 7698,
        "latitude": -22.854094,
        "longitude": -43.322246
      },
      {
        "id": 7699,
        "latitude": -22.886571,
        "longitude": -43.298741
      },
      {
        "id": 7700,
        "latitude": -22.912291,
        "longitude": -43.249421
      },
      {
        "id": 7701,
        "latitude": -22.870378,
        "longitude": -43.303844
      },
      {
        "id": 7703,
        "latitude": -22.912388,
        "longitude": -43.246577
      },
      {
        "id": 7704,
        "latitude": -22.93896,
        "longitude": -43.361603
      },
      {
        "id": 7705,
        "latitude": -22.926664,
        "longitude": -43.176615
      },
      {
        "id": 7706,
        "latitude": -22.905146,
        "longitude": -43.204266
      },
      {
        "id": 7708,
        "latitude": -22.947608,
        "longitude": -43.337357
      },
      {
        "id": 7709,
        "latitude": -22.99835,
        "longitude": -43.36545
      },
      {
        "id": 7710,
        "latitude": -22.923835,
        "longitude": -43.213163
      },
      {
        "id": 7711,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 7712,
        "latitude": -22.945152,
        "longitude": -43.384188
      },
      {
        "id": 7713,
        "latitude": -22.897747,
        "longitude": -43.346351
      },
      {
        "id": 7714,
        "latitude": -22.846631,
        "longitude": -43.271284
      },
      {
        "id": 7715,
        "latitude": -22.916802,
        "longitude": -43.202804
      },
      {
        "id": 7716,
        "latitude": -22.929323,
        "longitude": -43.353143
      },
      {
        "id": 7717,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7718,
        "latitude": -22.983271,
        "longitude": -43.491086
      },
      {
        "id": 7719,
        "latitude": -22.858938,
        "longitude": -43.362806
      },
      {
        "id": 7720,
        "latitude": -22.852968,
        "longitude": -43.394617
      },
      {
        "id": 7721,
        "latitude": -22.829704,
        "longitude": -43.352912
      },
      {
        "id": 7722,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7723,
        "latitude": -22.845253,
        "longitude": -43.288632
      },
      {
        "id": 7724,
        "latitude": -22.845464,
        "longitude": -43.266329
      },
      {
        "id": 7725,
        "latitude": -22.92236,
        "longitude": -43.214337
      },
      {
        "id": 7726,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 7727,
        "latitude": -22.916857,
        "longitude": -43.194016
      },
      {
        "id": 7728,
        "latitude": -22.937631,
        "longitude": -43.360245
      },
      {
        "id": 7729,
        "latitude": -22.888674,
        "longitude": -43.315892
      },
      {
        "id": 7730,
        "latitude": -22.867589,
        "longitude": -43.332065
      },
      {
        "id": 7731,
        "latitude": -22.900179,
        "longitude": -43.346028
      },
      {
        "id": 7732,
        "latitude": -22.883266,
        "longitude": -43.566836
      },
      {
        "id": 7733,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7734,
        "latitude": -22.94621,
        "longitude": -43.372115
      },
      {
        "id": 7735,
        "latitude": -22.897814,
        "longitude": -43.353196
      },
      {
        "id": 7736,
        "latitude": -22.896935,
        "longitude": -43.352664
      },
      {
        "id": 7737,
        "latitude": -22.94829,
        "longitude": -43.364381
      },
      {
        "id": 7738,
        "latitude": -22.84343,
        "longitude": -43.354
      },
      {
        "id": 7739,
        "latitude": -22.881018,
        "longitude": -43.37516
      },
      {
        "id": 7740,
        "latitude": -22.89607,
        "longitude": -43.305387
      },
      {
        "id": 7741,
        "latitude": -22.893637,
        "longitude": -43.349325
      },
      {
        "id": 7743,
        "latitude": -22.807792,
        "longitude": -43.182079
      },
      {
        "id": 7744,
        "latitude": -22.93309,
        "longitude": -43.35953
      },
      {
        "id": 7745,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7746,
        "latitude": -22.897194,
        "longitude": -43.289849
      },
      {
        "id": 7747,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7748,
        "latitude": -22.913861,
        "longitude": -43.252621
      },
      {
        "id": 7749,
        "latitude": -22.891616,
        "longitude": -43.399635
      },
      {
        "id": 7750,
        "latitude": -22.933525,
        "longitude": -43.396733
      },
      {
        "id": 7751,
        "latitude": -22.925255,
        "longitude": -43.202272
      },
      {
        "id": 7753,
        "latitude": -22.91336,
        "longitude": -43.233407
      },
      {
        "id": 7754,
        "latitude": -22.933525,
        "longitude": -43.396733
      },
      {
        "id": 7755,
        "latitude": -22.929413,
        "longitude": -43.568427
      },
      {
        "id": 7756,
        "latitude": -22.790406,
        "longitude": -43.171656
      },
      {
        "id": 7757,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7758,
        "latitude": -22.882503,
        "longitude": -43.558778
      },
      {
        "id": 7759,
        "latitude": -22.862687,
        "longitude": -43.362896
      },
      {
        "id": 7760,
        "latitude": -22.87623,
        "longitude": -43.51456
      },
      {
        "id": 7761,
        "latitude": -22.872534,
        "longitude": -43.530266
      },
      {
        "id": 7762,
        "latitude": -22.87802,
        "longitude": -43.363413
      },
      {
        "id": 7763,
        "latitude": -22.911334,
        "longitude": -43.176297
      },
      {
        "id": 7764,
        "latitude": -22.911334,
        "longitude": -43.176297
      },
      {
        "id": 7765,
        "latitude": -22.969145,
        "longitude": -43.385879
      },
      {
        "id": 7766,
        "latitude": -22.968214,
        "longitude": -43.386164
      },
      {
        "id": 7769,
        "latitude": -22.917434,
        "longitude": -43.244728
      },
      {
        "id": 7770,
        "latitude": -22.844472,
        "longitude": -43.303432
      },
      {
        "id": 7771,
        "latitude": -22.92121,
        "longitude": -43.211222
      },
      {
        "id": 7947,
        "latitude": -22.917592,
        "longitude": -43.245587
      },
      {
        "id": 7772,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 7773,
        "latitude": -22.896935,
        "longitude": -43.352664
      },
      {
        "id": 7774,
        "latitude": -22.897814,
        "longitude": -43.353196
      },
      {
        "id": 7775,
        "latitude": -22.843526,
        "longitude": -43.552785
      },
      {
        "id": 7776,
        "latitude": -22.78955,
        "longitude": -43.17206
      },
      {
        "id": 7777,
        "latitude": -22.933525,
        "longitude": -43.396733
      },
      {
        "id": 7778,
        "latitude": -22.888769,
        "longitude": -43.565502
      },
      {
        "id": 7779,
        "latitude": -22.858802,
        "longitude": -43.467279
      },
      {
        "id": 7780,
        "latitude": -22.87134,
        "longitude": -43.30454
      },
      {
        "id": 7781,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7782,
        "latitude": -22.913142,
        "longitude": -43.253474
      },
      {
        "id": 7783,
        "latitude": -22.847718,
        "longitude": -43.324185
      },
      {
        "id": 7784,
        "latitude": -22.877301,
        "longitude": -43.276816
      },
      {
        "id": 7785,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7786,
        "latitude": -22.910667,
        "longitude": -43.537965
      },
      {
        "id": 7787,
        "latitude": -22.915421,
        "longitude": -43.211207
      },
      {
        "id": 7788,
        "latitude": -22.84471,
        "longitude": -43.33409
      },
      {
        "id": 7789,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7790,
        "latitude": -22.916413,
        "longitude": -43.252892
      },
      {
        "id": 7791,
        "latitude": -22.891084,
        "longitude": -43.394623
      },
      {
        "id": 7792,
        "latitude": -22.822461,
        "longitude": -43.38724
      },
      {
        "id": 7794,
        "latitude": -22.992578,
        "longitude": -43.493494
      },
      {
        "id": 7795,
        "latitude": -22.871503,
        "longitude": -43.354326
      },
      {
        "id": 7796,
        "latitude": -22.914817,
        "longitude": -43.17924
      },
      {
        "id": 7797,
        "latitude": -22.899459,
        "longitude": -43.296436
      },
      {
        "id": 7798,
        "latitude": -22.78955,
        "longitude": -43.17206
      },
      {
        "id": 7799,
        "latitude": -22.920363,
        "longitude": -43.249587
      },
      {
        "id": 7800,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7801,
        "latitude": -23.018804,
        "longitude": -43.467859
      },
      {
        "id": 7802,
        "latitude": -22.889353,
        "longitude": -43.32311
      },
      {
        "id": 7803,
        "latitude": -22.86493,
        "longitude": -43.37014
      },
      {
        "id": 7804,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7805,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7806,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7807,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7808,
        "latitude": -22.91214,
        "longitude": -43.583269
      },
      {
        "id": 7809,
        "latitude": -22.936768,
        "longitude": -43.57372
      },
      {
        "id": 7810,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7811,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7812,
        "latitude": -25.454588,
        "longitude": -49.245463
      },
      {
        "id": 7813,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7814,
        "latitude": -25.454588,
        "longitude": -49.245463
      },
      {
        "id": 7815,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7816,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7817,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7818,
        "latitude": -22.874958,
        "longitude": -43.561405
      },
      {
        "id": 7819,
        "latitude": -22.863773,
        "longitude": -43.330377
      },
      {
        "id": 7821,
        "latitude": -22.914121,
        "longitude": -43.275207
      },
      {
        "id": 7822,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 7823,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 14916,
        "latitude": -29.689416,
        "longitude": -53.808401
      },
      {
        "id": 7824,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7825,
        "latitude": -22.91323,
        "longitude": -43.694813
      },
      {
        "id": 7826,
        "latitude": -22.873895,
        "longitude": -43.309337
      },
      {
        "id": 7827,
        "latitude": -22.865776,
        "longitude": -43.303931
      },
      {
        "id": 7828,
        "latitude": -22.929112,
        "longitude": -43.550509
      },
      {
        "id": 7829,
        "latitude": -22.897408,
        "longitude": -43.55234
      },
      {
        "id": 7830,
        "latitude": -22.897974,
        "longitude": -43.591161
      },
      {
        "id": 7831,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7832,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7833,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7834,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 7835,
        "latitude": -22.917812,
        "longitude": -43.39273
      },
      {
        "id": 7836,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7838,
        "latitude": -22.893145,
        "longitude": -43.480285
      },
      {
        "id": 7839,
        "latitude": -22.895633,
        "longitude": -43.448194
      },
      {
        "id": 7840,
        "latitude": -22.897192,
        "longitude": -43.352609
      },
      {
        "id": 7843,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 7844,
        "latitude": -22.917714,
        "longitude": -43.261165
      },
      {
        "id": 7845,
        "latitude": -22.84116,
        "longitude": -43.37228
      },
      {
        "id": 7846,
        "latitude": -22.890839,
        "longitude": -43.311953
      },
      {
        "id": 7847,
        "latitude": -22.839403,
        "longitude": -43.277681
      },
      {
        "id": 7848,
        "latitude": -22.94621,
        "longitude": -43.372115
      },
      {
        "id": 7849,
        "latitude": -22.88693,
        "longitude": -43.33079
      },
      {
        "id": 7850,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7851,
        "latitude": -22.93896,
        "longitude": -43.361603
      },
      {
        "id": 7852,
        "latitude": -22.840356,
        "longitude": -43.271557
      },
      {
        "id": 7853,
        "latitude": -22.907155,
        "longitude": -43.194171
      },
      {
        "id": 7854,
        "latitude": -22.896118,
        "longitude": -43.434505
      },
      {
        "id": 7855,
        "latitude": -22.955824,
        "longitude": -43.379336
      },
      {
        "id": 7856,
        "latitude": -22.830464,
        "longitude": -43.296385
      },
      {
        "id": 7857,
        "latitude": -23.006831,
        "longitude": -44.296118
      },
      {
        "id": 7858,
        "latitude": -22.917268,
        "longitude": -43.207016
      },
      {
        "id": 14917,
        "latitude": -29.688854,
        "longitude": -53.818502
      },
      {
        "id": 7859,
        "latitude": -22.849166,
        "longitude": -43.329184
      },
      {
        "id": 7860,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7862,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 7863,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7864,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7865,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 7866,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7867,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7868,
        "latitude": -22.77961,
        "longitude": -43.372747
      },
      {
        "id": 7869,
        "latitude": -25.454588,
        "longitude": -49.245463
      },
      {
        "id": 7870,
        "latitude": -22.839888,
        "longitude": -43.566125
      },
      {
        "id": 7871,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7872,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 7873,
        "latitude": -22.839888,
        "longitude": -43.566125
      },
      {
        "id": 7874,
        "latitude": -22.810976,
        "longitude": -43.207988
      },
      {
        "id": 7875,
        "latitude": -22.99835,
        "longitude": -43.36545
      },
      {
        "id": 7948,
        "latitude": -22.913646,
        "longitude": -43.189573
      },
      {
        "id": 7876,
        "latitude": -22.882552,
        "longitude": -43.301437
      },
      {
        "id": 7877,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 7878,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 7879,
        "latitude": -22.941778,
        "longitude": -43.243609
      },
      {
        "id": 7880,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 7881,
        "latitude": -22.846336,
        "longitude": -43.250557
      },
      {
        "id": 7882,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7883,
        "latitude": -23.008119,
        "longitude": -43.444037
      },
      {
        "id": 7885,
        "latitude": -22.904322,
        "longitude": -43.174979
      },
      {
        "id": 7886,
        "latitude": -22.90329,
        "longitude": -43.218832
      },
      {
        "id": 7887,
        "latitude": -22.78955,
        "longitude": -43.17206
      },
      {
        "id": 7888,
        "latitude": -22.939588,
        "longitude": -43.178237
      },
      {
        "id": 7889,
        "latitude": -22.853482,
        "longitude": -43.307883
      },
      {
        "id": 7890,
        "latitude": -23.020731,
        "longitude": -43.461778
      },
      {
        "id": 7891,
        "latitude": -23.024555,
        "longitude": -43.460996
      },
      {
        "id": 7892,
        "latitude": -23.019348,
        "longitude": -43.452404
      },
      {
        "id": 7893,
        "latitude": -22.916442,
        "longitude": -43.244062
      },
      {
        "id": 7894,
        "latitude": -23.000455,
        "longitude": -43.349044
      },
      {
        "id": 7895,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 7896,
        "latitude": -23.020309,
        "longitude": -43.464272
      },
      {
        "id": 7897,
        "latitude": -22.953468,
        "longitude": -43.357106
      },
      {
        "id": 7898,
        "latitude": -22.844831,
        "longitude": -43.300504
      },
      {
        "id": 7899,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7900,
        "latitude": -22.908635,
        "longitude": -43.177867
      },
      {
        "id": 7901,
        "latitude": -22.870491,
        "longitude": -43.576373
      },
      {
        "id": 7902,
        "latitude": -22.910362,
        "longitude": -43.538635
      },
      {
        "id": 7903,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 7904,
        "latitude": -22.91476,
        "longitude": -43.35758
      },
      {
        "id": 7905,
        "latitude": -22.898003,
        "longitude": -43.351992
      },
      {
        "id": 7906,
        "latitude": -22.89556,
        "longitude": -43.53195
      },
      {
        "id": 7907,
        "latitude": -22.879502,
        "longitude": -43.475246
      },
      {
        "id": 7908,
        "latitude": -22.887429,
        "longitude": -43.298034
      },
      {
        "id": 7909,
        "latitude": -22.93896,
        "longitude": -43.361603
      },
      {
        "id": 7910,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7911,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7912,
        "latitude": -22.912998,
        "longitude": -43.212842
      },
      {
        "id": 7913,
        "latitude": -22.877096,
        "longitude": -43.362037
      },
      {
        "id": 7914,
        "latitude": -22.990706,
        "longitude": -43.25011
      },
      {
        "id": 7916,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 7917,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 7918,
        "latitude": -22.939627,
        "longitude": -43.325118
      },
      {
        "id": 7919,
        "latitude": -22.918643,
        "longitude": -43.236554
      },
      {
        "id": 7920,
        "latitude": -22.836439,
        "longitude": -43.272721
      },
      {
        "id": 7921,
        "latitude": -22.853482,
        "longitude": -43.307883
      },
      {
        "id": 7922,
        "latitude": -22.853482,
        "longitude": -43.307883
      },
      {
        "id": 7923,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 7924,
        "latitude": -22.839042,
        "longitude": -43.309132
      },
      {
        "id": 7925,
        "latitude": -22.954727,
        "longitude": -43.181702
      },
      {
        "id": 7926,
        "latitude": -22.92254,
        "longitude": -43.376522
      },
      {
        "id": 7927,
        "latitude": -23.020731,
        "longitude": -43.461778
      },
      {
        "id": 7928,
        "latitude": -22.91476,
        "longitude": -43.35758
      },
      {
        "id": 7929,
        "latitude": -22.877096,
        "longitude": -43.362037
      },
      {
        "id": 7930,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7931,
        "latitude": -22.875727,
        "longitude": -43.411373
      },
      {
        "id": 7932,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 7933,
        "latitude": -22.924801,
        "longitude": -43.520671
      },
      {
        "id": 7934,
        "latitude": -22.999494,
        "longitude": -43.379055
      },
      {
        "id": 7935,
        "latitude": -22.828338,
        "longitude": -43.414021
      },
      {
        "id": 7936,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 7937,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7938,
        "latitude": -22.816704,
        "longitude": -43.376465
      },
      {
        "id": 7939,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 7940,
        "latitude": -22.825511,
        "longitude": -43.378112
      },
      {
        "id": 7941,
        "latitude": -22.925469,
        "longitude": -43.348667
      },
      {
        "id": 7942,
        "latitude": -22.81152,
        "longitude": -43.3245
      },
      {
        "id": 7943,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 7944,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 7945,
        "latitude": -22.825511,
        "longitude": -43.378112
      },
      {
        "id": 7946,
        "latitude": -22.891878,
        "longitude": -43.321025
      },
      {
        "id": 7949,
        "latitude": -22.83497,
        "longitude": -43.29188
      },
      {
        "id": 7950,
        "latitude": -22.883373,
        "longitude": -43.419519
      },
      {
        "id": 7951,
        "latitude": -22.922839,
        "longitude": -43.182276
      },
      {
        "id": 7952,
        "latitude": -22.898818,
        "longitude": -43.237423
      },
      {
        "id": 7953,
        "latitude": -23.006668,
        "longitude": -43.484781
      },
      {
        "id": 7954,
        "latitude": -22.924539,
        "longitude": -43.367273
      },
      {
        "id": 7955,
        "latitude": -22.99835,
        "longitude": -43.36545
      },
      {
        "id": 7956,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7957,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 7958,
        "latitude": -22.895745,
        "longitude": -43.359955
      },
      {
        "id": 7959,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 7960,
        "latitude": -22.94829,
        "longitude": -43.364381
      },
      {
        "id": 7961,
        "latitude": -22.939529,
        "longitude": -43.360761
      },
      {
        "id": 7962,
        "latitude": -22.980702,
        "longitude": -43.413594
      },
      {
        "id": 7963,
        "latitude": -22.942715,
        "longitude": -43.336116
      },
      {
        "id": 7964,
        "latitude": -23.020269,
        "longitude": -43.473551
      },
      {
        "id": 7965,
        "latitude": -22.842717,
        "longitude": -43.334885
      },
      {
        "id": 7966,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 7967,
        "latitude": -22.838193,
        "longitude": -43.274125
      },
      {
        "id": 7968,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 7969,
        "latitude": -22.822788,
        "longitude": -43.313453
      },
      {
        "id": 7971,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 7972,
        "latitude": -22.888231,
        "longitude": -43.597032
      },
      {
        "id": 7973,
        "latitude": -22.912291,
        "longitude": -43.249421
      },
      {
        "id": 7974,
        "latitude": -22.846573,
        "longitude": -43.372034
      },
      {
        "id": 7975,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7976,
        "latitude": -22.851052,
        "longitude": -43.274378
      },
      {
        "id": 7977,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 7978,
        "latitude": -22.837988,
        "longitude": -43.292336
      },
      {
        "id": 7979,
        "latitude": -22.817824,
        "longitude": -43.372255
      },
      {
        "id": 7980,
        "latitude": -22.82478,
        "longitude": -43.351668
      },
      {
        "id": 7981,
        "latitude": -22.838884,
        "longitude": -43.287852
      },
      {
        "id": 7982,
        "latitude": -22.896599,
        "longitude": -43.61141
      },
      {
        "id": 7983,
        "latitude": -22.896935,
        "longitude": -43.352664
      },
      {
        "id": 7984,
        "latitude": -22.920721,
        "longitude": -43.361916
      },
      {
        "id": 7985,
        "latitude": -22.888173,
        "longitude": -43.308013
      },
      {
        "id": 7986,
        "latitude": -22.979464,
        "longitude": -43.332707
      },
      {
        "id": 7987,
        "latitude": -22.83497,
        "longitude": -43.29188
      },
      {
        "id": 7988,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 7989,
        "latitude": -22.874958,
        "longitude": -43.561405
      },
      {
        "id": 7990,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 7991,
        "latitude": -22.875568,
        "longitude": -43.463409
      },
      {
        "id": 7992,
        "latitude": -22.88885,
        "longitude": -43.612001
      },
      {
        "id": 7993,
        "latitude": -22.84343,
        "longitude": -43.354
      },
      {
        "id": 7994,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 7995,
        "latitude": -22.875404,
        "longitude": -43.533619
      },
      {
        "id": 7996,
        "latitude": -22.875568,
        "longitude": -43.463409
      },
      {
        "id": 7997,
        "latitude": -22.9978,
        "longitude": -43.397834
      },
      {
        "id": 7998,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 7999,
        "latitude": -22.964361,
        "longitude": -43.616991
      },
      {
        "id": 8034,
        "latitude": -22.825511,
        "longitude": -43.378112
      },
      {
        "id": 8000,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 8001,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 8002,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 8003,
        "latitude": -22.875404,
        "longitude": -43.533619
      },
      {
        "id": 8004,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 8005,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 8006,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 8007,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 8008,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 8009,
        "latitude": -22.875568,
        "longitude": -43.463409
      },
      {
        "id": 8010,
        "latitude": -22.876046,
        "longitude": -43.533392
      },
      {
        "id": 8012,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 8013,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 8014,
        "latitude": -22.8528,
        "longitude": -43.35841
      },
      {
        "id": 8015,
        "latitude": -22.94554,
        "longitude": -43.597036
      },
      {
        "id": 8016,
        "latitude": -22.822832,
        "longitude": -43.318788
      },
      {
        "id": 8017,
        "latitude": -22.822832,
        "longitude": -43.318788
      },
      {
        "id": 8018,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 8019,
        "latitude": -21.502741,
        "longitude": -41.075792
      },
      {
        "id": 8020,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 8021,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 8022,
        "latitude": -22.825511,
        "longitude": -43.378112
      },
      {
        "id": 8023,
        "latitude": -22.825511,
        "longitude": -43.378112
      },
      {
        "id": 8024,
        "latitude": -22.823593,
        "longitude": -43.30883
      },
      {
        "id": 8025,
        "latitude": -22.867881,
        "longitude": -43.589515
      },
      {
        "id": 8026,
        "latitude": -22.789183,
        "longitude": -43.173893
      },
      {
        "id": 8027,
        "latitude": -22.870374,
        "longitude": -43.339416
      },
      {
        "id": 8028,
        "latitude": -22.875404,
        "longitude": -43.533619
      },
      {
        "id": 8029,
        "latitude": -22.847726,
        "longitude": -43.289695
      },
      {
        "id": 8030,
        "latitude": -22.867538,
        "longitude": -43.340302
      },
      {
        "id": 8031,
        "latitude": -22.83569,
        "longitude": -43.300835
      },
      {
        "id": 8032,
        "latitude": -22.84455,
        "longitude": -43.37628
      },
      {
        "id": 8033,
        "latitude": -22.825511,
        "longitude": -43.378112
      },
      {
        "id": 8035,
        "latitude": -22.931191,
        "longitude": -43.327205
      },
      {
        "id": 8036,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 8037,
        "latitude": -22.795113,
        "longitude": -43.171897
      },
      {
        "id": 8038,
        "latitude": -22.882208,
        "longitude": -43.280491
      },
      {
        "id": 8039,
        "latitude": -22.921302,
        "longitude": -43.190732
      },
      {
        "id": 8041,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 8042,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 8043,
        "latitude": -22.8882,
        "longitude": -43.30398
      },
      {
        "id": 8044,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 8045,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 8046,
        "latitude": -22.790915,
        "longitude": -43.170561
      },
      {
        "id": 8047,
        "latitude": -22.824565,
        "longitude": -43.351476
      },
      {
        "id": 8048,
        "latitude": -22.901932,
        "longitude": -43.344742
      },
      {
        "id": 8049,
        "latitude": -22.979464,
        "longitude": -43.332707
      },
      {
        "id": 8050,
        "latitude": -22.948629,
        "longitude": -43.329747
      },
      {
        "id": 8051,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 8052,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 8053,
        "latitude": -22.848695,
        "longitude": -43.466071
      },
      {
        "id": 8054,
        "latitude": -22.84116,
        "longitude": -43.37228
      },
      {
        "id": 8055,
        "latitude": -22.998021,
        "longitude": -43.398513
      },
      {
        "id": 8056,
        "latitude": -22.941887,
        "longitude": -43.355507
      },
      {
        "id": 8057,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 8058,
        "latitude": -22.867508,
        "longitude": -43.587815
      },
      {
        "id": 8059,
        "latitude": -22.872778,
        "longitude": -43.526439
      },
      {
        "id": 8060,
        "latitude": -22.881061,
        "longitude": -43.453407
      },
      {
        "id": 8061,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 8062,
        "latitude": -22.839888,
        "longitude": -43.566125
      },
      {
        "id": 8063,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 8064,
        "latitude": -22.85659,
        "longitude": -43.32936
      },
      {
        "id": 8065,
        "latitude": -22.9978,
        "longitude": -43.397834
      },
      {
        "id": 8066,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 8067,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 8068,
        "latitude": -22.90592,
        "longitude": -43.26596
      },
      {
        "id": 8070,
        "latitude": -22.893637,
        "longitude": -43.349325
      },
      {
        "id": 8071,
        "latitude": -22.939341,
        "longitude": -43.678812
      },
      {
        "id": 8072,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 8073,
        "latitude": -22.933525,
        "longitude": -43.396733
      },
      {
        "id": 8074,
        "latitude": -23.016495,
        "longitude": -43.47079
      },
      {
        "id": 8075,
        "latitude": -22.938653,
        "longitude": -43.560433
      },
      {
        "id": 8076,
        "latitude": -22.820794,
        "longitude": -43.311574
      },
      {
        "id": 8077,
        "latitude": -22.879553,
        "longitude": -43.549871
      },
      {
        "id": 8078,
        "latitude": -22.92649,
        "longitude": -43.248323
      },
      {
        "id": 8079,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 8080,
        "latitude": -22.92254,
        "longitude": -43.376522
      },
      {
        "id": 8081,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 8082,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 8083,
        "latitude": -22.965754,
        "longitude": -43.409382
      },
      {
        "id": 8084,
        "latitude": -22.943706,
        "longitude": -43.57237
      },
      {
        "id": 8085,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 8086,
        "latitude": -22.853417,
        "longitude": -43.319793
      },
      {
        "id": 8087,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 8088,
        "latitude": -22.822788,
        "longitude": -43.313453
      },
      {
        "id": 8089,
        "latitude": -22.842717,
        "longitude": -43.334885
      },
      {
        "id": 8090,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 8091,
        "latitude": -23.003888,
        "longitude": -43.434315
      },
      {
        "id": 8092,
        "latitude": -22.877878,
        "longitude": -43.642566
      },
      {
        "id": 8093,
        "latitude": -23.000558,
        "longitude": -43.635306
      },
      {
        "id": 8094,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 8095,
        "latitude": -22.836798,
        "longitude": -43.322197
      },
      {
        "id": 8096,
        "latitude": -22.865091,
        "longitude": -43.293299
      },
      {
        "id": 8097,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 8098,
        "latitude": -22.9742,
        "longitude": -43.393876
      },
      {
        "id": 8099,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 8100,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 8101,
        "latitude": -22.829077,
        "longitude": -43.312096
      },
      {
        "id": 8102,
        "latitude": -22.789943,
        "longitude": -43.180554
      },
      {
        "id": 8103,
        "latitude": -22.86694,
        "longitude": -43.28947
      },
      {
        "id": 8104,
        "latitude": -22.892347,
        "longitude": -43.302106
      },
      {
        "id": 8105,
        "latitude": -22.860996,
        "longitude": -43.515456
      },
      {
        "id": 8106,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 8107,
        "latitude": -22.899459,
        "longitude": -43.296436
      },
      {
        "id": 8108,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 8109,
        "latitude": -22.842717,
        "longitude": -43.334885
      },
      {
        "id": 8110,
        "latitude": -22.828153,
        "longitude": -43.353792
      },
      {
        "id": 8111,
        "latitude": -22.931854,
        "longitude": -43.65684
      },
      {
        "id": 8112,
        "latitude": -22.894198,
        "longitude": -43.537878
      },
      {
        "id": 8113,
        "latitude": -22.899223,
        "longitude": -43.230712
      },
      {
        "id": 8114,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 8115,
        "latitude": -22.882054,
        "longitude": -43.331975
      },
      {
        "id": 8116,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 8117,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 8118,
        "latitude": -22.887865,
        "longitude": -43.476446
      },
      {
        "id": 8119,
        "latitude": -22.883238,
        "longitude": -43.491436
      },
      {
        "id": 8120,
        "latitude": -22.856408,
        "longitude": -43.327299
      },
      {
        "id": 8121,
        "latitude": -22.882289,
        "longitude": -43.331672
      },
      {
        "id": 8123,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 8124,
        "latitude": -23.006831,
        "longitude": -44.296118
      },
      {
        "id": 8125,
        "latitude": -22.989292,
        "longitude": -43.453955
      },
      {
        "id": 8126,
        "latitude": -22.876799,
        "longitude": -43.415528
      },
      {
        "id": 8127,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 8128,
        "latitude": -22.883998,
        "longitude": -43.460984
      },
      {
        "id": 8129,
        "latitude": -22.858066,
        "longitude": -43.464866
      },
      {
        "id": 8131,
        "latitude": -22.985179,
        "longitude": -43.664281
      },
      {
        "id": 8132,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 8133,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 8134,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 8135,
        "latitude": -22.933588,
        "longitude": -43.636043
      },
      {
        "id": 8153,
        "latitude": -22.839888,
        "longitude": -43.566125
      },
      {
        "id": 8136,
        "latitude": -22.937659,
        "longitude": -43.325425
      },
      {
        "id": 8137,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 8138,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 8139,
        "latitude": -22.83489,
        "longitude": -43.272447
      },
      {
        "id": 8140,
        "latitude": -22.869742,
        "longitude": -43.259551
      },
      {
        "id": 8141,
        "latitude": -22.927193,
        "longitude": -43.349601
      },
      {
        "id": 8142,
        "latitude": -22.969345,
        "longitude": -43.425735
      },
      {
        "id": 8143,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 8144,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 8145,
        "latitude": -22.87344,
        "longitude": -43.314665
      },
      {
        "id": 8146,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 8147,
        "latitude": -22.938653,
        "longitude": -43.560433
      },
      {
        "id": 8148,
        "latitude": -22.890684,
        "longitude": -43.310886
      },
      {
        "id": 8149,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 8150,
        "latitude": -22.829601,
        "longitude": -43.301943
      },
      {
        "id": 8151,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 8152,
        "latitude": -22.914452,
        "longitude": -43.583694
      },
      {
        "id": 8154,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 8155,
        "latitude": -22.820794,
        "longitude": -43.311574
      },
      {
        "id": 8156,
        "latitude": -22.88901,
        "longitude": -43.27909
      },
      {
        "id": 8157,
        "latitude": -22.854309,
        "longitude": -43.482587
      },
      {
        "id": 8158,
        "latitude": -22.796174,
        "longitude": -43.19957
      },
      {
        "id": 8159,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 8161,
        "latitude": -22.915337,
        "longitude": -43.692224
      },
      {
        "id": 8162,
        "latitude": -22.811815,
        "longitude": -43.404805
      },
      {
        "id": 8163,
        "latitude": -23.00515,
        "longitude": -43.372192
      },
      {
        "id": 8164,
        "latitude": -22.83497,
        "longitude": -43.29188
      },
      {
        "id": 8165,
        "latitude": -22.964361,
        "longitude": -43.616991
      },
      {
        "id": 8166,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 8167,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 8168,
        "latitude": -22.929627,
        "longitude": -43.240089
      },
      {
        "id": 8169,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 8170,
        "latitude": -22.970516,
        "longitude": -43.700755
      },
      {
        "id": 8241,
        "latitude": -22.99835,
        "longitude": -43.36545
      },
      {
        "id": 8171,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 8172,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 8173,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 8174,
        "latitude": -22.9055,
        "longitude": -43.296505
      },
      {
        "id": 8175,
        "latitude": -22.855225,
        "longitude": -43.360721
      },
      {
        "id": 8176,
        "latitude": -22.89556,
        "longitude": -43.53195
      },
      {
        "id": 8177,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 8178,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 8179,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 8180,
        "latitude": -22.883288,
        "longitude": -43.440445
      },
      {
        "id": 8181,
        "latitude": -22.847641,
        "longitude": -43.251776
      },
      {
        "id": 8182,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 8183,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 8184,
        "latitude": -22.892004,
        "longitude": -43.353246
      },
      {
        "id": 8186,
        "latitude": -22.87802,
        "longitude": -43.363413
      },
      {
        "id": 8187,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 9443,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 8188,
        "latitude": -22.949702,
        "longitude": -43.642517
      },
      {
        "id": 8189,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 8190,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 8191,
        "latitude": -22.762621,
        "longitude": -43.0038
      },
      {
        "id": 8192,
        "latitude": -22.883393,
        "longitude": -43.440059
      },
      {
        "id": 8193,
        "latitude": -22.816704,
        "longitude": -43.376465
      },
      {
        "id": 8194,
        "latitude": -22.864196,
        "longitude": -43.249239
      },
      {
        "id": 8196,
        "latitude": -22.85623,
        "longitude": -43.3162
      },
      {
        "id": 8198,
        "latitude": -22.94621,
        "longitude": -43.372115
      },
      {
        "id": 8199,
        "latitude": -22.939211,
        "longitude": -43.250965
      },
      {
        "id": 8200,
        "latitude": -23.003122,
        "longitude": -43.483828
      },
      {
        "id": 8201,
        "latitude": -22.865205,
        "longitude": -43.297688
      },
      {
        "id": 8202,
        "latitude": -22.937466,
        "longitude": -43.364778
      },
      {
        "id": 8203,
        "latitude": -22.903626,
        "longitude": -43.313967
      },
      {
        "id": 8204,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 8205,
        "latitude": -22.816777,
        "longitude": -43.378516
      },
      {
        "id": 8206,
        "latitude": -23.025901,
        "longitude": -43.460477
      },
      {
        "id": 8207,
        "latitude": -22.865205,
        "longitude": -43.297688
      },
      {
        "id": 8208,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 8209,
        "latitude": -22.849702,
        "longitude": -43.2718
      },
      {
        "id": 8210,
        "latitude": -22.896771,
        "longitude": -43.30548
      },
      {
        "id": 8211,
        "latitude": -22.826639,
        "longitude": -43.316112
      },
      {
        "id": 8212,
        "latitude": -22.925469,
        "longitude": -43.348667
      },
      {
        "id": 8213,
        "latitude": -22.893569,
        "longitude": -43.311745
      },
      {
        "id": 8214,
        "latitude": -22.820918,
        "longitude": -43.291101
      },
      {
        "id": 8215,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 8216,
        "latitude": -22.873813,
        "longitude": -43.333529
      },
      {
        "id": 8217,
        "latitude": -22.912069,
        "longitude": -43.178761
      },
      {
        "id": 8218,
        "latitude": -22.865205,
        "longitude": -43.297688
      },
      {
        "id": 8219,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 8220,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 8221,
        "latitude": -22.897356,
        "longitude": -43.352805
      },
      {
        "id": 8222,
        "latitude": -22.892277,
        "longitude": -43.236753
      },
      {
        "id": 8223,
        "latitude": -22.886753,
        "longitude": -43.24635
      },
      {
        "id": 8224,
        "latitude": -22.877113,
        "longitude": -43.592125
      },
      {
        "id": 8225,
        "latitude": -22.817961,
        "longitude": -43.389893
      },
      {
        "id": 8226,
        "latitude": -22.917733,
        "longitude": -43.39389
      },
      {
        "id": 8227,
        "latitude": -22.881018,
        "longitude": -43.37516
      },
      {
        "id": 8228,
        "latitude": -22.923158,
        "longitude": -43.261364
      },
      {
        "id": 8229,
        "latitude": -22.842009,
        "longitude": -43.321289
      },
      {
        "id": 8230,
        "latitude": -22.84116,
        "longitude": -43.37228
      },
      {
        "id": 8231,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 8232,
        "latitude": -22.980331,
        "longitude": -43.223151
      },
      {
        "id": 8233,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 8234,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 8235,
        "latitude": -22.856794,
        "longitude": -43.464755
      },
      {
        "id": 8236,
        "latitude": -22.806045,
        "longitude": -43.32501
      },
      {
        "id": 8237,
        "latitude": -22.83569,
        "longitude": -43.300835
      },
      {
        "id": 8238,
        "latitude": -22.896935,
        "longitude": -43.352664
      },
      {
        "id": 8239,
        "latitude": -22.842009,
        "longitude": -43.321289
      },
      {
        "id": 8242,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 8243,
        "latitude": -22.834362,
        "longitude": -43.281508
      },
      {
        "id": 8244,
        "latitude": -22.817658,
        "longitude": -43.312079
      },
      {
        "id": 8245,
        "latitude": -22.834385,
        "longitude": -43.299553
      },
      {
        "id": 8246,
        "latitude": -22.953468,
        "longitude": -43.357106
      },
      {
        "id": 8247,
        "latitude": -22.854074,
        "longitude": -43.326821
      },
      {
        "id": 8248,
        "latitude": -22.912986,
        "longitude": -43.286105
      },
      {
        "id": 8249,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 8250,
        "latitude": -21.544845,
        "longitude": -42.183914
      },
      {
        "id": 8251,
        "latitude": -21.653007,
        "longitude": -41.748012
      },
      {
        "id": 8252,
        "latitude": -21.652445,
        "longitude": -41.748847
      },
      {
        "id": 8253,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8254,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8255,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8256,
        "latitude": -22.82683,
        "longitude": -43.038542
      },
      {
        "id": 8257,
        "latitude": -22.82366,
        "longitude": -43.024341
      },
      {
        "id": 8258,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8259,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 9444,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 8260,
        "latitude": -22.809923,
        "longitude": -43.00886
      },
      {
        "id": 8261,
        "latitude": -22.794655,
        "longitude": -42.995784
      },
      {
        "id": 8262,
        "latitude": -22.827023,
        "longitude": -43.039269
      },
      {
        "id": 8263,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8264,
        "latitude": -22.819364,
        "longitude": -42.958133
      },
      {
        "id": 8265,
        "latitude": -22.822145,
        "longitude": -43.304451
      },
      {
        "id": 8266,
        "latitude": -22.794462,
        "longitude": -42.98275
      },
      {
        "id": 8267,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8268,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8269,
        "latitude": -22.796982,
        "longitude": -42.992915
      },
      {
        "id": 8270,
        "latitude": -22.793704,
        "longitude": -42.993699
      },
      {
        "id": 8271,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8272,
        "latitude": -22.860246,
        "longitude": -42.939499
      },
      {
        "id": 8273,
        "latitude": -22.803213,
        "longitude": -42.985135
      },
      {
        "id": 8274,
        "latitude": -22.916442,
        "longitude": -43.244062
      },
      {
        "id": 8275,
        "latitude": -22.80928,
        "longitude": -43.01424
      },
      {
        "id": 8276,
        "latitude": -22.785494,
        "longitude": -42.953023
      },
      {
        "id": 8277,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 9445,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 8278,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8279,
        "latitude": -22.80928,
        "longitude": -43.01424
      },
      {
        "id": 8280,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8281,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8282,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8283,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8284,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8285,
        "latitude": -22.81089,
        "longitude": -43.185157
      },
      {
        "id": 8286,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8287,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8288,
        "latitude": -22.700602,
        "longitude": -42.876803
      },
      {
        "id": 8289,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8290,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8291,
        "latitude": -22.786848,
        "longitude": -42.954714
      },
      {
        "id": 8292,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8293,
        "latitude": -22.794462,
        "longitude": -42.98275
      },
      {
        "id": 8295,
        "latitude": -22.794462,
        "longitude": -42.98275
      },
      {
        "id": 8296,
        "latitude": -22.804118,
        "longitude": -43.00324
      },
      {
        "id": 8424,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8297,
        "latitude": -22.80928,
        "longitude": -43.01424
      },
      {
        "id": 8298,
        "latitude": -22.80928,
        "longitude": -43.01424
      },
      {
        "id": 8299,
        "latitude": -22.815322,
        "longitude": -43.049623
      },
      {
        "id": 8300,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8301,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8303,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8304,
        "latitude": -22.839684,
        "longitude": -43.228208
      },
      {
        "id": 8305,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8306,
        "latitude": -22.77556,
        "longitude": -43.03194
      },
      {
        "id": 8307,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8308,
        "latitude": -22.843295,
        "longitude": -43.075334
      },
      {
        "id": 8309,
        "latitude": -22.806669,
        "longitude": -42.973323
      },
      {
        "id": 8310,
        "latitude": -22.77556,
        "longitude": -43.03194
      },
      {
        "id": 8311,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8312,
        "latitude": -22.665841,
        "longitude": -43.04325
      },
      {
        "id": 8313,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8314,
        "latitude": -22.80985,
        "longitude": -43.049436
      },
      {
        "id": 8315,
        "latitude": -22.795508,
        "longitude": -43.048096
      },
      {
        "id": 8316,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8317,
        "latitude": -22.830952,
        "longitude": -42.972212
      },
      {
        "id": 8318,
        "latitude": -22.821419,
        "longitude": -42.984675
      },
      {
        "id": 8319,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8320,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8321,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8322,
        "latitude": -22.840703,
        "longitude": -42.986117
      },
      {
        "id": 8323,
        "latitude": -22.888824,
        "longitude": -43.23002
      },
      {
        "id": 8324,
        "latitude": -22.888824,
        "longitude": -43.23002
      },
      {
        "id": 8325,
        "latitude": -22.93333,
        "longitude": -42.93333
      },
      {
        "id": 8326,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8327,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8328,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8329,
        "latitude": -22.793767,
        "longitude": -42.993838
      },
      {
        "id": 8330,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8331,
        "latitude": -22.674479,
        "longitude": -43.25715
      },
      {
        "id": 8332,
        "latitude": -22.785604,
        "longitude": -43.375009
      },
      {
        "id": 8333,
        "latitude": -22.812763,
        "longitude": -42.989555
      },
      {
        "id": 8334,
        "latitude": -22.84764,
        "longitude": -43.006466
      },
      {
        "id": 8335,
        "latitude": -22.781131,
        "longitude": -43.032409
      },
      {
        "id": 8336,
        "latitude": -22.966963,
        "longitude": -43.202545
      },
      {
        "id": 8337,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8338,
        "latitude": -22.812005,
        "longitude": -43.022715
      },
      {
        "id": 8339,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8340,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8341,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8342,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8343,
        "latitude": -22.820937,
        "longitude": -43.057906
      },
      {
        "id": 8344,
        "latitude": -22.828639,
        "longitude": -43.035085
      },
      {
        "id": 8345,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8346,
        "latitude": -22.814501,
        "longitude": -43.031362
      },
      {
        "id": 8347,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8348,
        "latitude": -22.956284,
        "longitude": -42.986302
      },
      {
        "id": 8349,
        "latitude": -22.811404,
        "longitude": -43.058197
      },
      {
        "id": 8350,
        "latitude": -22.83218,
        "longitude": -43.295556
      },
      {
        "id": 8404,
        "latitude": -22.840653,
        "longitude": -43.261488
      },
      {
        "id": 8351,
        "latitude": -22.85861,
        "longitude": -43.03389
      },
      {
        "id": 8352,
        "latitude": -22.805204,
        "longitude": -43.02831
      },
      {
        "id": 8353,
        "latitude": -22.85411,
        "longitude": -43.085121
      },
      {
        "id": 8354,
        "latitude": -22.82486,
        "longitude": -43.074324
      },
      {
        "id": 8355,
        "latitude": -22.820149,
        "longitude": -42.984308
      },
      {
        "id": 8356,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8357,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8358,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8359,
        "latitude": -22.807196,
        "longitude": -42.970047
      },
      {
        "id": 8361,
        "latitude": -22.809991,
        "longitude": -43.022894
      },
      {
        "id": 8362,
        "latitude": -22.882625,
        "longitude": -43.020077
      },
      {
        "id": 8363,
        "latitude": -22.830019,
        "longitude": -43.04302
      },
      {
        "id": 8364,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8365,
        "latitude": -22.820938,
        "longitude": -43.079948
      },
      {
        "id": 8366,
        "latitude": -22.801543,
        "longitude": -42.99349
      },
      {
        "id": 8367,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8368,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8369,
        "latitude": -22.835178,
        "longitude": -43.012924
      },
      {
        "id": 8370,
        "latitude": -22.890548,
        "longitude": -43.310753
      },
      {
        "id": 8371,
        "latitude": -11.189907,
        "longitude": -38.001262
      },
      {
        "id": 8372,
        "latitude": -22.840667,
        "longitude": -42.98619
      },
      {
        "id": 8373,
        "latitude": -22.827196,
        "longitude": -42.98988
      },
      {
        "id": 8374,
        "latitude": -22.821493,
        "longitude": -43.056964
      },
      {
        "id": 8375,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8376,
        "latitude": -22.822297,
        "longitude": -43.057066
      },
      {
        "id": 8377,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8378,
        "latitude": -22.797206,
        "longitude": -42.978776
      },
      {
        "id": 8379,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8380,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8381,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8382,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8383,
        "latitude": -22.888824,
        "longitude": -43.23002
      },
      {
        "id": 8384,
        "latitude": -22.832728,
        "longitude": -42.977606
      },
      {
        "id": 8385,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8386,
        "latitude": -22.790721,
        "longitude": -42.952609
      },
      {
        "id": 8387,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8388,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8389,
        "latitude": -22.829977,
        "longitude": -43.084646
      },
      {
        "id": 8390,
        "latitude": -22.811175,
        "longitude": -42.96229
      },
      {
        "id": 8391,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8392,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8393,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8394,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8395,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8396,
        "latitude": -22.805617,
        "longitude": -42.949005
      },
      {
        "id": 8397,
        "latitude": -22.777202,
        "longitude": -43.364424
      },
      {
        "id": 8398,
        "latitude": -22.82822,
        "longitude": -42.985558
      },
      {
        "id": 8399,
        "latitude": -22.675173,
        "longitude": -43.252284
      },
      {
        "id": 8400,
        "latitude": -22.866571,
        "longitude": -43.027131
      },
      {
        "id": 8401,
        "latitude": -22.796481,
        "longitude": -42.981494
      },
      {
        "id": 8402,
        "latitude": -22.769966,
        "longitude": -43.381386
      },
      {
        "id": 8403,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8423,
        "latitude": -22.796796,
        "longitude": -43.000475
      },
      {
        "id": 8405,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8406,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8407,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8408,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8409,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8410,
        "latitude": -22.81472,
        "longitude": -42.946977
      },
      {
        "id": 8411,
        "latitude": -22.836982,
        "longitude": -42.987457
      },
      {
        "id": 8412,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8413,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8414,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8415,
        "latitude": -22.85861,
        "longitude": -43.03389
      },
      {
        "id": 8416,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8417,
        "latitude": -22.789595,
        "longitude": -43.031903
      },
      {
        "id": 8418,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8419,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8420,
        "latitude": -22.807766,
        "longitude": -43.036768
      },
      {
        "id": 8421,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8422,
        "latitude": -22.790096,
        "longitude": -42.998068
      },
      {
        "id": 8425,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8426,
        "latitude": -22.860117,
        "longitude": -42.939159
      },
      {
        "id": 8427,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8428,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8429,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8430,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8431,
        "latitude": -22.83264,
        "longitude": -42.97759
      },
      {
        "id": 8432,
        "latitude": -22.888824,
        "longitude": -43.23002
      },
      {
        "id": 8433,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8434,
        "latitude": -22.833454,
        "longitude": -42.986475
      },
      {
        "id": 8435,
        "latitude": -22.814549,
        "longitude": -42.973125
      },
      {
        "id": 8436,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8437,
        "latitude": -22.817351,
        "longitude": -42.973283
      },
      {
        "id": 8438,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8439,
        "latitude": -22.850347,
        "longitude": -43.008173
      },
      {
        "id": 8440,
        "latitude": -22.832728,
        "longitude": -42.977606
      },
      {
        "id": 8441,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8442,
        "latitude": -22.845185,
        "longitude": -43.064827
      },
      {
        "id": 8443,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8444,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8445,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8446,
        "latitude": -22.786848,
        "longitude": -42.954714
      },
      {
        "id": 8447,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8448,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8449,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8450,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8451,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8452,
        "latitude": -22.786848,
        "longitude": -42.954714
      },
      {
        "id": 8453,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8454,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8455,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8456,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8457,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8458,
        "latitude": -22.820023,
        "longitude": -42.972977
      },
      {
        "id": 8459,
        "latitude": -22.812033,
        "longitude": -43.063269
      },
      {
        "id": 8460,
        "latitude": -22.781075,
        "longitude": -42.952358
      },
      {
        "id": 8461,
        "latitude": -22.805617,
        "longitude": -42.949005
      },
      {
        "id": 8462,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8463,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8464,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8465,
        "latitude": -22.828281,
        "longitude": -42.985564
      },
      {
        "id": 8466,
        "latitude": -22.82822,
        "longitude": -42.985558
      },
      {
        "id": 8467,
        "latitude": -22.822145,
        "longitude": -43.304451
      },
      {
        "id": 8468,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8469,
        "latitude": -22.89685,
        "longitude": -43.117849
      },
      {
        "id": 8470,
        "latitude": -22.820718,
        "longitude": -42.980314
      },
      {
        "id": 8472,
        "latitude": -22.796481,
        "longitude": -42.981494
      },
      {
        "id": 8473,
        "latitude": -22.832431,
        "longitude": -43.274184
      },
      {
        "id": 8474,
        "latitude": -22.829995,
        "longitude": -43.014215
      },
      {
        "id": 8475,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8476,
        "latitude": -22.852487,
        "longitude": -43.351656
      },
      {
        "id": 8477,
        "latitude": -22.804118,
        "longitude": -43.00324
      },
      {
        "id": 8496,
        "latitude": -22.791728,
        "longitude": -42.997281
      },
      {
        "id": 8478,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8479,
        "latitude": -22.852487,
        "longitude": -43.351656
      },
      {
        "id": 8480,
        "latitude": -22.821909,
        "longitude": -42.975503
      },
      {
        "id": 8481,
        "latitude": -12.670575,
        "longitude": -48.618292
      },
      {
        "id": 8482,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8483,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8484,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8485,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8486,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8487,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8489,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8490,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8491,
        "latitude": -22.791728,
        "longitude": -42.997281
      },
      {
        "id": 8492,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8493,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8495,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8497,
        "latitude": -22.768362,
        "longitude": -43.013077
      },
      {
        "id": 8498,
        "latitude": -22.836491,
        "longitude": -43.008561
      },
      {
        "id": 8499,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8500,
        "latitude": -22.830344,
        "longitude": -43.058524
      },
      {
        "id": 8501,
        "latitude": -22.799098,
        "longitude": -42.981051
      },
      {
        "id": 8502,
        "latitude": -22.842907,
        "longitude": -43.07004
      },
      {
        "id": 8503,
        "latitude": -22.836491,
        "longitude": -43.008561
      },
      {
        "id": 8504,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8505,
        "latitude": -22.837714,
        "longitude": -43.006819
      },
      {
        "id": 8506,
        "latitude": -22.812529,
        "longitude": -43.024684
      },
      {
        "id": 8507,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8508,
        "latitude": -22.821455,
        "longitude": -42.984457
      },
      {
        "id": 8509,
        "latitude": -22.670162,
        "longitude": -43.262164
      },
      {
        "id": 8510,
        "latitude": -22.86019,
        "longitude": -43.017548
      },
      {
        "id": 8511,
        "latitude": -22.821872,
        "longitude": -43.078062
      },
      {
        "id": 8512,
        "latitude": -22.847573,
        "longitude": -43.088307
      },
      {
        "id": 8513,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8514,
        "latitude": -22.785494,
        "longitude": -42.953023
      },
      {
        "id": 8515,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8516,
        "latitude": -22.794372,
        "longitude": -42.950349
      },
      {
        "id": 8517,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8518,
        "latitude": -22.851917,
        "longitude": -43.008269
      },
      {
        "id": 8519,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8520,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8521,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8522,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8523,
        "latitude": -22.843006,
        "longitude": -43.016297
      },
      {
        "id": 8524,
        "latitude": -22.836491,
        "longitude": -43.008561
      },
      {
        "id": 8525,
        "latitude": -22.801243,
        "longitude": -43.049751
      },
      {
        "id": 8526,
        "latitude": -22.826399,
        "longitude": -43.075065
      },
      {
        "id": 8527,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8528,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8529,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8530,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8531,
        "latitude": -22.820318,
        "longitude": -42.983084
      },
      {
        "id": 8532,
        "latitude": -22.917696,
        "longitude": -43.187879
      },
      {
        "id": 8533,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8534,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8535,
        "latitude": -22.822686,
        "longitude": -42.984642
      },
      {
        "id": 8537,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8538,
        "latitude": -22.830727,
        "longitude": -42.974001
      },
      {
        "id": 8539,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8540,
        "latitude": -22.826399,
        "longitude": -43.075065
      },
      {
        "id": 8541,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8542,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8543,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8544,
        "latitude": -22.84731,
        "longitude": -43.08619
      },
      {
        "id": 8545,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8546,
        "latitude": -22.8235,
        "longitude": -43.07542
      },
      {
        "id": 8547,
        "latitude": -22.829117,
        "longitude": -43.032368
      },
      {
        "id": 8548,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8549,
        "latitude": -22.845978,
        "longitude": -43.372528
      },
      {
        "id": 8816,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8550,
        "latitude": -22.837695,
        "longitude": -43.266711
      },
      {
        "id": 8551,
        "latitude": -22.820778,
        "longitude": -42.984376
      },
      {
        "id": 8552,
        "latitude": -22.77556,
        "longitude": -43.03194
      },
      {
        "id": 8553,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8554,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8555,
        "latitude": -22.832316,
        "longitude": -42.994866
      },
      {
        "id": 8556,
        "latitude": -22.807829,
        "longitude": -42.978841
      },
      {
        "id": 8557,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8558,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8559,
        "latitude": -22.85861,
        "longitude": -43.03389
      },
      {
        "id": 8560,
        "latitude": -22.86397,
        "longitude": -42.331964
      },
      {
        "id": 8561,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8562,
        "latitude": -22.785171,
        "longitude": -43.036227
      },
      {
        "id": 8563,
        "latitude": -22.831561,
        "longitude": -42.965257
      },
      {
        "id": 8564,
        "latitude": -22.831895,
        "longitude": -43.031418
      },
      {
        "id": 8565,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8566,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8567,
        "latitude": -22.840831,
        "longitude": -42.988701
      },
      {
        "id": 8568,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8569,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8570,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8571,
        "latitude": -22.834109,
        "longitude": -43.008048
      },
      {
        "id": 8572,
        "latitude": -22.78756,
        "longitude": -42.954311
      },
      {
        "id": 8573,
        "latitude": -22.860393,
        "longitude": -43.026881
      },
      {
        "id": 8575,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8576,
        "latitude": -22.830758,
        "longitude": -43.075592
      },
      {
        "id": 8577,
        "latitude": -22.817771,
        "longitude": -43.018804
      },
      {
        "id": 8578,
        "latitude": -22.822297,
        "longitude": -43.057066
      },
      {
        "id": 8579,
        "latitude": -22.769532,
        "longitude": -42.970371
      },
      {
        "id": 8580,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8581,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8582,
        "latitude": -22.811404,
        "longitude": -43.058197
      },
      {
        "id": 8583,
        "latitude": -22.850077,
        "longitude": -43.09017
      },
      {
        "id": 8584,
        "latitude": -22.847473,
        "longitude": -43.088263
      },
      {
        "id": 8585,
        "latitude": -22.892023,
        "longitude": -43.246754
      },
      {
        "id": 8586,
        "latitude": -22.851124,
        "longitude": -43.073974
      },
      {
        "id": 8587,
        "latitude": -22.797666,
        "longitude": -42.980931
      },
      {
        "id": 8588,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8589,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8590,
        "latitude": -22.831484,
        "longitude": -43.021046
      },
      {
        "id": 8591,
        "latitude": -22.831484,
        "longitude": -43.021046
      },
      {
        "id": 8592,
        "latitude": -22.785532,
        "longitude": -43.023503
      },
      {
        "id": 8593,
        "latitude": -22.956284,
        "longitude": -42.986302
      },
      {
        "id": 8594,
        "latitude": -22.809125,
        "longitude": -42.945149
      },
      {
        "id": 8595,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8596,
        "latitude": -22.802604,
        "longitude": -43.372366
      },
      {
        "id": 8597,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8598,
        "latitude": -22.791728,
        "longitude": -42.997281
      },
      {
        "id": 8599,
        "latitude": -22.831733,
        "longitude": -43.275518
      },
      {
        "id": 8600,
        "latitude": -22.867915,
        "longitude": -43.367936
      },
      {
        "id": 8601,
        "latitude": -22.787239,
        "longitude": -43.371627
      },
      {
        "id": 8602,
        "latitude": -22.841521,
        "longitude": -43.309262
      },
      {
        "id": 8603,
        "latitude": -22.82822,
        "longitude": -42.985558
      },
      {
        "id": 8604,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8605,
        "latitude": -22.803684,
        "longitude": -42.951837
      },
      {
        "id": 8606,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8607,
        "latitude": -22.832316,
        "longitude": -42.994866
      },
      {
        "id": 8608,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8609,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8610,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8611,
        "latitude": -22.790721,
        "longitude": -42.952609
      },
      {
        "id": 8612,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8613,
        "latitude": -22.600317,
        "longitude": -43.291315
      },
      {
        "id": 8614,
        "latitude": -22.632885,
        "longitude": -43.308741
      },
      {
        "id": 8615,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8616,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8617,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8618,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8619,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8620,
        "latitude": -22.780079,
        "longitude": -42.991431
      },
      {
        "id": 8621,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8623,
        "latitude": -22.843977,
        "longitude": -42.956857
      },
      {
        "id": 8624,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8625,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8626,
        "latitude": -22.848169,
        "longitude": -43.012921
      },
      {
        "id": 8627,
        "latitude": -22.786848,
        "longitude": -42.954714
      },
      {
        "id": 8628,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8629,
        "latitude": -22.832728,
        "longitude": -42.977606
      },
      {
        "id": 8630,
        "latitude": -22.835884,
        "longitude": -43.050168
      },
      {
        "id": 8631,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8632,
        "latitude": -22.866379,
        "longitude": -43.012939
      },
      {
        "id": 8633,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8634,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8635,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8636,
        "latitude": -23.00692,
        "longitude": -44.295094
      },
      {
        "id": 8637,
        "latitude": -22.882625,
        "longitude": -43.020077
      },
      {
        "id": 8638,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8639,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8640,
        "latitude": -22.837895,
        "longitude": -43.07123
      },
      {
        "id": 8641,
        "latitude": -22.84764,
        "longitude": -43.006466
      },
      {
        "id": 8642,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8643,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8644,
        "latitude": -22.971775,
        "longitude": -43.28213
      },
      {
        "id": 8645,
        "latitude": -22.84764,
        "longitude": -43.006466
      },
      {
        "id": 8646,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8647,
        "latitude": -22.901999,
        "longitude": -43.052824
      },
      {
        "id": 8648,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8649,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8650,
        "latitude": -22.821901,
        "longitude": -43.030925
      },
      {
        "id": 8651,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8652,
        "latitude": -22.791406,
        "longitude": -43.034805
      },
      {
        "id": 8653,
        "latitude": -22.838044,
        "longitude": -43.08671
      },
      {
        "id": 8654,
        "latitude": -22.824717,
        "longitude": -43.083571
      },
      {
        "id": 8655,
        "latitude": -22.816262,
        "longitude": -42.959763
      },
      {
        "id": 8656,
        "latitude": -22.829995,
        "longitude": -43.014215
      },
      {
        "id": 8657,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8658,
        "latitude": -22.881368,
        "longitude": -43.021936
      },
      {
        "id": 8659,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8660,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8661,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8662,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8663,
        "latitude": -22.833997,
        "longitude": -43.061638
      },
      {
        "id": 8664,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8665,
        "latitude": -22.910727,
        "longitude": -43.292722
      },
      {
        "id": 8666,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8667,
        "latitude": -22.863514,
        "longitude": -43.082616
      },
      {
        "id": 8668,
        "latitude": -22.827827,
        "longitude": -43.057721
      },
      {
        "id": 8669,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8670,
        "latitude": -22.827669,
        "longitude": -43.315964
      },
      {
        "id": 8671,
        "latitude": -22.839738,
        "longitude": -42.994282
      },
      {
        "id": 8672,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8673,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8674,
        "latitude": -22.806021,
        "longitude": -42.958806
      },
      {
        "id": 8675,
        "latitude": -22.815287,
        "longitude": -42.966892
      },
      {
        "id": 8676,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8677,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8678,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8679,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8680,
        "latitude": -22.818799,
        "longitude": -43.064651
      },
      {
        "id": 8681,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8682,
        "latitude": -22.866571,
        "longitude": -43.027131
      },
      {
        "id": 8683,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8684,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8685,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8686,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8687,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8688,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8689,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8692,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8693,
        "latitude": -22.886105,
        "longitude": -42.984361
      },
      {
        "id": 8694,
        "latitude": -22.842149,
        "longitude": -43.090604
      },
      {
        "id": 8695,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8696,
        "latitude": -22.807442,
        "longitude": -43.00141
      },
      {
        "id": 8697,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8698,
        "latitude": -22.882625,
        "longitude": -43.020077
      },
      {
        "id": 8699,
        "latitude": -22.822297,
        "longitude": -43.057066
      },
      {
        "id": 8700,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8701,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8702,
        "latitude": -22.864661,
        "longitude": -43.009016
      },
      {
        "id": 8703,
        "latitude": -22.821039,
        "longitude": -42.994669
      },
      {
        "id": 8704,
        "latitude": -22.843006,
        "longitude": -43.016297
      },
      {
        "id": 8705,
        "latitude": -22.826399,
        "longitude": -43.075065
      },
      {
        "id": 8706,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8707,
        "latitude": -22.805617,
        "longitude": -42.949005
      },
      {
        "id": 8727,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8708,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8709,
        "latitude": -22.84731,
        "longitude": -43.08619
      },
      {
        "id": 8710,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8711,
        "latitude": -22.869209,
        "longitude": -43.029015
      },
      {
        "id": 8712,
        "latitude": -22.887251,
        "longitude": -42.989135
      },
      {
        "id": 8713,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8714,
        "latitude": -22.841984,
        "longitude": -43.012723
      },
      {
        "id": 8715,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8716,
        "latitude": -22.826014,
        "longitude": -43.055413
      },
      {
        "id": 8717,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8718,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8719,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8720,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8721,
        "latitude": -22.788461,
        "longitude": -42.953994
      },
      {
        "id": 8722,
        "latitude": -22.791619,
        "longitude": -42.952122
      },
      {
        "id": 8723,
        "latitude": -22.769966,
        "longitude": -43.381386
      },
      {
        "id": 8724,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8725,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8726,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8728,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8729,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8730,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8731,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8732,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8733,
        "latitude": -22.828513,
        "longitude": -42.958808
      },
      {
        "id": 8734,
        "latitude": -22.864077,
        "longitude": -43.364514
      },
      {
        "id": 8735,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8736,
        "latitude": -22.807023,
        "longitude": -43.000501
      },
      {
        "id": 8737,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8738,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8739,
        "latitude": -22.825827,
        "longitude": -43.010234
      },
      {
        "id": 8740,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8741,
        "latitude": -25.38348,
        "longitude": -49.334859
      },
      {
        "id": 8742,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8743,
        "latitude": -22.793607,
        "longitude": -43.032171
      },
      {
        "id": 8744,
        "latitude": -22.809334,
        "longitude": -43.030296
      },
      {
        "id": 8745,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8746,
        "latitude": -22.815105,
        "longitude": -43.224039
      },
      {
        "id": 8747,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8748,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8749,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8750,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8751,
        "latitude": -22.763717,
        "longitude": -43.36737
      },
      {
        "id": 8752,
        "latitude": -22.772176,
        "longitude": -42.9679
      },
      {
        "id": 8753,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8754,
        "latitude": -22.786697,
        "longitude": -43.334209
      },
      {
        "id": 8755,
        "latitude": -22.851909,
        "longitude": -42.994999
      },
      {
        "id": 8756,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8757,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 8758,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8759,
        "latitude": -22.768409,
        "longitude": -43.362572
      },
      {
        "id": 8760,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8761,
        "latitude": -22.837204,
        "longitude": -43.307324
      },
      {
        "id": 8762,
        "latitude": -22.812605,
        "longitude": -43.326936
      },
      {
        "id": 8763,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8764,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8765,
        "latitude": -22.8235,
        "longitude": -43.07542
      },
      {
        "id": 8766,
        "latitude": -22.803811,
        "longitude": -42.998281
      },
      {
        "id": 8767,
        "latitude": -22.817617,
        "longitude": -42.969509
      },
      {
        "id": 8768,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8769,
        "latitude": -22.811409,
        "longitude": -42.978711
      },
      {
        "id": 8770,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8771,
        "latitude": -22.811175,
        "longitude": -42.96229
      },
      {
        "id": 8772,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8773,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8774,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8775,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8776,
        "latitude": -22.817564,
        "longitude": -42.971552
      },
      {
        "id": 8777,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8778,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8779,
        "latitude": -22.787556,
        "longitude": -42.99619
      },
      {
        "id": 8780,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8781,
        "latitude": -22.790543,
        "longitude": -42.955754
      },
      {
        "id": 8782,
        "latitude": -22.800123,
        "longitude": -43.028159
      },
      {
        "id": 8783,
        "latitude": -22.82822,
        "longitude": -42.985558
      },
      {
        "id": 8784,
        "latitude": -22.89685,
        "longitude": -43.117849
      },
      {
        "id": 8785,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8786,
        "latitude": -22.495318,
        "longitude": -42.200625
      },
      {
        "id": 8787,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8788,
        "latitude": -22.842149,
        "longitude": -43.090604
      },
      {
        "id": 8790,
        "latitude": -21.546207,
        "longitude": -44.328358
      },
      {
        "id": 8791,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8792,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8793,
        "latitude": -22.850586,
        "longitude": -43.097749
      },
      {
        "id": 8794,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8795,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8796,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8797,
        "latitude": -22.807138,
        "longitude": -42.973073
      },
      {
        "id": 8798,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8799,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8800,
        "latitude": -22.77556,
        "longitude": -43.03194
      },
      {
        "id": 8801,
        "latitude": -22.805617,
        "longitude": -42.949005
      },
      {
        "id": 8802,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8803,
        "latitude": -22.847473,
        "longitude": -43.088263
      },
      {
        "id": 8804,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8805,
        "latitude": -22.817903,
        "longitude": -42.967252
      },
      {
        "id": 8806,
        "latitude": -22.843232,
        "longitude": -42.959742
      },
      {
        "id": 8807,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8808,
        "latitude": -22.899124,
        "longitude": -43.227195
      },
      {
        "id": 8809,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8810,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8811,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8812,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 8813,
        "latitude": -22.821984,
        "longitude": -42.986649
      },
      {
        "id": 8814,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8815,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8817,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8818,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8819,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8820,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8821,
        "latitude": -22.796344,
        "longitude": -43.018906
      },
      {
        "id": 8822,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8823,
        "latitude": -22.798842,
        "longitude": -42.981517
      },
      {
        "id": 8824,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8825,
        "latitude": -22.860905,
        "longitude": -43.089287
      },
      {
        "id": 8826,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8827,
        "latitude": -22.814925,
        "longitude": -43.062233
      },
      {
        "id": 8828,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8829,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8830,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8831,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8832,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8833,
        "latitude": -22.8235,
        "longitude": -43.07542
      },
      {
        "id": 8834,
        "latitude": -22.811561,
        "longitude": -42.980712
      },
      {
        "id": 8835,
        "latitude": -22.814813,
        "longitude": -42.978246
      },
      {
        "id": 8836,
        "latitude": -22.847473,
        "longitude": -43.088263
      },
      {
        "id": 8837,
        "latitude": -22.844598,
        "longitude": -43.018865
      },
      {
        "id": 8838,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8840,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8841,
        "latitude": -22.804034,
        "longitude": -42.957089
      },
      {
        "id": 8842,
        "latitude": -22.8312,
        "longitude": -43.042884
      },
      {
        "id": 8843,
        "latitude": -22.817903,
        "longitude": -42.967252
      },
      {
        "id": 8844,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8845,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8846,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8848,
        "latitude": -22.815287,
        "longitude": -42.966892
      },
      {
        "id": 8849,
        "latitude": -22.753262,
        "longitude": -43.379121
      },
      {
        "id": 8850,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8851,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8924,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8852,
        "latitude": -22.817903,
        "longitude": -42.967252
      },
      {
        "id": 8853,
        "latitude": -22.839485,
        "longitude": -43.313018
      },
      {
        "id": 8854,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8855,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8856,
        "latitude": -22.794501,
        "longitude": -43.008103
      },
      {
        "id": 8857,
        "latitude": -22.831733,
        "longitude": -43.275518
      },
      {
        "id": 8858,
        "latitude": -22.818128,
        "longitude": -42.994072
      },
      {
        "id": 8859,
        "latitude": -22.802121,
        "longitude": -43.004991
      },
      {
        "id": 8860,
        "latitude": -22.854254,
        "longitude": -43.087945
      },
      {
        "id": 8861,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8862,
        "latitude": -22.81686,
        "longitude": -42.985399
      },
      {
        "id": 8863,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8864,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8865,
        "latitude": -22.835469,
        "longitude": -42.996439
      },
      {
        "id": 8866,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8867,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8868,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8869,
        "latitude": -22.817056,
        "longitude": -43.347092
      },
      {
        "id": 10041,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 8871,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8872,
        "latitude": -22.796346,
        "longitude": -42.996255
      },
      {
        "id": 8873,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8874,
        "latitude": -22.842149,
        "longitude": -43.090604
      },
      {
        "id": 8875,
        "latitude": -22.852487,
        "longitude": -43.351656
      },
      {
        "id": 8876,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8877,
        "latitude": -22.815287,
        "longitude": -42.966892
      },
      {
        "id": 8878,
        "latitude": -22.845185,
        "longitude": -43.064827
      },
      {
        "id": 8879,
        "latitude": -22.852487,
        "longitude": -43.351656
      },
      {
        "id": 8880,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8881,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8882,
        "latitude": -22.790721,
        "longitude": -42.952609
      },
      {
        "id": 8883,
        "latitude": -22.815287,
        "longitude": -42.966892
      },
      {
        "id": 8884,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8885,
        "latitude": -22.815287,
        "longitude": -42.966892
      },
      {
        "id": 8886,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8887,
        "latitude": -22.823509,
        "longitude": -42.968548
      },
      {
        "id": 8888,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8889,
        "latitude": -22.807766,
        "longitude": -43.036768
      },
      {
        "id": 8890,
        "latitude": -22.79248,
        "longitude": -42.998917
      },
      {
        "id": 8891,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8892,
        "latitude": -22.821094,
        "longitude": -42.992044
      },
      {
        "id": 8893,
        "latitude": -22.825087,
        "longitude": -43.020315
      },
      {
        "id": 8894,
        "latitude": -22.890684,
        "longitude": -43.310886
      },
      {
        "id": 8895,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 8896,
        "latitude": -22.815287,
        "longitude": -42.966892
      },
      {
        "id": 8897,
        "latitude": -22.810882,
        "longitude": -43.017766
      },
      {
        "id": 8898,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 8899,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8900,
        "latitude": -22.961616,
        "longitude": -43.222372
      },
      {
        "id": 8901,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8902,
        "latitude": -29.830631,
        "longitude": -50.058054
      },
      {
        "id": 8903,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8904,
        "latitude": -22.828281,
        "longitude": -42.985564
      },
      {
        "id": 8905,
        "latitude": -22.833613,
        "longitude": -43.0601
      },
      {
        "id": 8906,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8907,
        "latitude": -22.794389,
        "longitude": -43.004752
      },
      {
        "id": 8908,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8909,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8910,
        "latitude": -22.882625,
        "longitude": -43.020077
      },
      {
        "id": 8911,
        "latitude": -22.817903,
        "longitude": -42.967252
      },
      {
        "id": 8912,
        "latitude": -22.772462,
        "longitude": -42.963735
      },
      {
        "id": 8913,
        "latitude": -22.826926,
        "longitude": -43.03688
      },
      {
        "id": 8914,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8915,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8916,
        "latitude": -29.996785,
        "longitude": -51.042991
      },
      {
        "id": 8918,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8919,
        "latitude": -22.882625,
        "longitude": -43.020077
      },
      {
        "id": 8920,
        "latitude": -22.862325,
        "longitude": -43.00216
      },
      {
        "id": 8921,
        "latitude": -22.817771,
        "longitude": -43.018804
      },
      {
        "id": 8922,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 8923,
        "latitude": -22.803478,
        "longitude": -43.38831
      },
      {
        "id": 8925,
        "latitude": -22.794306,
        "longitude": -43.341056
      },
      {
        "id": 8926,
        "latitude": -22.852926,
        "longitude": -43.31341
      },
      {
        "id": 8927,
        "latitude": -22.748492,
        "longitude": -43.516281
      },
      {
        "id": 8928,
        "latitude": -22.779395,
        "longitude": -43.392841
      },
      {
        "id": 8929,
        "latitude": -22.809422,
        "longitude": -43.385906
      },
      {
        "id": 8930,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8931,
        "latitude": -22.705995,
        "longitude": -43.443831
      },
      {
        "id": 8932,
        "latitude": -22.794736,
        "longitude": -43.391226
      },
      {
        "id": 8933,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8934,
        "latitude": -22.914157,
        "longitude": -43.281152
      },
      {
        "id": 8935,
        "latitude": -22.790959,
        "longitude": -43.380157
      },
      {
        "id": 8936,
        "latitude": -22.972876,
        "longitude": -43.332231
      },
      {
        "id": 8937,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8938,
        "latitude": -22.937226,
        "longitude": -43.175858
      },
      {
        "id": 8939,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8940,
        "latitude": -22.803369,
        "longitude": -43.364077
      },
      {
        "id": 8941,
        "latitude": -22.849996,
        "longitude": -42.238024
      },
      {
        "id": 8942,
        "latitude": -22.738437,
        "longitude": -47.32808
      },
      {
        "id": 8943,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8944,
        "latitude": -22.77805,
        "longitude": -43.381738
      },
      {
        "id": 8945,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8946,
        "latitude": -22.796745,
        "longitude": -43.398174
      },
      {
        "id": 8947,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 8948,
        "latitude": -22.848178,
        "longitude": -42.062868
      },
      {
        "id": 8949,
        "latitude": -22.832428,
        "longitude": -42.097179
      },
      {
        "id": 8950,
        "latitude": -22.850388,
        "longitude": -42.101584
      },
      {
        "id": 8951,
        "latitude": -15.810398,
        "longitude": -48.06324
      },
      {
        "id": 8952,
        "latitude": -22.848037,
        "longitude": -42.035437
      },
      {
        "id": 8953,
        "latitude": -22.813868,
        "longitude": -42.100808
      },
      {
        "id": 8955,
        "latitude": -22.921065,
        "longitude": -42.48219
      },
      {
        "id": 8956,
        "latitude": -22.882146,
        "longitude": -42.466427
      },
      {
        "id": 8957,
        "latitude": -22.91667,
        "longitude": -42.63333
      },
      {
        "id": 8958,
        "latitude": -22.921065,
        "longitude": -42.48219
      },
      {
        "id": 8959,
        "latitude": -22.894836,
        "longitude": -42.433862
      },
      {
        "id": 8960,
        "latitude": -22.91667,
        "longitude": -42.63333
      },
      {
        "id": 10077,
        "latitude": -29.924102,
        "longitude": -51.03558
      },
      {
        "id": 8961,
        "latitude": -22.77353,
        "longitude": -43.665527
      },
      {
        "id": 8962,
        "latitude": -22.763175,
        "longitude": -43.001128
      },
      {
        "id": 8963,
        "latitude": -22.763175,
        "longitude": -43.001128
      },
      {
        "id": 8964,
        "latitude": -22.77353,
        "longitude": -43.665527
      },
      {
        "id": 8965,
        "latitude": -22.763175,
        "longitude": -43.001128
      },
      {
        "id": 8966,
        "latitude": -22.77353,
        "longitude": -43.665527
      },
      {
        "id": 8967,
        "latitude": -22.77353,
        "longitude": -43.665527
      },
      {
        "id": 8968,
        "latitude": -22.763175,
        "longitude": -43.001128
      },
      {
        "id": 8969,
        "latitude": -21.775573,
        "longitude": -41.290209
      },
      {
        "id": 8970,
        "latitude": -22.77353,
        "longitude": -43.665527
      },
      {
        "id": 8971,
        "latitude": -22.889063,
        "longitude": -43.373657
      },
      {
        "id": 8972,
        "latitude": -22.631557,
        "longitude": -42.40821
      },
      {
        "id": 8973,
        "latitude": -22.731371,
        "longitude": -42.715222
      },
      {
        "id": 8974,
        "latitude": -22.728626,
        "longitude": -42.711995
      },
      {
        "id": 8975,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8976,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8977,
        "latitude": -22.713204,
        "longitude": -42.916743
      },
      {
        "id": 8978,
        "latitude": -22.73333,
        "longitude": -42.55
      },
      {
        "id": 8980,
        "latitude": -22.73333,
        "longitude": -42.8
      },
      {
        "id": 8981,
        "latitude": -22.73333,
        "longitude": -42.8
      },
      {
        "id": 8982,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8983,
        "latitude": -22.72856,
        "longitude": -42.713709
      },
      {
        "id": 8984,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8985,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8986,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8987,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8988,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8989,
        "latitude": -22.734056,
        "longitude": -42.718402
      },
      {
        "id": 8991,
        "latitude": -22.407885,
        "longitude": -42.994037
      },
      {
        "id": 8992,
        "latitude": -22.434326,
        "longitude": -42.947041
      },
      {
        "id": 8993,
        "latitude": -22.402964,
        "longitude": -42.955642
      },
      {
        "id": 8995,
        "latitude": -22.402964,
        "longitude": -42.955642
      },
      {
        "id": 8996,
        "latitude": -22.107627,
        "longitude": -43.192007
      },
      {
        "id": 8997,
        "latitude": -22.103649,
        "longitude": -43.183034
      },
      {
        "id": 8998,
        "latitude": -22.113064,
        "longitude": -43.210856
      },
      {
        "id": 10363,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 8999,
        "latitude": -21.582319,
        "longitude": -42.142529
      },
      {
        "id": 9000,
        "latitude": -22.108289,
        "longitude": -43.193106
      },
      {
        "id": 9001,
        "latitude": -22.103649,
        "longitude": -43.183034
      },
      {
        "id": 9002,
        "latitude": -22.239663,
        "longitude": -43.70162
      },
      {
        "id": 9003,
        "latitude": -22.239663,
        "longitude": -43.70162
      },
      {
        "id": 9005,
        "latitude": -22.523605,
        "longitude": -44.090279
      },
      {
        "id": 9006,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9007,
        "latitude": -22.528124,
        "longitude": -44.138998
      },
      {
        "id": 9008,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9009,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9010,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9011,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9012,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9013,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9014,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9015,
        "latitude": -22.490487,
        "longitude": -44.098374
      },
      {
        "id": 9016,
        "latitude": -22.532812,
        "longitude": -44.127915
      },
      {
        "id": 9017,
        "latitude": -5.582688,
        "longitude": -36.91995
      },
      {
        "id": 9018,
        "latitude": -5.582688,
        "longitude": -36.91995
      },
      {
        "id": 9019,
        "latitude": -5.582688,
        "longitude": -36.91995
      },
      {
        "id": 9020,
        "latitude": -5.583176,
        "longitude": -36.910646
      },
      {
        "id": 9022,
        "latitude": -5.596081,
        "longitude": -36.908563
      },
      {
        "id": 9023,
        "latitude": -5.582688,
        "longitude": -36.91995
      },
      {
        "id": 9024,
        "latitude": -5.596081,
        "longitude": -36.908563
      },
      {
        "id": 9025,
        "latitude": -5.49991,
        "longitude": -36.505876
      },
      {
        "id": 9027,
        "latitude": -5.191263,
        "longitude": -37.345701
      },
      {
        "id": 9028,
        "latitude": -5.043515,
        "longitude": -37.420667
      },
      {
        "id": 9029,
        "latitude": -5.9867,
        "longitude": -35.584322
      },
      {
        "id": 9030,
        "latitude": -6.243447,
        "longitude": -36.183002
      },
      {
        "id": 9031,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 9032,
        "latitude": -6.723174,
        "longitude": -37.200899
      },
      {
        "id": 9033,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 9034,
        "latitude": -7.202056,
        "longitude": -34.882124
      },
      {
        "id": 9035,
        "latitude": -6.077851,
        "longitude": -35.234921
      },
      {
        "id": 9036,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 9037,
        "latitude": -6.077851,
        "longitude": -35.234921
      },
      {
        "id": 9038,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 9039,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 9040,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 9041,
        "latitude": -6.077851,
        "longitude": -35.234921
      },
      {
        "id": 9043,
        "latitude": -27.81973,
        "longitude": -54.666788
      },
      {
        "id": 9044,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9045,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9046,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9047,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9048,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9049,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9050,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9051,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 10490,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 9052,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9053,
        "latitude": -22.78827,
        "longitude": -42.53696
      },
      {
        "id": 9054,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9055,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9056,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9057,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9058,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9059,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9060,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9061,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9062,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9063,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9064,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9065,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9066,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9067,
        "latitude": -23.5369,
        "longitude": -46.477737
      },
      {
        "id": 9068,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9069,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9070,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9071,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9072,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9073,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9074,
        "latitude": -5.170761,
        "longitude": -36.974452
      },
      {
        "id": 9075,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9076,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 9077,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9078,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9079,
        "latitude": -5.64416,
        "longitude": -35.437719
      },
      {
        "id": 9080,
        "latitude": -5.775823,
        "longitude": -35.282341
      },
      {
        "id": 9081,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9082,
        "latitude": -5.69017,
        "longitude": -35.461963
      },
      {
        "id": 9083,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9084,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9085,
        "latitude": -24.523136,
        "longitude": -48.105927
      },
      {
        "id": 9086,
        "latitude": -5.807641,
        "longitude": -35.355734
      },
      {
        "id": 9087,
        "latitude": -5.721089,
        "longitude": -35.243459
      },
      {
        "id": 9088,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9090,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9091,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9093,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9095,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9097,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9098,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9099,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9100,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9101,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9102,
        "latitude": -5.659752,
        "longitude": -35.425111
      },
      {
        "id": 9103,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9104,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9105,
        "latitude": -5.785299,
        "longitude": -35.326101
      },
      {
        "id": 9106,
        "latitude": -5.650421,
        "longitude": -35.456806
      },
      {
        "id": 9107,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9108,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9109,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9110,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9111,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9112,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9113,
        "latitude": -5.745186,
        "longitude": -35.298017
      },
      {
        "id": 9114,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9115,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9116,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9117,
        "latitude": -5.745186,
        "longitude": -35.298017
      },
      {
        "id": 9118,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9119,
        "latitude": -5.777151,
        "longitude": -35.279237
      },
      {
        "id": 9120,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9121,
        "latitude": -5.69017,
        "longitude": -35.461963
      },
      {
        "id": 9122,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9123,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9124,
        "latitude": -5.746587,
        "longitude": -35.254724
      },
      {
        "id": 9125,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9126,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9127,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9128,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9129,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9130,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9131,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9133,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9134,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9135,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9136,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9137,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9138,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9139,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9140,
        "latitude": -5.655699,
        "longitude": -35.421473
      },
      {
        "id": 9141,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9142,
        "latitude": -5.767313,
        "longitude": -35.280484
      },
      {
        "id": 9143,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9144,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9145,
        "latitude": -5.644807,
        "longitude": -35.43549
      },
      {
        "id": 9146,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9147,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9148,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9149,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9150,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9151,
        "latitude": -5.69017,
        "longitude": -35.461963
      },
      {
        "id": 9152,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9153,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9154,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9155,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9156,
        "latitude": -5.647781,
        "longitude": -35.434948
      },
      {
        "id": 9157,
        "latitude": -5.64486,
        "longitude": -35.435272
      },
      {
        "id": 9158,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 10683,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 9159,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9160,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9162,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9163,
        "latitude": -6.236805,
        "longitude": -36.009786
      },
      {
        "id": 9164,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9165,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9166,
        "latitude": -5.776054,
        "longitude": -35.278743
      },
      {
        "id": 9167,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9168,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9169,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9170,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9171,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9172,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9173,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9174,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9175,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9176,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9177,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9178,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9179,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9180,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9181,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9182,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9183,
        "latitude": -5.688289,
        "longitude": -35.211322
      },
      {
        "id": 9184,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9185,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9186,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9187,
        "latitude": -5.787553,
        "longitude": -35.306729
      },
      {
        "id": 9188,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9189,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9190,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9191,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9192,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9193,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 11092,
        "latitude": -8.749453,
        "longitude": -63.873544
      },
      {
        "id": 9194,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9195,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9196,
        "latitude": -5.751221,
        "longitude": -35.242445
      },
      {
        "id": 9197,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9198,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9199,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9200,
        "latitude": -5.7536,
        "longitude": -35.232082
      },
      {
        "id": 9201,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9202,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9203,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9204,
        "latitude": -29.498585,
        "longitude": -52.056323
      },
      {
        "id": 9205,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9206,
        "latitude": -32.063063,
        "longitude": -52.087104
      },
      {
        "id": 9207,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9208,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9209,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9210,
        "latitude": -5.659152,
        "longitude": -35.222325
      },
      {
        "id": 9211,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9212,
        "latitude": -5.713769,
        "longitude": -35.36877
      },
      {
        "id": 9213,
        "latitude": -20.095288,
        "longitude": -51.111899
      },
      {
        "id": 9214,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9215,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9216,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9217,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9218,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9219,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9220,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9221,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9222,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9223,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9224,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9225,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9226,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9227,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 11093,
        "latitude": -8.749453,
        "longitude": -63.873544
      },
      {
        "id": 9228,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9229,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9230,
        "latitude": -22.740295,
        "longitude": -43.432188
      },
      {
        "id": 9231,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9232,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9233,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9234,
        "latitude": -5.643213,
        "longitude": -35.445341
      },
      {
        "id": 9235,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9236,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9237,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9238,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9239,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9240,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9241,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9242,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9243,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9244,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9245,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9246,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9247,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9248,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9249,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9250,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9251,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9252,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9253,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9254,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9255,
        "latitude": -5.647594,
        "longitude": -35.297867
      },
      {
        "id": 9256,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9257,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9258,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9259,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9260,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9261,
        "latitude": -5.745626,
        "longitude": -35.310559
      },
      {
        "id": 9262,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9263,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9264,
        "latitude": -5.714085,
        "longitude": -35.366242
      },
      {
        "id": 9265,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9266,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9267,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9268,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9269,
        "latitude": -5.793671,
        "longitude": -35.329876
      },
      {
        "id": 9270,
        "latitude": -5.6515,
        "longitude": -35.432964
      },
      {
        "id": 9271,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9272,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9273,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9274,
        "latitude": -5.712973,
        "longitude": -35.367105
      },
      {
        "id": 9275,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9276,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9277,
        "latitude": -5.599512,
        "longitude": -35.319516
      },
      {
        "id": 9278,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9279,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9280,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9281,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9282,
        "latitude": -5.65518,
        "longitude": -35.45822
      },
      {
        "id": 9283,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9284,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9285,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9286,
        "latitude": -5.81594,
        "longitude": -35.447452
      },
      {
        "id": 9287,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9288,
        "latitude": -5.778202,
        "longitude": -35.252846
      },
      {
        "id": 9289,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9290,
        "latitude": -5.706501,
        "longitude": -35.304888
      },
      {
        "id": 9291,
        "latitude": -5.654856,
        "longitude": -35.457266
      },
      {
        "id": 9292,
        "latitude": -5.636861,
        "longitude": -35.426158
      },
      {
        "id": 9293,
        "latitude": -5.63977,
        "longitude": -35.427915
      },
      {
        "id": 9294,
        "latitude": -5.64486,
        "longitude": -35.435272
      },
      {
        "id": 9295,
        "latitude": -5.642132,
        "longitude": -35.431808
      },
      {
        "id": 9296,
        "latitude": -5.664709,
        "longitude": -35.274642
      },
      {
        "id": 9297,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9298,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9299,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9300,
        "latitude": -5.64486,
        "longitude": -35.435272
      },
      {
        "id": 9301,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9302,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9303,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9304,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9305,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9306,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9307,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9308,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9309,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9310,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9311,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9312,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9313,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9314,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9315,
        "latitude": -5.712973,
        "longitude": -35.367105
      },
      {
        "id": 9387,
        "latitude": -6.269126,
        "longitude": -35.21241
      },
      {
        "id": 9316,
        "latitude": -5.774997,
        "longitude": -35.279171
      },
      {
        "id": 9317,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9318,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9319,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9320,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9321,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9322,
        "latitude": -6.236805,
        "longitude": -36.009786
      },
      {
        "id": 9323,
        "latitude": -5.69017,
        "longitude": -35.461963
      },
      {
        "id": 9324,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9325,
        "latitude": -5.712973,
        "longitude": -35.367105
      },
      {
        "id": 9326,
        "latitude": -5.736117,
        "longitude": -35.365812
      },
      {
        "id": 9327,
        "latitude": -5.636874,
        "longitude": -35.433353
      },
      {
        "id": 9328,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9329,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9330,
        "latitude": -5.644866,
        "longitude": -35.434883
      },
      {
        "id": 9331,
        "latitude": -5.712973,
        "longitude": -35.367105
      },
      {
        "id": 9332,
        "latitude": -5.715587,
        "longitude": -35.36508
      },
      {
        "id": 9333,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9334,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9335,
        "latitude": -5.749751,
        "longitude": -35.296262
      },
      {
        "id": 9336,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9337,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9338,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9339,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9340,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9341,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9342,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 9343,
        "latitude": -6.256147,
        "longitude": -36.52587
      },
      {
        "id": 9344,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9345,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9346,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9347,
        "latitude": -6.02482,
        "longitude": -37.987805
      },
      {
        "id": 9348,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9349,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9350,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9351,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9352,
        "latitude": -6.263936,
        "longitude": -36.540787
      },
      {
        "id": 9353,
        "latitude": -5.67536,
        "longitude": -35.287413
      },
      {
        "id": 9354,
        "latitude": -6.223638,
        "longitude": -35.129111
      },
      {
        "id": 9355,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9357,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9358,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9360,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9362,
        "latitude": -5.683914,
        "longitude": -35.282741
      },
      {
        "id": 9363,
        "latitude": -5.669479,
        "longitude": -35.288582
      },
      {
        "id": 9364,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9365,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9366,
        "latitude": -5.700344,
        "longitude": -35.311418
      },
      {
        "id": 9367,
        "latitude": -5.765061,
        "longitude": -35.254547
      },
      {
        "id": 9368,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9369,
        "latitude": -5.726098,
        "longitude": -35.203772
      },
      {
        "id": 9370,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9371,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9372,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9373,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9374,
        "latitude": -5.327506,
        "longitude": -49.094296
      },
      {
        "id": 9375,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9376,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9377,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9378,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 9379,
        "latitude": -5.598392,
        "longitude": -37.697187
      },
      {
        "id": 9380,
        "latitude": -6.12158,
        "longitude": -36.816088
      },
      {
        "id": 9381,
        "latitude": -6.269126,
        "longitude": -35.21241
      },
      {
        "id": 9382,
        "latitude": -6.275943,
        "longitude": -35.213917
      },
      {
        "id": 9383,
        "latitude": -6.269126,
        "longitude": -35.21241
      },
      {
        "id": 9384,
        "latitude": -6.269126,
        "longitude": -35.21241
      },
      {
        "id": 9385,
        "latitude": -6.269126,
        "longitude": -35.21241
      },
      {
        "id": 9386,
        "latitude": -6.234445,
        "longitude": -35.155222
      },
      {
        "id": 9388,
        "latitude": -6.022,
        "longitude": -35.246928
      },
      {
        "id": 9389,
        "latitude": -5.509806,
        "longitude": -36.856602
      },
      {
        "id": 9390,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9391,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9392,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9393,
        "latitude": -5.297395,
        "longitude": -36.760164
      },
      {
        "id": 9394,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9395,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9396,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9397,
        "latitude": -5.505612,
        "longitude": -36.859499
      },
      {
        "id": 9398,
        "latitude": -5.297395,
        "longitude": -36.760164
      },
      {
        "id": 9399,
        "latitude": -5.639568,
        "longitude": -36.869135
      },
      {
        "id": 9400,
        "latitude": -5.639568,
        "longitude": -36.869135
      },
      {
        "id": 9401,
        "latitude": -5.792932,
        "longitude": -36.88499
      },
      {
        "id": 9402,
        "latitude": -5.806946,
        "longitude": -36.887087
      },
      {
        "id": 9403,
        "latitude": -6.017432,
        "longitude": -37.406271
      },
      {
        "id": 9404,
        "latitude": -6.017432,
        "longitude": -37.406271
      },
      {
        "id": 9405,
        "latitude": -6.017432,
        "longitude": -37.406271
      },
      {
        "id": 9406,
        "latitude": -6.017432,
        "longitude": -37.406271
      },
      {
        "id": 9407,
        "latitude": -6.017432,
        "longitude": -37.406271
      },
      {
        "id": 9408,
        "latitude": -6.21197,
        "longitude": -37.351904
      },
      {
        "id": 9409,
        "latitude": -6.586316,
        "longitude": -36.77076
      },
      {
        "id": 9410,
        "latitude": -6.586316,
        "longitude": -36.77076
      },
      {
        "id": 9411,
        "latitude": -5.536122,
        "longitude": -35.817684
      },
      {
        "id": 9412,
        "latitude": -5.536122,
        "longitude": -35.817684
      },
      {
        "id": 9414,
        "latitude": -5.536122,
        "longitude": -35.817684
      },
      {
        "id": 9415,
        "latitude": -5.536122,
        "longitude": -35.817684
      },
      {
        "id": 9416,
        "latitude": -5.536122,
        "longitude": -35.817684
      },
      {
        "id": 9417,
        "latitude": -6.096332,
        "longitude": -36.471913
      },
      {
        "id": 9418,
        "latitude": -6.150511,
        "longitude": -36.11718
      },
      {
        "id": 9419,
        "latitude": -5.946432,
        "longitude": -35.302625
      },
      {
        "id": 9421,
        "latitude": -5.864047,
        "longitude": -35.343786
      },
      {
        "id": 9422,
        "latitude": -5.864047,
        "longitude": -35.343786
      },
      {
        "id": 9424,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9426,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9427,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9428,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9429,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9430,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9431,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9432,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9433,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9434,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9435,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9436,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9437,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9438,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9439,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9440,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9441,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9442,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9446,
        "latitude": -5.210017,
        "longitude": -37.324284
      },
      {
        "id": 9447,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9448,
        "latitude": -5.192143,
        "longitude": -37.340308
      },
      {
        "id": 9449,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9450,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9451,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9452,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9453,
        "latitude": -5.242222,
        "longitude": -37.319092
      },
      {
        "id": 9454,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9455,
        "latitude": -5.242554,
        "longitude": -37.31926
      },
      {
        "id": 9456,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 9457,
        "latitude": -5.15949,
        "longitude": -37.341646
      },
      {
        "id": 9459,
        "latitude": -5.803737,
        "longitude": -35.218408
      },
      {
        "id": 9460,
        "latitude": -5.227802,
        "longitude": -35.842308
      },
      {
        "id": 9461,
        "latitude": -5.227802,
        "longitude": -35.842308
      },
      {
        "id": 9724,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9464,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9465,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9467,
        "latitude": -5.928471,
        "longitude": -35.26833
      },
      {
        "id": 9468,
        "latitude": -9.936643,
        "longitude": -67.815552
      },
      {
        "id": 9469,
        "latitude": -5.908102,
        "longitude": -35.285113
      },
      {
        "id": 9470,
        "latitude": -5.897148,
        "longitude": -35.263177
      },
      {
        "id": 9471,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9472,
        "latitude": -29.704936,
        "longitude": -53.755989
      },
      {
        "id": 9473,
        "latitude": -5.941296,
        "longitude": -35.258566
      },
      {
        "id": 9475,
        "latitude": -5.930322,
        "longitude": -35.241405
      },
      {
        "id": 9476,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9477,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9478,
        "latitude": -5.92345,
        "longitude": -35.250538
      },
      {
        "id": 9742,
        "latitude": -29.941792,
        "longitude": -51.181774
      },
      {
        "id": 9479,
        "latitude": -5.940727,
        "longitude": -35.285439
      },
      {
        "id": 9480,
        "latitude": -5.879179,
        "longitude": -35.207869
      },
      {
        "id": 9481,
        "latitude": -5.926416,
        "longitude": -35.289483
      },
      {
        "id": 9482,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9483,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9484,
        "latitude": -5.93858,
        "longitude": -35.248155
      },
      {
        "id": 9485,
        "latitude": -5.931081,
        "longitude": -35.286355
      },
      {
        "id": 9486,
        "latitude": -5.8203,
        "longitude": -35.207222
      },
      {
        "id": 9487,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9488,
        "latitude": -5.922358,
        "longitude": -35.374768
      },
      {
        "id": 9489,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9490,
        "latitude": -5.896672,
        "longitude": -35.205099
      },
      {
        "id": 9491,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9492,
        "latitude": -18.597233,
        "longitude": -45.34284
      },
      {
        "id": 9493,
        "latitude": -29.625297,
        "longitude": -50.957433
      },
      {
        "id": 9494,
        "latitude": -6.012112,
        "longitude": -35.260583
      },
      {
        "id": 9495,
        "latitude": -6.035022,
        "longitude": -35.345162
      },
      {
        "id": 9496,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 9497,
        "latitude": -29.704936,
        "longitude": -53.755989
      },
      {
        "id": 9498,
        "latitude": -5.946439,
        "longitude": -35.293379
      },
      {
        "id": 9499,
        "latitude": -5.270933,
        "longitude": -35.383738
      },
      {
        "id": 9500,
        "latitude": -5.32306,
        "longitude": -36.66361
      },
      {
        "id": 9501,
        "latitude": -6.769278,
        "longitude": -36.732499
      },
      {
        "id": 9502,
        "latitude": -6.771904,
        "longitude": -36.733298
      },
      {
        "id": 9503,
        "latitude": -6.348868,
        "longitude": -35.374275
      },
      {
        "id": 9504,
        "latitude": -5.78156,
        "longitude": -35.299463
      },
      {
        "id": 9505,
        "latitude": -5.763331,
        "longitude": -35.317315
      },
      {
        "id": 9506,
        "latitude": -5.763331,
        "longitude": -35.317315
      },
      {
        "id": 9507,
        "latitude": -5.763331,
        "longitude": -35.317315
      },
      {
        "id": 9508,
        "latitude": -5.84509,
        "longitude": -35.242377
      },
      {
        "id": 9509,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 9510,
        "latitude": -5.967106,
        "longitude": -35.281999
      },
      {
        "id": 9511,
        "latitude": -5.969147,
        "longitude": -35.281924
      },
      {
        "id": 9512,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 9513,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 9514,
        "latitude": -6.038138,
        "longitude": -35.341575
      },
      {
        "id": 9515,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 9516,
        "latitude": -6.051339,
        "longitude": -35.255192
      },
      {
        "id": 9517,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 9518,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 9519,
        "latitude": -5.975022,
        "longitude": -35.285023
      },
      {
        "id": 9520,
        "latitude": -21.728705,
        "longitude": -41.300231
      },
      {
        "id": 9521,
        "latitude": -6.195023,
        "longitude": -35.799132
      },
      {
        "id": 9522,
        "latitude": -6.233813,
        "longitude": -36.031791
      },
      {
        "id": 9523,
        "latitude": -5.951733,
        "longitude": -35.927524
      },
      {
        "id": 9524,
        "latitude": -6.390589,
        "longitude": -35.595851
      },
      {
        "id": 9525,
        "latitude": -6.195023,
        "longitude": -35.799132
      },
      {
        "id": 9526,
        "latitude": -6.147513,
        "longitude": -36.719624
      },
      {
        "id": 9527,
        "latitude": -5.192253,
        "longitude": -35.466841
      },
      {
        "id": 9528,
        "latitude": -5.866731,
        "longitude": -37.185415
      },
      {
        "id": 9529,
        "latitude": -5.642176,
        "longitude": -37.262363
      },
      {
        "id": 9530,
        "latitude": -29.781589,
        "longitude": -55.788905
      },
      {
        "id": 9531,
        "latitude": -29.995818,
        "longitude": -51.049686
      },
      {
        "id": 9532,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9533,
        "latitude": -29.998118,
        "longitude": -51.077251
      },
      {
        "id": 9534,
        "latitude": -30.011932,
        "longitude": -51.059665
      },
      {
        "id": 9535,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9536,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9537,
        "latitude": -29.997889,
        "longitude": -51.037882
      },
      {
        "id": 9540,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9541,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9542,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9543,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9544,
        "latitude": -29.996278,
        "longitude": -51.049461
      },
      {
        "id": 9545,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9546,
        "latitude": -30.001429,
        "longitude": -51.066657
      },
      {
        "id": 9794,
        "latitude": -29.668016,
        "longitude": -51.154114
      },
      {
        "id": 9547,
        "latitude": -30.032112,
        "longitude": -51.079132
      },
      {
        "id": 9548,
        "latitude": -29.998118,
        "longitude": -51.077251
      },
      {
        "id": 9549,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9550,
        "latitude": -22.537973,
        "longitude": -54.47229
      },
      {
        "id": 9551,
        "latitude": -29.741389,
        "longitude": -51.151975
      },
      {
        "id": 9552,
        "latitude": -29.981369,
        "longitude": -51.136113
      },
      {
        "id": 9553,
        "latitude": -29.990472,
        "longitude": -51.064686
      },
      {
        "id": 9554,
        "latitude": -28.377805,
        "longitude": -52.25569
      },
      {
        "id": 9555,
        "latitude": -28.377805,
        "longitude": -52.25569
      },
      {
        "id": 9556,
        "latitude": -30.069089,
        "longitude": -51.222866
      },
      {
        "id": 9557,
        "latitude": -30.010749,
        "longitude": -51.04735
      },
      {
        "id": 9558,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9559,
        "latitude": -30.001429,
        "longitude": -51.066657
      },
      {
        "id": 9560,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9561,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9562,
        "latitude": -30.065088,
        "longitude": -51.15396
      },
      {
        "id": 9563,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9599,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9564,
        "latitude": -30.031653,
        "longitude": -51.072103
      },
      {
        "id": 9565,
        "latitude": -30.040194,
        "longitude": -51.078903
      },
      {
        "id": 9566,
        "latitude": -30.040194,
        "longitude": -51.078903
      },
      {
        "id": 9567,
        "latitude": -30.036059,
        "longitude": -51.078605
      },
      {
        "id": 9568,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9569,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9570,
        "latitude": -28.66667,
        "longitude": -52.15
      },
      {
        "id": 9571,
        "latitude": -30.031197,
        "longitude": -51.07701
      },
      {
        "id": 9572,
        "latitude": -29.99687,
        "longitude": -51.049276
      },
      {
        "id": 9573,
        "latitude": -29.996304,
        "longitude": -51.048536
      },
      {
        "id": 9574,
        "latitude": -30.034771,
        "longitude": -51.077929
      },
      {
        "id": 9575,
        "latitude": -30.010053,
        "longitude": -51.155536
      },
      {
        "id": 9576,
        "latitude": -28.66667,
        "longitude": -52.15
      },
      {
        "id": 9577,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 9578,
        "latitude": -28.377805,
        "longitude": -52.25569
      },
      {
        "id": 9579,
        "latitude": -30.155311,
        "longitude": -51.146866
      },
      {
        "id": 9580,
        "latitude": -29.679039,
        "longitude": -51.118163
      },
      {
        "id": 9581,
        "latitude": -29.380417,
        "longitude": -51.968248
      },
      {
        "id": 9582,
        "latitude": -30.083145,
        "longitude": -51.736032
      },
      {
        "id": 9583,
        "latitude": -6.623825,
        "longitude": -39.09588
      },
      {
        "id": 9584,
        "latitude": -30.248251,
        "longitude": -50.254083
      },
      {
        "id": 9585,
        "latitude": -30.289828,
        "longitude": -51.301702
      },
      {
        "id": 9586,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9588,
        "latitude": -29.188538,
        "longitude": -51.495636
      },
      {
        "id": 9589,
        "latitude": -29.188538,
        "longitude": -51.495636
      },
      {
        "id": 9590,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9591,
        "latitude": -29.139281,
        "longitude": -51.518359
      },
      {
        "id": 9592,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9593,
        "latitude": -7.934747,
        "longitude": -46.653025
      },
      {
        "id": 9594,
        "latitude": -29.179175,
        "longitude": -51.489877
      },
      {
        "id": 9595,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9596,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9597,
        "latitude": -29.189759,
        "longitude": -51.509742
      },
      {
        "id": 9598,
        "latitude": -30.860052,
        "longitude": -51.780771
      },
      {
        "id": 9600,
        "latitude": -29.136563,
        "longitude": -51.519502
      },
      {
        "id": 9601,
        "latitude": -23.748954,
        "longitude": -46.677909
      },
      {
        "id": 9602,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9603,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9604,
        "latitude": -29.173167,
        "longitude": -51.508196
      },
      {
        "id": 9605,
        "latitude": -29.598429,
        "longitude": -51.930077
      },
      {
        "id": 9606,
        "latitude": -29.598429,
        "longitude": -51.930077
      },
      {
        "id": 9607,
        "latitude": -29.884204,
        "longitude": -54.829832
      },
      {
        "id": 9608,
        "latitude": -29.887358,
        "longitude": -51.087259
      },
      {
        "id": 9609,
        "latitude": -29.886784,
        "longitude": -50.775941
      },
      {
        "id": 9611,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9612,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9615,
        "latitude": -30.01104,
        "longitude": -51.173884
      },
      {
        "id": 9616,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9618,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9619,
        "latitude": -29.955027,
        "longitude": -51.104339
      },
      {
        "id": 9620,
        "latitude": -29.919915,
        "longitude": -51.071168
      },
      {
        "id": 9621,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9622,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9623,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9624,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9625,
        "latitude": -29.986651,
        "longitude": -51.115239
      },
      {
        "id": 9626,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9628,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9629,
        "latitude": -12.861127,
        "longitude": -38.435907
      },
      {
        "id": 9630,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9631,
        "latitude": -29.932445,
        "longitude": -51.102224
      },
      {
        "id": 9632,
        "latitude": -29.888088,
        "longitude": -51.086104
      },
      {
        "id": 9633,
        "latitude": -29.888088,
        "longitude": -51.086104
      },
      {
        "id": 9635,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9636,
        "latitude": -29.888088,
        "longitude": -51.086104
      },
      {
        "id": 9637,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9638,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9639,
        "latitude": -29.750266,
        "longitude": -51.090379
      },
      {
        "id": 9640,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9641,
        "latitude": -29.668944,
        "longitude": -51.215438
      },
      {
        "id": 9642,
        "latitude": -29.906667,
        "longitude": -51.085947
      },
      {
        "id": 9643,
        "latitude": -8.189607,
        "longitude": -37.980137
      },
      {
        "id": 9644,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9645,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9646,
        "latitude": -5.750759,
        "longitude": -35.238131
      },
      {
        "id": 9647,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9648,
        "latitude": -29.750926,
        "longitude": -50.869856
      },
      {
        "id": 9649,
        "latitude": -20.51483,
        "longitude": -54.610109
      },
      {
        "id": 9651,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9652,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9653,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9654,
        "latitude": -29.906624,
        "longitude": -51.095097
      },
      {
        "id": 9656,
        "latitude": -29.760877,
        "longitude": -51.090779
      },
      {
        "id": 9657,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9658,
        "latitude": -29.888088,
        "longitude": -51.086104
      },
      {
        "id": 9659,
        "latitude": -18.522582,
        "longitude": -43.02216
      },
      {
        "id": 9660,
        "latitude": -30.176619,
        "longitude": -51.190882
      },
      {
        "id": 9661,
        "latitude": -23.019478,
        "longitude": -47.455403
      },
      {
        "id": 9663,
        "latitude": -29.789548,
        "longitude": -50.911553
      },
      {
        "id": 9664,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9665,
        "latitude": -29.906667,
        "longitude": -51.085947
      },
      {
        "id": 9666,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9667,
        "latitude": -29.925181,
        "longitude": -51.082321
      },
      {
        "id": 9668,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9669,
        "latitude": -29.771883,
        "longitude": -50.871923
      },
      {
        "id": 9670,
        "latitude": -29.949271,
        "longitude": -51.096458
      },
      {
        "id": 9671,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9672,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9673,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9674,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9675,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9676,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 9677,
        "latitude": -30.014959,
        "longitude": -51.181264
      },
      {
        "id": 9678,
        "latitude": -30.837639,
        "longitude": -51.81922
      },
      {
        "id": 9679,
        "latitude": -27.717326,
        "longitude": -52.621144
      },
      {
        "id": 9680,
        "latitude": -29.363741,
        "longitude": -50.809435
      },
      {
        "id": 9681,
        "latitude": -29.439149,
        "longitude": -50.579338
      },
      {
        "id": 9682,
        "latitude": -29.922683,
        "longitude": -51.139574
      },
      {
        "id": 9683,
        "latitude": -29.893921,
        "longitude": -51.134836
      },
      {
        "id": 9684,
        "latitude": -29.92468,
        "longitude": -51.132855
      },
      {
        "id": 9685,
        "latitude": -29.905386,
        "longitude": -51.153477
      },
      {
        "id": 9687,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9688,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9689,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9690,
        "latitude": -29.938521,
        "longitude": -50.987528
      },
      {
        "id": 9691,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9692,
        "latitude": -29.887446,
        "longitude": -51.190193
      },
      {
        "id": 9693,
        "latitude": -19.878758,
        "longitude": -43.918546
      },
      {
        "id": 9694,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9695,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9696,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9697,
        "latitude": -29.934368,
        "longitude": -51.059904
      },
      {
        "id": 9698,
        "latitude": -29.92468,
        "longitude": -51.132855
      },
      {
        "id": 9699,
        "latitude": -29.927422,
        "longitude": -51.155804
      },
      {
        "id": 9700,
        "latitude": -29.902137,
        "longitude": -51.145567
      },
      {
        "id": 9701,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9702,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9703,
        "latitude": -29.905386,
        "longitude": -51.153477
      },
      {
        "id": 9704,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9705,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9706,
        "latitude": -29.667832,
        "longitude": -51.182056
      },
      {
        "id": 9707,
        "latitude": -29.938521,
        "longitude": -50.987528
      },
      {
        "id": 9708,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9709,
        "latitude": -27.400074,
        "longitude": -54.081493
      },
      {
        "id": 9711,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9712,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9713,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9714,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9715,
        "latitude": -29.951452,
        "longitude": -51.186921
      },
      {
        "id": 9716,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9717,
        "latitude": -30.030033,
        "longitude": -51.230167
      },
      {
        "id": 9718,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9719,
        "latitude": -29.921605,
        "longitude": -51.179953
      },
      {
        "id": 9720,
        "latitude": -29.921605,
        "longitude": -51.179953
      },
      {
        "id": 9721,
        "latitude": -29.893921,
        "longitude": -51.134836
      },
      {
        "id": 9722,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9723,
        "latitude": -29.95573,
        "longitude": -51.1955
      },
      {
        "id": 9725,
        "latitude": -29.929573,
        "longitude": -51.165047
      },
      {
        "id": 9726,
        "latitude": -29.951173,
        "longitude": -51.178037
      },
      {
        "id": 9727,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9728,
        "latitude": -29.872715,
        "longitude": -51.18636
      },
      {
        "id": 9729,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 9730,
        "latitude": -29.927422,
        "longitude": -51.155804
      },
      {
        "id": 9731,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9732,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9733,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 9734,
        "latitude": -29.652459,
        "longitude": -51.181653
      },
      {
        "id": 9735,
        "latitude": -29.752062,
        "longitude": -51.157768
      },
      {
        "id": 9736,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9737,
        "latitude": -29.750942,
        "longitude": -51.090423
      },
      {
        "id": 9738,
        "latitude": -30.006425,
        "longitude": -52.909662
      },
      {
        "id": 9739,
        "latitude": -29.902073,
        "longitude": -51.150249
      },
      {
        "id": 9740,
        "latitude": -29.83966,
        "longitude": -50.925528
      },
      {
        "id": 9741,
        "latitude": -29.927422,
        "longitude": -51.155804
      },
      {
        "id": 9882,
        "latitude": -29.150132,
        "longitude": -51.210023
      },
      {
        "id": 9743,
        "latitude": -29.941792,
        "longitude": -51.181774
      },
      {
        "id": 9744,
        "latitude": -21.120801,
        "longitude": -42.928612
      },
      {
        "id": 9745,
        "latitude": -29.902137,
        "longitude": -51.145567
      },
      {
        "id": 9746,
        "latitude": -29.88095,
        "longitude": -51.176518
      },
      {
        "id": 9747,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9748,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9749,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 9750,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9751,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9752,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9753,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9754,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9755,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9756,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9757,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9758,
        "latitude": -29.905386,
        "longitude": -51.153477
      },
      {
        "id": 9759,
        "latitude": -29.905386,
        "longitude": -51.153477
      },
      {
        "id": 9760,
        "latitude": -29.905386,
        "longitude": -51.153477
      },
      {
        "id": 9761,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9762,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9763,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9764,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9765,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9766,
        "latitude": -29.926635,
        "longitude": -51.192233
      },
      {
        "id": 9767,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9768,
        "latitude": -29.931539,
        "longitude": -51.193773
      },
      {
        "id": 9769,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9770,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9771,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9772,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9773,
        "latitude": -29.92468,
        "longitude": -51.132855
      },
      {
        "id": 9774,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9775,
        "latitude": -29.938521,
        "longitude": -50.987528
      },
      {
        "id": 9776,
        "latitude": -29.896845,
        "longitude": -51.155596
      },
      {
        "id": 9777,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9778,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9779,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9780,
        "latitude": -30.035433,
        "longitude": -51.109516
      },
      {
        "id": 9781,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9782,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9783,
        "latitude": -29.938521,
        "longitude": -50.987528
      },
      {
        "id": 9784,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 9785,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9786,
        "latitude": -29.931539,
        "longitude": -51.193773
      },
      {
        "id": 9787,
        "latitude": -29.905386,
        "longitude": -51.153477
      },
      {
        "id": 9788,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9789,
        "latitude": -7.410808,
        "longitude": -40.650927
      },
      {
        "id": 9790,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 9791,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 9792,
        "latitude": -29.931742,
        "longitude": -51.193133
      },
      {
        "id": 9793,
        "latitude": -29.92468,
        "longitude": -51.132855
      },
      {
        "id": 9795,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 9796,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 9797,
        "latitude": -29.926924,
        "longitude": -51.191805
      },
      {
        "id": 9798,
        "latitude": -29.879791,
        "longitude": -51.173104
      },
      {
        "id": 9799,
        "latitude": -29.90545,
        "longitude": -51.147762
      },
      {
        "id": 9800,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 9801,
        "latitude": -29.743454,
        "longitude": -50.016251
      },
      {
        "id": 9802,
        "latitude": -29.682559,
        "longitude": -49.981777
      },
      {
        "id": 9803,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9804,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9805,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9806,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9807,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9808,
        "latitude": -31.76548,
        "longitude": -52.427678
      },
      {
        "id": 9809,
        "latitude": -31.759204,
        "longitude": -52.441927
      },
      {
        "id": 9810,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9811,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9812,
        "latitude": -31.75108,
        "longitude": -52.470653
      },
      {
        "id": 9813,
        "latitude": -29.122071,
        "longitude": -51.119818
      },
      {
        "id": 9814,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9815,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9816,
        "latitude": -29.150132,
        "longitude": -51.210023
      },
      {
        "id": 9817,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9819,
        "latitude": -29.19964,
        "longitude": -51.191282
      },
      {
        "id": 9820,
        "latitude": -29.173222,
        "longitude": -51.186023
      },
      {
        "id": 9821,
        "latitude": -29.215254,
        "longitude": -51.192261
      },
      {
        "id": 9823,
        "latitude": -29.121261,
        "longitude": -51.191564
      },
      {
        "id": 9824,
        "latitude": -29.174868,
        "longitude": -51.251883
      },
      {
        "id": 9825,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9826,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9828,
        "latitude": -29.126102,
        "longitude": -51.16378
      },
      {
        "id": 9829,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9830,
        "latitude": -29.122205,
        "longitude": -51.183747
      },
      {
        "id": 9831,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9832,
        "latitude": -29.157195,
        "longitude": -51.131232
      },
      {
        "id": 9833,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9834,
        "latitude": -29.126102,
        "longitude": -51.16378
      },
      {
        "id": 9835,
        "latitude": -29.169543,
        "longitude": -51.17631
      },
      {
        "id": 9836,
        "latitude": -30.008043,
        "longitude": -52.898579
      },
      {
        "id": 9837,
        "latitude": -16.050371,
        "longitude": -47.984394
      },
      {
        "id": 9838,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9839,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9840,
        "latitude": -29.1192,
        "longitude": -51.189932
      },
      {
        "id": 9841,
        "latitude": -29.1656,
        "longitude": -51.2331
      },
      {
        "id": 9842,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9843,
        "latitude": -29.11878,
        "longitude": -51.191668
      },
      {
        "id": 9844,
        "latitude": -29.160373,
        "longitude": -51.210063
      },
      {
        "id": 9845,
        "latitude": -29.1656,
        "longitude": -51.2331
      },
      {
        "id": 9846,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9847,
        "latitude": -29.206509,
        "longitude": -51.236237
      },
      {
        "id": 9848,
        "latitude": -29.201957,
        "longitude": -51.238193
      },
      {
        "id": 9849,
        "latitude": -29.183225,
        "longitude": -51.15237
      },
      {
        "id": 9850,
        "latitude": -29.183225,
        "longitude": -51.15237
      },
      {
        "id": 9851,
        "latitude": -29.125874,
        "longitude": -51.160518
      },
      {
        "id": 9852,
        "latitude": -29.117821,
        "longitude": -51.183003
      },
      {
        "id": 9853,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9854,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9855,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9856,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9857,
        "latitude": -29.18677,
        "longitude": -51.149086
      },
      {
        "id": 9858,
        "latitude": -29.117821,
        "longitude": -51.183003
      },
      {
        "id": 9859,
        "latitude": -29.119653,
        "longitude": -51.171141
      },
      {
        "id": 9860,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9861,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9862,
        "latitude": -29.157983,
        "longitude": -51.125407
      },
      {
        "id": 9863,
        "latitude": -29.18677,
        "longitude": -51.149086
      },
      {
        "id": 9864,
        "latitude": -29.211514,
        "longitude": -51.285632
      },
      {
        "id": 9866,
        "latitude": -29.150748,
        "longitude": -51.151184
      },
      {
        "id": 9868,
        "latitude": -29.166402,
        "longitude": -51.178779
      },
      {
        "id": 9869,
        "latitude": -29.136795,
        "longitude": -51.191338
      },
      {
        "id": 9871,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9872,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9873,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9874,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9876,
        "latitude": -29.211514,
        "longitude": -51.285632
      },
      {
        "id": 9877,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9878,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9879,
        "latitude": -29.211514,
        "longitude": -51.285632
      },
      {
        "id": 9880,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9881,
        "latitude": -29.294046,
        "longitude": -51.1816
      },
      {
        "id": 9884,
        "latitude": -29.13179,
        "longitude": -51.170297
      },
      {
        "id": 9885,
        "latitude": -29.193142,
        "longitude": -51.220334
      },
      {
        "id": 9886,
        "latitude": -29.294046,
        "longitude": -51.1816
      },
      {
        "id": 9887,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9888,
        "latitude": -29.161476,
        "longitude": -51.215484
      },
      {
        "id": 9889,
        "latitude": -29.155075,
        "longitude": -51.199702
      },
      {
        "id": 9890,
        "latitude": -29.169833,
        "longitude": -51.123826
      },
      {
        "id": 9891,
        "latitude": -29.339667,
        "longitude": -51.026777
      },
      {
        "id": 9892,
        "latitude": -29.193328,
        "longitude": -51.21877
      },
      {
        "id": 9893,
        "latitude": -29.118595,
        "longitude": -51.18964
      },
      {
        "id": 9894,
        "latitude": -29.1544,
        "longitude": -51.0949
      },
      {
        "id": 9895,
        "latitude": -29.141584,
        "longitude": -51.215281
      },
      {
        "id": 9896,
        "latitude": -29.151987,
        "longitude": -51.22829
      },
      {
        "id": 9897,
        "latitude": -29.126102,
        "longitude": -51.16378
      },
      {
        "id": 9898,
        "latitude": -29.100579,
        "longitude": -51.123097
      },
      {
        "id": 9899,
        "latitude": -29.12939,
        "longitude": -51.169787
      },
      {
        "id": 9933,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9900,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9901,
        "latitude": -29.12939,
        "longitude": -51.169787
      },
      {
        "id": 9902,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9903,
        "latitude": -29.183435,
        "longitude": -51.216579
      },
      {
        "id": 9904,
        "latitude": -29.183225,
        "longitude": -51.15237
      },
      {
        "id": 9905,
        "latitude": -29.17197,
        "longitude": -51.154673
      },
      {
        "id": 9906,
        "latitude": -29.183225,
        "longitude": -51.15237
      },
      {
        "id": 9907,
        "latitude": -29.18677,
        "longitude": -51.149086
      },
      {
        "id": 9908,
        "latitude": -29.129187,
        "longitude": -51.169783
      },
      {
        "id": 9909,
        "latitude": -29.198383,
        "longitude": -51.141612
      },
      {
        "id": 9910,
        "latitude": -29.117821,
        "longitude": -51.183003
      },
      {
        "id": 9911,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9912,
        "latitude": -29.217228,
        "longitude": -51.19022
      },
      {
        "id": 9913,
        "latitude": -29.211514,
        "longitude": -51.285632
      },
      {
        "id": 9914,
        "latitude": -29.183435,
        "longitude": -51.216579
      },
      {
        "id": 9915,
        "latitude": -27.632122,
        "longitude": -52.242581
      },
      {
        "id": 9916,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9917,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9918,
        "latitude": -29.212296,
        "longitude": -51.19546
      },
      {
        "id": 9919,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 9920,
        "latitude": -29.119653,
        "longitude": -51.171141
      },
      {
        "id": 9921,
        "latitude": -29.183225,
        "longitude": -51.15237
      },
      {
        "id": 9922,
        "latitude": -29.125874,
        "longitude": -51.160518
      },
      {
        "id": 9923,
        "latitude": -29.166288,
        "longitude": -51.169223
      },
      {
        "id": 9924,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9925,
        "latitude": -29.117387,
        "longitude": -51.177079
      },
      {
        "id": 9926,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9927,
        "latitude": -29.1656,
        "longitude": -51.2331
      },
      {
        "id": 9928,
        "latitude": -29.11904,
        "longitude": -51.19186
      },
      {
        "id": 9929,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9930,
        "latitude": -29.183663,
        "longitude": -51.154873
      },
      {
        "id": 9931,
        "latitude": -20.105153,
        "longitude": -44.840523
      },
      {
        "id": 9932,
        "latitude": -29.186161,
        "longitude": -51.238778
      },
      {
        "id": 9934,
        "latitude": -29.126102,
        "longitude": -51.16378
      },
      {
        "id": 9935,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9936,
        "latitude": -29.198981,
        "longitude": -51.201723
      },
      {
        "id": 9937,
        "latitude": -29.198383,
        "longitude": -51.141612
      },
      {
        "id": 9938,
        "latitude": -29.212296,
        "longitude": -51.19546
      },
      {
        "id": 9939,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9940,
        "latitude": -29.17197,
        "longitude": -51.154673
      },
      {
        "id": 9941,
        "latitude": -29.119735,
        "longitude": -51.19057
      },
      {
        "id": 9942,
        "latitude": -29.166288,
        "longitude": -51.169223
      },
      {
        "id": 9943,
        "latitude": -29.129187,
        "longitude": -51.169783
      },
      {
        "id": 9944,
        "latitude": -29.150132,
        "longitude": -51.210023
      },
      {
        "id": 9945,
        "latitude": -29.166474,
        "longitude": -51.183035
      },
      {
        "id": 9946,
        "latitude": -28.857742,
        "longitude": -51.28524
      },
      {
        "id": 9947,
        "latitude": -29.17197,
        "longitude": -51.154673
      },
      {
        "id": 9948,
        "latitude": -29.2107,
        "longitude": -51.1762
      },
      {
        "id": 9949,
        "latitude": -29.160868,
        "longitude": -51.133173
      },
      {
        "id": 9950,
        "latitude": -29.456022,
        "longitude": -51.294312
      },
      {
        "id": 9951,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9952,
        "latitude": -29.214339,
        "longitude": -51.282784
      },
      {
        "id": 9953,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9954,
        "latitude": -29.202138,
        "longitude": -51.205956
      },
      {
        "id": 9955,
        "latitude": -29.202111,
        "longitude": -51.206653
      },
      {
        "id": 9956,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 9957,
        "latitude": -31.850663,
        "longitude": -52.81793
      },
      {
        "id": 9958,
        "latitude": -29.965275,
        "longitude": -51.61727
      },
      {
        "id": 9959,
        "latitude": -29.965275,
        "longitude": -51.61727
      },
      {
        "id": 9960,
        "latitude": -30.176336,
        "longitude": -50.21983
      },
      {
        "id": 9961,
        "latitude": -28.626955,
        "longitude": -53.618271
      },
      {
        "id": 9962,
        "latitude": -29.108907,
        "longitude": -51.979413
      },
      {
        "id": 9963,
        "latitude": -29.992856,
        "longitude": -51.31124
      },
      {
        "id": 9964,
        "latitude": -4.443833,
        "longitude": -43.8936
      },
      {
        "id": 9965,
        "latitude": -29.992856,
        "longitude": -51.31124
      },
      {
        "id": 9966,
        "latitude": -30.001655,
        "longitude": -51.316667
      },
      {
        "id": 9967,
        "latitude": -29.998614,
        "longitude": -51.31338
      },
      {
        "id": 9968,
        "latitude": -29.22833,
        "longitude": -51.886357
      },
      {
        "id": 9969,
        "latitude": -20.223464,
        "longitude": -42.654406
      },
      {
        "id": 9970,
        "latitude": -30.546644,
        "longitude": -52.53774
      },
      {
        "id": 9971,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 9972,
        "latitude": -27.65201,
        "longitude": -52.254765
      },
      {
        "id": 9973,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 9974,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 9975,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 9976,
        "latitude": -27.640871,
        "longitude": -52.258214
      },
      {
        "id": 9977,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 9978,
        "latitude": -29.652459,
        "longitude": -51.181653
      },
      {
        "id": 9979,
        "latitude": -29.656787,
        "longitude": -51.175619
      },
      {
        "id": 9980,
        "latitude": -29.652459,
        "longitude": -51.181653
      },
      {
        "id": 9981,
        "latitude": -30.044639,
        "longitude": -51.203519
      },
      {
        "id": 9982,
        "latitude": -29.90259,
        "longitude": -51.02097
      },
      {
        "id": 9983,
        "latitude": -29.865134,
        "longitude": -51.116621
      },
      {
        "id": 9984,
        "latitude": -29.846367,
        "longitude": -51.16725
      },
      {
        "id": 9985,
        "latitude": -29.861066,
        "longitude": -51.171341
      },
      {
        "id": 9986,
        "latitude": -30.041629,
        "longitude": -51.164783
      },
      {
        "id": 9987,
        "latitude": -30.038216,
        "longitude": -51.156881
      },
      {
        "id": 9988,
        "latitude": -29.846367,
        "longitude": -51.16725
      },
      {
        "id": 9989,
        "latitude": -29.861066,
        "longitude": -51.171341
      },
      {
        "id": 9990,
        "latitude": -27.980345,
        "longitude": -50.97705
      },
      {
        "id": 9991,
        "latitude": -27.980345,
        "longitude": -50.97705
      },
      {
        "id": 9992,
        "latitude": -29.223934,
        "longitude": -51.306484
      },
      {
        "id": 9994,
        "latitude": -29.2412,
        "longitude": -51.35199
      },
      {
        "id": 9996,
        "latitude": -29.138481,
        "longitude": -51.188848
      },
      {
        "id": 9997,
        "latitude": -32.012527,
        "longitude": -52.039954
      },
      {
        "id": 9998,
        "latitude": -29.225196,
        "longitude": -51.349382
      },
      {
        "id": 9999,
        "latitude": -30.035835,
        "longitude": -51.230078
      },
      {
        "id": 10000,
        "latitude": -29.240486,
        "longitude": -51.522423
      },
      {
        "id": 10001,
        "latitude": -29.274616,
        "longitude": -51.526687
      },
      {
        "id": 10002,
        "latitude": -29.240486,
        "longitude": -51.522423
      },
      {
        "id": 10003,
        "latitude": -27.891096,
        "longitude": -52.228247
      },
      {
        "id": 10004,
        "latitude": -27.87883,
        "longitude": -52.225374
      },
      {
        "id": 10005,
        "latitude": -29.366056,
        "longitude": -50.860929
      },
      {
        "id": 10006,
        "latitude": -29.880359,
        "longitude": -51.045676
      },
      {
        "id": 10007,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10008,
        "latitude": -29.932041,
        "longitude": -51.058053
      },
      {
        "id": 10009,
        "latitude": -29.931846,
        "longitude": -51.002343
      },
      {
        "id": 10010,
        "latitude": -29.735123,
        "longitude": -51.166331
      },
      {
        "id": 10011,
        "latitude": -29.951521,
        "longitude": -51.053166
      },
      {
        "id": 10012,
        "latitude": -29.905121,
        "longitude": -51.059215
      },
      {
        "id": 10013,
        "latitude": -29.918009,
        "longitude": -50.902719
      },
      {
        "id": 10014,
        "latitude": -29.936074,
        "longitude": -50.99887
      },
      {
        "id": 10015,
        "latitude": -29.957426,
        "longitude": -50.972988
      },
      {
        "id": 10016,
        "latitude": -29.88267,
        "longitude": -51.006303
      },
      {
        "id": 10017,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10018,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10019,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10020,
        "latitude": -29.944022,
        "longitude": -50.993094
      },
      {
        "id": 10023,
        "latitude": -29.947796,
        "longitude": -51.06491
      },
      {
        "id": 10024,
        "latitude": -29.941906,
        "longitude": -51.064191
      },
      {
        "id": 10026,
        "latitude": -29.889031,
        "longitude": -50.983103
      },
      {
        "id": 10027,
        "latitude": -30.073751,
        "longitude": -51.167707
      },
      {
        "id": 10028,
        "latitude": -29.947796,
        "longitude": -51.06491
      },
      {
        "id": 10029,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10030,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10031,
        "latitude": -30.069063,
        "longitude": -51.17403
      },
      {
        "id": 10032,
        "latitude": -30.063362,
        "longitude": -51.211722
      },
      {
        "id": 10033,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10034,
        "latitude": -29.941906,
        "longitude": -51.064191
      },
      {
        "id": 10035,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10036,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10037,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10038,
        "latitude": -29.950219,
        "longitude": -50.972817
      },
      {
        "id": 10039,
        "latitude": -29.950726,
        "longitude": -51.162417
      },
      {
        "id": 10040,
        "latitude": -29.948469,
        "longitude": -51.045124
      },
      {
        "id": 10042,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10043,
        "latitude": -30.110876,
        "longitude": -51.211944
      },
      {
        "id": 10044,
        "latitude": -29.951521,
        "longitude": -51.053166
      },
      {
        "id": 10045,
        "latitude": -29.939213,
        "longitude": -51.046763
      },
      {
        "id": 10046,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10047,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10048,
        "latitude": -30.023863,
        "longitude": -51.213866
      },
      {
        "id": 10049,
        "latitude": -30.339064,
        "longitude": -54.088938
      },
      {
        "id": 10050,
        "latitude": -29.948469,
        "longitude": -51.045124
      },
      {
        "id": 10052,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10053,
        "latitude": -29.901261,
        "longitude": -51.1862
      },
      {
        "id": 10054,
        "latitude": -29.763068,
        "longitude": -51.111666
      },
      {
        "id": 10055,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10056,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10057,
        "latitude": -13.404368,
        "longitude": -43.613494
      },
      {
        "id": 10059,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10060,
        "latitude": -29.94844,
        "longitude": -51.000102
      },
      {
        "id": 10061,
        "latitude": -29.936076,
        "longitude": -51.052123
      },
      {
        "id": 10062,
        "latitude": -29.928843,
        "longitude": -50.937001
      },
      {
        "id": 10063,
        "latitude": -29.873634,
        "longitude": -51.075524
      },
      {
        "id": 10064,
        "latitude": -29.997118,
        "longitude": -51.190036
      },
      {
        "id": 10065,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10066,
        "latitude": -29.933058,
        "longitude": -51.028683
      },
      {
        "id": 10067,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10068,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10069,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10070,
        "latitude": -29.951521,
        "longitude": -51.053166
      },
      {
        "id": 10071,
        "latitude": -29.952463,
        "longitude": -51.056538
      },
      {
        "id": 10072,
        "latitude": -29.932378,
        "longitude": -51.057497
      },
      {
        "id": 10073,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10074,
        "latitude": -29.88267,
        "longitude": -51.006303
      },
      {
        "id": 10075,
        "latitude": -29.90164,
        "longitude": -51.019907
      },
      {
        "id": 10076,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10078,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10079,
        "latitude": -29.952123,
        "longitude": -51.052607
      },
      {
        "id": 10080,
        "latitude": -29.951521,
        "longitude": -51.053166
      },
      {
        "id": 10081,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10082,
        "latitude": -29.887652,
        "longitude": -50.984685
      },
      {
        "id": 10083,
        "latitude": -29.947385,
        "longitude": -50.991377
      },
      {
        "id": 10084,
        "latitude": -29.958857,
        "longitude": -50.975118
      },
      {
        "id": 10085,
        "latitude": -29.936076,
        "longitude": -51.052123
      },
      {
        "id": 10086,
        "latitude": -29.94702,
        "longitude": -50.997409
      },
      {
        "id": 10087,
        "latitude": -29.876087,
        "longitude": -51.000003
      },
      {
        "id": 10088,
        "latitude": 1.01218,
        "longitude": -60.03623
      },
      {
        "id": 10089,
        "latitude": -29.947796,
        "longitude": -51.06491
      },
      {
        "id": 10090,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10091,
        "latitude": -29.951521,
        "longitude": -51.053166
      },
      {
        "id": 10092,
        "latitude": -29.91887,
        "longitude": -51.014992
      },
      {
        "id": 10093,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10094,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 10095,
        "latitude": -28.548815,
        "longitude": -52.215512
      },
      {
        "id": 10096,
        "latitude": -29.896244,
        "longitude": -50.995707
      },
      {
        "id": 10097,
        "latitude": -29.939213,
        "longitude": -51.046763
      },
      {
        "id": 10098,
        "latitude": -30.030033,
        "longitude": -51.230167
      },
      {
        "id": 10099,
        "latitude": -30.165227,
        "longitude": -51.398994
      },
      {
        "id": 10100,
        "latitude": -29.830689,
        "longitude": -50.056363
      },
      {
        "id": 10101,
        "latitude": -30.030553,
        "longitude": -51.161836
      },
      {
        "id": 10102,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10103,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10104,
        "latitude": -30.151227,
        "longitude": -51.33302
      },
      {
        "id": 10105,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10106,
        "latitude": -30.168343,
        "longitude": -51.402703
      },
      {
        "id": 10107,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10108,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10109,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10110,
        "latitude": -30.132229,
        "longitude": -51.339843
      },
      {
        "id": 10111,
        "latitude": -30.113073,
        "longitude": -51.329442
      },
      {
        "id": 10112,
        "latitude": -20.517168,
        "longitude": -54.608301
      },
      {
        "id": 10113,
        "latitude": -30.14697,
        "longitude": -51.32397
      },
      {
        "id": 10114,
        "latitude": -28.73421,
        "longitude": -52.844126
      },
      {
        "id": 10115,
        "latitude": -29.943501,
        "longitude": -50.116886
      },
      {
        "id": 10116,
        "latitude": -29.943501,
        "longitude": -50.116886
      },
      {
        "id": 10117,
        "latitude": -29.943501,
        "longitude": -50.116886
      },
      {
        "id": 10118,
        "latitude": -29.973171,
        "longitude": -50.126661
      },
      {
        "id": 10119,
        "latitude": -29.992109,
        "longitude": -51.065136
      },
      {
        "id": 10120,
        "latitude": -29.943501,
        "longitude": -50.116886
      },
      {
        "id": 10121,
        "latitude": -29.943501,
        "longitude": -50.116886
      },
      {
        "id": 10122,
        "latitude": -29.943501,
        "longitude": -50.116886
      },
      {
        "id": 10123,
        "latitude": -29.598756,
        "longitude": -53.758458
      },
      {
        "id": 10125,
        "latitude": -27.730079,
        "longitude": -52.534191
      },
      {
        "id": 10127,
        "latitude": -28.217379,
        "longitude": -51.540104
      },
      {
        "id": 10128,
        "latitude": -29.459986,
        "longitude": -51.970668
      },
      {
        "id": 10129,
        "latitude": -29.450669,
        "longitude": -51.992197
      },
      {
        "id": 10130,
        "latitude": -29.467152,
        "longitude": -51.962405
      },
      {
        "id": 10131,
        "latitude": -29.467152,
        "longitude": -51.962405
      },
      {
        "id": 10132,
        "latitude": -29.467152,
        "longitude": -51.962405
      },
      {
        "id": 10133,
        "latitude": -29.467152,
        "longitude": -51.962405
      },
      {
        "id": 10134,
        "latitude": -29.467152,
        "longitude": -51.962405
      },
      {
        "id": 10135,
        "latitude": -29.467709,
        "longitude": -51.200113
      },
      {
        "id": 10136,
        "latitude": -28.449305,
        "longitude": -52.200216
      },
      {
        "id": 10137,
        "latitude": -29.528124,
        "longitude": -52.127807
      },
      {
        "id": 10138,
        "latitude": -29.682611,
        "longitude": -51.468746
      },
      {
        "id": 10139,
        "latitude": -29.682611,
        "longitude": -51.468746
      },
      {
        "id": 10140,
        "latitude": -29.848997,
        "longitude": -51.276428
      },
      {
        "id": 10141,
        "latitude": -29.848997,
        "longitude": -51.276428
      },
      {
        "id": 10142,
        "latitude": -29.696615,
        "longitude": -51.115211
      },
      {
        "id": 10143,
        "latitude": -29.644647,
        "longitude": -51.110463
      },
      {
        "id": 10145,
        "latitude": -29.650547,
        "longitude": -51.172285
      },
      {
        "id": 10146,
        "latitude": -29.701743,
        "longitude": -51.129859
      },
      {
        "id": 10147,
        "latitude": -29.676663,
        "longitude": -51.088913
      },
      {
        "id": 10148,
        "latitude": -29.705041,
        "longitude": -51.10921
      },
      {
        "id": 10149,
        "latitude": -29.644647,
        "longitude": -51.110463
      },
      {
        "id": 10150,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10151,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10152,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10156,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10157,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10158,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10159,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10160,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10161,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10162,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10163,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10164,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10165,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10166,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10167,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10168,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10169,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10170,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10171,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10172,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10173,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10174,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10175,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10176,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10177,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10178,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10179,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10180,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10181,
        "latitude": -29.67274,
        "longitude": -51.174477
      },
      {
        "id": 10182,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10183,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10184,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10185,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10186,
        "latitude": -29.690571,
        "longitude": -51.142904
      },
      {
        "id": 10187,
        "latitude": -29.889073,
        "longitude": -50.27405
      },
      {
        "id": 10188,
        "latitude": -29.889073,
        "longitude": -50.27405
      },
      {
        "id": 10189,
        "latitude": -30.258025,
        "longitude": -50.509795
      },
      {
        "id": 10190,
        "latitude": -29.624257,
        "longitude": -50.83118
      },
      {
        "id": 10191,
        "latitude": -29.624257,
        "longitude": -50.83118
      },
      {
        "id": 10192,
        "latitude": -29.624257,
        "longitude": -50.83118
      },
      {
        "id": 10193,
        "latitude": -29.624257,
        "longitude": -50.83118
      },
      {
        "id": 10194,
        "latitude": -29.624257,
        "longitude": -50.83118
      },
      {
        "id": 10195,
        "latitude": -29.624257,
        "longitude": -50.83118
      },
      {
        "id": 10197,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10198,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10200,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10201,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10219,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10202,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10203,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10204,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10205,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10206,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10207,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10208,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10209,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10210,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10211,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10212,
        "latitude": -28.294351,
        "longitude": -52.448018
      },
      {
        "id": 10213,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10214,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10215,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10216,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10217,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10218,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10220,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10221,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10222,
        "latitude": -28.25506,
        "longitude": -52.396661
      },
      {
        "id": 10223,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10224,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10225,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10226,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10227,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10228,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10229,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10230,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10231,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10232,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10234,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10235,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10236,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10307,
        "latitude": -31.661848,
        "longitude": -52.3094
      },
      {
        "id": 10237,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10238,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10239,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10241,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10242,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10243,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10244,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10245,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10246,
        "latitude": -31.771205,
        "longitude": -52.352579
      },
      {
        "id": 10247,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10248,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10250,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10251,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10253,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10254,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10326,
        "latitude": -31.761613,
        "longitude": -52.237861
      },
      {
        "id": 10255,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10256,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10257,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10258,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10259,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10260,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10261,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10262,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10263,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10264,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10265,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10266,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10267,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10268,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10269,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10270,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10271,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10272,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10273,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10274,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10276,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10277,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10278,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10279,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10280,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10281,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10282,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10283,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10284,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10285,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10286,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10287,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10288,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10362,
        "latitude": -29.693255,
        "longitude": -51.233184
      },
      {
        "id": 10289,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10290,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10291,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10292,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10293,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10294,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10295,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10296,
        "latitude": -31.741856,
        "longitude": -52.315897
      },
      {
        "id": 10297,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10298,
        "latitude": -31.739753,
        "longitude": -52.3926
      },
      {
        "id": 10299,
        "latitude": -31.778649,
        "longitude": -52.351652
      },
      {
        "id": 10300,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10301,
        "latitude": -31.78097,
        "longitude": -52.34347
      },
      {
        "id": 10303,
        "latitude": -31.722079,
        "longitude": -52.344187
      },
      {
        "id": 10304,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10306,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10308,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10309,
        "latitude": -31.778649,
        "longitude": -52.351652
      },
      {
        "id": 10310,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10311,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10312,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10313,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10314,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10315,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10316,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10317,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10318,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10319,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10320,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10321,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10322,
        "latitude": -31.762155,
        "longitude": -52.351424
      },
      {
        "id": 10323,
        "latitude": -31.773877,
        "longitude": -52.236959
      },
      {
        "id": 10324,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10325,
        "latitude": -31.772196,
        "longitude": -52.328375
      },
      {
        "id": 10327,
        "latitude": -31.78097,
        "longitude": -52.34347
      },
      {
        "id": 10328,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10329,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10330,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10331,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10332,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10333,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10334,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10335,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10336,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10337,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10338,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10339,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10340,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10341,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10342,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10343,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10344,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10345,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10346,
        "latitude": -31.765424,
        "longitude": -52.346435
      },
      {
        "id": 10347,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10348,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10349,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10350,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10351,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10352,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10353,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10354,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10355,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10356,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10357,
        "latitude": -31.769974,
        "longitude": -52.341016
      },
      {
        "id": 10358,
        "latitude": -29.449553,
        "longitude": -51.099423
      },
      {
        "id": 10359,
        "latitude": -31.579265,
        "longitude": -53.382219
      },
      {
        "id": 10360,
        "latitude": -31.448451,
        "longitude": -53.103993
      },
      {
        "id": 10361,
        "latitude": -29.693255,
        "longitude": -51.233184
      },
      {
        "id": 10364,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10365,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10366,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10368,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10369,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10370,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10371,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10372,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10373,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10374,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10375,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10376,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10377,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10378,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10379,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10380,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10381,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10382,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10383,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10384,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10385,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10387,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10388,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10389,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10390,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10391,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10392,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10393,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10394,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10395,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10396,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10397,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10398,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10399,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10400,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10401,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10402,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10403,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10407,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10408,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10409,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10412,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10414,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10415,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10416,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10418,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10419,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10420,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10421,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10424,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10426,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10427,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10429,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10430,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10432,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10433,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10434,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10435,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10436,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10437,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10438,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10440,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10441,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10442,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10443,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10444,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10445,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10447,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10448,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10449,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10450,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10451,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 11589,
        "latitude": -22.952024,
        "longitude": -46.541859
      },
      {
        "id": 10452,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10453,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10454,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10455,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10456,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10457,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10458,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10459,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10460,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10461,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10462,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10463,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10464,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10465,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10466,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10467,
        "latitude": -29.924251,
        "longitude": -50.887346
      },
      {
        "id": 10468,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10469,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10470,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10471,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10472,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10473,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10474,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10475,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10476,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10477,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10478,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10479,
        "latitude": -30.043111,
        "longitude": -51.137665
      },
      {
        "id": 10480,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10481,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10482,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10483,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10484,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10485,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10486,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10487,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10488,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10489,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10491,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10492,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10493,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10494,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10495,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10496,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10497,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10498,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10499,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10500,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10501,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10502,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10503,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10504,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10505,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10506,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10507,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 11693,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 10508,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10509,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10510,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10511,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10512,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10513,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10514,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10515,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10516,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10517,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10518,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10519,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10520,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10521,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10522,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10523,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10524,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10525,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10526,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10527,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10529,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10530,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10531,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10532,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10534,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10535,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10536,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10537,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10538,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10539,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10540,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10541,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10542,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10544,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10545,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10546,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10547,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10548,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10549,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10551,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10552,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10553,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10554,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10555,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10556,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10557,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10558,
        "latitude": -30.092985,
        "longitude": -51.224363
      },
      {
        "id": 10559,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10560,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10561,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10562,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10563,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10564,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10565,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10566,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10567,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10568,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10569,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10570,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10571,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10572,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10573,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10574,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10575,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10576,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10577,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10578,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10579,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10580,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10581,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10582,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10583,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10584,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10585,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10586,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10587,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10588,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10589,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10590,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10591,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10592,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10593,
        "latitude": -29.996808,
        "longitude": -51.08859
      },
      {
        "id": 10594,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10595,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10596,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10597,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10598,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10599,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10600,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10601,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10602,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10603,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10604,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10605,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10606,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10607,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10608,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10609,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10610,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10611,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10612,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10613,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10614,
        "latitude": -30.005517,
        "longitude": -51.196933
      },
      {
        "id": 10615,
        "latitude": -30.069939,
        "longitude": -51.192394
      },
      {
        "id": 10616,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10617,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10618,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10619,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10620,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10621,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10622,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10623,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10624,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10625,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10626,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10627,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10628,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10629,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10630,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10631,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10632,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10633,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10634,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10635,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10636,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10637,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10638,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10639,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10640,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10641,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10642,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10643,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10644,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10645,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10646,
        "latitude": -30.239796,
        "longitude": -51.090096
      },
      {
        "id": 10647,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10648,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10649,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10650,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10651,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10652,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10653,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10654,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10655,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10656,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10657,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10658,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10659,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10660,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10661,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10662,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10663,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10664,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10665,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10666,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10667,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10668,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10669,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10670,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10671,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10672,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10673,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10674,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10675,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10676,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10677,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10678,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10679,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10680,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10681,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10682,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10684,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10685,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10686,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10687,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10688,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10689,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10690,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10691,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10692,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10693,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10694,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10695,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10696,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10697,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10698,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10699,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10700,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10701,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10702,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10703,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10704,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10705,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10706,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10707,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10708,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10709,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10710,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10711,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10712,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10713,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10714,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10715,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10716,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10717,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10718,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10719,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10720,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10721,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10722,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10723,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10724,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10725,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10726,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10727,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10728,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10729,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10730,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10731,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10732,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10733,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10734,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10735,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10736,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10737,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10738,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10739,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10740,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10741,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10742,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10743,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10744,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10745,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10746,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10747,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10748,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10749,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10750,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10751,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10752,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10753,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10754,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10755,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10756,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10757,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10758,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10759,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10760,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10761,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10762,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10763,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10764,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10765,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10766,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10767,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10768,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10769,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10770,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10771,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10772,
        "latitude": -30.039693,
        "longitude": -51.159659
      },
      {
        "id": 10773,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10774,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10775,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10776,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10777,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10778,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10779,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10780,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10781,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10782,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10783,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10784,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10785,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10786,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10787,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10788,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10789,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10790,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10791,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10792,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10793,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10794,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10795,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10796,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10797,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10798,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10799,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10800,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10801,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10802,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10803,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10804,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10805,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10806,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10807,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10808,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10809,
        "latitude": -30.0325,
        "longitude": -51.230377
      },
      {
        "id": 10810,
        "latitude": -29.814561,
        "longitude": -53.373455
      },
      {
        "id": 10811,
        "latitude": -29.814561,
        "longitude": -53.373455
      },
      {
        "id": 10812,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10813,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10814,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10815,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10816,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10817,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10818,
        "latitude": -32.078127,
        "longitude": -52.251892
      },
      {
        "id": 10819,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10820,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10821,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10822,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10823,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10824,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10825,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10826,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10827,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10828,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10829,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10831,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10832,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10833,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10834,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10835,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10836,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10837,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10838,
        "latitude": -32.033425,
        "longitude": -52.09913
      },
      {
        "id": 10839,
        "latitude": -30.252715,
        "longitude": -54.917938
      },
      {
        "id": 10840,
        "latitude": -29.089977,
        "longitude": -53.211124
      },
      {
        "id": 10841,
        "latitude": -29.089977,
        "longitude": -53.211124
      },
      {
        "id": 10842,
        "latitude": -27.949938,
        "longitude": -51.807444
      },
      {
        "id": 10843,
        "latitude": -30.88941,
        "longitude": -55.531769
      },
      {
        "id": 10844,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10845,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10846,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10847,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10848,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10849,
        "latitude": -29.761678,
        "longitude": -52.411576
      },
      {
        "id": 10850,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10851,
        "latitude": -29.714187,
        "longitude": -52.428591
      },
      {
        "id": 10852,
        "latitude": -29.684091,
        "longitude": -53.786687
      },
      {
        "id": 10853,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10854,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10855,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10856,
        "latitude": -29.674199,
        "longitude": -53.810497
      },
      {
        "id": 10857,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10858,
        "latitude": -29.691314,
        "longitude": -53.827445
      },
      {
        "id": 10859,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10860,
        "latitude": -29.686456,
        "longitude": -53.790468
      },
      {
        "id": 10861,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10862,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10863,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10864,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10865,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10866,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10867,
        "latitude": -29.686051,
        "longitude": -53.806921
      },
      {
        "id": 10868,
        "latitude": -27.864355,
        "longitude": -54.477929
      },
      {
        "id": 10869,
        "latitude": -27.864355,
        "longitude": -54.477929
      },
      {
        "id": 10870,
        "latitude": -33.519833,
        "longitude": -53.369397
      },
      {
        "id": 10871,
        "latitude": -28.300048,
        "longitude": -54.266997
      },
      {
        "id": 10872,
        "latitude": -29.826768,
        "longitude": -50.517489
      },
      {
        "id": 10873,
        "latitude": -29.826768,
        "longitude": -50.517489
      },
      {
        "id": 10874,
        "latitude": -29.826768,
        "longitude": -50.517489
      },
      {
        "id": 10875,
        "latitude": -28.494656,
        "longitude": -52.025477
      },
      {
        "id": 10876,
        "latitude": -28.659224,
        "longitude": -56.00443
      },
      {
        "id": 10877,
        "latitude": -27.816272,
        "longitude": -51.829774
      },
      {
        "id": 10878,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10879,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10880,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10882,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10883,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10884,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10886,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10887,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10888,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10890,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10892,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10893,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10894,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10895,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10896,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10897,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10898,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10899,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10900,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10901,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10902,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10903,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10904,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10905,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10906,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10907,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10908,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10909,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10910,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10913,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10914,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10915,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10916,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10917,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10918,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10919,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10920,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10921,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10923,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10924,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10925,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10926,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10927,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10928,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10930,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10931,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10932,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10933,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10934,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10935,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10936,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10937,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10938,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10939,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10940,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10941,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10942,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10943,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10944,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10945,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10946,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10947,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10982,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10948,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10949,
        "latitude": -29.777502,
        "longitude": -51.152137
      },
      {
        "id": 10950,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10951,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10952,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10953,
        "latitude": -29.766143,
        "longitude": -51.163536
      },
      {
        "id": 10954,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10955,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10956,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10957,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10958,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10959,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10960,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10961,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10962,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10963,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10964,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10965,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10966,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10967,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10968,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10969,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10970,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10971,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10972,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10973,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10974,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10975,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10976,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10977,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10978,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10979,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10980,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10981,
        "latitude": -29.754441,
        "longitude": -51.15165
      },
      {
        "id": 10983,
        "latitude": -28.97068,
        "longitude": -51.068767
      },
      {
        "id": 10984,
        "latitude": -28.97068,
        "longitude": -51.068767
      },
      {
        "id": 10985,
        "latitude": -29.615273,
        "longitude": -54.179031
      },
      {
        "id": 10987,
        "latitude": -29.639238,
        "longitude": -51.006859
      },
      {
        "id": 10988,
        "latitude": -29.639238,
        "longitude": -51.006859
      },
      {
        "id": 10989,
        "latitude": -29.639238,
        "longitude": -51.006859
      },
      {
        "id": 10990,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10991,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10992,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10993,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10994,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10995,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10997,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10998,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 10999,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11000,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11001,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11002,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11003,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11004,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11005,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11006,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11007,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11008,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11009,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11010,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11011,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11012,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11013,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11014,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11015,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11016,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11017,
        "latitude": -29.819702,
        "longitude": -51.160872
      },
      {
        "id": 11018,
        "latitude": -30.611886,
        "longitude": -51.581712
      },
      {
        "id": 11019,
        "latitude": -30.611886,
        "longitude": -51.581712
      },
      {
        "id": 11020,
        "latitude": -28.069233,
        "longitude": -52.011716
      },
      {
        "id": 11021,
        "latitude": -29.64039,
        "longitude": -50.80061
      },
      {
        "id": 11022,
        "latitude": -29.645204,
        "longitude": -50.783217
      },
      {
        "id": 11023,
        "latitude": -29.79433,
        "longitude": -51.865346
      },
      {
        "id": 11024,
        "latitude": -29.481657,
        "longitude": -51.813752
      },
      {
        "id": 11025,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11027,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11028,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11029,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11030,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11031,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11032,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11033,
        "latitude": -29.985534,
        "longitude": -50.131608
      },
      {
        "id": 11034,
        "latitude": -29.515502,
        "longitude": -50.777064
      },
      {
        "id": 11035,
        "latitude": -29.497324,
        "longitude": -50.786758
      },
      {
        "id": 11036,
        "latitude": -29.079876,
        "longitude": -53.838667
      },
      {
        "id": 11037,
        "latitude": -29.079876,
        "longitude": -53.838667
      },
      {
        "id": 11038,
        "latitude": -27.762299,
        "longitude": -54.482932
      },
      {
        "id": 11039,
        "latitude": -29.756073,
        "longitude": -57.086755
      },
      {
        "id": 11040,
        "latitude": -29.756073,
        "longitude": -57.086755
      },
      {
        "id": 11041,
        "latitude": -29.756073,
        "longitude": -57.086755
      },
      {
        "id": 11042,
        "latitude": -28.51029,
        "longitude": -50.935604
      },
      {
        "id": 11043,
        "latitude": -28.51029,
        "longitude": -50.935604
      },
      {
        "id": 11044,
        "latitude": -28.51029,
        "longitude": -50.935604
      },
      {
        "id": 11045,
        "latitude": -29.614306,
        "longitude": -52.193159
      },
      {
        "id": 11046,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11047,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11049,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11050,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11052,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11053,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11055,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11056,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11057,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11058,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11059,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11060,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11061,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11062,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11063,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11064,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11065,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11066,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11067,
        "latitude": -30.073583,
        "longitude": -51.038529
      },
      {
        "id": 11068,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11069,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11071,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11072,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11073,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11074,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11075,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11076,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11077,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11078,
        "latitude": -30.081934,
        "longitude": -51.026193
      },
      {
        "id": 11079,
        "latitude": -9.907652,
        "longitude": -63.033069
      },
      {
        "id": 11080,
        "latitude": -11.4333,
        "longitude": -61.44205
      },
      {
        "id": 11081,
        "latitude": -13.186987,
        "longitude": -60.816836
      },
      {
        "id": 11082,
        "latitude": -10.780424,
        "longitude": -65.332783
      },
      {
        "id": 11083,
        "latitude": -10.877815,
        "longitude": -61.927785
      },
      {
        "id": 11084,
        "latitude": -10.877815,
        "longitude": -61.927785
      },
      {
        "id": 11085,
        "latitude": -10.877815,
        "longitude": -61.927785
      },
      {
        "id": 11086,
        "latitude": -9.185,
        "longitude": -61.926403
      },
      {
        "id": 11087,
        "latitude": -10.717914,
        "longitude": -62.256661
      },
      {
        "id": 11088,
        "latitude": -8.749453,
        "longitude": -63.873544
      },
      {
        "id": 11089,
        "latitude": -8.749453,
        "longitude": -63.873544
      },
      {
        "id": 11090,
        "latitude": -8.749453,
        "longitude": -63.873544
      },
      {
        "id": 11091,
        "latitude": -8.749453,
        "longitude": -63.873544
      },
      {
        "id": 11094,
        "latitude": -11.727071,
        "longitude": -61.771411
      },
      {
        "id": 11095,
        "latitude": -11.727071,
        "longitude": -61.771411
      },
      {
        "id": 11096,
        "latitude": -11.727071,
        "longitude": -61.771411
      },
      {
        "id": 11097,
        "latitude": -11.727071,
        "longitude": -61.771411
      },
      {
        "id": 11098,
        "latitude": 0.938731,
        "longitude": -60.423646
      },
      {
        "id": 11099,
        "latitude": -26.370332,
        "longitude": -48.721099
      },
      {
        "id": 11100,
        "latitude": -28.934883,
        "longitude": -49.485839
      },
      {
        "id": 11101,
        "latitude": -28.934883,
        "longitude": -49.485839
      },
      {
        "id": 11102,
        "latitude": -28.934883,
        "longitude": -49.485839
      },
      {
        "id": 11103,
        "latitude": -26.956998,
        "longitude": -49.376775
      },
      {
        "id": 11105,
        "latitude": -26.63382,
        "longitude": -48.6835
      },
      {
        "id": 11106,
        "latitude": -26.63382,
        "longitude": -48.6835
      },
      {
        "id": 11107,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11108,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11109,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11110,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11111,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11112,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11113,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11114,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11115,
        "latitude": -27.496128,
        "longitude": -48.654243
      },
      {
        "id": 11116,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11117,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11118,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11119,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11120,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11121,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11122,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11123,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11124,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11125,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11126,
        "latitude": -26.919557,
        "longitude": -49.065803
      },
      {
        "id": 11127,
        "latitude": -27.096463,
        "longitude": -48.913632
      },
      {
        "id": 11128,
        "latitude": -27.096463,
        "longitude": -48.913632
      },
      {
        "id": 11129,
        "latitude": -27.096463,
        "longitude": -48.913632
      },
      {
        "id": 11130,
        "latitude": -26.77567,
        "longitude": -51.012004
      },
      {
        "id": 11131,
        "latitude": -27.075193,
        "longitude": -53.250213
      },
      {
        "id": 11133,
        "latitude": -27.02736,
        "longitude": -48.653566
      },
      {
        "id": 11134,
        "latitude": -27.344065,
        "longitude": -51.61104
      },
      {
        "id": 11135,
        "latitude": -27.092236,
        "longitude": -52.616688
      },
      {
        "id": 11136,
        "latitude": -27.092236,
        "longitude": -52.616688
      },
      {
        "id": 11137,
        "latitude": -27.092236,
        "longitude": -52.616688
      },
      {
        "id": 11138,
        "latitude": -27.092236,
        "longitude": -52.616688
      },
      {
        "id": 11139,
        "latitude": -27.092236,
        "longitude": -52.616688
      },
      {
        "id": 11140,
        "latitude": -27.231201,
        "longitude": -52.023102
      },
      {
        "id": 11141,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11142,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11143,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11144,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11145,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11146,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11147,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11148,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11149,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11150,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11151,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11152,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11153,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11154,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11155,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11156,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11157,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11158,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11159,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11160,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11161,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11162,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11163,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11164,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11165,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11166,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11167,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11168,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11169,
        "latitude": -28.678994,
        "longitude": -49.369563
      },
      {
        "id": 11170,
        "latitude": -27.290066,
        "longitude": -50.577968
      },
      {
        "id": 11171,
        "latitude": -27.290066,
        "longitude": -50.577968
      },
      {
        "id": 11172,
        "latitude": -27.5973,
        "longitude": -48.54961
      },
      {
        "id": 11173,
        "latitude": -27.5973,
        "longitude": -48.54961
      },
      {
        "id": 11174,
        "latitude": -27.5973,
        "longitude": -48.54961
      },
      {
        "id": 11175,
        "latitude": -27.5973,
        "longitude": -48.54961
      },
      {
        "id": 11176,
        "latitude": -27.5973,
        "longitude": -48.54961
      },
      {
        "id": 11177,
        "latitude": -27.5973,
        "longitude": -48.54961
      },
      {
        "id": 11178,
        "latitude": -28.74876,
        "longitude": -49.473766
      },
      {
        "id": 11554,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11179,
        "latitude": -28.74876,
        "longitude": -49.473766
      },
      {
        "id": 11180,
        "latitude": -27.023288,
        "longitude": -50.919978
      },
      {
        "id": 11181,
        "latitude": -27.023288,
        "longitude": -50.919978
      },
      {
        "id": 11182,
        "latitude": -26.930731,
        "longitude": -48.956682
      },
      {
        "id": 11183,
        "latitude": -26.930731,
        "longitude": -48.956682
      },
      {
        "id": 11184,
        "latitude": -26.474481,
        "longitude": -48.998115
      },
      {
        "id": 11185,
        "latitude": -26.474481,
        "longitude": -48.998115
      },
      {
        "id": 11186,
        "latitude": -28.711655,
        "longitude": -49.297144
      },
      {
        "id": 11187,
        "latitude": -26.890175,
        "longitude": -49.241674
      },
      {
        "id": 11188,
        "latitude": -26.890175,
        "longitude": -49.241674
      },
      {
        "id": 11189,
        "latitude": -26.356701,
        "longitude": -49.909504
      },
      {
        "id": 11190,
        "latitude": -26.904679,
        "longitude": -48.655298
      },
      {
        "id": 11191,
        "latitude": -26.904679,
        "longitude": -48.655298
      },
      {
        "id": 11192,
        "latitude": -26.904679,
        "longitude": -48.655298
      },
      {
        "id": 11193,
        "latitude": -26.904679,
        "longitude": -48.655298
      },
      {
        "id": 11194,
        "latitude": -26.904679,
        "longitude": -48.655298
      },
      {
        "id": 11195,
        "latitude": -26.904679,
        "longitude": -48.655298
      },
      {
        "id": 11281,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11196,
        "latitude": -27.09472,
        "longitude": -48.613778
      },
      {
        "id": 11197,
        "latitude": -28.623737,
        "longitude": -49.028866
      },
      {
        "id": 11198,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11199,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11200,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11201,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11202,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11203,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11204,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11205,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11206,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11207,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11208,
        "latitude": -26.489743,
        "longitude": -49.077806
      },
      {
        "id": 11209,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11211,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11212,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11213,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11215,
        "latitude": -26.33274,
        "longitude": -48.845314
      },
      {
        "id": 11216,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11217,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11218,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11219,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11220,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11221,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11222,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11223,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11224,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11225,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11226,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11227,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11228,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11229,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11230,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11231,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11232,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11233,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11234,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11235,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11236,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11237,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11238,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11239,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11240,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11241,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11242,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11243,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11244,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11245,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11246,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11247,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11248,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11249,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11250,
        "latitude": -26.30449,
        "longitude": -48.848673
      },
      {
        "id": 11251,
        "latitude": -27.838935,
        "longitude": -50.350149
      },
      {
        "id": 11252,
        "latitude": -27.816566,
        "longitude": -50.325883
      },
      {
        "id": 11253,
        "latitude": -28.394224,
        "longitude": -49.39754
      },
      {
        "id": 11254,
        "latitude": -28.652228,
        "longitude": -49.213398
      },
      {
        "id": 11255,
        "latitude": -28.652228,
        "longitude": -49.213398
      },
      {
        "id": 11256,
        "latitude": -28.652228,
        "longitude": -49.213398
      },
      {
        "id": 11257,
        "latitude": -26.902769,
        "longitude": -52.907126
      },
      {
        "id": 11258,
        "latitude": -27.48323,
        "longitude": -50.11952
      },
      {
        "id": 11259,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11262,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11263,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11367,
        "latitude": -27.453536,
        "longitude": -51.55359
      },
      {
        "id": 11264,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11265,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11266,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11267,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11268,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11270,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11271,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11272,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11273,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11274,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11275,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11276,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11277,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11278,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11279,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11280,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11282,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11283,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11284,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11285,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11286,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11287,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11288,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11289,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11290,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11291,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11292,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11293,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11294,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11295,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11296,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11297,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11298,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11299,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11300,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11301,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11302,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11303,
        "latitude": -27.644777,
        "longitude": -48.664632
      },
      {
        "id": 11304,
        "latitude": -27.216261,
        "longitude": -49.643654
      },
      {
        "id": 11305,
        "latitude": -26.984189,
        "longitude": -50.007621
      },
      {
        "id": 11306,
        "latitude": -26.604932,
        "longitude": -53.057763
      },
      {
        "id": 11307,
        "latitude": -26.236894,
        "longitude": -48.634286
      },
      {
        "id": 11308,
        "latitude": -27.277547,
        "longitude": -48.84961
      },
      {
        "id": 11309,
        "latitude": -27.277547,
        "longitude": -48.84961
      },
      {
        "id": 11311,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11312,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11313,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11314,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11315,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11316,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11317,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11318,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11319,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11320,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11321,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11322,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11323,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11324,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11326,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11327,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11328,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11329,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11330,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11331,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11332,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11333,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11334,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11335,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11336,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11337,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11338,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11339,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11340,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11341,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11342,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11343,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11344,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11345,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11346,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11347,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11348,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11349,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11350,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11351,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11352,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11353,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11354,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11355,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11356,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11357,
        "latitude": -27.615773,
        "longitude": -48.627649
      },
      {
        "id": 11358,
        "latitude": -26.35566,
        "longitude": -52.849837
      },
      {
        "id": 11359,
        "latitude": -26.411571,
        "longitude": -49.074041
      },
      {
        "id": 11361,
        "latitude": -28.471488,
        "longitude": -49.014132
      },
      {
        "id": 11362,
        "latitude": -28.530481,
        "longitude": -49.059012
      },
      {
        "id": 11363,
        "latitude": -28.471488,
        "longitude": -49.014132
      },
      {
        "id": 11364,
        "latitude": -28.519138,
        "longitude": -49.320178
      },
      {
        "id": 11366,
        "latitude": -27.453536,
        "longitude": -51.55359
      },
      {
        "id": 11368,
        "latitude": -21.686652,
        "longitude": -51.076298
      },
      {
        "id": 11369,
        "latitude": -21.9527,
        "longitude": -51.413956
      },
      {
        "id": 11370,
        "latitude": -21.021364,
        "longitude": -47.3712
      },
      {
        "id": 11371,
        "latitude": -22.076415,
        "longitude": -51.47224
      },
      {
        "id": 11372,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11373,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11375,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11376,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11377,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11378,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11379,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11380,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11381,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11382,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11383,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11384,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11385,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11386,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11387,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11388,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11389,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11390,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11391,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11392,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11393,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11394,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11395,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11396,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11397,
        "latitude": -22.739246,
        "longitude": -47.330603
      },
      {
        "id": 11398,
        "latitude": -22.705353,
        "longitude": -46.779596
      },
      {
        "id": 11399,
        "latitude": -20.896505,
        "longitude": -51.374277
      },
      {
        "id": 11400,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11401,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11403,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11404,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11405,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11406,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11407,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11408,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11409,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11410,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11411,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11412,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11413,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11414,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11415,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11416,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11417,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11418,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11419,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11420,
        "latitude": -21.207992,
        "longitude": -50.439023
      },
      {
        "id": 11422,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11424,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11425,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11426,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11427,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11428,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11429,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11430,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11431,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11432,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11433,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11434,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11435,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11436,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11437,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11438,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11439,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11440,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11441,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11442,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11443,
        "latitude": -21.780463,
        "longitude": -48.153163
      },
      {
        "id": 11444,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11445,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11446,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11447,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11448,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11449,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11450,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11451,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11452,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11453,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11454,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11455,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11456,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11457,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11458,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11459,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11460,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11461,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11462,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11463,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11464,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11465,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11466,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11467,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11468,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11469,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11470,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11471,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11472,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11473,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11474,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11475,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11476,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11477,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11478,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11479,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11480,
        "latitude": -21.788671,
        "longitude": -48.17731
      },
      {
        "id": 11481,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11553,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11482,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11483,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11484,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11485,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11486,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11487,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11488,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11489,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11490,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11491,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11492,
        "latitude": -22.356948,
        "longitude": -47.383889
      },
      {
        "id": 11493,
        "latitude": -23.117739,
        "longitude": -46.554786
      },
      {
        "id": 11494,
        "latitude": -23.117739,
        "longitude": -46.554786
      },
      {
        "id": 11495,
        "latitude": -23.117739,
        "longitude": -46.554786
      },
      {
        "id": 11496,
        "latitude": -23.117739,
        "longitude": -46.554786
      },
      {
        "id": 11497,
        "latitude": -23.117739,
        "longitude": -46.554786
      },
      {
        "id": 11498,
        "latitude": -23.104522,
        "longitude": -48.92591
      },
      {
        "id": 11499,
        "latitude": -20.917897,
        "longitude": -49.451791
      },
      {
        "id": 11500,
        "latitude": -20.917897,
        "longitude": -49.451791
      },
      {
        "id": 11501,
        "latitude": -20.917897,
        "longitude": -49.451791
      },
      {
        "id": 11502,
        "latitude": -22.490859,
        "longitude": -48.558349
      },
      {
        "id": 11503,
        "latitude": -22.490859,
        "longitude": -48.558349
      },
      {
        "id": 11504,
        "latitude": -20.553144,
        "longitude": -48.569751
      },
      {
        "id": 11505,
        "latitude": -20.553144,
        "longitude": -48.569751
      },
      {
        "id": 11506,
        "latitude": -21.189885,
        "longitude": -48.155064
      },
      {
        "id": 11507,
        "latitude": -21.189885,
        "longitude": -48.155064
      },
      {
        "id": 11508,
        "latitude": -23.511218,
        "longitude": -46.876461
      },
      {
        "id": 11511,
        "latitude": -23.511218,
        "longitude": -46.876461
      },
      {
        "id": 11512,
        "latitude": -23.511218,
        "longitude": -46.876461
      },
      {
        "id": 11513,
        "latitude": -23.511218,
        "longitude": -46.876461
      },
      {
        "id": 11514,
        "latitude": -23.511218,
        "longitude": -46.876461
      },
      {
        "id": 11515,
        "latitude": -20.892867,
        "longitude": -47.592149
      },
      {
        "id": 11516,
        "latitude": -20.892867,
        "longitude": -47.592149
      },
      {
        "id": 11517,
        "latitude": -20.892867,
        "longitude": -47.592149
      },
      {
        "id": 11518,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11520,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11523,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11524,
        "latitude": -22.271049,
        "longitude": -49.070283
      },
      {
        "id": 11525,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11526,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11528,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11529,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11530,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11531,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11532,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11533,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11534,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11535,
        "latitude": -22.31655,
        "longitude": -49.063365
      },
      {
        "id": 11536,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11537,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11538,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11539,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11540,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11541,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11542,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11543,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11544,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11545,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11546,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11547,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11548,
        "latitude": -22.32181,
        "longitude": -49.070586
      },
      {
        "id": 11549,
        "latitude": -20.949077,
        "longitude": -48.479083
      },
      {
        "id": 11550,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11551,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11552,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11555,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11556,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11557,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11558,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11559,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11560,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11561,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11562,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11563,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11564,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11565,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 11566,
        "latitude": -21.993021,
        "longitude": -48.391737
      },
      {
        "id": 11567,
        "latitude": -22.142183,
        "longitude": -48.522276
      },
      {
        "id": 11568,
        "latitude": -23.283889,
        "longitude": -47.673823
      },
      {
        "id": 11569,
        "latitude": -23.283889,
        "longitude": -47.673823
      },
      {
        "id": 11570,
        "latitude": -23.283889,
        "longitude": -47.673823
      },
      {
        "id": 11571,
        "latitude": -23.135386,
        "longitude": -46.464805
      },
      {
        "id": 11572,
        "latitude": -23.135386,
        "longitude": -46.464805
      },
      {
        "id": 11573,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11574,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11575,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11576,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11577,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11578,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11579,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11580,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11581,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11582,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11583,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11584,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11585,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11586,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11587,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11588,
        "latitude": -22.887963,
        "longitude": -48.441071
      },
      {
        "id": 11590,
        "latitude": -22.952024,
        "longitude": -46.541859
      },
      {
        "id": 11591,
        "latitude": -20.98623,
        "longitude": -47.657877
      },
      {
        "id": 11592,
        "latitude": -20.98623,
        "longitude": -47.657877
      },
      {
        "id": 11593,
        "latitude": -20.98623,
        "longitude": -47.657877
      },
      {
        "id": 11594,
        "latitude": -23.099204,
        "longitude": -45.707645
      },
      {
        "id": 11595,
        "latitude": -23.055233,
        "longitude": -45.706877
      },
      {
        "id": 11596,
        "latitude": -23.099204,
        "longitude": -45.707645
      },
      {
        "id": 11597,
        "latitude": -23.099204,
        "longitude": -45.707645
      },
      {
        "id": 11598,
        "latitude": -23.099204,
        "longitude": -45.707645
      },
      {
        "id": 11599,
        "latitude": -23.099204,
        "longitude": -45.707645
      },
      {
        "id": 11600,
        "latitude": -22.672762,
        "longitude": -45.002227
      },
      {
        "id": 11601,
        "latitude": -23.356111,
        "longitude": -46.876944
      },
      {
        "id": 11602,
        "latitude": -23.356111,
        "longitude": -46.876944
      },
      {
        "id": 11603,
        "latitude": -23.356111,
        "longitude": -46.876944
      },
      {
        "id": 11604,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11605,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11606,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11694,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 11607,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11608,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11609,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11610,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11611,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11612,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11613,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11614,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11615,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11616,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11617,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11618,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11619,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11620,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11621,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11622,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11623,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11624,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11625,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 11626,
        "latitude": -23.207791,
        "longitude": -46.788919
      },
      {
        "id": 11628,
        "latitude": -22.692986,
        "longitude": -45.518492
      },
      {
        "id": 11629,
        "latitude": -23.006662,
        "longitude": -49.784063
      },
      {
        "id": 11630,
        "latitude": -23.006662,
        "longitude": -49.784063
      },
      {
        "id": 11631,
        "latitude": -23.47048,
        "longitude": -47.735231
      },
      {
        "id": 11632,
        "latitude": -22.99993,
        "longitude": -47.503107
      },
      {
        "id": 11633,
        "latitude": -23.62028,
        "longitude": -45.41306
      },
      {
        "id": 11634,
        "latitude": -23.62028,
        "longitude": -45.41306
      },
      {
        "id": 11635,
        "latitude": -23.62028,
        "longitude": -45.41306
      },
      {
        "id": 11636,
        "latitude": -23.572915,
        "longitude": -46.816004
      },
      {
        "id": 11637,
        "latitude": -23.523467,
        "longitude": -46.840681
      },
      {
        "id": 11638,
        "latitude": -23.523467,
        "longitude": -46.840681
      },
      {
        "id": 11639,
        "latitude": -23.523467,
        "longitude": -46.840681
      },
      {
        "id": 11640,
        "latitude": -21.133104,
        "longitude": -48.971282
      },
      {
        "id": 11641,
        "latitude": -21.1232,
        "longitude": -48.961845
      },
      {
        "id": 11642,
        "latitude": -21.133104,
        "longitude": -48.971282
      },
      {
        "id": 11643,
        "latitude": -21.133104,
        "longitude": -48.971282
      },
      {
        "id": 11644,
        "latitude": -21.133104,
        "longitude": -48.971282
      },
      {
        "id": 11645,
        "latitude": -21.049149,
        "longitude": -49.057892
      },
      {
        "id": 11646,
        "latitude": -20.902843,
        "longitude": -49.269967
      },
      {
        "id": 11647,
        "latitude": -22.50958,
        "longitude": -47.775468
      },
      {
        "id": 11648,
        "latitude": -21.559463,
        "longitude": -50.448624
      },
      {
        "id": 11649,
        "latitude": -22.64374,
        "longitude": -47.197209
      },
      {
        "id": 11651,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11652,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11653,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11654,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11655,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11656,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11657,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11658,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11659,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11660,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11661,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11662,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11663,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11664,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11665,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11666,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11667,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11668,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11669,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11670,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11671,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11672,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11673,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11674,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11675,
        "latitude": -23.603889,
        "longitude": -46.918889
      },
      {
        "id": 11676,
        "latitude": -22.578369,
        "longitude": -44.964204
      },
      {
        "id": 11677,
        "latitude": -23.885798,
        "longitude": -46.424187
      },
      {
        "id": 11678,
        "latitude": -23.686643,
        "longitude": -46.624694
      },
      {
        "id": 11679,
        "latitude": -23.686643,
        "longitude": -46.624694
      },
      {
        "id": 11680,
        "latitude": -23.686643,
        "longitude": -46.624694
      },
      {
        "id": 11681,
        "latitude": -23.6756,
        "longitude": -46.621665
      },
      {
        "id": 11682,
        "latitude": -23.686643,
        "longitude": -46.624694
      },
      {
        "id": 11683,
        "latitude": -23.8318,
        "longitude": -46.81562
      },
      {
        "id": 11684,
        "latitude": -23.8318,
        "longitude": -46.81562
      },
      {
        "id": 11685,
        "latitude": -22.192952,
        "longitude": -46.747002
      },
      {
        "id": 11686,
        "latitude": -22.274272,
        "longitude": -46.95233
      },
      {
        "id": 11687,
        "latitude": -22.274272,
        "longitude": -46.95233
      },
      {
        "id": 11688,
        "latitude": -23.390039,
        "longitude": -49.510902
      },
      {
        "id": 11689,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 11690,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 11691,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 11692,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 11695,
        "latitude": -20.282565,
        "longitude": -50.250076
      },
      {
        "id": 11697,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11698,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11699,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11700,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11701,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11702,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11703,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11704,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11705,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11706,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11707,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11708,
        "latitude": -23.541056,
        "longitude": -46.37097
      },
      {
        "id": 11709,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11710,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11711,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11712,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11713,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11714,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11715,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11716,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11717,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11718,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11719,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11720,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11721,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11722,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11723,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11724,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11725,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11726,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11915,
        "latitude": -23.257875,
        "longitude": -47.3025
      },
      {
        "id": 11727,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11728,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11729,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11730,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11731,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11732,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11733,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11734,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11735,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11736,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11737,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11738,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11739,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11740,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11741,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11742,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11743,
        "latitude": -20.538177,
        "longitude": -47.40098
      },
      {
        "id": 11744,
        "latitude": -23.281731,
        "longitude": -46.7449
      },
      {
        "id": 11745,
        "latitude": -23.281731,
        "longitude": -46.7449
      },
      {
        "id": 11746,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11747,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11748,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11749,
        "latitude": -23.327911,
        "longitude": -46.704934
      },
      {
        "id": 11750,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11751,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11752,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11753,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11754,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11755,
        "latitude": -23.332804,
        "longitude": -46.724446
      },
      {
        "id": 11756,
        "latitude": -22.212577,
        "longitude": -49.654799
      },
      {
        "id": 11757,
        "latitude": -21.798856,
        "longitude": -49.92881
      },
      {
        "id": 11758,
        "latitude": -20.324587,
        "longitude": -48.314882
      },
      {
        "id": 11759,
        "latitude": -20.79663,
        "longitude": -49.225392
      },
      {
        "id": 11761,
        "latitude": -20.42905,
        "longitude": -47.825007
      },
      {
        "id": 11762,
        "latitude": -20.42905,
        "longitude": -47.825007
      },
      {
        "id": 11763,
        "latitude": -21.032239,
        "longitude": -51.210046
      },
      {
        "id": 11764,
        "latitude": -23.417308,
        "longitude": -46.033553
      },
      {
        "id": 11765,
        "latitude": -22.805784,
        "longitude": -45.190893
      },
      {
        "id": 11766,
        "latitude": -21.35938,
        "longitude": -48.23158
      },
      {
        "id": 11767,
        "latitude": -21.35938,
        "longitude": -48.23158
      },
      {
        "id": 11768,
        "latitude": -21.35938,
        "longitude": -48.23158
      },
      {
        "id": 11769,
        "latitude": -23.992777,
        "longitude": -46.255833
      },
      {
        "id": 11770,
        "latitude": -23.992777,
        "longitude": -46.255833
      },
      {
        "id": 11771,
        "latitude": -23.992777,
        "longitude": -46.255833
      },
      {
        "id": 11773,
        "latitude": -23.992777,
        "longitude": -46.255833
      },
      {
        "id": 11774,
        "latitude": -23.992777,
        "longitude": -46.255833
      },
      {
        "id": 11775,
        "latitude": -23.992777,
        "longitude": -46.255833
      },
      {
        "id": 11777,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11778,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11779,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11780,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11782,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11783,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11784,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11785,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11786,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11787,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11788,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11789,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11790,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11791,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11792,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11793,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11794,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11795,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11796,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11797,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11798,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11799,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11800,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11801,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11802,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11803,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11804,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11805,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11806,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11807,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11808,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11809,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11810,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11811,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11812,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11813,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11814,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11815,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11816,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11817,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11818,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11819,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11820,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11821,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11822,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11823,
        "latitude": -23.467594,
        "longitude": -46.52777
      },
      {
        "id": 11824,
        "latitude": -22.866596,
        "longitude": -47.212297
      },
      {
        "id": 11825,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11826,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11827,
        "latitude": -22.841416,
        "longitude": -47.224185
      },
      {
        "id": 11828,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11829,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11830,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11831,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11832,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11833,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11834,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11835,
        "latitude": -22.862018,
        "longitude": -47.216422
      },
      {
        "id": 11836,
        "latitude": -21.892834,
        "longitude": -49.022608
      },
      {
        "id": 11837,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11838,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11839,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11840,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11841,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11842,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11843,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11844,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11845,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11846,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11847,
        "latitude": -21.75623,
        "longitude": -48.831903
      },
      {
        "id": 11848,
        "latitude": -22.516537,
        "longitude": -48.558256
      },
      {
        "id": 11849,
        "latitude": -20.041175,
        "longitude": -47.747668
      },
      {
        "id": 11850,
        "latitude": -20.041175,
        "longitude": -47.747668
      },
      {
        "id": 11851,
        "latitude": -24.744529,
        "longitude": -47.552553
      },
      {
        "id": 11852,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11854,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11855,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11856,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11857,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11858,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11859,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11860,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11861,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11862,
        "latitude": -23.09028,
        "longitude": -47.21806
      },
      {
        "id": 11916,
        "latitude": -23.240287,
        "longitude": -47.319145
      },
      {
        "id": 11863,
        "latitude": -23.350898,
        "longitude": -47.687611
      },
      {
        "id": 11864,
        "latitude": -23.350898,
        "longitude": -47.687611
      },
      {
        "id": 11865,
        "latitude": -21.312294,
        "longitude": -49.059634
      },
      {
        "id": 11866,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11867,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11868,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11869,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11870,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11871,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11872,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11873,
        "latitude": -24.184885,
        "longitude": -46.792157
      },
      {
        "id": 11874,
        "latitude": -23.71763,
        "longitude": -46.849244
      },
      {
        "id": 11875,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11876,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11877,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11878,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11879,
        "latitude": -23.585451,
        "longitude": -48.080066
      },
      {
        "id": 11880,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11881,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11882,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11883,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11884,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11885,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11886,
        "latitude": -23.588607,
        "longitude": -48.048326
      },
      {
        "id": 11887,
        "latitude": -23.54888,
        "longitude": -46.933889
      },
      {
        "id": 11888,
        "latitude": -22.436111,
        "longitude": -46.821667
      },
      {
        "id": 11889,
        "latitude": -24.573508,
        "longitude": -49.170385
      },
      {
        "id": 11890,
        "latitude": -21.595837,
        "longitude": -48.81227
      },
      {
        "id": 11891,
        "latitude": -21.595837,
        "longitude": -48.81227
      },
      {
        "id": 11892,
        "latitude": -21.595837,
        "longitude": -48.81227
      },
      {
        "id": 11893,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11894,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11895,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11896,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11897,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11898,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11899,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11900,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11901,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11902,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11903,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11904,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11905,
        "latitude": -23.475449,
        "longitude": -46.351403
      },
      {
        "id": 11906,
        "latitude": -24.114537,
        "longitude": -49.335246
      },
      {
        "id": 11907,
        "latitude": -24.114537,
        "longitude": -49.335246
      },
      {
        "id": 11908,
        "latitude": -24.114537,
        "longitude": -49.335246
      },
      {
        "id": 11909,
        "latitude": -24.114537,
        "longitude": -49.335246
      },
      {
        "id": 11910,
        "latitude": -23.005554,
        "longitude": -46.839773
      },
      {
        "id": 11911,
        "latitude": -23.005554,
        "longitude": -46.839773
      },
      {
        "id": 11913,
        "latitude": -23.005554,
        "longitude": -46.839773
      },
      {
        "id": 11914,
        "latitude": -23.26378,
        "longitude": -47.299846
      },
      {
        "id": 11917,
        "latitude": -23.26378,
        "longitude": -47.299846
      },
      {
        "id": 11918,
        "latitude": -23.26378,
        "longitude": -47.299846
      },
      {
        "id": 11919,
        "latitude": -23.26378,
        "longitude": -47.299846
      },
      {
        "id": 11920,
        "latitude": -23.153106,
        "longitude": -47.058035
      },
      {
        "id": 11921,
        "latitude": -23.140925,
        "longitude": -47.119409
      },
      {
        "id": 11922,
        "latitude": -23.153106,
        "longitude": -47.058035
      },
      {
        "id": 11923,
        "latitude": -23.153106,
        "longitude": -47.058035
      },
      {
        "id": 11924,
        "latitude": -20.33666,
        "longitude": -47.779792
      },
      {
        "id": 11925,
        "latitude": -20.33666,
        "longitude": -47.779792
      },
      {
        "id": 11926,
        "latitude": -20.33666,
        "longitude": -47.779792
      },
      {
        "id": 11927,
        "latitude": -21.252037,
        "longitude": -48.325223
      },
      {
        "id": 11928,
        "latitude": -21.252037,
        "longitude": -48.325223
      },
      {
        "id": 11929,
        "latitude": -21.252037,
        "longitude": -48.325223
      },
      {
        "id": 11930,
        "latitude": -21.252037,
        "longitude": -48.325223
      },
      {
        "id": 11931,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11932,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 12000,
        "latitude": -21.721129,
        "longitude": -51.0188
      },
      {
        "id": 11933,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11934,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11935,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11936,
        "latitude": -23.329823,
        "longitude": -45.995849
      },
      {
        "id": 11937,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11938,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11939,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11940,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11941,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11942,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11943,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11944,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11945,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11946,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11947,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11948,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 12071,
        "latitude": -20.534543,
        "longitude": -49.318695
      },
      {
        "id": 11949,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11950,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11951,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11952,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11953,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11954,
        "latitude": -23.305068,
        "longitude": -45.972308
      },
      {
        "id": 11955,
        "latitude": -22.70374,
        "longitude": -46.985062
      },
      {
        "id": 11956,
        "latitude": -20.267177,
        "longitude": -50.549244
      },
      {
        "id": 11957,
        "latitude": -20.267177,
        "longitude": -50.549244
      },
      {
        "id": 11958,
        "latitude": -20.267177,
        "longitude": -50.549244
      },
      {
        "id": 11959,
        "latitude": -23.528225,
        "longitude": -46.902003
      },
      {
        "id": 11960,
        "latitude": -23.544235,
        "longitude": -46.903716
      },
      {
        "id": 11961,
        "latitude": -23.528225,
        "longitude": -46.902003
      },
      {
        "id": 11962,
        "latitude": -23.528225,
        "longitude": -46.902003
      },
      {
        "id": 11963,
        "latitude": -23.528225,
        "longitude": -46.902003
      },
      {
        "id": 11964,
        "latitude": -23.528225,
        "longitude": -46.902003
      },
      {
        "id": 11965,
        "latitude": -21.025513,
        "longitude": -47.770681
      },
      {
        "id": 11966,
        "latitude": -21.025513,
        "longitude": -47.770681
      },
      {
        "id": 11967,
        "latitude": -23.094965,
        "longitude": -46.714857
      },
      {
        "id": 11968,
        "latitude": -23.094965,
        "longitude": -46.714857
      },
      {
        "id": 11970,
        "latitude": -23.564115,
        "longitude": -46.434737
      },
      {
        "id": 11972,
        "latitude": -23.188767,
        "longitude": -46.884506
      },
      {
        "id": 11973,
        "latitude": -23.188767,
        "longitude": -46.884506
      },
      {
        "id": 11974,
        "latitude": -23.188767,
        "longitude": -46.884506
      },
      {
        "id": 11975,
        "latitude": -23.188767,
        "longitude": -46.884506
      },
      {
        "id": 11976,
        "latitude": -21.510953,
        "longitude": -51.435151
      },
      {
        "id": 11977,
        "latitude": -23.051476,
        "longitude": -47.837013
      },
      {
        "id": 11978,
        "latitude": -23.051476,
        "longitude": -47.837013
      },
      {
        "id": 11979,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11980,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11981,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11982,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11983,
        "latitude": -22.572907,
        "longitude": -47.391257
      },
      {
        "id": 11984,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11985,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11986,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11987,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11988,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11989,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11990,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11991,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11992,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11993,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11994,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11995,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11996,
        "latitude": -22.561507,
        "longitude": -47.401766
      },
      {
        "id": 11997,
        "latitude": -21.674187,
        "longitude": -49.751893
      },
      {
        "id": 11998,
        "latitude": -21.674187,
        "longitude": -49.751893
      },
      {
        "id": 11999,
        "latitude": -22.736765,
        "longitude": -45.107088
      },
      {
        "id": 12001,
        "latitude": -23.540125,
        "longitude": -47.184632
      },
      {
        "id": 12002,
        "latitude": -23.540125,
        "longitude": -47.184632
      },
      {
        "id": 12003,
        "latitude": -23.319035,
        "longitude": -46.587638
      },
      {
        "id": 12004,
        "latitude": -23.319035,
        "longitude": -46.587638
      },
      {
        "id": 12006,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12008,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12009,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12010,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12011,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12012,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12013,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12014,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12015,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12016,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12017,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12018,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12019,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12020,
        "latitude": -22.238845,
        "longitude": -49.925102
      },
      {
        "id": 12021,
        "latitude": -22.2172,
        "longitude": -49.950006
      },
      {
        "id": 12022,
        "latitude": -21.603361,
        "longitude": -48.36606
      },
      {
        "id": 12023,
        "latitude": -23.683391,
        "longitude": -46.454717
      },
      {
        "id": 12024,
        "latitude": -23.666657,
        "longitude": -46.438953
      },
      {
        "id": 12025,
        "latitude": -23.666953,
        "longitude": -46.461692
      },
      {
        "id": 12026,
        "latitude": -23.666953,
        "longitude": -46.461692
      },
      {
        "id": 12027,
        "latitude": -22.413319,
        "longitude": -48.450554
      },
      {
        "id": 12028,
        "latitude": -24.283908,
        "longitude": -47.453513
      },
      {
        "id": 12029,
        "latitude": -21.132831,
        "longitude": -51.102942
      },
      {
        "id": 12030,
        "latitude": -21.132831,
        "longitude": -51.102942
      },
      {
        "id": 12031,
        "latitude": -20.814141,
        "longitude": -49.507449
      },
      {
        "id": 12032,
        "latitude": -20.814141,
        "longitude": -49.507449
      },
      {
        "id": 12033,
        "latitude": -20.814141,
        "longitude": -49.507449
      },
      {
        "id": 12034,
        "latitude": -20.814141,
        "longitude": -49.507449
      },
      {
        "id": 12035,
        "latitude": -20.814141,
        "longitude": -49.507449
      },
      {
        "id": 12036,
        "latitude": -20.814141,
        "longitude": -49.507449
      },
      {
        "id": 12037,
        "latitude": -21.464731,
        "longitude": -47.002405
      },
      {
        "id": 12038,
        "latitude": -23.523428,
        "longitude": -46.192667
      },
      {
        "id": 12039,
        "latitude": -23.539643,
        "longitude": -46.260791
      },
      {
        "id": 12041,
        "latitude": -23.523428,
        "longitude": -46.192667
      },
      {
        "id": 12042,
        "latitude": -23.523428,
        "longitude": -46.192667
      },
      {
        "id": 12043,
        "latitude": -23.523428,
        "longitude": -46.192667
      },
      {
        "id": 12044,
        "latitude": -23.523428,
        "longitude": -46.192667
      },
      {
        "id": 12045,
        "latitude": -23.523428,
        "longitude": -46.192667
      },
      {
        "id": 12046,
        "latitude": -22.372222,
        "longitude": -46.9425
      },
      {
        "id": 12047,
        "latitude": -22.372222,
        "longitude": -46.9425
      },
      {
        "id": 12048,
        "latitude": -22.372222,
        "longitude": -46.9425
      },
      {
        "id": 12049,
        "latitude": -22.372222,
        "longitude": -46.9425
      },
      {
        "id": 12050,
        "latitude": -22.372222,
        "longitude": -46.9425
      },
      {
        "id": 12051,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12053,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12055,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12056,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12057,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12058,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12059,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12060,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12061,
        "latitude": -24.095945,
        "longitude": -46.623436
      },
      {
        "id": 12062,
        "latitude": -22.945043,
        "longitude": -47.312182
      },
      {
        "id": 12063,
        "latitude": -22.945043,
        "longitude": -47.312182
      },
      {
        "id": 12064,
        "latitude": -22.945043,
        "longitude": -47.312182
      },
      {
        "id": 12065,
        "latitude": -22.945043,
        "longitude": -47.312182
      },
      {
        "id": 12066,
        "latitude": -22.945043,
        "longitude": -47.312182
      },
      {
        "id": 12067,
        "latitude": -23.378379,
        "longitude": -45.443817
      },
      {
        "id": 12068,
        "latitude": -23.181931,
        "longitude": -46.399299
      },
      {
        "id": 12069,
        "latitude": -20.842992,
        "longitude": -49.635767
      },
      {
        "id": 12070,
        "latitude": -20.534543,
        "longitude": -49.318695
      },
      {
        "id": 12072,
        "latitude": -22.780575,
        "longitude": -47.299381
      },
      {
        "id": 12073,
        "latitude": -22.780575,
        "longitude": -47.299381
      },
      {
        "id": 12074,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12075,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12076,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12077,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12078,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12079,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12080,
        "latitude": -21.467456,
        "longitude": -49.223538
      },
      {
        "id": 12081,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12082,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12083,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12084,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12085,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12086,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12087,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12088,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12089,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12090,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12091,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12092,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12093,
        "latitude": -23.504169,
        "longitude": -46.792004
      },
      {
        "id": 12094,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12095,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12096,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12097,
        "latitude": -23.532486,
        "longitude": -46.79168
      },
      {
        "id": 12098,
        "latitude": -22.977792,
        "longitude": -49.868204
      },
      {
        "id": 12099,
        "latitude": -22.977792,
        "longitude": -49.868204
      },
      {
        "id": 12100,
        "latitude": -22.977792,
        "longitude": -49.868204
      },
      {
        "id": 12101,
        "latitude": -23.38642,
        "longitude": -45.66268
      },
      {
        "id": 12102,
        "latitude": -20.303942,
        "longitude": -50.013581
      },
      {
        "id": 12103,
        "latitude": -20.303942,
        "longitude": -50.013581
      },
      {
        "id": 12104,
        "latitude": -22.763039,
        "longitude": -47.153221
      },
      {
        "id": 12105,
        "latitude": -21.419241,
        "longitude": -50.076559
      },
      {
        "id": 12106,
        "latitude": -21.419241,
        "longitude": -50.076559
      },
      {
        "id": 12107,
        "latitude": -21.419241,
        "longitude": -50.076559
      },
      {
        "id": 12108,
        "latitude": -20.637851,
        "longitude": -51.105217
      },
      {
        "id": 12109,
        "latitude": -20.637851,
        "longitude": -51.105217
      },
      {
        "id": 12110,
        "latitude": -20.637851,
        "longitude": -51.105217
      },
      {
        "id": 12111,
        "latitude": -24.321811,
        "longitude": -46.997745
      },
      {
        "id": 12112,
        "latitude": -24.321811,
        "longitude": -46.997745
      },
      {
        "id": 12113,
        "latitude": -24.321811,
        "longitude": -46.997745
      },
      {
        "id": 12114,
        "latitude": -24.321811,
        "longitude": -46.997745
      },
      {
        "id": 12115,
        "latitude": -24.321811,
        "longitude": -46.997745
      },
      {
        "id": 12116,
        "latitude": -24.321811,
        "longitude": -46.997745
      },
      {
        "id": 12117,
        "latitude": -23.70741,
        "longitude": -47.425232
      },
      {
        "id": 12119,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12120,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12121,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12122,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12123,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12124,
        "latitude": -22.937662,
        "longitude": -45.452244
      },
      {
        "id": 12125,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12126,
        "latitude": -22.9364,
        "longitude": -45.46226
      },
      {
        "id": 12127,
        "latitude": -21.1853,
        "longitude": -48.90861
      },
      {
        "id": 12128,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12129,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12130,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12131,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12132,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12133,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12134,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12135,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12136,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12137,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12138,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12139,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12140,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12141,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12142,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12143,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12144,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12145,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12146,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12147,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12148,
        "latitude": -22.746316,
        "longitude": -47.680871
      },
      {
        "id": 12149,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12150,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12151,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12152,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12153,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12154,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12155,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12156,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12157,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12158,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12159,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12160,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12161,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12162,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12163,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12164,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12165,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12166,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12167,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12168,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12169,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12170,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12171,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12172,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12173,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12597,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12174,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12175,
        "latitude": -22.725165,
        "longitude": -47.649327
      },
      {
        "id": 12176,
        "latitude": -21.995788,
        "longitude": -49.454968
      },
      {
        "id": 12177,
        "latitude": -22.273874,
        "longitude": -51.499887
      },
      {
        "id": 12178,
        "latitude": -22.273874,
        "longitude": -51.499887
      },
      {
        "id": 12179,
        "latitude": -21.998047,
        "longitude": -47.428086
      },
      {
        "id": 12180,
        "latitude": -21.998047,
        "longitude": -47.428086
      },
      {
        "id": 12181,
        "latitude": -21.998047,
        "longitude": -47.428086
      },
      {
        "id": 12182,
        "latitude": -23.524908,
        "longitude": -46.344566
      },
      {
        "id": 12183,
        "latitude": -23.524908,
        "longitude": -46.344566
      },
      {
        "id": 12184,
        "latitude": -22.108829,
        "longitude": -50.172075
      },
      {
        "id": 12185,
        "latitude": -21.023971,
        "longitude": -48.039346
      },
      {
        "id": 12186,
        "latitude": -20.44071,
        "longitude": -50.523533
      },
      {
        "id": 12187,
        "latitude": -23.211071,
        "longitude": -47.524716
      },
      {
        "id": 12188,
        "latitude": -23.211071,
        "longitude": -47.524716
      },
      {
        "id": 12189,
        "latitude": -23.211071,
        "longitude": -47.524716
      },
      {
        "id": 12190,
        "latitude": -21.356327,
        "longitude": -48.080916
      },
      {
        "id": 12191,
        "latitude": -21.359782,
        "longitude": -48.07466
      },
      {
        "id": 12193,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12194,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12195,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12196,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12197,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12198,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12199,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12200,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12201,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12202,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12203,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12204,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12205,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12206,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12207,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12208,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12209,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12210,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12211,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12212,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12213,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12214,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12215,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12216,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12217,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12218,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12219,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12220,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12221,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12222,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12223,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12224,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12225,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12226,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12227,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12228,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12229,
        "latitude": -24.008979,
        "longitude": -46.414494
      },
      {
        "id": 12230,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12231,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12232,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12233,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12234,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12235,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12236,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12237,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12238,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12239,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12240,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12241,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12984,
        "latitude": -22.97056,
        "longitude": -46.99583
      },
      {
        "id": 12242,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12243,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12244,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12245,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12246,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12247,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12248,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12249,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12250,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12251,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12252,
        "latitude": -22.122517,
        "longitude": -51.388253
      },
      {
        "id": 12253,
        "latitude": -21.540491,
        "longitude": -49.857471
      },
      {
        "id": 12254,
        "latitude": -22.219106,
        "longitude": -51.303456
      },
      {
        "id": 12255,
        "latitude": -21.888412,
        "longitude": -49.22273
      },
      {
        "id": 12256,
        "latitude": -23.712939,
        "longitude": -46.415087
      },
      {
        "id": 12390,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12260,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12261,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12262,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12263,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12264,
        "latitude": -21.102108,
        "longitude": -47.829715
      },
      {
        "id": 12265,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12267,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12268,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12269,
        "latitude": -21.156856,
        "longitude": -47.814196
      },
      {
        "id": 12270,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12271,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12272,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12273,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 13053,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 12274,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12275,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12276,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12277,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12278,
        "latitude": -21.185453,
        "longitude": -47.787256
      },
      {
        "id": 12279,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12280,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12281,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12282,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12283,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12284,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12285,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12286,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12287,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12288,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12289,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12290,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12291,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12292,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12293,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12294,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12295,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12296,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12297,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12298,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12299,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12300,
        "latitude": -21.103681,
        "longitude": -47.825743
      },
      {
        "id": 12301,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12302,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12303,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12304,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12305,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12306,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12307,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12308,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12309,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12310,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12311,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12312,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12313,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12314,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12315,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12316,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12317,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12318,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12319,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12320,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12321,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12322,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12323,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12324,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12325,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12326,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12327,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12328,
        "latitude": -21.177632,
        "longitude": -47.810098
      },
      {
        "id": 12329,
        "latitude": -21.58811,
        "longitude": -48.072426
      },
      {
        "id": 12330,
        "latitude": -22.410011,
        "longitude": -47.560393
      },
      {
        "id": 12331,
        "latitude": -22.410011,
        "longitude": -47.560393
      },
      {
        "id": 12332,
        "latitude": -22.410011,
        "longitude": -47.560393
      },
      {
        "id": 12333,
        "latitude": -22.410011,
        "longitude": -47.560393
      },
      {
        "id": 12335,
        "latitude": -23.198998,
        "longitude": -47.291368
      },
      {
        "id": 12336,
        "latitude": -23.198998,
        "longitude": -47.291368
      },
      {
        "id": 12337,
        "latitude": -23.64925,
        "longitude": -47.572947
      },
      {
        "id": 12338,
        "latitude": -23.64925,
        "longitude": -47.572947
      },
      {
        "id": 12339,
        "latitude": -20.032349,
        "longitude": -50.727839
      },
      {
        "id": 12340,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12341,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12342,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12343,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12344,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12345,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12346,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12347,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12348,
        "latitude": -22.754254,
        "longitude": -47.413788
      },
      {
        "id": 12349,
        "latitude": -23.397435,
        "longitude": -45.884229
      },
      {
        "id": 12350,
        "latitude": -22.906072,
        "longitude": -49.627738
      },
      {
        "id": 12351,
        "latitude": -22.906072,
        "longitude": -49.627738
      },
      {
        "id": 12352,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12353,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12354,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12391,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12358,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12359,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12360,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12361,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12362,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12363,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12364,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12365,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12366,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12367,
        "latitude": -23.669716,
        "longitude": -46.516308
      },
      {
        "id": 12368,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12369,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12370,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12371,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12577,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12372,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12373,
        "latitude": -23.653351,
        "longitude": -46.527904
      },
      {
        "id": 12374,
        "latitude": -21.089305,
        "longitude": -47.152343
      },
      {
        "id": 12375,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12376,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12378,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12379,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12380,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12381,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12382,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12383,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12384,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12385,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12386,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12387,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12388,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12389,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12392,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12393,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12394,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12395,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12396,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12397,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12398,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12399,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12400,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12401,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12402,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12403,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12404,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12405,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12406,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12407,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12408,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12409,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12410,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12411,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12412,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12413,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12414,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12415,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12416,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12417,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12418,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12419,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12420,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12421,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12422,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12423,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12424,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12425,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12426,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12427,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12428,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12429,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12430,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12431,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12432,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12433,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12434,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12435,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12436,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12437,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12438,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12439,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12440,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12441,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12442,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12443,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12444,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12445,
        "latitude": -23.933599,
        "longitude": -46.32864
      },
      {
        "id": 12447,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12448,
        "latitude": -23.735886,
        "longitude": -46.555712
      },
      {
        "id": 12449,
        "latitude": -23.735886,
        "longitude": -46.555712
      },
      {
        "id": 12450,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12452,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12453,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12454,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12455,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12456,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12457,
        "latitude": -23.716799,
        "longitude": -46.542461
      },
      {
        "id": 12458,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12459,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12460,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12461,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12462,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12463,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12464,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12465,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12466,
        "latitude": -23.658344,
        "longitude": -46.574629
      },
      {
        "id": 12467,
        "latitude": -23.657498,
        "longitude": -46.575495
      },
      {
        "id": 12468,
        "latitude": -23.619592,
        "longitude": -46.568832
      },
      {
        "id": 12469,
        "latitude": -23.619592,
        "longitude": -46.568832
      },
      {
        "id": 12470,
        "latitude": -23.647461,
        "longitude": -46.568826
      },
      {
        "id": 12471,
        "latitude": -23.619592,
        "longitude": -46.568832
      },
      {
        "id": 12472,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12473,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12474,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12475,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12476,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12477,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12478,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12479,
        "latitude": -21.955573,
        "longitude": -47.884399
      },
      {
        "id": 12480,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12481,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12482,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12483,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12484,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12485,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12486,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12487,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12488,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12489,
        "latitude": -22.01804,
        "longitude": -47.891154
      },
      {
        "id": 12490,
        "latitude": -22.31804,
        "longitude": -49.086286
      },
      {
        "id": 12491,
        "latitude": -22.31804,
        "longitude": -49.086286
      },
      {
        "id": 12492,
        "latitude": -22.31804,
        "longitude": -49.086286
      },
      {
        "id": 12493,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12494,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12495,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12496,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12497,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12498,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12500,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12501,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12502,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12503,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12504,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12505,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12506,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12507,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12509,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12510,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12511,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12512,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12513,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12514,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12515,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12516,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12517,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12518,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12519,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12520,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12521,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12522,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12523,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12524,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12525,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12526,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12527,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12528,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12529,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12530,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12531,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12532,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12533,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12534,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12535,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12536,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12537,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12538,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12539,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12540,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12541,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12542,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12543,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12544,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12545,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12546,
        "latitude": -20.812585,
        "longitude": -49.380421
      },
      {
        "id": 12551,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12552,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12553,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12554,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12555,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12556,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12557,
        "latitude": -23.213362,
        "longitude": -45.806303
      },
      {
        "id": 12558,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12559,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12560,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12561,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12562,
        "latitude": -23.123152,
        "longitude": -45.516448
      },
      {
        "id": 12565,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12569,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12570,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12571,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12572,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12573,
        "latitude": -23.478185,
        "longitude": -46.696229
      },
      {
        "id": 12574,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12576,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12579,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12580,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12581,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12582,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12583,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12584,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12585,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12586,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12587,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12588,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12589,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12590,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12591,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12592,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12593,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12594,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12595,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12598,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12599,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12601,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12602,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12603,
        "latitude": -23.701141,
        "longitude": -46.706962
      },
      {
        "id": 12604,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12606,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12607,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12609,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12610,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12611,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12612,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12614,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12615,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12616,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12617,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12618,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12619,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12620,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12623,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12624,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12625,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12627,
        "latitude": -23.539721,
        "longitude": -46.521287
      },
      {
        "id": 12628,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12629,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12630,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12631,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12632,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12633,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12634,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12635,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12636,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12637,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12638,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12641,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12643,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12644,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12645,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12646,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12647,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12648,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12649,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12650,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12651,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12652,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12653,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12654,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12655,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12656,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12657,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12659,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12660,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12661,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12662,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12663,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12664,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12665,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12666,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12667,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12668,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12669,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12670,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12671,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12672,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12673,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12674,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12675,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12676,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12677,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12678,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12679,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12680,
        "latitude": -23.618803,
        "longitude": -46.599172
      },
      {
        "id": 12681,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12700,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12682,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12683,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12684,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12685,
        "latitude": -23.541009,
        "longitude": -46.7444
      },
      {
        "id": 12686,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12687,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12688,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12689,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12690,
        "latitude": -23.46644,
        "longitude": -46.568245
      },
      {
        "id": 12691,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12692,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12693,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12694,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12695,
        "latitude": -23.562637,
        "longitude": -46.599309
      },
      {
        "id": 12696,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12697,
        "latitude": -22.954313,
        "longitude": -45.454758
      },
      {
        "id": 12698,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12699,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12701,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12702,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12703,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12704,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12705,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12706,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12707,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12708,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12709,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12710,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12711,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12712,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12713,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12714,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12715,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12716,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12717,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12718,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12719,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12720,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12721,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12722,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12723,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12724,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12725,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12726,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12727,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12728,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12729,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12730,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12731,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12732,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12733,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12734,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12735,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12736,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12737,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12738,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12739,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12740,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12741,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12742,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12743,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12744,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12745,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12746,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12747,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12748,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12749,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12750,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12751,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12752,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12753,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12754,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12755,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12756,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12757,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12758,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12759,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12760,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12761,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12762,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12763,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12764,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12765,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12782,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12766,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12767,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12768,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12769,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12770,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12771,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12772,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12773,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12774,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12775,
        "latitude": -23.563197,
        "longitude": -46.555006
      },
      {
        "id": 12776,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12777,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12778,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12779,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12780,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12781,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12783,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12784,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12785,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12786,
        "latitude": -23.636091,
        "longitude": -46.646139
      },
      {
        "id": 12787,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12788,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12789,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12790,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12791,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12792,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12793,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12794,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12795,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12796,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12797,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12798,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12799,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12800,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12801,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12802,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12803,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12804,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 12805,
        "latitude": -23.581014,
        "longitude": -46.589166
      },
      {
        "id": 12806,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12808,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12809,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12810,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12811,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12812,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12813,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12814,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12815,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12816,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12817,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12818,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12819,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12820,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12821,
        "latitude": -23.966418,
        "longitude": -46.38614
      },
      {
        "id": 12822,
        "latitude": -21.206688,
        "longitude": -47.605377
      },
      {
        "id": 12824,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12825,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12826,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12827,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12828,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12829,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12830,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12831,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12947,
        "latitude": -23.348985,
        "longitude": -47.849017
      },
      {
        "id": 12832,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12833,
        "latitude": -21.137578,
        "longitude": -47.991374
      },
      {
        "id": 12834,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12835,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12836,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12837,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12838,
        "latitude": -23.422117,
        "longitude": -47.462697
      },
      {
        "id": 12839,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12840,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12841,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12842,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12843,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12844,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12845,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12846,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12847,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12848,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12849,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12850,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12851,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12852,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12853,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12854,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12855,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12856,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12857,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12858,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12859,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12860,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12861,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12862,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12863,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12864,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12965,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12865,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12866,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12867,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12868,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12869,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12870,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12871,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12872,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12873,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12874,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12875,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12876,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12877,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12878,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12879,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12880,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12881,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12882,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12883,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12884,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12885,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12886,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12887,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12888,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12889,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12890,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12891,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12892,
        "latitude": -23.445241,
        "longitude": -47.380014
      },
      {
        "id": 12893,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12894,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12895,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12896,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12983,
        "latitude": -22.97056,
        "longitude": -46.99583
      },
      {
        "id": 12897,
        "latitude": -23.500345,
        "longitude": -47.458286
      },
      {
        "id": 12898,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12899,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12900,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12901,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12902,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12903,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12904,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12905,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12906,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12907,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12908,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12909,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12910,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12911,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12912,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 13317,
        "latitude": -22.922423,
        "longitude": -43.2142
      },
      {
        "id": 12913,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12914,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12915,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12916,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12917,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12918,
        "latitude": -22.821796,
        "longitude": -47.267105
      },
      {
        "id": 12919,
        "latitude": -20.501119,
        "longitude": -51.024677
      },
      {
        "id": 12920,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12921,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12922,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12923,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12924,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12925,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12926,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12927,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12928,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12929,
        "latitude": -23.542784,
        "longitude": -46.310839
      },
      {
        "id": 12930,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12931,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12932,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12933,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12934,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12935,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12936,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12937,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12938,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12939,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12940,
        "latitude": -23.612191,
        "longitude": -46.779054
      },
      {
        "id": 12941,
        "latitude": -23.348985,
        "longitude": -47.849017
      },
      {
        "id": 12942,
        "latitude": -23.348985,
        "longitude": -47.849017
      },
      {
        "id": 12943,
        "latitude": -23.353408,
        "longitude": -47.858826
      },
      {
        "id": 12944,
        "latitude": -23.348985,
        "longitude": -47.849017
      },
      {
        "id": 12945,
        "latitude": -23.348985,
        "longitude": -47.849017
      },
      {
        "id": 12946,
        "latitude": -23.348985,
        "longitude": -47.849017
      },
      {
        "id": 12948,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12949,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12950,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12951,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12952,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12953,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12954,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12955,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12956,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12957,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12958,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12959,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12960,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12961,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12962,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12963,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12964,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12966,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12967,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12968,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12969,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12970,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12971,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12972,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12973,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12974,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12975,
        "latitude": -23.031448,
        "longitude": -45.561279
      },
      {
        "id": 12976,
        "latitude": -22.53002,
        "longitude": -52.168685
      },
      {
        "id": 12977,
        "latitude": -23.10303,
        "longitude": -47.714794
      },
      {
        "id": 12978,
        "latitude": -23.433162,
        "longitude": -45.083415
      },
      {
        "id": 12979,
        "latitude": -23.433162,
        "longitude": -45.083415
      },
      {
        "id": 12980,
        "latitude": -20.244491,
        "longitude": -50.645087
      },
      {
        "id": 12981,
        "latitude": -20.413606,
        "longitude": -50.094931
      },
      {
        "id": 12982,
        "latitude": -20.422611,
        "longitude": -50.086151
      },
      {
        "id": 12985,
        "latitude": -22.97056,
        "longitude": -46.99583
      },
      {
        "id": 12986,
        "latitude": -22.97056,
        "longitude": -46.99583
      },
      {
        "id": 12987,
        "latitude": -22.97056,
        "longitude": -46.99583
      },
      {
        "id": 12988,
        "latitude": -23.599338,
        "longitude": -47.022038
      },
      {
        "id": 12989,
        "latitude": -23.599338,
        "longitude": -47.022038
      },
      {
        "id": 12990,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12991,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12992,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12993,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12994,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12995,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12996,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12997,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12998,
        "latitude": -23.53831,
        "longitude": -47.44225
      },
      {
        "id": 12999,
        "latitude": -20.425291,
        "longitude": -49.972028
      },
      {
        "id": 13000,
        "latitude": -20.425291,
        "longitude": -49.972028
      },
      {
        "id": 13001,
        "latitude": -20.425291,
        "longitude": -49.972028
      },
      {
        "id": 13002,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13003,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13004,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13005,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13006,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13007,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13008,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13009,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13010,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13011,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13012,
        "latitude": -10.916206,
        "longitude": -37.077466
      },
      {
        "id": 13013,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13014,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13015,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13016,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13017,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13018,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13019,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13020,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13021,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13022,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13023,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13024,
        "latitude": -10.907569,
        "longitude": -37.038625
      },
      {
        "id": 13025,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13026,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13027,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13028,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13029,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13030,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13031,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13033,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13034,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13035,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13036,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13037,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13038,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13039,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13040,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13041,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13042,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13043,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13044,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13045,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13046,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13047,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13048,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13049,
        "latitude": -10.50494,
        "longitude": -37.055257
      },
      {
        "id": 13050,
        "latitude": -11.475702,
        "longitude": -37.755521
      },
      {
        "id": 13051,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 13052,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 13054,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 13055,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 13056,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 13057,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 13058,
        "latitude": -10.549566,
        "longitude": -37.535154
      },
      {
        "id": 13059,
        "latitude": -10.549566,
        "longitude": -37.535154
      },
      {
        "id": 13060,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13061,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13063,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13064,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13065,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13066,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13067,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13068,
        "latitude": -10.68562,
        "longitude": -37.427052
      },
      {
        "id": 13069,
        "latitude": -11.276014,
        "longitude": -37.78666
      },
      {
        "id": 13070,
        "latitude": -11.276014,
        "longitude": -37.78666
      },
      {
        "id": 13159,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13071,
        "latitude": -11.276014,
        "longitude": -37.78666
      },
      {
        "id": 13072,
        "latitude": -11.276014,
        "longitude": -37.78666
      },
      {
        "id": 13073,
        "latitude": -11.276014,
        "longitude": -37.78666
      },
      {
        "id": 13074,
        "latitude": -11.276014,
        "longitude": -37.78666
      },
      {
        "id": 13075,
        "latitude": -10.996167,
        "longitude": -37.304584
      },
      {
        "id": 13076,
        "latitude": -10.593489,
        "longitude": -36.941514
      },
      {
        "id": 13077,
        "latitude": -10.316989,
        "longitude": -36.57689
      },
      {
        "id": 13078,
        "latitude": -10.49275,
        "longitude": -37.193168
      },
      {
        "id": 13079,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13082,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13083,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13084,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13085,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13087,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13088,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13089,
        "latitude": -10.855312,
        "longitude": -37.126486
      },
      {
        "id": 13090,
        "latitude": -10.711999,
        "longitude": -38.17492
      },
      {
        "id": 13091,
        "latitude": -10.711999,
        "longitude": -38.17492
      },
      {
        "id": 13092,
        "latitude": -10.711999,
        "longitude": -38.17492
      },
      {
        "id": 13093,
        "latitude": -10.711999,
        "longitude": -38.17492
      },
      {
        "id": 13094,
        "latitude": -10.711999,
        "longitude": -38.17492
      },
      {
        "id": 13095,
        "latitude": -9.915276,
        "longitude": -37.279137
      },
      {
        "id": 13096,
        "latitude": -10.212427,
        "longitude": -36.837225
      },
      {
        "id": 13097,
        "latitude": -10.212427,
        "longitude": -36.837225
      },
      {
        "id": 13098,
        "latitude": -11.028245,
        "longitude": -37.47828
      },
      {
        "id": 13100,
        "latitude": -10.73883,
        "longitude": -37.809016
      },
      {
        "id": 13101,
        "latitude": -11.187872,
        "longitude": -38.00304
      },
      {
        "id": 13102,
        "latitude": -11.187872,
        "longitude": -38.00304
      },
      {
        "id": 13104,
        "latitude": -11.375248,
        "longitude": -37.84134
      },
      {
        "id": 13105,
        "latitude": -8.061269,
        "longitude": -48.471677
      },
      {
        "id": 13106,
        "latitude": -12.134792,
        "longitude": -49.173714
      },
      {
        "id": 13315,
        "latitude": -22.855632,
        "longitude": -43.311685
      },
      {
        "id": 13107,
        "latitude": -11.72794,
        "longitude": -49.068046
      },
      {
        "id": 13108,
        "latitude": -11.72794,
        "longitude": -49.068046
      },
      {
        "id": 13109,
        "latitude": -9.561794,
        "longitude": -48.396654
      },
      {
        "id": 13110,
        "latitude": -9.410818,
        "longitude": -35.506497
      },
      {
        "id": 13112,
        "latitude": -2.911183,
        "longitude": -60.038781
      },
      {
        "id": 13113,
        "latitude": -3.083715,
        "longitude": -59.978985
      },
      {
        "id": 13114,
        "latitude": -12.53505,
        "longitude": -40.296816
      },
      {
        "id": 13115,
        "latitude": -15.24994,
        "longitude": -40.241608
      },
      {
        "id": 13116,
        "latitude": -1.411655,
        "longitude": -48.393267
      },
      {
        "id": 13117,
        "latitude": -12.999551,
        "longitude": -38.510602
      },
      {
        "id": 13118,
        "latitude": -14.880181,
        "longitude": -40.837074
      },
      {
        "id": 13120,
        "latitude": -3.790849,
        "longitude": -38.546742
      },
      {
        "id": 13121,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 13122,
        "latitude": -3.830174,
        "longitude": -38.478636
      },
      {
        "id": 13123,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 13124,
        "latitude": -3.82449,
        "longitude": -38.478745
      },
      {
        "id": 13125,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 13129,
        "latitude": -10.924744,
        "longitude": -38.055388
      },
      {
        "id": 13130,
        "latitude": -20.849768,
        "longitude": -41.158154
      },
      {
        "id": 13131,
        "latitude": -20.197398,
        "longitude": -40.248083
      },
      {
        "id": 13132,
        "latitude": -20.168218,
        "longitude": -40.192592
      },
      {
        "id": 13133,
        "latitude": -20.298049,
        "longitude": -40.300462
      },
      {
        "id": 13137,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 13139,
        "latitude": -18.173651,
        "longitude": -47.941292
      },
      {
        "id": 13140,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 13141,
        "latitude": -3.866328,
        "longitude": -38.587371
      },
      {
        "id": 13142,
        "latitude": -15.779274,
        "longitude": -48.785487
      },
      {
        "id": 13143,
        "latitude": -16.727179,
        "longitude": -49.347626
      },
      {
        "id": 13144,
        "latitude": -16.542734,
        "longitude": -49.401476
      },
      {
        "id": 13145,
        "latitude": -15.869897,
        "longitude": -50.863986
      },
      {
        "id": 13147,
        "latitude": -16.151599,
        "longitude": -47.955172
      },
      {
        "id": 13148,
        "latitude": -28.257071,
        "longitude": -52.421919
      },
      {
        "id": 13149,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 13150,
        "latitude": -10.832073,
        "longitude": -37.060203
      },
      {
        "id": 13151,
        "latitude": -16.248027,
        "longitude": -47.960758
      },
      {
        "id": 13152,
        "latitude": -19.817445,
        "longitude": -43.941659
      },
      {
        "id": 13153,
        "latitude": -16.408317,
        "longitude": -49.223478
      },
      {
        "id": 13155,
        "latitude": -5.133927,
        "longitude": -42.775301
      },
      {
        "id": 13156,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13157,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13158,
        "latitude": -27.642608,
        "longitude": -48.694278
      },
      {
        "id": 13161,
        "latitude": -15.150253,
        "longitude": -47.76799
      },
      {
        "id": 13162,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 13163,
        "latitude": -16.081197,
        "longitude": -47.975973
      },
      {
        "id": 13164,
        "latitude": -16.070548,
        "longitude": -48.001595
      },
      {
        "id": 13165,
        "latitude": -16.072898,
        "longitude": -48.009333
      },
      {
        "id": 13166,
        "latitude": -15.801944,
        "longitude": -47.889668
      },
      {
        "id": 13167,
        "latitude": -16.116387,
        "longitude": -47.9686
      },
      {
        "id": 13168,
        "latitude": -16.060447,
        "longitude": -47.972354
      },
      {
        "id": 13169,
        "latitude": -5.506711,
        "longitude": -45.249828
      },
      {
        "id": 13170,
        "latitude": -4.136985,
        "longitude": -44.130339
      },
      {
        "id": 13171,
        "latitude": -2.506678,
        "longitude": -44.210013
      },
      {
        "id": 13172,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 13173,
        "latitude": -15.626204,
        "longitude": -55.974625
      },
      {
        "id": 13174,
        "latitude": -14.711355,
        "longitude": -56.319658
      },
      {
        "id": 13175,
        "latitude": -11.862643,
        "longitude": -55.486386
      },
      {
        "id": 13176,
        "latitude": -20.486101,
        "longitude": -55.822326
      },
      {
        "id": 13178,
        "latitude": -20.559477,
        "longitude": -54.603204
      },
      {
        "id": 13179,
        "latitude": -20.435005,
        "longitude": -54.61933
      },
      {
        "id": 13180,
        "latitude": -22.261568,
        "longitude": -54.769748
      },
      {
        "id": 13182,
        "latitude": -22.080423,
        "longitude": -46.57307
      },
      {
        "id": 13183,
        "latitude": -18.650869,
        "longitude": -48.181447
      },
      {
        "id": 13185,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 13186,
        "latitude": -19.934435,
        "longitude": -44.099759
      },
      {
        "id": 13187,
        "latitude": -19.986353,
        "longitude": -44.184409
      },
      {
        "id": 13189,
        "latitude": -20.163905,
        "longitude": -44.842151
      },
      {
        "id": 13190,
        "latitude": -20.172606,
        "longitude": -44.852716
      },
      {
        "id": 13191,
        "latitude": -20.022751,
        "longitude": -44.054349
      },
      {
        "id": 13192,
        "latitude": -19.824644,
        "longitude": -44.603473
      },
      {
        "id": 13316,
        "latitude": -22.84343,
        "longitude": -43.354
      },
      {
        "id": 13194,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 13199,
        "latitude": -16.712848,
        "longitude": -43.853246
      },
      {
        "id": 13200,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 13202,
        "latitude": -19.281415,
        "longitude": -44.402093
      },
      {
        "id": 13203,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 13204,
        "latitude": -18.951107,
        "longitude": -46.991199
      },
      {
        "id": 13205,
        "latitude": -22.223582,
        "longitude": -45.934907
      },
      {
        "id": 13206,
        "latitude": -22.291817,
        "longitude": -45.916744
      },
      {
        "id": 13207,
        "latitude": -19.779297,
        "longitude": -43.910916
      },
      {
        "id": 13208,
        "latitude": -19.416522,
        "longitude": -44.234719
      },
      {
        "id": 13209,
        "latitude": -19.416522,
        "longitude": -44.234719
      },
      {
        "id": 13210,
        "latitude": -23.568583,
        "longitude": -46.84162
      },
      {
        "id": 13211,
        "latitude": -19.545088,
        "longitude": -42.621684
      },
      {
        "id": 13212,
        "latitude": -19.700706,
        "longitude": -47.653149
      },
      {
        "id": 13214,
        "latitude": -19.774842,
        "longitude": -47.949624
      },
      {
        "id": 13215,
        "latitude": -18.953448,
        "longitude": -48.318476
      },
      {
        "id": 13217,
        "latitude": -18.942951,
        "longitude": -48.339102
      },
      {
        "id": 13218,
        "latitude": -18.952744,
        "longitude": -48.322636
      },
      {
        "id": 13219,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13220,
        "latitude": -18.926003,
        "longitude": -48.315035
      },
      {
        "id": 13222,
        "latitude": -19.697454,
        "longitude": -43.920415
      },
      {
        "id": 13223,
        "latitude": -21.009911,
        "longitude": -42.836006
      },
      {
        "id": 13224,
        "latitude": -1.343595,
        "longitude": -48.400723
      },
      {
        "id": 13225,
        "latitude": -1.335659,
        "longitude": -48.399615
      },
      {
        "id": 13226,
        "latitude": -2.130787,
        "longitude": -47.565531
      },
      {
        "id": 13227,
        "latitude": -1.275899,
        "longitude": -47.932467
      },
      {
        "id": 13230,
        "latitude": -18.393155,
        "longitude": -49.218908
      },
      {
        "id": 13231,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 13233,
        "latitude": -7.598009,
        "longitude": -72.76737
      },
      {
        "id": 13235,
        "latitude": -7.098477,
        "longitude": -34.866102
      },
      {
        "id": 13236,
        "latitude": -7.076283,
        "longitude": -36.062993
      },
      {
        "id": 13237,
        "latitude": -6.973483,
        "longitude": -34.98048
      },
      {
        "id": 13239,
        "latitude": -24.96001,
        "longitude": -53.455156
      },
      {
        "id": 13241,
        "latitude": -25.584117,
        "longitude": -49.321017
      },
      {
        "id": 13242,
        "latitude": -25.459935,
        "longitude": -49.280018
      },
      {
        "id": 13243,
        "latitude": -25.492227,
        "longitude": -50.671782
      },
      {
        "id": 13244,
        "latitude": -25.553336,
        "longitude": -48.551379
      },
      {
        "id": 13245,
        "latitude": -23.802602,
        "longitude": -53.681956
      },
      {
        "id": 13246,
        "latitude": -23.423695,
        "longitude": -51.86016
      },
      {
        "id": 13247,
        "latitude": -24.168473,
        "longitude": -54.093496
      },
      {
        "id": 13248,
        "latitude": -8.236898,
        "longitude": -35.747351
      },
      {
        "id": 13249,
        "latitude": -8.02078,
        "longitude": -35.006924
      },
      {
        "id": 13250,
        "latitude": -8.02078,
        "longitude": -35.006924
      },
      {
        "id": 13251,
        "latitude": -7.762404,
        "longitude": -34.853478
      },
      {
        "id": 13252,
        "latitude": -8.023449,
        "longitude": -34.868223
      },
      {
        "id": 13253,
        "latitude": -7.887356,
        "longitude": -34.831492
      },
      {
        "id": 13255,
        "latitude": -8.06667,
        "longitude": -34.95
      },
      {
        "id": 13256,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 13257,
        "latitude": -7.872647,
        "longitude": -38.756422
      },
      {
        "id": 13258,
        "latitude": -7.994158,
        "longitude": -35.032829
      },
      {
        "id": 13259,
        "latitude": -9.071845,
        "longitude": -44.357992
      },
      {
        "id": 13260,
        "latitude": -5.320489,
        "longitude": -41.552803
      },
      {
        "id": 13261,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 13262,
        "latitude": -5.156115,
        "longitude": -42.787662
      },
      {
        "id": 13263,
        "latitude": -4.938202,
        "longitude": -43.048127
      },
      {
        "id": 13266,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 13268,
        "latitude": -22.750728,
        "longitude": -42.936256
      },
      {
        "id": 13269,
        "latitude": -22.747424,
        "longitude": -42.885474
      },
      {
        "id": 13270,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 13272,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 13273,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 13274,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 13275,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 13276,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 13277,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 13278,
        "latitude": -22.958393,
        "longitude": -42.986451
      },
      {
        "id": 13279,
        "latitude": -15.840398,
        "longitude": -48.01826
      },
      {
        "id": 13280,
        "latitude": -22.814166,
        "longitude": -43.410281
      },
      {
        "id": 13281,
        "latitude": -22.813454,
        "longitude": -43.413208
      },
      {
        "id": 13283,
        "latitude": -22.904041,
        "longitude": -43.111785
      },
      {
        "id": 13284,
        "latitude": -22.202707,
        "longitude": -42.396675
      },
      {
        "id": 13286,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 13287,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 13288,
        "latitude": -22.402655,
        "longitude": -43.103477
      },
      {
        "id": 13289,
        "latitude": -22.510777,
        "longitude": -43.186622
      },
      {
        "id": 13290,
        "latitude": -22.48132,
        "longitude": -44.479897
      },
      {
        "id": 13291,
        "latitude": -22.48132,
        "longitude": -44.479897
      },
      {
        "id": 13292,
        "latitude": -22.493407,
        "longitude": -41.930307
      },
      {
        "id": 13293,
        "latitude": -22.89997,
        "longitude": -43.57851
      },
      {
        "id": 13294,
        "latitude": -22.83497,
        "longitude": -43.29188
      },
      {
        "id": 13295,
        "latitude": -22.884024,
        "longitude": -43.423957
      },
      {
        "id": 13296,
        "latitude": -22.999205,
        "longitude": -43.344453
      },
      {
        "id": 13297,
        "latitude": -22.87623,
        "longitude": -43.51456
      },
      {
        "id": 13298,
        "latitude": -22.909909,
        "longitude": -43.291135
      },
      {
        "id": 13299,
        "latitude": -22.91817,
        "longitude": -43.20397
      },
      {
        "id": 13303,
        "latitude": -22.924539,
        "longitude": -43.367273
      },
      {
        "id": 13304,
        "latitude": -23.001152,
        "longitude": -43.390441
      },
      {
        "id": 13305,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 13306,
        "latitude": -22.892277,
        "longitude": -43.236753
      },
      {
        "id": 13307,
        "latitude": -22.857276,
        "longitude": -43.304855
      },
      {
        "id": 13308,
        "latitude": -22.964361,
        "longitude": -43.616991
      },
      {
        "id": 13309,
        "latitude": -22.872636,
        "longitude": -43.271022
      },
      {
        "id": 13310,
        "latitude": -23.020731,
        "longitude": -43.461778
      },
      {
        "id": 13311,
        "latitude": -22.936403,
        "longitude": -43.371359
      },
      {
        "id": 13312,
        "latitude": -22.885176,
        "longitude": -43.366322
      },
      {
        "id": 13313,
        "latitude": -22.853103,
        "longitude": -43.270658
      },
      {
        "id": 13314,
        "latitude": -22.870385,
        "longitude": -43.431617
      },
      {
        "id": 13602,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 13319,
        "latitude": -22.59959,
        "longitude": -43.180341
      },
      {
        "id": 13320,
        "latitude": -22.850586,
        "longitude": -43.097749
      },
      {
        "id": 13321,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13322,
        "latitude": -22.832177,
        "longitude": -42.977493
      },
      {
        "id": 13323,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13325,
        "latitude": -22.538066,
        "longitude": -43.198711
      },
      {
        "id": 13326,
        "latitude": -22.448639,
        "longitude": -43.829556
      },
      {
        "id": 13327,
        "latitude": -5.75292,
        "longitude": -35.264721
      },
      {
        "id": 13328,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 13329,
        "latitude": -5.91377,
        "longitude": -35.284732
      },
      {
        "id": 13330,
        "latitude": -6.269126,
        "longitude": -35.21241
      },
      {
        "id": 13331,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 13332,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 13333,
        "latitude": -21.826684,
        "longitude": -46.906085
      },
      {
        "id": 13334,
        "latitude": -5.635388,
        "longitude": -35.434909
      },
      {
        "id": 13335,
        "latitude": -29.990472,
        "longitude": -51.064686
      },
      {
        "id": 13337,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 13338,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 13339,
        "latitude": -29.668676,
        "longitude": -51.156089
      },
      {
        "id": 13340,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 13341,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 13342,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 13343,
        "latitude": -29.669556,
        "longitude": -51.145846
      },
      {
        "id": 13344,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 13345,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 13346,
        "latitude": -29.142242,
        "longitude": -51.215569
      },
      {
        "id": 13347,
        "latitude": -29.144925,
        "longitude": -51.176712
      },
      {
        "id": 13348,
        "latitude": -29.126102,
        "longitude": -51.16378
      },
      {
        "id": 13349,
        "latitude": -29.153359,
        "longitude": -51.213127
      },
      {
        "id": 13350,
        "latitude": -27.642816,
        "longitude": -52.256387
      },
      {
        "id": 14116,
        "latitude": -15.330546,
        "longitude": -43.67672
      },
      {
        "id": 13351,
        "latitude": -29.648885,
        "longitude": -51.151148
      },
      {
        "id": 13352,
        "latitude": -29.862539,
        "longitude": -51.1559
      },
      {
        "id": 13353,
        "latitude": -29.952123,
        "longitude": -51.052607
      },
      {
        "id": 13354,
        "latitude": -29.919561,
        "longitude": -51.070715
      },
      {
        "id": 13356,
        "latitude": -30.142704,
        "longitude": -52.038573
      },
      {
        "id": 13358,
        "latitude": -29.69181,
        "longitude": -51.119311
      },
      {
        "id": 13359,
        "latitude": -31.763627,
        "longitude": -52.322694
      },
      {
        "id": 13360,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 13361,
        "latitude": -31.767806,
        "longitude": -52.342159
      },
      {
        "id": 13362,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 13363,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14510,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 13368,
        "latitude": -30.089882,
        "longitude": -51.132681
      },
      {
        "id": 13370,
        "latitude": -29.986029,
        "longitude": -51.191436
      },
      {
        "id": 13371,
        "latitude": -8.848513,
        "longitude": -36.132607
      },
      {
        "id": 13372,
        "latitude": -29.980934,
        "longitude": -51.125213
      },
      {
        "id": 13373,
        "latitude": -30.033751,
        "longitude": -51.2266
      },
      {
        "id": 13374,
        "latitude": -30.093339,
        "longitude": -51.220688
      },
      {
        "id": 13375,
        "latitude": -30.115153,
        "longitude": -51.228616
      },
      {
        "id": 13376,
        "latitude": -29.684007,
        "longitude": -53.790699
      },
      {
        "id": 13377,
        "latitude": -27.865444,
        "longitude": -54.466326
      },
      {
        "id": 13378,
        "latitude": -30.033587,
        "longitude": -50.165372
      },
      {
        "id": 13379,
        "latitude": -30.040464,
        "longitude": -50.161532
      },
      {
        "id": 13380,
        "latitude": -30.08146,
        "longitude": -51.041351
      },
      {
        "id": 13382,
        "latitude": -26.910668,
        "longitude": -49.13306
      },
      {
        "id": 13383,
        "latitude": -27.107601,
        "longitude": -48.909055
      },
      {
        "id": 13384,
        "latitude": -26.875827,
        "longitude": -48.662676
      },
      {
        "id": 15002,
        "latitude": -22.901775,
        "longitude": -47.059382
      },
      {
        "id": 13385,
        "latitude": -28.691356,
        "longitude": -49.366645
      },
      {
        "id": 13386,
        "latitude": -26.87482,
        "longitude": -48.696748
      },
      {
        "id": 13387,
        "latitude": -27.681867,
        "longitude": -48.672309
      },
      {
        "id": 13388,
        "latitude": -19.501524,
        "longitude": -42.575543
      },
      {
        "id": 13390,
        "latitude": -27.595514,
        "longitude": -48.612348
      },
      {
        "id": 13391,
        "latitude": -27.545394,
        "longitude": -48.633082
      },
      {
        "id": 13393,
        "latitude": -22.758249,
        "longitude": -47.204679
      },
      {
        "id": 13394,
        "latitude": -22.73884,
        "longitude": -47.279917
      },
      {
        "id": 13395,
        "latitude": -21.838555,
        "longitude": -48.141652
      },
      {
        "id": 13396,
        "latitude": -21.838555,
        "longitude": -48.141652
      },
      {
        "id": 13397,
        "latitude": -22.3293,
        "longitude": -49.047742
      },
      {
        "id": 13398,
        "latitude": -21.290849,
        "longitude": -50.34144
      },
      {
        "id": 13399,
        "latitude": -22.899717,
        "longitude": -48.436577
      },
      {
        "id": 13400,
        "latitude": -22.904243,
        "longitude": -47.109468
      },
      {
        "id": 13401,
        "latitude": -23.626201,
        "longitude": -46.938771
      },
      {
        "id": 13402,
        "latitude": -23.626201,
        "longitude": -46.938771
      },
      {
        "id": 13403,
        "latitude": -23.626201,
        "longitude": -46.938771
      },
      {
        "id": 13404,
        "latitude": -23.530385,
        "longitude": -46.615032
      },
      {
        "id": 13405,
        "latitude": -23.537757,
        "longitude": -46.371597
      },
      {
        "id": 13406,
        "latitude": -20.411148,
        "longitude": -47.28488
      },
      {
        "id": 13407,
        "latitude": -23.537261,
        "longitude": -46.919961
      },
      {
        "id": 13408,
        "latitude": -21.253237,
        "longitude": -50.643346
      },
      {
        "id": 13409,
        "latitude": -23.98842,
        "longitude": -46.263312
      },
      {
        "id": 13410,
        "latitude": -23.408212,
        "longitude": -46.401559
      },
      {
        "id": 13411,
        "latitude": -21.142556,
        "longitude": -48.941492
      },
      {
        "id": 13412,
        "latitude": -20.041141,
        "longitude": -47.753124
      },
      {
        "id": 13414,
        "latitude": -23.243743,
        "longitude": -45.908971
      },
      {
        "id": 13415,
        "latitude": -23.160761,
        "longitude": -46.920091
      },
      {
        "id": 13416,
        "latitude": -21.419241,
        "longitude": -50.076559
      },
      {
        "id": 13417,
        "latitude": -21.419983,
        "longitude": -50.077457
      },
      {
        "id": 13418,
        "latitude": -22.751548,
        "longitude": -47.666561
      },
      {
        "id": 13419,
        "latitude": -22.753741,
        "longitude": -47.679718
      },
      {
        "id": 13420,
        "latitude": -22.755466,
        "longitude": -47.61646
      },
      {
        "id": 13421,
        "latitude": -22.696861,
        "longitude": -47.677268
      },
      {
        "id": 13422,
        "latitude": -24.032525,
        "longitude": -46.514463
      },
      {
        "id": 13423,
        "latitude": -22.129985,
        "longitude": -51.404801
      },
      {
        "id": 13424,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 13425,
        "latitude": -21.146161,
        "longitude": -47.986731
      },
      {
        "id": 13426,
        "latitude": -22.149528,
        "longitude": -51.176524
      },
      {
        "id": 13427,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 13428,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 13429,
        "latitude": -21.1961,
        "longitude": -47.732742
      },
      {
        "id": 13430,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 13431,
        "latitude": -23.578362,
        "longitude": -46.736164
      },
      {
        "id": 13432,
        "latitude": -22.367793,
        "longitude": -47.90803
      },
      {
        "id": 13433,
        "latitude": -23.597315,
        "longitude": -47.582864
      },
      {
        "id": 13434,
        "latitude": -23.650936,
        "longitude": -47.583874
      },
      {
        "id": 13435,
        "latitude": -22.801544,
        "longitude": -47.325922
      },
      {
        "id": 13436,
        "latitude": -21.976651,
        "longitude": -46.787727
      },
      {
        "id": 13437,
        "latitude": -20.782769,
        "longitude": -49.351995
      },
      {
        "id": 13438,
        "latitude": -23.182982,
        "longitude": -45.81293
      },
      {
        "id": 13439,
        "latitude": -23.24692,
        "longitude": -45.830452
      },
      {
        "id": 13440,
        "latitude": -23.623992,
        "longitude": -46.468413
      },
      {
        "id": 13441,
        "latitude": -23.567348,
        "longitude": -46.613317
      },
      {
        "id": 13442,
        "latitude": -23.514663,
        "longitude": -46.598324
      },
      {
        "id": 13443,
        "latitude": -23.698651,
        "longitude": -46.605549
      },
      {
        "id": 13444,
        "latitude": -23.523057,
        "longitude": -46.528776
      },
      {
        "id": 13445,
        "latitude": -23.437091,
        "longitude": -46.74038
      },
      {
        "id": 13446,
        "latitude": -23.524708,
        "longitude": -46.399215
      },
      {
        "id": 13447,
        "latitude": -23.434918,
        "longitude": -46.741056
      },
      {
        "id": 13448,
        "latitude": -23.626998,
        "longitude": -46.756703
      },
      {
        "id": 13449,
        "latitude": -23.968947,
        "longitude": -46.375138
      },
      {
        "id": 13450,
        "latitude": -23.46544,
        "longitude": -47.445165
      },
      {
        "id": 13451,
        "latitude": -23.46544,
        "longitude": -47.445165
      },
      {
        "id": 13452,
        "latitude": -23.571938,
        "longitude": -47.72237
      },
      {
        "id": 13453,
        "latitude": -23.46544,
        "longitude": -47.445165
      },
      {
        "id": 13454,
        "latitude": -23.512356,
        "longitude": -47.492553
      },
      {
        "id": 13455,
        "latitude": -23.517751,
        "longitude": -47.469932
      },
      {
        "id": 13456,
        "latitude": -22.823847,
        "longitude": -47.237604
      },
      {
        "id": 13457,
        "latitude": -22.983047,
        "longitude": -47.0348
      },
      {
        "id": 13458,
        "latitude": -23.605068,
        "longitude": -46.77292
      },
      {
        "id": 13460,
        "latitude": -23.026673,
        "longitude": -45.556172
      },
      {
        "id": 13461,
        "latitude": -23.445445,
        "longitude": -46.616941
      },
      {
        "id": 13462,
        "latitude": -21.529727,
        "longitude": -42.199357
      },
      {
        "id": 13463,
        "latitude": -10.792582,
        "longitude": -37.051473
      },
      {
        "id": 13464,
        "latitude": -21.529727,
        "longitude": -42.199357
      },
      {
        "id": 13465,
        "latitude": -10.685045,
        "longitude": -37.430958
      },
      {
        "id": 13466,
        "latitude": -9.906274,
        "longitude": -36.357134
      },
      {
        "id": 13467,
        "latitude": -2.990883,
        "longitude": -60.030052
      },
      {
        "id": 13468,
        "latitude": -23.428888,
        "longitude": -51.442899
      },
      {
        "id": 13469,
        "latitude": -20.222427,
        "longitude": -40.226065
      },
      {
        "id": 13470,
        "latitude": -12.2,
        "longitude": -37.91667
      },
      {
        "id": 13471,
        "latitude": -15.24994,
        "longitude": -40.241608
      },
      {
        "id": 13472,
        "latitude": -12.885395,
        "longitude": -38.343461
      },
      {
        "id": 13473,
        "latitude": -12.95543,
        "longitude": -38.440695
      },
      {
        "id": 13474,
        "latitude": -22.846965,
        "longitude": -47.077519
      },
      {
        "id": 13475,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 13476,
        "latitude": -14.853541,
        "longitude": -40.817605
      },
      {
        "id": 13478,
        "latitude": -20.145671,
        "longitude": -40.270525
      },
      {
        "id": 13479,
        "latitude": -1.356835,
        "longitude": -48.367142
      },
      {
        "id": 13480,
        "latitude": -7.082802,
        "longitude": -37.253085
      },
      {
        "id": 13481,
        "latitude": -15.023947,
        "longitude": -51.303965
      },
      {
        "id": 13483,
        "latitude": -15.824504,
        "longitude": -47.927115
      },
      {
        "id": 13484,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13485,
        "latitude": -16.952627,
        "longitude": -39.773428
      },
      {
        "id": 13487,
        "latitude": -16.073018,
        "longitude": -48.081606
      },
      {
        "id": 13488,
        "latitude": -15.46464,
        "longitude": -47.601375
      },
      {
        "id": 13489,
        "latitude": -13.265058,
        "longitude": -46.876
      },
      {
        "id": 13490,
        "latitude": -15.456356,
        "longitude": -47.625225
      },
      {
        "id": 13491,
        "latitude": -15.946991,
        "longitude": -48.28973
      },
      {
        "id": 13492,
        "latitude": -15.88575,
        "longitude": -50.758045
      },
      {
        "id": 13494,
        "latitude": -18.007274,
        "longitude": -49.363695
      },
      {
        "id": 13495,
        "latitude": -20.435005,
        "longitude": -54.61933
      },
      {
        "id": 13496,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 13497,
        "latitude": -19.385606,
        "longitude": -54.587644
      },
      {
        "id": 13498,
        "latitude": -24.934855,
        "longitude": -54.368052
      },
      {
        "id": 13499,
        "latitude": -19.932418,
        "longitude": -44.023375
      },
      {
        "id": 13500,
        "latitude": -19.935623,
        "longitude": -44.004785
      },
      {
        "id": 13501,
        "latitude": -20.674089,
        "longitude": -43.766995
      },
      {
        "id": 13502,
        "latitude": -18.940278,
        "longitude": -48.33412
      },
      {
        "id": 13503,
        "latitude": -16.005581,
        "longitude": -41.281571
      },
      {
        "id": 13504,
        "latitude": -23.00692,
        "longitude": -44.295094
      },
      {
        "id": 13505,
        "latitude": -20.005468,
        "longitude": -44.167032
      },
      {
        "id": 13506,
        "latitude": -25.475307,
        "longitude": -49.223098
      },
      {
        "id": 13507,
        "latitude": -20.012375,
        "longitude": -44.035797
      },
      {
        "id": 13508,
        "latitude": -20.144657,
        "longitude": -44.878966
      },
      {
        "id": 13509,
        "latitude": -20.683694,
        "longitude": -43.786849
      },
      {
        "id": 13510,
        "latitude": -20.022751,
        "longitude": -44.054349
      },
      {
        "id": 13511,
        "latitude": -20.050596,
        "longitude": -44.050121
      },
      {
        "id": 13512,
        "latitude": -20.683694,
        "longitude": -43.786849
      },
      {
        "id": 13513,
        "latitude": -20.022751,
        "longitude": -44.054349
      },
      {
        "id": 13514,
        "latitude": -20.022751,
        "longitude": -44.054349
      },
      {
        "id": 13515,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 13516,
        "latitude": -21.791958,
        "longitude": -43.351836
      },
      {
        "id": 13517,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 13518,
        "latitude": -21.786375,
        "longitude": -43.389407
      },
      {
        "id": 13519,
        "latitude": -19.635738,
        "longitude": -43.896613
      },
      {
        "id": 13520,
        "latitude": -29.163408,
        "longitude": -51.111387
      },
      {
        "id": 13521,
        "latitude": -21.799649,
        "longitude": -46.523669
      },
      {
        "id": 13522,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 13523,
        "latitude": -19.788368,
        "longitude": -43.917415
      },
      {
        "id": 13524,
        "latitude": -19.774842,
        "longitude": -47.949624
      },
      {
        "id": 13525,
        "latitude": -19.774842,
        "longitude": -47.949624
      },
      {
        "id": 13526,
        "latitude": -18.922676,
        "longitude": -48.193647
      },
      {
        "id": 13527,
        "latitude": -18.921566,
        "longitude": -48.193387
      },
      {
        "id": 13528,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 13529,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13530,
        "latitude": -18.953639,
        "longitude": -48.244144
      },
      {
        "id": 13531,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 13532,
        "latitude": -18.954529,
        "longitude": -48.305456
      },
      {
        "id": 13533,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 13534,
        "latitude": -18.916414,
        "longitude": -48.266615
      },
      {
        "id": 13535,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13536,
        "latitude": -23.256462,
        "longitude": -45.908358
      },
      {
        "id": 13537,
        "latitude": -18.966827,
        "longitude": -48.276229
      },
      {
        "id": 13538,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13539,
        "latitude": -27.211299,
        "longitude": -49.670957
      },
      {
        "id": 13540,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13541,
        "latitude": -18.901815,
        "longitude": -48.286234
      },
      {
        "id": 13542,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13543,
        "latitude": -18.936299,
        "longitude": -48.324859
      },
      {
        "id": 13544,
        "latitude": -18.923792,
        "longitude": -48.321478
      },
      {
        "id": 13545,
        "latitude": -18.978924,
        "longitude": -48.277956
      },
      {
        "id": 13546,
        "latitude": -18.941496,
        "longitude": -48.346353
      },
      {
        "id": 13547,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 13548,
        "latitude": -18.95319,
        "longitude": -48.23499
      },
      {
        "id": 13549,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 13550,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 13551,
        "latitude": -1.299825,
        "longitude": -48.474268
      },
      {
        "id": 13552,
        "latitude": -26.278866,
        "longitude": -49.383243
      },
      {
        "id": 13553,
        "latitude": -8.030178,
        "longitude": -50.029925
      },
      {
        "id": 13554,
        "latitude": -7.070122,
        "longitude": -35.829145
      },
      {
        "id": 13555,
        "latitude": -23.704395,
        "longitude": -46.606014
      },
      {
        "id": 13556,
        "latitude": -25.463221,
        "longitude": -49.31922
      },
      {
        "id": 13558,
        "latitude": -23.61241,
        "longitude": -52.083464
      },
      {
        "id": 13559,
        "latitude": -23.423318,
        "longitude": -51.894753
      },
      {
        "id": 13560,
        "latitude": -23.76017,
        "longitude": -53.881703
      },
      {
        "id": 13561,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 13562,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 13563,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 13564,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 13565,
        "latitude": -8.272046,
        "longitude": -35.970224
      },
      {
        "id": 13566,
        "latitude": -8.02482,
        "longitude": -34.920855
      },
      {
        "id": 13567,
        "latitude": -7.916392,
        "longitude": -34.826282
      },
      {
        "id": 13568,
        "latitude": -7.92874,
        "longitude": -34.8256
      },
      {
        "id": 13569,
        "latitude": -7.915813,
        "longitude": -34.833522
      },
      {
        "id": 13570,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 13571,
        "latitude": -7.906502,
        "longitude": -36.039373
      },
      {
        "id": 13572,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 13574,
        "latitude": -7.872647,
        "longitude": -38.756422
      },
      {
        "id": 13575,
        "latitude": -7.872647,
        "longitude": -38.756422
      },
      {
        "id": 13576,
        "latitude": -4.818703,
        "longitude": -42.183451
      },
      {
        "id": 13577,
        "latitude": -4.83434,
        "longitude": -42.176421
      },
      {
        "id": 13578,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 13579,
        "latitude": -21.740744,
        "longitude": -41.34923
      },
      {
        "id": 13580,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 13581,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 13582,
        "latitude": -21.765454,
        "longitude": -41.359147
      },
      {
        "id": 13583,
        "latitude": -21.76877,
        "longitude": -41.310275
      },
      {
        "id": 13584,
        "latitude": -21.747894,
        "longitude": -41.345353
      },
      {
        "id": 13585,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 13586,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 13587,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 13588,
        "latitude": -22.330395,
        "longitude": -41.775649
      },
      {
        "id": 13589,
        "latitude": -22.947828,
        "longitude": -43.384878
      },
      {
        "id": 13590,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 13591,
        "latitude": -22.90277,
        "longitude": -43.179097
      },
      {
        "id": 13592,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 13593,
        "latitude": -22.337707,
        "longitude": -41.752782
      },
      {
        "id": 13594,
        "latitude": -22.337707,
        "longitude": -41.752782
      },
      {
        "id": 13595,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 13596,
        "latitude": -22.340624,
        "longitude": -41.750439
      },
      {
        "id": 13597,
        "latitude": -22.807272,
        "longitude": -43.19665
      },
      {
        "id": 13598,
        "latitude": -22.741808,
        "longitude": -43.426808
      },
      {
        "id": 13599,
        "latitude": -22.828636,
        "longitude": -43.274128
      },
      {
        "id": 13600,
        "latitude": -22.124558,
        "longitude": -45.073787
      },
      {
        "id": 13601,
        "latitude": -22.494528,
        "longitude": -41.926797
      },
      {
        "id": 13603,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 13605,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 13606,
        "latitude": -22.871426,
        "longitude": -43.487899
      },
      {
        "id": 13607,
        "latitude": -22.863482,
        "longitude": -43.374284
      },
      {
        "id": 13608,
        "latitude": -22.896019,
        "longitude": -43.264267
      },
      {
        "id": 13609,
        "latitude": -22.9978,
        "longitude": -43.397834
      },
      {
        "id": 13610,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 13611,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 13612,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 13613,
        "latitude": -22.921696,
        "longitude": -43.619056
      },
      {
        "id": 13614,
        "latitude": -22.933588,
        "longitude": -43.636043
      },
      {
        "id": 13615,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 13616,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 13617,
        "latitude": -22.933588,
        "longitude": -43.636043
      },
      {
        "id": 13618,
        "latitude": -22.856997,
        "longitude": -43.44749
      },
      {
        "id": 13619,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 13620,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 13622,
        "latitude": -22.999106,
        "longitude": -43.397665
      },
      {
        "id": 13623,
        "latitude": -22.929585,
        "longitude": -43.555198
      },
      {
        "id": 13624,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 13625,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 13626,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 13627,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 13628,
        "latitude": -22.857238,
        "longitude": -43.447509
      },
      {
        "id": 13630,
        "latitude": -22.945157,
        "longitude": -43.56145
      },
      {
        "id": 13631,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 13632,
        "latitude": -22.940485,
        "longitude": -43.577827
      },
      {
        "id": 13633,
        "latitude": -22.862648,
        "longitude": -43.544331
      },
      {
        "id": 13634,
        "latitude": -22.789183,
        "longitude": -43.173893
      },
      {
        "id": 13635,
        "latitude": -22.661538,
        "longitude": -43.288941
      },
      {
        "id": 13636,
        "latitude": -22.862648,
        "longitude": -43.544331
      },
      {
        "id": 13637,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 13638,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13639,
        "latitude": -22.81686,
        "longitude": -42.985399
      },
      {
        "id": 13640,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13641,
        "latitude": -22.899209,
        "longitude": -43.227215
      },
      {
        "id": 13642,
        "latitude": -22.841507,
        "longitude": -43.318353
      },
      {
        "id": 13643,
        "latitude": -22.841507,
        "longitude": -43.318353
      },
      {
        "id": 13644,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13645,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13646,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13647,
        "latitude": -22.802826,
        "longitude": -43.013333
      },
      {
        "id": 13648,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13649,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13650,
        "latitude": -22.892239,
        "longitude": -42.922888
      },
      {
        "id": 13651,
        "latitude": -22.79831,
        "longitude": -42.974948
      },
      {
        "id": 13652,
        "latitude": -22.790854,
        "longitude": -42.961756
      },
      {
        "id": 13653,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13654,
        "latitude": -22.870859,
        "longitude": -43.002776
      },
      {
        "id": 13655,
        "latitude": -22.654312,
        "longitude": -43.312902
      },
      {
        "id": 13656,
        "latitude": -22.777202,
        "longitude": -43.364424
      },
      {
        "id": 13657,
        "latitude": -22.7591,
        "longitude": -43.282518
      },
      {
        "id": 13658,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 13659,
        "latitude": -22.846817,
        "longitude": -43.312604
      },
      {
        "id": 13660,
        "latitude": -22.842149,
        "longitude": -43.090604
      },
      {
        "id": 13661,
        "latitude": -22.851909,
        "longitude": -42.994999
      },
      {
        "id": 13662,
        "latitude": -22.424267,
        "longitude": -42.972302
      },
      {
        "id": 13663,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 13664,
        "latitude": -5.90882,
        "longitude": -35.274212
      },
      {
        "id": 13665,
        "latitude": -30.037595,
        "longitude": -51.084972
      },
      {
        "id": 13666,
        "latitude": -29.788866,
        "longitude": -51.141273
      },
      {
        "id": 13667,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 13668,
        "latitude": -29.88095,
        "longitude": -51.176518
      },
      {
        "id": 13669,
        "latitude": -29.933509,
        "longitude": -51.195328
      },
      {
        "id": 13670,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 13671,
        "latitude": -29.183555,
        "longitude": -51.217804
      },
      {
        "id": 14134,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 13672,
        "latitude": -29.125874,
        "longitude": -51.160518
      },
      {
        "id": 13673,
        "latitude": -29.125874,
        "longitude": -51.160518
      },
      {
        "id": 13674,
        "latitude": -29.294046,
        "longitude": -51.1816
      },
      {
        "id": 13675,
        "latitude": -29.125874,
        "longitude": -51.160518
      },
      {
        "id": 13676,
        "latitude": -29.18559,
        "longitude": -51.17972
      },
      {
        "id": 13677,
        "latitude": -29.225196,
        "longitude": -51.349382
      },
      {
        "id": 13678,
        "latitude": -28.548815,
        "longitude": -52.215512
      },
      {
        "id": 13679,
        "latitude": -29.931151,
        "longitude": -51.068286
      },
      {
        "id": 13680,
        "latitude": -29.948469,
        "longitude": -51.045124
      },
      {
        "id": 13681,
        "latitude": -25.647268,
        "longitude": -49.325023
      },
      {
        "id": 13682,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 13683,
        "latitude": -31.684022,
        "longitude": -52.352136
      },
      {
        "id": 13684,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 13685,
        "latitude": -30.131168,
        "longitude": -51.214741
      },
      {
        "id": 13686,
        "latitude": -30.021674,
        "longitude": -51.197213
      },
      {
        "id": 13687,
        "latitude": -29.999636,
        "longitude": -51.147689
      },
      {
        "id": 13688,
        "latitude": -29.93659,
        "longitude": -51.034025
      },
      {
        "id": 13689,
        "latitude": -30.046329,
        "longitude": -51.136251
      },
      {
        "id": 13690,
        "latitude": -29.751054,
        "longitude": -51.088615
      },
      {
        "id": 13691,
        "latitude": -29.729803,
        "longitude": -51.147294
      },
      {
        "id": 13692,
        "latitude": -27.595514,
        "longitude": -48.612348
      },
      {
        "id": 13693,
        "latitude": -26.873234,
        "longitude": -52.392515
      },
      {
        "id": 13694,
        "latitude": -22.554536,
        "longitude": -47.39569
      },
      {
        "id": 13695,
        "latitude": -21.200153,
        "longitude": -50.446949
      },
      {
        "id": 13696,
        "latitude": -21.774766,
        "longitude": -48.183585
      },
      {
        "id": 13697,
        "latitude": -20.551204,
        "longitude": -48.56912
      },
      {
        "id": 13698,
        "latitude": -20.553144,
        "longitude": -48.569751
      },
      {
        "id": 13699,
        "latitude": -22.320231,
        "longitude": -49.024549
      },
      {
        "id": 13700,
        "latitude": -22.3293,
        "longitude": -49.047742
      },
      {
        "id": 13701,
        "latitude": -21.287646,
        "longitude": -50.335994
      },
      {
        "id": 13702,
        "latitude": -22.730305,
        "longitude": -48.571632
      },
      {
        "id": 13703,
        "latitude": -23.71287,
        "longitude": -47.002594
      },
      {
        "id": 13704,
        "latitude": -23.71287,
        "longitude": -47.002594
      },
      {
        "id": 13706,
        "latitude": -23.56051,
        "longitude": -46.364918
      },
      {
        "id": 13707,
        "latitude": -20.411148,
        "longitude": -47.28488
      },
      {
        "id": 13708,
        "latitude": -8.848513,
        "longitude": -36.132607
      },
      {
        "id": 13709,
        "latitude": -23.441785,
        "longitude": -46.389024
      },
      {
        "id": 13710,
        "latitude": -23.40239,
        "longitude": -46.49705
      },
      {
        "id": 13711,
        "latitude": -23.592477,
        "longitude": -48.044392
      },
      {
        "id": 13712,
        "latitude": -23.302904,
        "longitude": -45.957015
      },
      {
        "id": 13713,
        "latitude": -23.290958,
        "longitude": -45.950296
      },
      {
        "id": 13714,
        "latitude": -22.21372,
        "longitude": -49.953246
      },
      {
        "id": 13715,
        "latitude": -23.568553,
        "longitude": -46.79072
      },
      {
        "id": 13716,
        "latitude": -23.530005,
        "longitude": -46.812831
      },
      {
        "id": 13717,
        "latitude": -22.005544,
        "longitude": -47.421198
      },
      {
        "id": 13718,
        "latitude": -22.129985,
        "longitude": -51.404801
      },
      {
        "id": 13719,
        "latitude": -22.742014,
        "longitude": -47.393786
      },
      {
        "id": 13720,
        "latitude": -20.804075,
        "longitude": -49.38158
      },
      {
        "id": 13722,
        "latitude": -20.804075,
        "longitude": -49.38158
      },
      {
        "id": 13723,
        "latitude": -6.2647,
        "longitude": -36.540852
      },
      {
        "id": 13724,
        "latitude": -23.521376,
        "longitude": -46.641071
      },
      {
        "id": 13725,
        "latitude": -23.514535,
        "longitude": -46.443849
      },
      {
        "id": 13726,
        "latitude": -23.570533,
        "longitude": -46.663713
      },
      {
        "id": 13727,
        "latitude": -23.617061,
        "longitude": -46.57546
      },
      {
        "id": 13728,
        "latitude": -23.666018,
        "longitude": -46.675842
      },
      {
        "id": 13729,
        "latitude": -1.346228,
        "longitude": -48.381336
      },
      {
        "id": 13730,
        "latitude": -22.817961,
        "longitude": -47.255558
      },
      {
        "id": 13731,
        "latitude": -22.812262,
        "longitude": -47.172839
      },
      {
        "id": 13732,
        "latitude": -23.036001,
        "longitude": -45.54607
      },
      {
        "id": 13735,
        "latitude": -9.796062,
        "longitude": -36.792616
      },
      {
        "id": 13737,
        "latitude": -9.560143,
        "longitude": -37.384437
      },
      {
        "id": 13738,
        "latitude": -9.590598,
        "longitude": -35.7366
      },
      {
        "id": 13739,
        "latitude": -9.576052,
        "longitude": -35.735703
      },
      {
        "id": 14135,
        "latitude": -19.772909,
        "longitude": -44.084175
      },
      {
        "id": 13740,
        "latitude": -9.576052,
        "longitude": -35.735703
      },
      {
        "id": 13741,
        "latitude": -9.588095,
        "longitude": -35.76748
      },
      {
        "id": 13743,
        "latitude": -12.819162,
        "longitude": -38.244236
      },
      {
        "id": 13744,
        "latitude": -12.248321,
        "longitude": -38.95461
      },
      {
        "id": 13745,
        "latitude": -12.529813,
        "longitude": -40.301384
      },
      {
        "id": 13746,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 13747,
        "latitude": -15.653022,
        "longitude": -40.063118
      },
      {
        "id": 13750,
        "latitude": -15.621587,
        "longitude": -40.265719
      },
      {
        "id": 13751,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 13753,
        "latitude": -12.964413,
        "longitude": -38.421936
      },
      {
        "id": 13754,
        "latitude": -12.94591,
        "longitude": -38.487989
      },
      {
        "id": 13755,
        "latitude": -12.943069,
        "longitude": -38.487654
      },
      {
        "id": 13756,
        "latitude": -12.788679,
        "longitude": -38.398968
      },
      {
        "id": 13757,
        "latitude": -12.96458,
        "longitude": -38.50395
      },
      {
        "id": 13758,
        "latitude": -9.732806,
        "longitude": -38.124412
      },
      {
        "id": 13759,
        "latitude": -11.452289,
        "longitude": -39.190044
      },
      {
        "id": 13760,
        "latitude": -14.873991,
        "longitude": -40.842653
      },
      {
        "id": 13761,
        "latitude": -14.838495,
        "longitude": -40.831616
      },
      {
        "id": 13762,
        "latitude": -14.887206,
        "longitude": -40.853552
      },
      {
        "id": 13763,
        "latitude": -14.873991,
        "longitude": -40.842653
      },
      {
        "id": 13765,
        "latitude": -3.730056,
        "longitude": -38.659308
      },
      {
        "id": 13766,
        "latitude": -3.716167,
        "longitude": -38.667422
      },
      {
        "id": 13768,
        "latitude": -3.781855,
        "longitude": -38.599044
      },
      {
        "id": 13769,
        "latitude": -3.744442,
        "longitude": -38.669069
      },
      {
        "id": 13770,
        "latitude": -3.697445,
        "longitude": -38.594427
      },
      {
        "id": 13771,
        "latitude": -3.876044,
        "longitude": -38.456817
      },
      {
        "id": 13772,
        "latitude": -3.865278,
        "longitude": -38.440757
      },
      {
        "id": 13774,
        "latitude": -3.827371,
        "longitude": -38.593993
      },
      {
        "id": 13775,
        "latitude": -3.727895,
        "longitude": -38.494079
      },
      {
        "id": 13777,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 13778,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 13779,
        "latitude": -3.822973,
        "longitude": -38.480591
      },
      {
        "id": 13780,
        "latitude": -3.787471,
        "longitude": -38.601542
      },
      {
        "id": 13781,
        "latitude": -3.75952,
        "longitude": -38.55142
      },
      {
        "id": 13782,
        "latitude": -3.817091,
        "longitude": -38.469407
      },
      {
        "id": 13783,
        "latitude": -3.738103,
        "longitude": -38.480336
      },
      {
        "id": 13784,
        "latitude": -3.771154,
        "longitude": -38.479151
      },
      {
        "id": 13785,
        "latitude": -3.815094,
        "longitude": -38.482891
      },
      {
        "id": 13786,
        "latitude": -3.821205,
        "longitude": -38.477013
      },
      {
        "id": 13787,
        "latitude": -3.845023,
        "longitude": -38.474449
      },
      {
        "id": 13788,
        "latitude": -3.807641,
        "longitude": -38.535229
      },
      {
        "id": 13789,
        "latitude": -3.713987,
        "longitude": -38.591141
      },
      {
        "id": 13790,
        "latitude": -3.739272,
        "longitude": -38.539073
      },
      {
        "id": 13791,
        "latitude": -3.80936,
        "longitude": -38.479879
      },
      {
        "id": 13792,
        "latitude": -3.783801,
        "longitude": -38.608186
      },
      {
        "id": 13793,
        "latitude": -3.829752,
        "longitude": -38.507168
      },
      {
        "id": 13794,
        "latitude": -3.774183,
        "longitude": -38.61235
      },
      {
        "id": 13798,
        "latitude": -3.854626,
        "longitude": -38.58068
      },
      {
        "id": 13799,
        "latitude": -4.097568,
        "longitude": -38.679268
      },
      {
        "id": 13801,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 13802,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 13803,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 13804,
        "latitude": -4.223673,
        "longitude": -38.727987
      },
      {
        "id": 13805,
        "latitude": -4.168755,
        "longitude": -38.470337
      },
      {
        "id": 13807,
        "latitude": -7.832999,
        "longitude": -39.07256
      },
      {
        "id": 13808,
        "latitude": -7.82956,
        "longitude": -39.076955
      },
      {
        "id": 13809,
        "latitude": -4.040236,
        "longitude": -40.870366
      },
      {
        "id": 13810,
        "latitude": -29.1626,
        "longitude": -51.211534
      },
      {
        "id": 13812,
        "latitude": -15.793404,
        "longitude": -47.882317
      },
      {
        "id": 13813,
        "latitude": -20.492572,
        "longitude": -49.803433
      },
      {
        "id": 13814,
        "latitude": -15.838031,
        "longitude": -48.031634
      },
      {
        "id": 13815,
        "latitude": -29.681255,
        "longitude": -53.811342
      },
      {
        "id": 13816,
        "latitude": -15.81667,
        "longitude": -48.11667
      },
      {
        "id": 13819,
        "latitude": -15.810092,
        "longitude": -47.86167
      },
      {
        "id": 13820,
        "latitude": -15.603161,
        "longitude": -47.648136
      },
      {
        "id": 13821,
        "latitude": -20.776658,
        "longitude": -40.584429
      },
      {
        "id": 13822,
        "latitude": -19.819704,
        "longitude": -40.273941
      },
      {
        "id": 13823,
        "latitude": -20.128008,
        "longitude": -40.273738
      },
      {
        "id": 13824,
        "latitude": -20.443049,
        "longitude": -40.357379
      },
      {
        "id": 13825,
        "latitude": -15.745179,
        "longitude": -48.276748
      },
      {
        "id": 13828,
        "latitude": -7.093073,
        "longitude": -37.253108
      },
      {
        "id": 13829,
        "latitude": -29.260387,
        "longitude": -51.51285
      },
      {
        "id": 13838,
        "latitude": -7.083145,
        "longitude": -37.25306
      },
      {
        "id": 13839,
        "latitude": -0.667571,
        "longitude": -49.173684
      },
      {
        "id": 13840,
        "latitude": -15.944177,
        "longitude": -48.274241
      },
      {
        "id": 13841,
        "latitude": -16.067958,
        "longitude": -47.973556
      },
      {
        "id": 13842,
        "latitude": -16.521527,
        "longitude": -50.380413
      },
      {
        "id": 13843,
        "latitude": -16.375995,
        "longitude": -48.98167
      },
      {
        "id": 13844,
        "latitude": -16.334603,
        "longitude": -48.936638
      },
      {
        "id": 13845,
        "latitude": -16.386527,
        "longitude": -51.555023
      },
      {
        "id": 13846,
        "latitude": -14.906734,
        "longitude": -51.068194
      },
      {
        "id": 13847,
        "latitude": -18.136273,
        "longitude": -49.04415
      },
      {
        "id": 13848,
        "latitude": -18.136273,
        "longitude": -49.04415
      },
      {
        "id": 13849,
        "latitude": -17.743849,
        "longitude": -48.628836
      },
      {
        "id": 13850,
        "latitude": -18.173651,
        "longitude": -47.941292
      },
      {
        "id": 13851,
        "latitude": -15.296368,
        "longitude": -49.598053
      },
      {
        "id": 13852,
        "latitude": -16.093418,
        "longitude": -47.955107
      },
      {
        "id": 13854,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 13855,
        "latitude": -6.901028,
        "longitude": -35.525618
      },
      {
        "id": 13856,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 13858,
        "latitude": -3.866328,
        "longitude": -38.587371
      },
      {
        "id": 26,
        "latitude": -9.067213,
        "longitude": -68.66127
      },
      {
        "id": 13860,
        "latitude": -5.168863,
        "longitude": -37.333095
      },
      {
        "id": 13861,
        "latitude": -21.366933,
        "longitude": -46.516934
      },
      {
        "id": 13862,
        "latitude": -3.866328,
        "longitude": -38.587371
      },
      {
        "id": 13863,
        "latitude": -16.097129,
        "longitude": -47.960415
      },
      {
        "id": 13865,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 13866,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 13867,
        "latitude": -16.077775,
        "longitude": -47.907257
      },
      {
        "id": 13868,
        "latitude": -12.356089,
        "longitude": -38.387987
      },
      {
        "id": 13869,
        "latitude": -16.090441,
        "longitude": -47.955887
      },
      {
        "id": 13870,
        "latitude": -16.579343,
        "longitude": -48.903292
      },
      {
        "id": 13871,
        "latitude": -16.097129,
        "longitude": -47.960415
      },
      {
        "id": 13872,
        "latitude": -3.866328,
        "longitude": -38.587371
      },
      {
        "id": 13873,
        "latitude": -16.10944,
        "longitude": -47.94917
      },
      {
        "id": 13874,
        "latitude": -16.090797,
        "longitude": -47.952256
      },
      {
        "id": 13875,
        "latitude": -16.098241,
        "longitude": -47.939247
      },
      {
        "id": 13876,
        "latitude": -22.891804,
        "longitude": -43.61583
      },
      {
        "id": 13877,
        "latitude": -12.291878,
        "longitude": -37.935067
      },
      {
        "id": 13878,
        "latitude": -15.550744,
        "longitude": -47.330686
      },
      {
        "id": 13879,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13880,
        "latitude": -16.748375,
        "longitude": -49.384659
      },
      {
        "id": 13881,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13882,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13883,
        "latitude": -16.737463,
        "longitude": -49.206768
      },
      {
        "id": 13884,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13885,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13886,
        "latitude": -16.707955,
        "longitude": -49.328807
      },
      {
        "id": 13887,
        "latitude": -2.593173,
        "longitude": -45.799408
      },
      {
        "id": 13888,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13889,
        "latitude": -16.75183,
        "longitude": -49.311643
      },
      {
        "id": 13890,
        "latitude": -16.67417,
        "longitude": -49.283844
      },
      {
        "id": 13891,
        "latitude": -16.752036,
        "longitude": -49.34082
      },
      {
        "id": 13892,
        "latitude": -16.752036,
        "longitude": -49.34082
      },
      {
        "id": 13893,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13894,
        "latitude": -16.674551,
        "longitude": -49.303598
      },
      {
        "id": 13895,
        "latitude": -16.797764,
        "longitude": -49.365983
      },
      {
        "id": 13896,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 13897,
        "latitude": -16.516543,
        "longitude": -49.398904
      },
      {
        "id": 13898,
        "latitude": -16.367252,
        "longitude": -49.492142
      },
      {
        "id": 13899,
        "latitude": -18.391362,
        "longitude": -49.229478
      },
      {
        "id": 13900,
        "latitude": -15.745906,
        "longitude": -49.333904
      },
      {
        "id": 13902,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 13903,
        "latitude": -11.259014,
        "longitude": -42.154594
      },
      {
        "id": 13904,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 13905,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 13906,
        "latitude": -19.813931,
        "longitude": -43.942166
      },
      {
        "id": 13907,
        "latitude": -29.691889,
        "longitude": -51.244158
      },
      {
        "id": 13908,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 13909,
        "latitude": -16.244157,
        "longitude": -47.929457
      },
      {
        "id": 13910,
        "latitude": -14.534361,
        "longitude": -40.361604
      },
      {
        "id": 13911,
        "latitude": -19.813968,
        "longitude": -43.942258
      },
      {
        "id": 13912,
        "latitude": -15.981373,
        "longitude": -50.296237
      },
      {
        "id": 13913,
        "latitude": -10.112897,
        "longitude": -37.41099
      },
      {
        "id": 13914,
        "latitude": -9.665648,
        "longitude": -35.761666
      },
      {
        "id": 13915,
        "latitude": -23.692137,
        "longitude": -47.253893
      },
      {
        "id": 13916,
        "latitude": -14.098174,
        "longitude": -50.335748
      },
      {
        "id": 13923,
        "latitude": -17.868103,
        "longitude": -51.696091
      },
      {
        "id": 13932,
        "latitude": -15.644284,
        "longitude": -47.80128
      },
      {
        "id": 13940,
        "latitude": -9.5492,
        "longitude": -35.719023
      },
      {
        "id": 13942,
        "latitude": -21.803495,
        "longitude": -49.62184
      },
      {
        "id": 13943,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13944,
        "latitude": -21.803495,
        "longitude": -49.62184
      },
      {
        "id": 13945,
        "latitude": -13.77886,
        "longitude": -39.187943
      },
      {
        "id": 13946,
        "latitude": -15.478203,
        "longitude": -47.589
      },
      {
        "id": 13947,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13953,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13954,
        "latitude": -15.853215,
        "longitude": -50.918492
      },
      {
        "id": 13955,
        "latitude": -3.31881,
        "longitude": -45.77532
      },
      {
        "id": 13956,
        "latitude": -16.077773,
        "longitude": -47.969982
      },
      {
        "id": 13957,
        "latitude": -16.630031,
        "longitude": -49.329163
      },
      {
        "id": 13958,
        "latitude": -19.878758,
        "longitude": -43.918546
      },
      {
        "id": 13959,
        "latitude": -1.378348,
        "longitude": -48.422653
      },
      {
        "id": 13960,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13961,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13962,
        "latitude": -17.638593,
        "longitude": -46.277582
      },
      {
        "id": 13963,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13964,
        "latitude": -15.462471,
        "longitude": -47.613246
      },
      {
        "id": 13965,
        "latitude": -15.447822,
        "longitude": -47.609655
      },
      {
        "id": 13966,
        "latitude": -29.830533,
        "longitude": -50.058519
      },
      {
        "id": 13967,
        "latitude": -15.648423,
        "longitude": -47.800524
      },
      {
        "id": 13968,
        "latitude": -15.646146,
        "longitude": -47.638543
      },
      {
        "id": 13969,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13970,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13972,
        "latitude": -9.565654,
        "longitude": -35.7227
      },
      {
        "id": 13973,
        "latitude": -15.446299,
        "longitude": -47.591401
      },
      {
        "id": 13974,
        "latitude": -21.149245,
        "longitude": -44.258074
      },
      {
        "id": 13975,
        "latitude": -22.980846,
        "longitude": -43.190435
      },
      {
        "id": 14005,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 14006,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 14007,
        "latitude": -14.710554,
        "longitude": -47.52523
      },
      {
        "id": 14008,
        "latitude": -16.650155,
        "longitude": -49.495697
      },
      {
        "id": 14009,
        "latitude": -14.52922,
        "longitude": -49.134306
      },
      {
        "id": 14011,
        "latitude": -21.493588,
        "longitude": -41.652993
      },
      {
        "id": 14016,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 14019,
        "latitude": -15.794634,
        "longitude": -47.934953
      },
      {
        "id": 14020,
        "latitude": -3.729753,
        "longitude": -38.569822
      },
      {
        "id": 14021,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 14022,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 14023,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 14025,
        "latitude": -16.991946,
        "longitude": -42.360613
      },
      {
        "id": 14026,
        "latitude": -16.082096,
        "longitude": -47.992201
      },
      {
        "id": 14027,
        "latitude": -9.044968,
        "longitude": -41.033477
      },
      {
        "id": 14028,
        "latitude": -3.730277,
        "longitude": -38.571346
      },
      {
        "id": 14029,
        "latitude": -16.051473,
        "longitude": -47.974083
      },
      {
        "id": 14030,
        "latitude": -18.006421,
        "longitude": -49.369036
      },
      {
        "id": 14031,
        "latitude": -18.007482,
        "longitude": -49.362631
      },
      {
        "id": 14032,
        "latitude": -4.944691,
        "longitude": -47.491238
      },
      {
        "id": 14033,
        "latitude": -19.86878,
        "longitude": -43.920468
      },
      {
        "id": 14034,
        "latitude": -5.173175,
        "longitude": -47.779869
      },
      {
        "id": 14035,
        "latitude": -5.173175,
        "longitude": -47.779869
      },
      {
        "id": 14036,
        "latitude": -2.532029,
        "longitude": -44.103348
      },
      {
        "id": 14037,
        "latitude": -2.234585,
        "longitude": -45.300981
      },
      {
        "id": 14038,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 14039,
        "latitude": -2.549205,
        "longitude": -44.070278
      },
      {
        "id": 14040,
        "latitude": -2.622972,
        "longitude": -44.160307
      },
      {
        "id": 14041,
        "latitude": -2.500677,
        "longitude": -44.228546
      },
      {
        "id": 14042,
        "latitude": -2.560025,
        "longitude": -44.231338
      },
      {
        "id": 14043,
        "latitude": -5.258082,
        "longitude": -44.056227
      },
      {
        "id": 14044,
        "latitude": -15.579321,
        "longitude": -56.10009
      },
      {
        "id": 14045,
        "latitude": -15.650649,
        "longitude": -56.144078
      },
      {
        "id": 14046,
        "latitude": -15.647883,
        "longitude": -56.030009
      },
      {
        "id": 14047,
        "latitude": -15.609549,
        "longitude": -57.919062
      },
      {
        "id": 14048,
        "latitude": -16.262369,
        "longitude": -56.629626
      },
      {
        "id": 14049,
        "latitude": -15.543002,
        "longitude": -54.305467
      },
      {
        "id": 14050,
        "latitude": -25.455089,
        "longitude": -49.195346
      },
      {
        "id": 14051,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 14052,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 14053,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 14054,
        "latitude": -20.398544,
        "longitude": -54.569416
      },
      {
        "id": 14055,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 14056,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 14057,
        "latitude": -20.503414,
        "longitude": -54.624705
      },
      {
        "id": 14058,
        "latitude": -20.490358,
        "longitude": -54.649525
      },
      {
        "id": 14059,
        "latitude": -20.505407,
        "longitude": -54.612908
      },
      {
        "id": 14060,
        "latitude": -23.074113,
        "longitude": -54.189565
      },
      {
        "id": 14061,
        "latitude": -22.553524,
        "longitude": -55.663394
      },
      {
        "id": 14062,
        "latitude": -19.385606,
        "longitude": -54.587644
      },
      {
        "id": 14063,
        "latitude": -24.639637,
        "longitude": -47.372957
      },
      {
        "id": 14064,
        "latitude": -16.1732,
        "longitude": -40.691715
      },
      {
        "id": 14065,
        "latitude": -21.611123,
        "longitude": -42.833551
      },
      {
        "id": 14066,
        "latitude": -5.328157,
        "longitude": -49.087249
      },
      {
        "id": 14067,
        "latitude": -21.184711,
        "longitude": -43.969733
      },
      {
        "id": 14068,
        "latitude": -19.934745,
        "longitude": -44.021462
      },
      {
        "id": 14069,
        "latitude": -19.914456,
        "longitude": -43.93603
      },
      {
        "id": 14070,
        "latitude": -19.914319,
        "longitude": -43.959502
      },
      {
        "id": 14072,
        "latitude": -19.932418,
        "longitude": -44.023375
      },
      {
        "id": 14073,
        "latitude": -19.860695,
        "longitude": -43.885312
      },
      {
        "id": 14074,
        "latitude": -19.863323,
        "longitude": -43.890616
      },
      {
        "id": 14075,
        "latitude": -19.95309,
        "longitude": -43.979223
      },
      {
        "id": 14077,
        "latitude": -19.832628,
        "longitude": -43.916314
      },
      {
        "id": 14078,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 14079,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 14080,
        "latitude": -19.975859,
        "longitude": -44.228419
      },
      {
        "id": 14081,
        "latitude": -19.934435,
        "longitude": -44.099759
      },
      {
        "id": 14082,
        "latitude": -19.860466,
        "longitude": -44.001557
      },
      {
        "id": 14083,
        "latitude": -19.931583,
        "longitude": -44.132631
      },
      {
        "id": 14084,
        "latitude": -19.969757,
        "longitude": -44.180914
      },
      {
        "id": 14085,
        "latitude": -19.949525,
        "longitude": -44.196963
      },
      {
        "id": 14086,
        "latitude": -17.011811,
        "longitude": -46.010573
      },
      {
        "id": 14087,
        "latitude": -21.022613,
        "longitude": -45.184233
      },
      {
        "id": 14088,
        "latitude": -20.736565,
        "longitude": -42.028628
      },
      {
        "id": 14089,
        "latitude": -20.480167,
        "longitude": -43.891995
      },
      {
        "id": 14090,
        "latitude": -19.917952,
        "longitude": -44.126317
      },
      {
        "id": 14091,
        "latitude": -19.920306,
        "longitude": -44.098208
      },
      {
        "id": 14092,
        "latitude": -19.83381,
        "longitude": -44.018285
      },
      {
        "id": 14093,
        "latitude": -19.918382,
        "longitude": -44.037918
      },
      {
        "id": 14094,
        "latitude": -19.901941,
        "longitude": -44.070015
      },
      {
        "id": 14095,
        "latitude": -19.898763,
        "longitude": -47.6867
      },
      {
        "id": 14096,
        "latitude": -19.930308,
        "longitude": -44.049893
      },
      {
        "id": 14097,
        "latitude": -19.901941,
        "longitude": -44.070015
      },
      {
        "id": 14098,
        "latitude": -19.897398,
        "longitude": -44.07714
      },
      {
        "id": 14099,
        "latitude": -18.479955,
        "longitude": -47.197209
      },
      {
        "id": 14100,
        "latitude": -18.479955,
        "longitude": -47.197209
      },
      {
        "id": 14101,
        "latitude": -19.530137,
        "longitude": -42.575319
      },
      {
        "id": 14102,
        "latitude": -20.194646,
        "longitude": -44.944369
      },
      {
        "id": 14103,
        "latitude": -19.808481,
        "longitude": -44.305516
      },
      {
        "id": 14104,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 14105,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 14106,
        "latitude": -20.023909,
        "longitude": -48.924974
      },
      {
        "id": 14107,
        "latitude": -18.884314,
        "longitude": -41.990684
      },
      {
        "id": 14108,
        "latitude": -22.879695,
        "longitude": -43.304001
      },
      {
        "id": 14109,
        "latitude": -19.210732,
        "longitude": -49.784046
      },
      {
        "id": 14110,
        "latitude": -20.004917,
        "longitude": -44.086388
      },
      {
        "id": 14111,
        "latitude": -20.09357,
        "longitude": -44.614214
      },
      {
        "id": 14112,
        "latitude": -20.094682,
        "longitude": -44.614268
      },
      {
        "id": 14113,
        "latitude": -20.067727,
        "longitude": -44.563095
      },
      {
        "id": 14114,
        "latitude": -18.979024,
        "longitude": -49.463928
      },
      {
        "id": 14115,
        "latitude": -18.949434,
        "longitude": -49.460054
      },
      {
        "id": 14117,
        "latitude": -21.814411,
        "longitude": -43.360008
      },
      {
        "id": 14118,
        "latitude": -21.741598,
        "longitude": -43.389655
      },
      {
        "id": 14119,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 14120,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 14121,
        "latitude": -21.780276,
        "longitude": -43.39251
      },
      {
        "id": 14122,
        "latitude": -21.453073,
        "longitude": -43.552597
      },
      {
        "id": 14123,
        "latitude": -21.790234,
        "longitude": -43.391371
      },
      {
        "id": 14124,
        "latitude": -21.709879,
        "longitude": -43.391265
      },
      {
        "id": 14125,
        "latitude": -21.709968,
        "longitude": -43.356277
      },
      {
        "id": 14126,
        "latitude": -22.532996,
        "longitude": -44.774904
      },
      {
        "id": 14127,
        "latitude": -21.760953,
        "longitude": -43.350113
      },
      {
        "id": 14128,
        "latitude": -23.432431,
        "longitude": -46.565431
      },
      {
        "id": 14129,
        "latitude": -20.061768,
        "longitude": -44.174592
      },
      {
        "id": 14130,
        "latitude": -19.559752,
        "longitude": -44.08366
      },
      {
        "id": 14131,
        "latitude": -16.226772,
        "longitude": -41.480235
      },
      {
        "id": 14132,
        "latitude": -22.26179,
        "longitude": -45.711653
      },
      {
        "id": 14133,
        "latitude": -16.717573,
        "longitude": -43.850965
      },
      {
        "id": 14136,
        "latitude": -19.874953,
        "longitude": -44.983925
      },
      {
        "id": 14137,
        "latitude": -22.288365,
        "longitude": -46.379433
      },
      {
        "id": 14138,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 14139,
        "latitude": -20.717103,
        "longitude": -46.615257
      },
      {
        "id": 14140,
        "latitude": -19.529657,
        "longitude": -43.945838
      },
      {
        "id": 14142,
        "latitude": -19.225155,
        "longitude": -45.002647
      },
      {
        "id": 14143,
        "latitude": -19.324132,
        "longitude": -45.232906
      },
      {
        "id": 14144,
        "latitude": -22.24159,
        "longitude": -45.867736
      },
      {
        "id": 14145,
        "latitude": -19.780165,
        "longitude": -44.090739
      },
      {
        "id": 14146,
        "latitude": -19.804032,
        "longitude": -44.024646
      },
      {
        "id": 14147,
        "latitude": -19.50089,
        "longitude": -42.575252
      },
      {
        "id": 14148,
        "latitude": -19.759836,
        "longitude": -44.078546
      },
      {
        "id": 14149,
        "latitude": -19.569225,
        "longitude": -44.027084
      },
      {
        "id": 14150,
        "latitude": -19.804032,
        "longitude": -44.024646
      },
      {
        "id": 14151,
        "latitude": -19.780165,
        "longitude": -44.090739
      },
      {
        "id": 14152,
        "latitude": -19.658892,
        "longitude": -43.7731
      },
      {
        "id": 14153,
        "latitude": -19.775636,
        "longitude": -43.891233
      },
      {
        "id": 14154,
        "latitude": -19.658892,
        "longitude": -43.7731
      },
      {
        "id": 14156,
        "latitude": -15.954852,
        "longitude": -44.849136
      },
      {
        "id": 14157,
        "latitude": -20.035825,
        "longitude": -41.35315
      },
      {
        "id": 14158,
        "latitude": -21.13447,
        "longitude": -44.252062
      },
      {
        "id": 14159,
        "latitude": -20.059812,
        "longitude": -44.270691
      },
      {
        "id": 14160,
        "latitude": -19.447335,
        "longitude": -44.237536
      },
      {
        "id": 14161,
        "latitude": -21.688236,
        "longitude": -45.238009
      },
      {
        "id": 14162,
        "latitude": -19.752329,
        "longitude": -47.976018
      },
      {
        "id": 14163,
        "latitude": -19.755439,
        "longitude": -47.974824
      },
      {
        "id": 14164,
        "latitude": -21.813856,
        "longitude": -48.19278
      },
      {
        "id": 14165,
        "latitude": -19.756867,
        "longitude": -47.886716
      },
      {
        "id": 14166,
        "latitude": -18.958052,
        "longitude": -48.223834
      },
      {
        "id": 14167,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14168,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14169,
        "latitude": -18.926574,
        "longitude": -48.318571
      },
      {
        "id": 14170,
        "latitude": -18.885947,
        "longitude": -48.298872
      },
      {
        "id": 14171,
        "latitude": -18.922843,
        "longitude": -48.311302
      },
      {
        "id": 14172,
        "latitude": -18.909619,
        "longitude": -48.328029
      },
      {
        "id": 14173,
        "latitude": -18.885222,
        "longitude": -48.218888
      },
      {
        "id": 14174,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14175,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14176,
        "latitude": -18.921853,
        "longitude": -48.254151
      },
      {
        "id": 14177,
        "latitude": -18.900458,
        "longitude": -48.293908
      },
      {
        "id": 14178,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14179,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14180,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14181,
        "latitude": -18.908017,
        "longitude": -48.282512
      },
      {
        "id": 14182,
        "latitude": -9.481829,
        "longitude": -35.853233
      },
      {
        "id": 14183,
        "latitude": -18.923487,
        "longitude": -48.276092
      },
      {
        "id": 14184,
        "latitude": -21.573053,
        "longitude": -45.43903
      },
      {
        "id": 14185,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 14186,
        "latitude": -21.571433,
        "longitude": -45.453542
      },
      {
        "id": 14187,
        "latitude": -21.590265,
        "longitude": -45.466728
      },
      {
        "id": 14188,
        "latitude": -17.596985,
        "longitude": -44.737352
      },
      {
        "id": 14191,
        "latitude": -20.763998,
        "longitude": -42.88435
      },
      {
        "id": 14192,
        "latitude": -1.335733,
        "longitude": -48.441825
      },
      {
        "id": 14193,
        "latitude": -9.528786,
        "longitude": -37.295856
      },
      {
        "id": 14194,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 14195,
        "latitude": -2.013587,
        "longitude": -47.678979
      },
      {
        "id": 14196,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 14197,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 14198,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 14199,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 14200,
        "latitude": -2.131463,
        "longitude": -47.557198
      },
      {
        "id": 14201,
        "latitude": -1.466751,
        "longitude": -48.49935
      },
      {
        "id": 14202,
        "latitude": -1.448755,
        "longitude": -48.487867
      },
      {
        "id": 14203,
        "latitude": -1.456039,
        "longitude": -48.504524
      },
      {
        "id": 14222,
        "latitude": -0.620769,
        "longitude": -47.327467
      },
      {
        "id": 14204,
        "latitude": -1.682005,
        "longitude": -50.477921
      },
      {
        "id": 14205,
        "latitude": -1.286585,
        "longitude": -47.912471
      },
      {
        "id": 14206,
        "latitude": -1.312024,
        "longitude": -48.153051
      },
      {
        "id": 14207,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 14208,
        "latitude": -1.293189,
        "longitude": -47.931829
      },
      {
        "id": 14209,
        "latitude": -20.536892,
        "longitude": -56.899294
      },
      {
        "id": 14210,
        "latitude": -2.558829,
        "longitude": -47.500595
      },
      {
        "id": 14211,
        "latitude": -2.048927,
        "longitude": -47.549605
      },
      {
        "id": 14212,
        "latitude": -5.368623,
        "longitude": -49.103778
      },
      {
        "id": 14213,
        "latitude": -9.330016,
        "longitude": -50.324049
      },
      {
        "id": 14214,
        "latitude": -7.962898,
        "longitude": -34.913527
      },
      {
        "id": 14215,
        "latitude": -1.440364,
        "longitude": -48.487088
      },
      {
        "id": 14216,
        "latitude": -1.89135,
        "longitude": -48.766469
      },
      {
        "id": 14217,
        "latitude": -1.532406,
        "longitude": -49.226563
      },
      {
        "id": 14218,
        "latitude": -1.544279,
        "longitude": -47.107736
      },
      {
        "id": 14219,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 14220,
        "latitude": -2.993658,
        "longitude": -47.356839
      },
      {
        "id": 14221,
        "latitude": -6.073832,
        "longitude": -49.880433
      },
      {
        "id": 14223,
        "latitude": -1.226232,
        "longitude": -48.291652
      },
      {
        "id": 14224,
        "latitude": -1.226232,
        "longitude": -48.291652
      },
      {
        "id": 14225,
        "latitude": -1.67934,
        "longitude": -46.710667
      },
      {
        "id": 14226,
        "latitude": -1.346539,
        "longitude": -47.573178
      },
      {
        "id": 14227,
        "latitude": -1.675821,
        "longitude": -47.772634
      },
      {
        "id": 14228,
        "latitude": -2.390449,
        "longitude": -48.230738
      },
      {
        "id": 14229,
        "latitude": -7.201531,
        "longitude": -34.884578
      },
      {
        "id": 14230,
        "latitude": -7.15186,
        "longitude": -34.918893
      },
      {
        "id": 14231,
        "latitude": -7.230798,
        "longitude": -35.898981
      },
      {
        "id": 14232,
        "latitude": -7.522016,
        "longitude": -36.000136
      },
      {
        "id": 14233,
        "latitude": -7.251652,
        "longitude": -34.917821
      },
      {
        "id": 14234,
        "latitude": -7.251652,
        "longitude": -34.917821
      },
      {
        "id": 14235,
        "latitude": -7.284418,
        "longitude": -34.805254
      },
      {
        "id": 14236,
        "latitude": -7.206989,
        "longitude": -34.835537
      },
      {
        "id": 14237,
        "latitude": -7.159247,
        "longitude": -34.858136
      },
      {
        "id": 14238,
        "latitude": -7.374417,
        "longitude": -35.243036
      },
      {
        "id": 14239,
        "latitude": -7.898416,
        "longitude": -37.125249
      },
      {
        "id": 14240,
        "latitude": -7.017985,
        "longitude": -37.277773
      },
      {
        "id": 14241,
        "latitude": -7.017985,
        "longitude": -37.277773
      },
      {
        "id": 14242,
        "latitude": -7.159235,
        "longitude": -34.972163
      },
      {
        "id": 14243,
        "latitude": -23.414273,
        "longitude": -51.419135
      },
      {
        "id": 14244,
        "latitude": -22.789402,
        "longitude": -51.713643
      },
      {
        "id": 14245,
        "latitude": -25.472622,
        "longitude": -49.528768
      },
      {
        "id": 14246,
        "latitude": -25.472622,
        "longitude": -49.528768
      },
      {
        "id": 14247,
        "latitude": -19.58795,
        "longitude": -46.92365
      },
      {
        "id": 14248,
        "latitude": -22.820526,
        "longitude": -51.593133
      },
      {
        "id": 14249,
        "latitude": -23.656546,
        "longitude": -52.601748
      },
      {
        "id": 14250,
        "latitude": -23.381624,
        "longitude": -52.944371
      },
      {
        "id": 14251,
        "latitude": -25.477187,
        "longitude": -49.34205
      },
      {
        "id": 14252,
        "latitude": -25.367628,
        "longitude": -49.27893
      },
      {
        "id": 14253,
        "latitude": -25.386638,
        "longitude": -49.244511
      },
      {
        "id": 14254,
        "latitude": -25.440369,
        "longitude": -49.273438
      },
      {
        "id": 14255,
        "latitude": -25.617797,
        "longitude": -49.345988
      },
      {
        "id": 14256,
        "latitude": -25.55361,
        "longitude": -49.30972
      },
      {
        "id": 14257,
        "latitude": -16.521542,
        "longitude": -41.881067
      },
      {
        "id": 14258,
        "latitude": -25.41532,
        "longitude": -49.206238
      },
      {
        "id": 14259,
        "latitude": -25.444313,
        "longitude": -49.283656
      },
      {
        "id": 14260,
        "latitude": -23.61241,
        "longitude": -52.083464
      },
      {
        "id": 14261,
        "latitude": -23.61241,
        "longitude": -52.083464
      },
      {
        "id": 14262,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 14263,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 14264,
        "latitude": -22.86226,
        "longitude": -51.390048
      },
      {
        "id": 14265,
        "latitude": -23.086863,
        "longitude": -51.953688
      },
      {
        "id": 14266,
        "latitude": -25.511383,
        "longitude": -54.550203
      },
      {
        "id": 14267,
        "latitude": -24.070076,
        "longitude": -53.847873
      },
      {
        "id": 14268,
        "latitude": -26.070904,
        "longitude": -53.052875
      },
      {
        "id": 14269,
        "latitude": -23.146597,
        "longitude": -51.772289
      },
      {
        "id": 14270,
        "latitude": -23.325554,
        "longitude": -51.157232
      },
      {
        "id": 14271,
        "latitude": -23.325554,
        "longitude": -51.157232
      },
      {
        "id": 14272,
        "latitude": -23.325576,
        "longitude": -52.118382
      },
      {
        "id": 14273,
        "latitude": -23.348434,
        "longitude": -52.102536
      },
      {
        "id": 14274,
        "latitude": -23.423318,
        "longitude": -51.894753
      },
      {
        "id": 14275,
        "latitude": -23.422098,
        "longitude": -51.938731
      },
      {
        "id": 14276,
        "latitude": -23.422098,
        "longitude": -51.938731
      },
      {
        "id": 14277,
        "latitude": -23.422098,
        "longitude": -51.938731
      },
      {
        "id": 14278,
        "latitude": -23.423318,
        "longitude": -51.894753
      },
      {
        "id": 14279,
        "latitude": -26.221301,
        "longitude": -52.681969
      },
      {
        "id": 14280,
        "latitude": -23.318846,
        "longitude": -51.360924
      },
      {
        "id": 14281,
        "latitude": -25.37474,
        "longitude": -49.058744
      },
      {
        "id": 14282,
        "latitude": -25.556468,
        "longitude": -49.177815
      },
      {
        "id": 14283,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 14284,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 14285,
        "latitude": -23.456553,
        "longitude": -51.872803
      },
      {
        "id": 14286,
        "latitude": -23.429155,
        "longitude": -51.863326
      },
      {
        "id": 14287,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 14288,
        "latitude": -23.660985,
        "longitude": -51.680353
      },
      {
        "id": 14289,
        "latitude": -23.447442,
        "longitude": -51.871421
      },
      {
        "id": 14339,
        "latitude": -7.98303,
        "longitude": -34.911872
      },
      {
        "id": 14290,
        "latitude": -23.456553,
        "longitude": -51.872803
      },
      {
        "id": 14291,
        "latitude": -24.727909,
        "longitude": -53.719934
      },
      {
        "id": 14292,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 14293,
        "latitude": -8.173709,
        "longitude": -36.201019
      },
      {
        "id": 14294,
        "latitude": -8.329319,
        "longitude": -36.418301
      },
      {
        "id": 14295,
        "latitude": -8.205138,
        "longitude": -35.577607
      },
      {
        "id": 14296,
        "latitude": -8.085994,
        "longitude": -35.031818
      },
      {
        "id": 14297,
        "latitude": -7.999357,
        "longitude": -36.062069
      },
      {
        "id": 14298,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 14299,
        "latitude": -7.9934,
        "longitude": -35.210132
      },
      {
        "id": 14300,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 14301,
        "latitude": -8.895012,
        "longitude": -36.498124
      },
      {
        "id": 14302,
        "latitude": -8.001434,
        "longitude": -35.293681
      },
      {
        "id": 14303,
        "latitude": -7.834606,
        "longitude": -34.914914
      },
      {
        "id": 14304,
        "latitude": -7.839255,
        "longitude": -34.907612
      },
      {
        "id": 14305,
        "latitude": -6.973537,
        "longitude": -39.445528
      },
      {
        "id": 14306,
        "latitude": -7.85623,
        "longitude": -34.906176
      },
      {
        "id": 14307,
        "latitude": -7.839941,
        "longitude": -34.92354
      },
      {
        "id": 14308,
        "latitude": -7.882483,
        "longitude": -34.903147
      },
      {
        "id": 14309,
        "latitude": -8.574288,
        "longitude": -36.869678
      },
      {
        "id": 14310,
        "latitude": -7.759569,
        "longitude": -34.855342
      },
      {
        "id": 14311,
        "latitude": -7.404845,
        "longitude": -35.115834
      },
      {
        "id": 14312,
        "latitude": -8.177348,
        "longitude": -34.937884
      },
      {
        "id": 14313,
        "latitude": -8.177348,
        "longitude": -34.937884
      },
      {
        "id": 14314,
        "latitude": -8.177348,
        "longitude": -34.937884
      },
      {
        "id": 14315,
        "latitude": -8.177348,
        "longitude": -34.937884
      },
      {
        "id": 14317,
        "latitude": -21.260109,
        "longitude": -50.643019
      },
      {
        "id": 14318,
        "latitude": -7.842758,
        "longitude": -35.231968
      },
      {
        "id": 14319,
        "latitude": -8.657675,
        "longitude": -36.318092
      },
      {
        "id": 14320,
        "latitude": -7.874462,
        "longitude": -35.439207
      },
      {
        "id": 14321,
        "latitude": -7.919483,
        "longitude": -34.84888
      },
      {
        "id": 14322,
        "latitude": -7.908446,
        "longitude": -34.83623
      },
      {
        "id": 14323,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14324,
        "latitude": -7.957471,
        "longitude": -34.846313
      },
      {
        "id": 14325,
        "latitude": -7.93841,
        "longitude": -34.852347
      },
      {
        "id": 14326,
        "latitude": -8.113858,
        "longitude": -35.099845
      },
      {
        "id": 14327,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14328,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14329,
        "latitude": -7.901807,
        "longitude": -34.843522
      },
      {
        "id": 14330,
        "latitude": -8.025352,
        "longitude": -34.927348
      },
      {
        "id": 14331,
        "latitude": -26.278866,
        "longitude": -49.383243
      },
      {
        "id": 14332,
        "latitude": -8.011253,
        "longitude": -35.004794
      },
      {
        "id": 14333,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14334,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14335,
        "latitude": -7.888113,
        "longitude": -34.836907
      },
      {
        "id": 14337,
        "latitude": -7.901328,
        "longitude": -34.834521
      },
      {
        "id": 14338,
        "latitude": -14.839145,
        "longitude": -40.873633
      },
      {
        "id": 14340,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14341,
        "latitude": -7.907201,
        "longitude": -34.831904
      },
      {
        "id": 14342,
        "latitude": -8.442292,
        "longitude": -35.020856
      },
      {
        "id": 14343,
        "latitude": -7.902224,
        "longitude": -34.83341
      },
      {
        "id": 14344,
        "latitude": -7.946516,
        "longitude": -34.894345
      },
      {
        "id": 14345,
        "latitude": -7.960161,
        "longitude": -34.855511
      },
      {
        "id": 14346,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14347,
        "latitude": -7.945901,
        "longitude": -34.856119
      },
      {
        "id": 14348,
        "latitude": -8.446385,
        "longitude": -35.018061
      },
      {
        "id": 14349,
        "latitude": -12.896282,
        "longitude": -38.431
      },
      {
        "id": 14350,
        "latitude": -7.941174,
        "longitude": -34.876023
      },
      {
        "id": 14351,
        "latitude": -7.511963,
        "longitude": -35.313921
      },
      {
        "id": 14352,
        "latitude": -7.92874,
        "longitude": -34.8256
      },
      {
        "id": 14353,
        "latitude": -7.919483,
        "longitude": -34.84888
      },
      {
        "id": 14354,
        "latitude": -7.896826,
        "longitude": -34.832549
      },
      {
        "id": 14355,
        "latitude": -8.380619,
        "longitude": -36.727424
      },
      {
        "id": 14356,
        "latitude": -8.366241,
        "longitude": -36.676798
      },
      {
        "id": 14357,
        "latitude": -8.381931,
        "longitude": -36.727843
      },
      {
        "id": 14358,
        "latitude": -8.361893,
        "longitude": -36.696892
      },
      {
        "id": 14359,
        "latitude": -8.361893,
        "longitude": -36.696892
      },
      {
        "id": 14360,
        "latitude": -8.380619,
        "longitude": -36.727424
      },
      {
        "id": 14361,
        "latitude": -8.361893,
        "longitude": -36.696892
      },
      {
        "id": 14362,
        "latitude": -8.380619,
        "longitude": -36.727424
      },
      {
        "id": 14363,
        "latitude": -8.361893,
        "longitude": -36.696892
      },
      {
        "id": 14364,
        "latitude": -7.984288,
        "longitude": -38.30528
      },
      {
        "id": 14365,
        "latitude": -8.055802,
        "longitude": -34.921399
      },
      {
        "id": 14366,
        "latitude": -8.055802,
        "longitude": -34.921399
      },
      {
        "id": 14367,
        "latitude": -8.055802,
        "longitude": -34.921399
      },
      {
        "id": 14368,
        "latitude": -7.898035,
        "longitude": -36.049962
      },
      {
        "id": 14369,
        "latitude": -8.144155,
        "longitude": -36.369364
      },
      {
        "id": 14370,
        "latitude": -8.151454,
        "longitude": -36.37083
      },
      {
        "id": 14371,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 14388,
        "latitude": -5.320489,
        "longitude": -41.552803
      },
      {
        "id": 14372,
        "latitude": -7.938999,
        "longitude": -36.199688
      },
      {
        "id": 14373,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 14374,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 14375,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 14376,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 14377,
        "latitude": -7.943538,
        "longitude": -36.197935
      },
      {
        "id": 14378,
        "latitude": -7.953143,
        "longitude": -36.216641
      },
      {
        "id": 14379,
        "latitude": -7.993735,
        "longitude": -38.624074
      },
      {
        "id": 14380,
        "latitude": -7.864096,
        "longitude": -38.765906
      },
      {
        "id": 14381,
        "latitude": -7.992547,
        "longitude": -38.623893
      },
      {
        "id": 14382,
        "latitude": -8.129583,
        "longitude": -34.90563
      },
      {
        "id": 14383,
        "latitude": -8.017922,
        "longitude": -35.007277
      },
      {
        "id": 14384,
        "latitude": -7.986215,
        "longitude": -35.051252
      },
      {
        "id": 14385,
        "latitude": -7.990644,
        "longitude": -35.050418
      },
      {
        "id": 14386,
        "latitude": -7.848887,
        "longitude": -35.752269
      },
      {
        "id": 14387,
        "latitude": -8.144155,
        "longitude": -36.369364
      },
      {
        "id": 14389,
        "latitude": -6.774139,
        "longitude": -43.012571
      },
      {
        "id": 14390,
        "latitude": -7.6013,
        "longitude": -43.028671
      },
      {
        "id": 14391,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 14392,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 14393,
        "latitude": -4.993661,
        "longitude": -42.836644
      },
      {
        "id": 14394,
        "latitude": -5.145072,
        "longitude": -42.782314
      },
      {
        "id": 14395,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 14396,
        "latitude": -5.051453,
        "longitude": -42.786841
      },
      {
        "id": 14397,
        "latitude": -5.067432,
        "longitude": -42.752333
      },
      {
        "id": 14398,
        "latitude": -15.802781,
        "longitude": -47.892775
      },
      {
        "id": 14399,
        "latitude": -5.040104,
        "longitude": -42.7487
      },
      {
        "id": 14400,
        "latitude": -22.866297,
        "longitude": -42.264261
      },
      {
        "id": 14401,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 14402,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 14403,
        "latitude": -22.86556,
        "longitude": -42.332685
      },
      {
        "id": 14404,
        "latitude": -22.900085,
        "longitude": -42.065583
      },
      {
        "id": 14405,
        "latitude": -22.900085,
        "longitude": -42.065583
      },
      {
        "id": 14406,
        "latitude": -22.890777,
        "longitude": -42.031259
      },
      {
        "id": 14407,
        "latitude": -22.465725,
        "longitude": -42.654425
      },
      {
        "id": 14408,
        "latitude": -21.775688,
        "longitude": -41.286461
      },
      {
        "id": 14409,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 14410,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 14411,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 14412,
        "latitude": -21.758129,
        "longitude": -41.320716
      },
      {
        "id": 14413,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 14414,
        "latitude": -22.110891,
        "longitude": -56.550649
      },
      {
        "id": 14415,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 14416,
        "latitude": -22.775415,
        "longitude": -43.302629
      },
      {
        "id": 14417,
        "latitude": -22.833332,
        "longitude": -42.231291
      },
      {
        "id": 14418,
        "latitude": -22.818243,
        "longitude": -42.210304
      },
      {
        "id": 14419,
        "latitude": -22.828504,
        "longitude": -42.217256
      },
      {
        "id": 14420,
        "latitude": -22.750719,
        "longitude": -42.937075
      },
      {
        "id": 14421,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 14422,
        "latitude": -22.731088,
        "longitude": -42.84973
      },
      {
        "id": 14423,
        "latitude": -22.712756,
        "longitude": -42.82214
      },
      {
        "id": 14424,
        "latitude": -23.939985,
        "longitude": -49.746363
      },
      {
        "id": 14425,
        "latitude": -22.854245,
        "longitude": -42.929408
      },
      {
        "id": 14426,
        "latitude": -22.755626,
        "longitude": -42.864629
      },
      {
        "id": 14427,
        "latitude": -22.704685,
        "longitude": -42.602439
      },
      {
        "id": 14428,
        "latitude": -22.737509,
        "longitude": -42.846491
      },
      {
        "id": 14429,
        "latitude": -22.730498,
        "longitude": -42.850455
      },
      {
        "id": 14430,
        "latitude": -22.760857,
        "longitude": -42.941607
      },
      {
        "id": 14431,
        "latitude": -22.865923,
        "longitude": -43.796262
      },
      {
        "id": 14432,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 14433,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 14434,
        "latitude": -22.846777,
        "longitude": -43.792583
      },
      {
        "id": 14435,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 14436,
        "latitude": -22.89046,
        "longitude": -43.58559
      },
      {
        "id": 14437,
        "latitude": -22.869486,
        "longitude": -43.779212
      },
      {
        "id": 14438,
        "latitude": -22.86136,
        "longitude": -43.78403
      },
      {
        "id": 14439,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 14440,
        "latitude": -22.352922,
        "longitude": -41.769431
      },
      {
        "id": 14441,
        "latitude": -22.337707,
        "longitude": -41.752782
      },
      {
        "id": 14442,
        "latitude": -22.326691,
        "longitude": -41.729129
      },
      {
        "id": 14443,
        "latitude": -22.342647,
        "longitude": -41.803587
      },
      {
        "id": 14444,
        "latitude": -22.807798,
        "longitude": -43.050365
      },
      {
        "id": 14445,
        "latitude": -22.63798,
        "longitude": -43.038804
      },
      {
        "id": 14446,
        "latitude": -22.657321,
        "longitude": -43.059133
      },
      {
        "id": 14447,
        "latitude": -22.676036,
        "longitude": -43.173591
      },
      {
        "id": 14448,
        "latitude": -22.70388,
        "longitude": -43.150224
      },
      {
        "id": 14449,
        "latitude": -22.852761,
        "longitude": -43.300724
      },
      {
        "id": 14450,
        "latitude": -22.955395,
        "longitude": -42.811584
      },
      {
        "id": 14451,
        "latitude": -21.293108,
        "longitude": -46.141775
      },
      {
        "id": 14452,
        "latitude": -22.930527,
        "longitude": -42.828996
      },
      {
        "id": 14453,
        "latitude": -22.908695,
        "longitude": -42.816971
      },
      {
        "id": 14454,
        "latitude": -22.9306,
        "longitude": -42.889505
      },
      {
        "id": 14455,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 14456,
        "latitude": -22.940746,
        "longitude": -42.887534
      },
      {
        "id": 14457,
        "latitude": -22.772934,
        "longitude": -43.414798
      },
      {
        "id": 14458,
        "latitude": -22.454312,
        "longitude": -43.470438
      },
      {
        "id": 14459,
        "latitude": -22.811759,
        "longitude": -43.435297
      },
      {
        "id": 14460,
        "latitude": -22.906908,
        "longitude": -43.178427
      },
      {
        "id": 14461,
        "latitude": -22.830381,
        "longitude": -43.431671
      },
      {
        "id": 14462,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 14463,
        "latitude": -22.882714,
        "longitude": -43.084526
      },
      {
        "id": 14464,
        "latitude": -22.881182,
        "longitude": -43.08622
      },
      {
        "id": 14465,
        "latitude": -22.862378,
        "longitude": -43.095623
      },
      {
        "id": 14466,
        "latitude": -22.919278,
        "longitude": -43.048744
      },
      {
        "id": 14467,
        "latitude": -22.940581,
        "longitude": -43.014879
      },
      {
        "id": 14468,
        "latitude": -22.866909,
        "longitude": -43.105553
      },
      {
        "id": 14469,
        "latitude": -22.293312,
        "longitude": -42.544383
      },
      {
        "id": 14470,
        "latitude": -22.235145,
        "longitude": -42.51722
      },
      {
        "id": 14471,
        "latitude": -22.765459,
        "longitude": -43.557737
      },
      {
        "id": 14472,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14473,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 14474,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14475,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14476,
        "latitude": -22.765459,
        "longitude": -43.557737
      },
      {
        "id": 14477,
        "latitude": -22.88483,
        "longitude": -43.229632
      },
      {
        "id": 14478,
        "latitude": -22.765698,
        "longitude": -43.514625
      },
      {
        "id": 14479,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14480,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14481,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14482,
        "latitude": -22.866807,
        "longitude": -43.235949
      },
      {
        "id": 14483,
        "latitude": -22.765926,
        "longitude": -43.514518
      },
      {
        "id": 14484,
        "latitude": -22.7863,
        "longitude": -43.541498
      },
      {
        "id": 14485,
        "latitude": -22.754466,
        "longitude": -43.42922
      },
      {
        "id": 14486,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 14487,
        "latitude": -22.769304,
        "longitude": -43.505112
      },
      {
        "id": 14488,
        "latitude": -22.76165,
        "longitude": -43.465702
      },
      {
        "id": 14489,
        "latitude": -22.818129,
        "longitude": -43.614813
      },
      {
        "id": 14490,
        "latitude": -22.78345,
        "longitude": -43.54767
      },
      {
        "id": 14491,
        "latitude": -22.723096,
        "longitude": -43.580527
      },
      {
        "id": 14492,
        "latitude": -22.439384,
        "longitude": -43.438519
      },
      {
        "id": 14493,
        "latitude": -22.719325,
        "longitude": -43.580431
      },
      {
        "id": 14494,
        "latitude": -22.620073,
        "longitude": -44.63853
      },
      {
        "id": 14495,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 14496,
        "latitude": -22.491664,
        "longitude": -41.923098
      },
      {
        "id": 14497,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 14498,
        "latitude": -22.496083,
        "longitude": -41.910578
      },
      {
        "id": 14499,
        "latitude": -22.492822,
        "longitude": -41.907927
      },
      {
        "id": 14500,
        "latitude": -22.500837,
        "longitude": -41.928883
      },
      {
        "id": 14501,
        "latitude": -22.51666,
        "longitude": -41.932636
      },
      {
        "id": 14502,
        "latitude": -22.502399,
        "longitude": -41.921091
      },
      {
        "id": 14503,
        "latitude": -22.913259,
        "longitude": -43.249602
      },
      {
        "id": 14504,
        "latitude": -22.869326,
        "longitude": -43.329739
      },
      {
        "id": 14505,
        "latitude": -22.963994,
        "longitude": -43.409796
      },
      {
        "id": 14506,
        "latitude": -22.867881,
        "longitude": -43.589515
      },
      {
        "id": 14507,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14508,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 14509,
        "latitude": -22.866953,
        "longitude": -43.463328
      },
      {
        "id": 14511,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 14512,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 14513,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 14514,
        "latitude": -22.856783,
        "longitude": -43.447489
      },
      {
        "id": 14515,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14516,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 14517,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 14518,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 14519,
        "latitude": -22.887865,
        "longitude": -43.476446
      },
      {
        "id": 14520,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 14521,
        "latitude": -22.907427,
        "longitude": -43.685603
      },
      {
        "id": 14522,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 14523,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 14524,
        "latitude": -22.92175,
        "longitude": -43.619067
      },
      {
        "id": 14525,
        "latitude": -22.916532,
        "longitude": -43.244497
      },
      {
        "id": 14526,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14527,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14528,
        "latitude": -23.003036,
        "longitude": -43.316344
      },
      {
        "id": 14529,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 14530,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14532,
        "latitude": -22.889793,
        "longitude": -43.311054
      },
      {
        "id": 14533,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14534,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 14535,
        "latitude": -22.999139,
        "longitude": -43.396742
      },
      {
        "id": 14537,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14538,
        "latitude": -22.94534,
        "longitude": -43.649505
      },
      {
        "id": 14539,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14540,
        "latitude": -22.907115,
        "longitude": -43.182146
      },
      {
        "id": 14541,
        "latitude": -22.949391,
        "longitude": -43.185487
      },
      {
        "id": 14542,
        "latitude": -22.997686,
        "longitude": -43.629628
      },
      {
        "id": 14543,
        "latitude": -22.886822,
        "longitude": -43.325828
      },
      {
        "id": 14544,
        "latitude": -22.836813,
        "longitude": -43.38395
      },
      {
        "id": 14545,
        "latitude": -22.92515,
        "longitude": -43.547316
      },
      {
        "id": 14546,
        "latitude": -22.905273,
        "longitude": -43.567056
      },
      {
        "id": 14547,
        "latitude": -22.877154,
        "longitude": -43.222269
      },
      {
        "id": 14548,
        "latitude": -21.502741,
        "longitude": -41.075792
      },
      {
        "id": 14549,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14551,
        "latitude": -22.849021,
        "longitude": -43.380253
      },
      {
        "id": 14552,
        "latitude": -22.919693,
        "longitude": -43.195897
      },
      {
        "id": 14554,
        "latitude": -22.898727,
        "longitude": -43.6132
      },
      {
        "id": 14555,
        "latitude": -22.964361,
        "longitude": -43.616991
      },
      {
        "id": 14556,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 14557,
        "latitude": -22.873045,
        "longitude": -43.552806
      },
      {
        "id": 14558,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 14559,
        "latitude": -22.838476,
        "longitude": -43.377695
      },
      {
        "id": 14560,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14561,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 14562,
        "latitude": -22.946671,
        "longitude": -43.561654
      },
      {
        "id": 14563,
        "latitude": -22.945157,
        "longitude": -43.56145
      },
      {
        "id": 14564,
        "latitude": -22.949888,
        "longitude": -43.651379
      },
      {
        "id": 14565,
        "latitude": -22.946671,
        "longitude": -43.561654
      },
      {
        "id": 14566,
        "latitude": -22.945157,
        "longitude": -43.56145
      },
      {
        "id": 14567,
        "latitude": -22.929585,
        "longitude": -43.555198
      },
      {
        "id": 14569,
        "latitude": -23.032085,
        "longitude": -43.55487
      },
      {
        "id": 14570,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14571,
        "latitude": -22.911014,
        "longitude": -43.209373
      },
      {
        "id": 14572,
        "latitude": -22.883848,
        "longitude": -43.440435
      },
      {
        "id": 14573,
        "latitude": -22.964361,
        "longitude": -43.616991
      },
      {
        "id": 14574,
        "latitude": -22.914238,
        "longitude": -43.375013
      },
      {
        "id": 14575,
        "latitude": -22.90862,
        "longitude": -43.61659
      },
      {
        "id": 14576,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14577,
        "latitude": -22.920587,
        "longitude": -43.61132
      },
      {
        "id": 14578,
        "latitude": -22.935472,
        "longitude": -43.636009
      },
      {
        "id": 14579,
        "latitude": -22.966746,
        "longitude": -43.348398
      },
      {
        "id": 14580,
        "latitude": -22.973035,
        "longitude": -43.391887
      },
      {
        "id": 14581,
        "latitude": -22.937143,
        "longitude": -43.370088
      },
      {
        "id": 14582,
        "latitude": -22.997872,
        "longitude": -43.396472
      },
      {
        "id": 14583,
        "latitude": -22.823887,
        "longitude": -43.169038
      },
      {
        "id": 14584,
        "latitude": -22.88948,
        "longitude": -43.224055
      },
      {
        "id": 14585,
        "latitude": -22.884638,
        "longitude": -43.617826
      },
      {
        "id": 14586,
        "latitude": -22.886099,
        "longitude": -43.602205
      },
      {
        "id": 14587,
        "latitude": -22.92576,
        "longitude": -43.37977
      },
      {
        "id": 14588,
        "latitude": -22.929784,
        "longitude": -43.195451
      },
      {
        "id": 14589,
        "latitude": -23.00515,
        "longitude": -43.372192
      },
      {
        "id": 14590,
        "latitude": -22.991798,
        "longitude": -43.479257
      },
      {
        "id": 14591,
        "latitude": -22.817882,
        "longitude": -43.190339
      },
      {
        "id": 14592,
        "latitude": -22.896544,
        "longitude": -43.311997
      },
      {
        "id": 14593,
        "latitude": -22.872487,
        "longitude": -43.36167
      },
      {
        "id": 14594,
        "latitude": -22.850179,
        "longitude": -43.376597
      },
      {
        "id": 14595,
        "latitude": -22.78955,
        "longitude": -43.17206
      },
      {
        "id": 14597,
        "latitude": -22.913965,
        "longitude": -43.55728
      },
      {
        "id": 14598,
        "latitude": -22.87986,
        "longitude": -43.302271
      },
      {
        "id": 14599,
        "latitude": -22.86493,
        "longitude": -43.37014
      },
      {
        "id": 14601,
        "latitude": -22.883612,
        "longitude": -43.440702
      },
      {
        "id": 14602,
        "latitude": -22.976478,
        "longitude": -43.187679
      },
      {
        "id": 14603,
        "latitude": -22.877831,
        "longitude": -43.259303
      },
      {
        "id": 14605,
        "latitude": -22.912886,
        "longitude": -43.686028
      },
      {
        "id": 14606,
        "latitude": -23.005055,
        "longitude": -43.371697
      },
      {
        "id": 14607,
        "latitude": -22.915119,
        "longitude": -43.563103
      },
      {
        "id": 14608,
        "latitude": -22.918831,
        "longitude": -43.260823
      },
      {
        "id": 14609,
        "latitude": -22.982588,
        "longitude": -43.492086
      },
      {
        "id": 14610,
        "latitude": -23.025349,
        "longitude": -43.489793
      },
      {
        "id": 14611,
        "latitude": -22.837711,
        "longitude": -43.31745
      },
      {
        "id": 14612,
        "latitude": -23.020731,
        "longitude": -43.461778
      },
      {
        "id": 14613,
        "latitude": -22.839596,
        "longitude": -43.325956
      },
      {
        "id": 14614,
        "latitude": -23.006965,
        "longitude": -44.2952
      },
      {
        "id": 14615,
        "latitude": -22.945157,
        "longitude": -43.56145
      },
      {
        "id": 14616,
        "latitude": -22.885329,
        "longitude": -43.286619
      },
      {
        "id": 14617,
        "latitude": -22.854637,
        "longitude": -43.270174
      },
      {
        "id": 14618,
        "latitude": -22.892368,
        "longitude": -43.633848
      },
      {
        "id": 14619,
        "latitude": -22.870009,
        "longitude": -43.309239
      },
      {
        "id": 14620,
        "latitude": -22.93392,
        "longitude": -43.348656
      },
      {
        "id": 14621,
        "latitude": -22.896876,
        "longitude": -43.348358
      },
      {
        "id": 14622,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 14623,
        "latitude": -22.842958,
        "longitude": -43.36177
      },
      {
        "id": 14624,
        "latitude": -22.842009,
        "longitude": -43.321289
      },
      {
        "id": 14625,
        "latitude": -22.919623,
        "longitude": -43.545023
      },
      {
        "id": 14626,
        "latitude": -22.866775,
        "longitude": -43.291774
      },
      {
        "id": 14627,
        "latitude": -22.95971,
        "longitude": -43.36908
      },
      {
        "id": 14628,
        "latitude": -22.935024,
        "longitude": -43.518246
      },
      {
        "id": 14629,
        "latitude": -22.89607,
        "longitude": -43.305387
      },
      {
        "id": 14630,
        "latitude": -22.703391,
        "longitude": -43.275901
      },
      {
        "id": 14631,
        "latitude": -22.843963,
        "longitude": -42.998524
      },
      {
        "id": 14632,
        "latitude": -22.82822,
        "longitude": -42.985558
      },
      {
        "id": 14633,
        "latitude": -22.815693,
        "longitude": -42.975863
      },
      {
        "id": 14634,
        "latitude": -22.785494,
        "longitude": -42.953023
      },
      {
        "id": 14635,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14636,
        "latitude": -22.742965,
        "longitude": -43.341716
      },
      {
        "id": 14637,
        "latitude": -22.78333,
        "longitude": -42.96667
      },
      {
        "id": 14638,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14639,
        "latitude": -22.811161,
        "longitude": -42.956131
      },
      {
        "id": 14640,
        "latitude": -22.7334,
        "longitude": -43.327734
      },
      {
        "id": 14641,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14642,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14643,
        "latitude": -22.761717,
        "longitude": -43.345141
      },
      {
        "id": 14644,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14645,
        "latitude": -22.773232,
        "longitude": -43.377775
      },
      {
        "id": 14646,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14647,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14648,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14649,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14650,
        "latitude": -22.817771,
        "longitude": -43.018804
      },
      {
        "id": 14651,
        "latitude": -22.820778,
        "longitude": -42.984376
      },
      {
        "id": 14652,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14653,
        "latitude": -22.878045,
        "longitude": -42.983499
      },
      {
        "id": 14654,
        "latitude": -22.803811,
        "longitude": -42.998281
      },
      {
        "id": 14655,
        "latitude": -22.495318,
        "longitude": -42.200625
      },
      {
        "id": 14656,
        "latitude": -22.821523,
        "longitude": -42.983965
      },
      {
        "id": 14657,
        "latitude": -22.807442,
        "longitude": -43.00141
      },
      {
        "id": 14658,
        "latitude": -22.809129,
        "longitude": -43.042411
      },
      {
        "id": 14659,
        "latitude": -22.804922,
        "longitude": -42.947441
      },
      {
        "id": 14660,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14661,
        "latitude": -22.971197,
        "longitude": -43.282118
      },
      {
        "id": 14662,
        "latitude": -22.825087,
        "longitude": -43.020315
      },
      {
        "id": 14663,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14664,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14665,
        "latitude": -22.807023,
        "longitude": -43.000501
      },
      {
        "id": 14666,
        "latitude": -22.831733,
        "longitude": -43.275518
      },
      {
        "id": 14667,
        "latitude": -22.791728,
        "longitude": -42.997281
      },
      {
        "id": 14668,
        "latitude": -22.874363,
        "longitude": -43.029329
      },
      {
        "id": 14669,
        "latitude": -22.781776,
        "longitude": -43.380456
      },
      {
        "id": 14670,
        "latitude": -22.845876,
        "longitude": -43.005614
      },
      {
        "id": 14671,
        "latitude": -22.826907,
        "longitude": -43.071465
      },
      {
        "id": 14672,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14673,
        "latitude": -22.878045,
        "longitude": -42.983499
      },
      {
        "id": 14674,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14675,
        "latitude": -22.843006,
        "longitude": -43.016297
      },
      {
        "id": 14676,
        "latitude": -22.836453,
        "longitude": -42.981759
      },
      {
        "id": 14677,
        "latitude": -22.882625,
        "longitude": -43.020077
      },
      {
        "id": 14678,
        "latitude": -22.836525,
        "longitude": -43.015931
      },
      {
        "id": 14679,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14680,
        "latitude": -22.808405,
        "longitude": -43.222933
      },
      {
        "id": 14681,
        "latitude": -22.815386,
        "longitude": -42.985294
      },
      {
        "id": 14682,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14683,
        "latitude": -22.817564,
        "longitude": -42.971552
      },
      {
        "id": 14684,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14685,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14686,
        "latitude": -22.835253,
        "longitude": -42.993758
      },
      {
        "id": 14687,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14688,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14689,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14690,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14691,
        "latitude": -22.834109,
        "longitude": -43.008048
      },
      {
        "id": 14692,
        "latitude": -22.860905,
        "longitude": -43.089287
      },
      {
        "id": 14693,
        "latitude": -22.783584,
        "longitude": -43.042472
      },
      {
        "id": 14694,
        "latitude": -22.791812,
        "longitude": -42.970601
      },
      {
        "id": 14695,
        "latitude": -22.825087,
        "longitude": -43.020315
      },
      {
        "id": 14696,
        "latitude": -22.77805,
        "longitude": -43.381738
      },
      {
        "id": 14697,
        "latitude": -22.800664,
        "longitude": -43.376133
      },
      {
        "id": 14698,
        "latitude": -22.786509,
        "longitude": -43.346134
      },
      {
        "id": 14699,
        "latitude": -22.861895,
        "longitude": -43.49249
      },
      {
        "id": 14700,
        "latitude": -22.873802,
        "longitude": -42.450487
      },
      {
        "id": 14701,
        "latitude": -22.480667,
        "longitude": -43.82932
      },
      {
        "id": 14702,
        "latitude": -5.655241,
        "longitude": -37.799308
      },
      {
        "id": 14703,
        "latitude": -5.158895,
        "longitude": -37.354599
      },
      {
        "id": 14704,
        "latitude": -6.373528,
        "longitude": -35.141388
      },
      {
        "id": 14705,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 14706,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 14707,
        "latitude": -5.34107,
        "longitude": -36.832045
      },
      {
        "id": 14708,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14709,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14710,
        "latitude": -5.668939,
        "longitude": -35.527152
      },
      {
        "id": 14711,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14712,
        "latitude": -5.6615,
        "longitude": -35.423388
      },
      {
        "id": 14713,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14714,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14715,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14716,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14717,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14718,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14719,
        "latitude": -5.713549,
        "longitude": -35.368035
      },
      {
        "id": 14720,
        "latitude": -5.640303,
        "longitude": -35.43382
      },
      {
        "id": 14721,
        "latitude": -5.739297,
        "longitude": -35.303279
      },
      {
        "id": 14722,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14723,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14724,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14725,
        "latitude": -5.744326,
        "longitude": -35.241845
      },
      {
        "id": 14726,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14727,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14728,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14729,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14730,
        "latitude": -5.856572,
        "longitude": -35.199487
      },
      {
        "id": 14731,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14732,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14733,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14734,
        "latitude": -5.71521,
        "longitude": -35.365375
      },
      {
        "id": 14735,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14736,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14737,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14738,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14739,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14740,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14741,
        "latitude": -5.774952,
        "longitude": -35.280234
      },
      {
        "id": 14742,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14743,
        "latitude": -5.69017,
        "longitude": -35.461963
      },
      {
        "id": 14744,
        "latitude": -5.712973,
        "longitude": -35.367105
      },
      {
        "id": 14745,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14746,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14747,
        "latitude": -5.805894,
        "longitude": -35.356609
      },
      {
        "id": 14748,
        "latitude": -5.69017,
        "longitude": -35.461963
      },
      {
        "id": 14749,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14750,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14751,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14752,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14753,
        "latitude": -5.642939,
        "longitude": -35.427469
      },
      {
        "id": 14754,
        "latitude": -5.698778,
        "longitude": -35.314857
      },
      {
        "id": 14755,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14756,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14757,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14758,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14759,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14760,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14761,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14762,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14763,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14764,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14765,
        "latitude": -5.703651,
        "longitude": -35.306905
      },
      {
        "id": 14766,
        "latitude": -6.12158,
        "longitude": -36.816088
      },
      {
        "id": 14767,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 14768,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 14769,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 14770,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 14771,
        "latitude": -5.197518,
        "longitude": -37.349139
      },
      {
        "id": 14772,
        "latitude": -5.926785,
        "longitude": -35.281093
      },
      {
        "id": 14790,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 14773,
        "latitude": -5.885541,
        "longitude": -35.197916
      },
      {
        "id": 14774,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 14775,
        "latitude": -5.76077,
        "longitude": -35.287443
      },
      {
        "id": 14776,
        "latitude": -5.927567,
        "longitude": -35.254337
      },
      {
        "id": 14777,
        "latitude": -5.909406,
        "longitude": -35.251107
      },
      {
        "id": 14778,
        "latitude": -6.314219,
        "longitude": -35.476651
      },
      {
        "id": 14779,
        "latitude": -5.763331,
        "longitude": -35.317315
      },
      {
        "id": 14780,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 14781,
        "latitude": -5.987617,
        "longitude": -35.26815
      },
      {
        "id": 14782,
        "latitude": -5.7541,
        "longitude": -35.301248
      },
      {
        "id": 14783,
        "latitude": -21.491678,
        "longitude": -56.155889
      },
      {
        "id": 14784,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 14785,
        "latitude": -6.066334,
        "longitude": -35.24398
      },
      {
        "id": 14786,
        "latitude": -5.930091,
        "longitude": -38.046057
      },
      {
        "id": 14787,
        "latitude": -5.987097,
        "longitude": -35.579871
      },
      {
        "id": 14788,
        "latitude": -5.192253,
        "longitude": -35.466841
      },
      {
        "id": 14789,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 14791,
        "latitude": -28.377805,
        "longitude": -52.25569
      },
      {
        "id": 14792,
        "latitude": -28.66667,
        "longitude": -52.15
      },
      {
        "id": 14793,
        "latitude": -30.027644,
        "longitude": -51.073228
      },
      {
        "id": 14794,
        "latitude": -30.009746,
        "longitude": -51.066098
      },
      {
        "id": 14795,
        "latitude": -29.62278,
        "longitude": -50.923233
      },
      {
        "id": 14796,
        "latitude": -31.328854,
        "longitude": -54.113438
      },
      {
        "id": 14797,
        "latitude": -29.095722,
        "longitude": -52.584494
      },
      {
        "id": 14799,
        "latitude": -22.074366,
        "longitude": -46.579881
      },
      {
        "id": 14800,
        "latitude": -29.786759,
        "longitude": -51.143929
      },
      {
        "id": 14801,
        "latitude": -29.888088,
        "longitude": -51.086104
      },
      {
        "id": 14802,
        "latitude": -29.937858,
        "longitude": -51.099118
      },
      {
        "id": 14803,
        "latitude": -30.853634,
        "longitude": -51.817083
      },
      {
        "id": 14804,
        "latitude": -29.919314,
        "longitude": -51.135983
      },
      {
        "id": 14805,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 14806,
        "latitude": -29.937924,
        "longitude": -50.986666
      },
      {
        "id": 14807,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 14808,
        "latitude": -29.758206,
        "longitude": -51.096477
      },
      {
        "id": 14809,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 14810,
        "latitude": -29.965057,
        "longitude": -51.179767
      },
      {
        "id": 14811,
        "latitude": -29.761681,
        "longitude": -51.148475
      },
      {
        "id": 14812,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 14813,
        "latitude": -29.910757,
        "longitude": -51.179688
      },
      {
        "id": 14814,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 14815,
        "latitude": -29.917618,
        "longitude": -51.181017
      },
      {
        "id": 14816,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 14817,
        "latitude": -29.880912,
        "longitude": -51.173316
      },
      {
        "id": 14818,
        "latitude": -28.300098,
        "longitude": -52.795661
      },
      {
        "id": 14819,
        "latitude": -28.300098,
        "longitude": -52.795661
      },
      {
        "id": 14820,
        "latitude": -29.201237,
        "longitude": -51.194017
      },
      {
        "id": 14821,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 14822,
        "latitude": -29.923064,
        "longitude": -50.907629
      },
      {
        "id": 14823,
        "latitude": -29.164,
        "longitude": -51.188416
      },
      {
        "id": 14824,
        "latitude": -29.18677,
        "longitude": -51.149086
      },
      {
        "id": 14825,
        "latitude": -29.117373,
        "longitude": -51.186103
      },
      {
        "id": 14826,
        "latitude": -29.165416,
        "longitude": -51.127865
      },
      {
        "id": 14827,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 14828,
        "latitude": -29.166194,
        "longitude": -51.224496
      },
      {
        "id": 14829,
        "latitude": -29.154483,
        "longitude": -51.142117
      },
      {
        "id": 14830,
        "latitude": -29.1528,
        "longitude": -51.138788
      },
      {
        "id": 14831,
        "latitude": -31.849809,
        "longitude": -52.805197
      },
      {
        "id": 14832,
        "latitude": -30.030033,
        "longitude": -51.230167
      },
      {
        "id": 14833,
        "latitude": -30.176336,
        "longitude": -50.21983
      },
      {
        "id": 14834,
        "latitude": -28.649335,
        "longitude": -53.607844
      },
      {
        "id": 14835,
        "latitude": -29.505791,
        "longitude": -51.989455
      },
      {
        "id": 14836,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 14837,
        "latitude": -27.640642,
        "longitude": -52.270241
      },
      {
        "id": 14838,
        "latitude": -27.909191,
        "longitude": -52.257556
      },
      {
        "id": 14839,
        "latitude": -29.846367,
        "longitude": -51.16725
      },
      {
        "id": 14840,
        "latitude": -29.846367,
        "longitude": -51.16725
      },
      {
        "id": 14841,
        "latitude": -29.843871,
        "longitude": -51.184651
      },
      {
        "id": 14842,
        "latitude": -29.225196,
        "longitude": -51.349382
      },
      {
        "id": 14843,
        "latitude": -29.928843,
        "longitude": -50.937001
      },
      {
        "id": 14915,
        "latitude": -29.737651,
        "longitude": -52.421205
      },
      {
        "id": 14844,
        "latitude": -29.958857,
        "longitude": -50.975118
      },
      {
        "id": 14845,
        "latitude": -29.94844,
        "longitude": -51.000102
      },
      {
        "id": 14846,
        "latitude": -29.930085,
        "longitude": -50.974738
      },
      {
        "id": 14847,
        "latitude": -30.135808,
        "longitude": -51.32143
      },
      {
        "id": 14848,
        "latitude": -29.611341,
        "longitude": -51.161248
      },
      {
        "id": 14849,
        "latitude": -29.473471,
        "longitude": -51.808541
      },
      {
        "id": 14850,
        "latitude": -29.67981,
        "longitude": -51.482713
      },
      {
        "id": 14851,
        "latitude": -29.644647,
        "longitude": -51.110463
      },
      {
        "id": 14852,
        "latitude": -29.696615,
        "longitude": -51.115211
      },
      {
        "id": 14853,
        "latitude": -30.259434,
        "longitude": -50.511695
      },
      {
        "id": 14854,
        "latitude": -30.346073,
        "longitude": -50.281882
      },
      {
        "id": 14855,
        "latitude": -31.732457,
        "longitude": -52.216295
      },
      {
        "id": 14856,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14857,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14858,
        "latitude": -4.457958,
        "longitude": -42.407488
      },
      {
        "id": 14859,
        "latitude": -30.860052,
        "longitude": -51.780771
      },
      {
        "id": 14860,
        "latitude": -31.745506,
        "longitude": -52.293001
      },
      {
        "id": 14861,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14862,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14863,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14864,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14865,
        "latitude": -31.727191,
        "longitude": -52.385946
      },
      {
        "id": 14866,
        "latitude": -31.758919,
        "longitude": -52.375174
      },
      {
        "id": 14867,
        "latitude": -31.732245,
        "longitude": -52.330596
      },
      {
        "id": 14868,
        "latitude": -31.730376,
        "longitude": -52.331287
      },
      {
        "id": 14869,
        "latitude": -30.07489,
        "longitude": -51.195234
      },
      {
        "id": 14870,
        "latitude": -30.166254,
        "longitude": -51.14711
      },
      {
        "id": 14871,
        "latitude": -29.924251,
        "longitude": -50.887346
      },
      {
        "id": 14872,
        "latitude": -29.979745,
        "longitude": -51.113605
      },
      {
        "id": 14873,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14874,
        "latitude": -30.010766,
        "longitude": -51.162547
      },
      {
        "id": 14875,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14876,
        "latitude": -29.978879,
        "longitude": -51.18439
      },
      {
        "id": 14877,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14878,
        "latitude": -29.980934,
        "longitude": -51.125213
      },
      {
        "id": 14880,
        "latitude": -30.120812,
        "longitude": -51.199758
      },
      {
        "id": 14881,
        "latitude": -29.981371,
        "longitude": -51.134168
      },
      {
        "id": 14882,
        "latitude": -30.146933,
        "longitude": -51.21349
      },
      {
        "id": 14883,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14884,
        "latitude": -30.08162,
        "longitude": -51.239527
      },
      {
        "id": 14885,
        "latitude": -30.016192,
        "longitude": -51.173847
      },
      {
        "id": 14886,
        "latitude": -30.146933,
        "longitude": -51.21349
      },
      {
        "id": 14887,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14889,
        "latitude": -30.165707,
        "longitude": -51.148464
      },
      {
        "id": 14890,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14891,
        "latitude": -29.924251,
        "longitude": -50.887346
      },
      {
        "id": 14892,
        "latitude": -30.058603,
        "longitude": -51.20672
      },
      {
        "id": 14893,
        "latitude": -30.065534,
        "longitude": -51.203991
      },
      {
        "id": 14894,
        "latitude": -29.991712,
        "longitude": -51.11084
      },
      {
        "id": 14895,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14896,
        "latitude": -30.018094,
        "longitude": -51.101212
      },
      {
        "id": 14897,
        "latitude": -30.098776,
        "longitude": -51.236982
      },
      {
        "id": 14898,
        "latitude": -30.09769,
        "longitude": -51.239421
      },
      {
        "id": 14899,
        "latitude": -29.830227,
        "longitude": -50.058242
      },
      {
        "id": 14900,
        "latitude": -30.162395,
        "longitude": -51.151315
      },
      {
        "id": 14901,
        "latitude": -30.118322,
        "longitude": -51.213192
      },
      {
        "id": 14902,
        "latitude": -30.173889,
        "longitude": -51.181388
      },
      {
        "id": 14903,
        "latitude": -29.999825,
        "longitude": -51.151007
      },
      {
        "id": 14904,
        "latitude": -30.113595,
        "longitude": -51.24914
      },
      {
        "id": 14905,
        "latitude": -30.13249,
        "longitude": -51.197118
      },
      {
        "id": 14906,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14907,
        "latitude": -30.058603,
        "longitude": -51.20672
      },
      {
        "id": 14908,
        "latitude": -30.032142,
        "longitude": -51.225973
      },
      {
        "id": 14909,
        "latitude": -30.007599,
        "longitude": -51.099385
      },
      {
        "id": 14910,
        "latitude": -30.121127,
        "longitude": -51.22669
      },
      {
        "id": 14911,
        "latitude": -30.052768,
        "longitude": -51.160348
      },
      {
        "id": 14912,
        "latitude": -30.099115,
        "longitude": -51.179506
      },
      {
        "id": 14913,
        "latitude": -27.946344,
        "longitude": -51.822043
      },
      {
        "id": 14914,
        "latitude": -30.88941,
        "longitude": -55.531769
      },
      {
        "id": 14918,
        "latitude": -28.302823,
        "longitude": -54.265688
      },
      {
        "id": 14919,
        "latitude": -30.335946,
        "longitude": -54.319271
      },
      {
        "id": 14920,
        "latitude": -29.781392,
        "longitude": -51.116725
      },
      {
        "id": 14921,
        "latitude": -29.781392,
        "longitude": -51.116725
      },
      {
        "id": 14922,
        "latitude": -23.00692,
        "longitude": -44.295094
      },
      {
        "id": 14923,
        "latitude": -29.73557,
        "longitude": -51.146239
      },
      {
        "id": 14924,
        "latitude": -29.774802,
        "longitude": -51.119598
      },
      {
        "id": 14925,
        "latitude": -30.020738,
        "longitude": -51.169992
      },
      {
        "id": 14926,
        "latitude": -29.745468,
        "longitude": -51.149008
      },
      {
        "id": 14927,
        "latitude": -29.781392,
        "longitude": -51.116725
      },
      {
        "id": 14928,
        "latitude": -29.781392,
        "longitude": -51.116725
      },
      {
        "id": 14929,
        "latitude": -29.744958,
        "longitude": -51.146661
      },
      {
        "id": 14930,
        "latitude": -29.652582,
        "longitude": -50.986702
      },
      {
        "id": 14931,
        "latitude": -29.638374,
        "longitude": -50.99626
      },
      {
        "id": 14932,
        "latitude": -29.823182,
        "longitude": -51.136124
      },
      {
        "id": 14933,
        "latitude": -29.679882,
        "longitude": -51.128528
      },
      {
        "id": 14934,
        "latitude": -29.862016,
        "longitude": -51.248174
      },
      {
        "id": 14935,
        "latitude": -29.647179,
        "longitude": -50.777192
      },
      {
        "id": 14936,
        "latitude": -29.792656,
        "longitude": -51.857804
      },
      {
        "id": 14937,
        "latitude": -29.516586,
        "longitude": -50.776865
      },
      {
        "id": 14938,
        "latitude": -28.50311,
        "longitude": -50.932114
      },
      {
        "id": 14939,
        "latitude": -30.08146,
        "longitude": -51.041351
      },
      {
        "id": 14940,
        "latitude": -30.08146,
        "longitude": -51.041351
      },
      {
        "id": 14941,
        "latitude": -30.08146,
        "longitude": -51.041351
      },
      {
        "id": 14942,
        "latitude": -30.860052,
        "longitude": -51.780771
      },
      {
        "id": 14943,
        "latitude": -30.08146,
        "longitude": -51.041351
      },
      {
        "id": 14944,
        "latitude": -10.44413,
        "longitude": -62.471139
      },
      {
        "id": 14945,
        "latitude": -10.892057,
        "longitude": -61.906372
      },
      {
        "id": 14946,
        "latitude": -8.759295,
        "longitude": -63.816057
      },
      {
        "id": 14947,
        "latitude": -11.900833,
        "longitude": -61.230557
      },
      {
        "id": 14948,
        "latitude": -28.953757,
        "longitude": -49.49707
      },
      {
        "id": 14949,
        "latitude": -29.157645,
        "longitude": -49.76188
      },
      {
        "id": 14950,
        "latitude": -26.927532,
        "longitude": -49.114319
      },
      {
        "id": 14951,
        "latitude": -26.903659,
        "longitude": -48.701878
      },
      {
        "id": 14952,
        "latitude": -27.088831,
        "longitude": -52.61481
      },
      {
        "id": 14953,
        "latitude": -28.697492,
        "longitude": -49.404683
      },
      {
        "id": 14954,
        "latitude": -27.574554,
        "longitude": -48.534922
      },
      {
        "id": 14955,
        "latitude": -27.594255,
        "longitude": -48.549432
      },
      {
        "id": 14956,
        "latitude": -26.91166,
        "longitude": -49.250405
      },
      {
        "id": 14957,
        "latitude": -26.875727,
        "longitude": -48.743155
      },
      {
        "id": 14958,
        "latitude": -26.171181,
        "longitude": -48.600218
      },
      {
        "id": 14959,
        "latitude": -26.485005,
        "longitude": -49.109227
      },
      {
        "id": 14960,
        "latitude": -26.361736,
        "longitude": -48.812728
      },
      {
        "id": 14961,
        "latitude": -26.290137,
        "longitude": -48.92012
      },
      {
        "id": 14962,
        "latitude": -26.28431,
        "longitude": -48.91114
      },
      {
        "id": 14963,
        "latitude": -8.848433,
        "longitude": -36.132527
      },
      {
        "id": 14964,
        "latitude": -27.796341,
        "longitude": -50.32685
      },
      {
        "id": 14966,
        "latitude": -27.667509,
        "longitude": -48.67892
      },
      {
        "id": 14967,
        "latitude": -27.667509,
        "longitude": -48.67892
      },
      {
        "id": 14968,
        "latitude": -27.676671,
        "longitude": -48.665464
      },
      {
        "id": 14969,
        "latitude": -27.667509,
        "longitude": -48.67892
      },
      {
        "id": 14970,
        "latitude": -27.567532,
        "longitude": -48.67457
      },
      {
        "id": 14971,
        "latitude": -27.5749,
        "longitude": -48.667758
      },
      {
        "id": 14972,
        "latitude": -0.667571,
        "longitude": -49.173684
      },
      {
        "id": 14973,
        "latitude": -27.595514,
        "longitude": -48.612348
      },
      {
        "id": 14974,
        "latitude": -27.543395,
        "longitude": -48.625878
      },
      {
        "id": 14975,
        "latitude": -27.595514,
        "longitude": -48.612348
      },
      {
        "id": 14976,
        "latitude": -19.417314,
        "longitude": -45.150082
      },
      {
        "id": 14977,
        "latitude": -29.104976,
        "longitude": -49.642983
      },
      {
        "id": 14978,
        "latitude": -29.099721,
        "longitude": -49.645587
      },
      {
        "id": 14979,
        "latitude": -29.106306,
        "longitude": -49.637998
      },
      {
        "id": 14980,
        "latitude": -22.71814,
        "longitude": -47.32003
      },
      {
        "id": 14981,
        "latitude": -22.731108,
        "longitude": -47.308048
      },
      {
        "id": 14982,
        "latitude": -22.723048,
        "longitude": -47.282869
      },
      {
        "id": 14983,
        "latitude": -22.732698,
        "longitude": -47.304047
      },
      {
        "id": 14984,
        "latitude": -22.766388,
        "longitude": -47.345468
      },
      {
        "id": 14985,
        "latitude": -21.758793,
        "longitude": -48.165739
      },
      {
        "id": 14986,
        "latitude": -21.787799,
        "longitude": -48.173348
      },
      {
        "id": 14987,
        "latitude": -21.766007,
        "longitude": -48.154397
      },
      {
        "id": 14988,
        "latitude": -22.368022,
        "longitude": -47.363765
      },
      {
        "id": 14989,
        "latitude": -23.146947,
        "longitude": -46.58262
      },
      {
        "id": 14990,
        "latitude": -22.482793,
        "longitude": -48.561609
      },
      {
        "id": 14991,
        "latitude": -22.497767,
        "longitude": -48.551114
      },
      {
        "id": 14992,
        "latitude": -23.496212,
        "longitude": -46.847052
      },
      {
        "id": 14993,
        "latitude": -20.896663,
        "longitude": -47.585634
      },
      {
        "id": 14994,
        "latitude": -22.3293,
        "longitude": -49.047742
      },
      {
        "id": 14995,
        "latitude": -22.29899,
        "longitude": -49.080423
      },
      {
        "id": 14996,
        "latitude": -22.899717,
        "longitude": -48.436577
      },
      {
        "id": 14997,
        "latitude": -22.90716,
        "longitude": -48.439257
      },
      {
        "id": 14998,
        "latitude": -22.621226,
        "longitude": -48.840679
      },
      {
        "id": 14999,
        "latitude": -22.663705,
        "longitude": -45.014139
      },
      {
        "id": 15000,
        "latitude": -23.375973,
        "longitude": -46.727128
      },
      {
        "id": 15001,
        "latitude": -22.903334,
        "longitude": -46.794315
      },
      {
        "id": 15003,
        "latitude": -23.66292,
        "longitude": -46.653385
      },
      {
        "id": 15004,
        "latitude": -22.904243,
        "longitude": -47.109468
      },
      {
        "id": 15005,
        "latitude": -22.905639,
        "longitude": -47.059564
      },
      {
        "id": 15006,
        "latitude": -22.91966,
        "longitude": -46.769206
      },
      {
        "id": 15007,
        "latitude": -23.205304,
        "longitude": -46.755822
      },
      {
        "id": 15008,
        "latitude": -23.205304,
        "longitude": -46.755822
      },
      {
        "id": 15009,
        "latitude": -23.205304,
        "longitude": -46.755822
      },
      {
        "id": 15010,
        "latitude": -22.979664,
        "longitude": -47.498498
      },
      {
        "id": 15011,
        "latitude": -23.459067,
        "longitude": -46.505619
      },
      {
        "id": 15012,
        "latitude": -22.480508,
        "longitude": -47.460874
      },
      {
        "id": 15013,
        "latitude": -23.626201,
        "longitude": -46.938771
      },
      {
        "id": 15014,
        "latitude": -23.564578,
        "longitude": -47.196613
      },
      {
        "id": 15015,
        "latitude": -23.71287,
        "longitude": -47.002594
      },
      {
        "id": 15016,
        "latitude": -23.626201,
        "longitude": -46.938771
      },
      {
        "id": 15017,
        "latitude": -23.623176,
        "longitude": -46.913653
      },
      {
        "id": 15018,
        "latitude": -23.713733,
        "longitude": -46.628736
      },
      {
        "id": 15019,
        "latitude": -21.483106,
        "longitude": -51.538804
      },
      {
        "id": 15020,
        "latitude": -20.550297,
        "longitude": -47.404672
      },
      {
        "id": 15021,
        "latitude": -20.539692,
        "longitude": -47.385939
      },
      {
        "id": 15022,
        "latitude": -20.539692,
        "longitude": -47.385939
      },
      {
        "id": 15023,
        "latitude": -20.539692,
        "longitude": -47.385939
      },
      {
        "id": 15024,
        "latitude": -20.411148,
        "longitude": -47.28488
      },
      {
        "id": 15025,
        "latitude": -23.459548,
        "longitude": -46.506906
      },
      {
        "id": 15026,
        "latitude": -21.253237,
        "longitude": -50.643346
      },
      {
        "id": 15027,
        "latitude": -21.361951,
        "longitude": -48.228771
      },
      {
        "id": 15028,
        "latitude": -23.986638,
        "longitude": -46.26697
      },
      {
        "id": 15029,
        "latitude": -23.965663,
        "longitude": -46.21678
      },
      {
        "id": 15030,
        "latitude": -23.94822,
        "longitude": -46.273395
      },
      {
        "id": 15031,
        "latitude": -23.441785,
        "longitude": -46.389024
      },
      {
        "id": 15032,
        "latitude": -23.474541,
        "longitude": -46.544843
      },
      {
        "id": 15033,
        "latitude": -23.40239,
        "longitude": -46.49705
      },
      {
        "id": 15034,
        "latitude": -23.433931,
        "longitude": -46.794678
      },
      {
        "id": 15035,
        "latitude": -23.442622,
        "longitude": -46.391846
      },
      {
        "id": 15036,
        "latitude": -23.433869,
        "longitude": -46.546958
      },
      {
        "id": 15037,
        "latitude": -23.441785,
        "longitude": -46.389024
      },
      {
        "id": 15038,
        "latitude": -23.441785,
        "longitude": -46.389024
      },
      {
        "id": 15039,
        "latitude": -23.441785,
        "longitude": -46.389024
      },
      {
        "id": 15040,
        "latitude": -22.875689,
        "longitude": -47.216496
      },
      {
        "id": 15041,
        "latitude": -22.898043,
        "longitude": -47.1647
      },
      {
        "id": 15042,
        "latitude": -21.943245,
        "longitude": -48.001253
      },
      {
        "id": 15043,
        "latitude": -22.82566,
        "longitude": -47.096956
      },
      {
        "id": 15044,
        "latitude": -23.111564,
        "longitude": -47.200582
      },
      {
        "id": 15045,
        "latitude": -23.111564,
        "longitude": -47.200582
      },
      {
        "id": 15046,
        "latitude": -21.49406,
        "longitude": -48.051413
      },
      {
        "id": 15047,
        "latitude": -20.444969,
        "longitude": -48.014099
      },
      {
        "id": 15048,
        "latitude": -20.787988,
        "longitude": -42.789075
      },
      {
        "id": 15049,
        "latitude": -23.005554,
        "longitude": -46.839773
      },
      {
        "id": 15050,
        "latitude": -23.120416,
        "longitude": -46.55998
      },
      {
        "id": 15051,
        "latitude": -23.297603,
        "longitude": -45.943239
      },
      {
        "id": 15052,
        "latitude": -22.690695,
        "longitude": -46.988009
      },
      {
        "id": 15053,
        "latitude": -22.688234,
        "longitude": -46.997183
      },
      {
        "id": 15054,
        "latitude": -22.154376,
        "longitude": -48.428808
      },
      {
        "id": 15171,
        "latitude": -10.93706,
        "longitude": -37.128336
      },
      {
        "id": 15055,
        "latitude": -22.26811,
        "longitude": -48.55622
      },
      {
        "id": 15056,
        "latitude": -23.149501,
        "longitude": -47.017205
      },
      {
        "id": 15057,
        "latitude": -23.421315,
        "longitude": -46.89025
      },
      {
        "id": 15058,
        "latitude": -22.576428,
        "longitude": -47.409184
      },
      {
        "id": 15059,
        "latitude": -22.566015,
        "longitude": -47.371377
      },
      {
        "id": 15060,
        "latitude": -22.566015,
        "longitude": -47.371377
      },
      {
        "id": 15061,
        "latitude": -22.576428,
        "longitude": -47.409184
      },
      {
        "id": 15062,
        "latitude": -22.576428,
        "longitude": -47.409184
      },
      {
        "id": 15063,
        "latitude": -21.552464,
        "longitude": -47.696563
      },
      {
        "id": 15064,
        "latitude": -22.21372,
        "longitude": -49.953246
      },
      {
        "id": 15065,
        "latitude": -22.21372,
        "longitude": -49.953246
      },
      {
        "id": 15066,
        "latitude": -22.21372,
        "longitude": -49.953246
      },
      {
        "id": 15067,
        "latitude": -22.380917,
        "longitude": -49.757828
      },
      {
        "id": 15068,
        "latitude": -22.21372,
        "longitude": -49.953246
      },
      {
        "id": 15069,
        "latitude": -20.804881,
        "longitude": -49.512603
      },
      {
        "id": 15070,
        "latitude": -23.540903,
        "longitude": -46.285319
      },
      {
        "id": 15071,
        "latitude": -23.497992,
        "longitude": -46.182517
      },
      {
        "id": 15072,
        "latitude": -22.320276,
        "longitude": -46.944085
      },
      {
        "id": 15073,
        "latitude": -24.116637,
        "longitude": -46.661673
      },
      {
        "id": 15074,
        "latitude": -24.082605,
        "longitude": -46.625618
      },
      {
        "id": 15075,
        "latitude": -20.732401,
        "longitude": -48.053535
      },
      {
        "id": 15076,
        "latitude": -23.193884,
        "longitude": -46.376418
      },
      {
        "id": 15077,
        "latitude": -22.779154,
        "longitude": -47.295256
      },
      {
        "id": 15078,
        "latitude": -23.545173,
        "longitude": -46.814955
      },
      {
        "id": 15079,
        "latitude": -23.504772,
        "longitude": -46.795093
      },
      {
        "id": 15080,
        "latitude": -22.92286,
        "longitude": -45.451818
      },
      {
        "id": 15081,
        "latitude": -22.75578,
        "longitude": -47.616219
      },
      {
        "id": 15082,
        "latitude": -22.755466,
        "longitude": -47.61646
      },
      {
        "id": 15083,
        "latitude": -22.737901,
        "longitude": -47.609404
      },
      {
        "id": 15084,
        "latitude": -22.005544,
        "longitude": -47.421198
      },
      {
        "id": 15085,
        "latitude": -23.213887,
        "longitude": -47.523451
      },
      {
        "id": 15086,
        "latitude": -5.134166,
        "longitude": -42.776456
      },
      {
        "id": 15087,
        "latitude": -21.361805,
        "longitude": -48.073948
      },
      {
        "id": 15088,
        "latitude": -24.001936,
        "longitude": -46.439557
      },
      {
        "id": 15089,
        "latitude": -24.036662,
        "longitude": -46.50461
      },
      {
        "id": 15090,
        "latitude": -21.771097,
        "longitude": -52.115534
      },
      {
        "id": 15091,
        "latitude": -21.964925,
        "longitude": -51.30673
      },
      {
        "id": 15092,
        "latitude": -22.125061,
        "longitude": -51.371052
      },
      {
        "id": 15093,
        "latitude": -22.129985,
        "longitude": -51.404801
      },
      {
        "id": 15094,
        "latitude": -23.398665,
        "longitude": -47.006595
      },
      {
        "id": 15095,
        "latitude": -22.129985,
        "longitude": -51.404801
      },
      {
        "id": 15096,
        "latitude": -22.234458,
        "longitude": -50.884868
      },
      {
        "id": 15097,
        "latitude": -22.222097,
        "longitude": -51.305273
      },
      {
        "id": 15098,
        "latitude": -21.113934,
        "longitude": -47.818523
      },
      {
        "id": 15099,
        "latitude": -21.214873,
        "longitude": -47.822255
      },
      {
        "id": 15100,
        "latitude": -21.212727,
        "longitude": -47.794717
      },
      {
        "id": 15101,
        "latitude": -21.152949,
        "longitude": -47.809017
      },
      {
        "id": 15102,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 15103,
        "latitude": -21.113934,
        "longitude": -47.818523
      },
      {
        "id": 15104,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 15105,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 15106,
        "latitude": -21.167342,
        "longitude": -47.804416
      },
      {
        "id": 15107,
        "latitude": -22.805059,
        "longitude": -47.34209
      },
      {
        "id": 15108,
        "latitude": -22.742014,
        "longitude": -47.393786
      },
      {
        "id": 15109,
        "latitude": -22.742014,
        "longitude": -47.393786
      },
      {
        "id": 15110,
        "latitude": -22.905166,
        "longitude": -49.61753
      },
      {
        "id": 15111,
        "latitude": -23.572074,
        "longitude": -46.629567
      },
      {
        "id": 15112,
        "latitude": -23.6149,
        "longitude": -46.529458
      },
      {
        "id": 15113,
        "latitude": -22.724183,
        "longitude": -47.354446
      },
      {
        "id": 15114,
        "latitude": -23.620375,
        "longitude": -46.540832
      },
      {
        "id": 15115,
        "latitude": -23.682371,
        "longitude": -46.510067
      },
      {
        "id": 15116,
        "latitude": -23.724314,
        "longitude": -46.55307
      },
      {
        "id": 15117,
        "latitude": -23.711001,
        "longitude": -46.534966
      },
      {
        "id": 15118,
        "latitude": -23.707573,
        "longitude": -46.526733
      },
      {
        "id": 15119,
        "latitude": -22.035155,
        "longitude": -47.872097
      },
      {
        "id": 15120,
        "latitude": -22.035155,
        "longitude": -47.872097
      },
      {
        "id": 15121,
        "latitude": -20.755524,
        "longitude": -49.141361
      },
      {
        "id": 15122,
        "latitude": -22.008223,
        "longitude": -47.894618
      },
      {
        "id": 15123,
        "latitude": -21.595819,
        "longitude": -46.892917
      },
      {
        "id": 15124,
        "latitude": -20.799058,
        "longitude": -49.333988
      },
      {
        "id": 15125,
        "latitude": -20.804075,
        "longitude": -49.38158
      },
      {
        "id": 15126,
        "latitude": -20.804075,
        "longitude": -49.38158
      },
      {
        "id": 15127,
        "latitude": -20.749568,
        "longitude": -49.387583
      },
      {
        "id": 15128,
        "latitude": -20.789308,
        "longitude": -49.350649
      },
      {
        "id": 15130,
        "latitude": -20.749568,
        "longitude": -49.387583
      },
      {
        "id": 15131,
        "latitude": -20.799058,
        "longitude": -49.333988
      },
      {
        "id": 15132,
        "latitude": -20.847176,
        "longitude": -49.341772
      },
      {
        "id": 15133,
        "latitude": -23.006976,
        "longitude": -44.295838
      },
      {
        "id": 15134,
        "latitude": -23.232108,
        "longitude": -45.896284
      },
      {
        "id": 15135,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 15136,
        "latitude": -23.570533,
        "longitude": -46.663713
      },
      {
        "id": 15137,
        "latitude": -23.630834,
        "longitude": -46.642244
      },
      {
        "id": 15138,
        "latitude": -23.567813,
        "longitude": -46.543096
      },
      {
        "id": 15139,
        "latitude": -23.558143,
        "longitude": -46.512312
      },
      {
        "id": 15140,
        "latitude": -23.553577,
        "longitude": -46.447306
      },
      {
        "id": 15141,
        "latitude": -23.66997,
        "longitude": -46.767365
      },
      {
        "id": 15142,
        "latitude": -27.149097,
        "longitude": -48.587549
      },
      {
        "id": 15143,
        "latitude": -23.570533,
        "longitude": -46.663713
      },
      {
        "id": 15144,
        "latitude": -23.543852,
        "longitude": -46.625339
      },
      {
        "id": 15145,
        "latitude": -23.493905,
        "longitude": -46.749053
      },
      {
        "id": 15146,
        "latitude": -23.57036,
        "longitude": -46.636209
      },
      {
        "id": 15147,
        "latitude": -23.672552,
        "longitude": -46.710876
      },
      {
        "id": 15148,
        "latitude": -23.653746,
        "longitude": -46.767797
      },
      {
        "id": 15149,
        "latitude": -23.570533,
        "longitude": -46.663713
      },
      {
        "id": 15150,
        "latitude": -23.550651,
        "longitude": -46.633382
      },
      {
        "id": 15151,
        "latitude": -23.618397,
        "longitude": -46.728693
      },
      {
        "id": 15152,
        "latitude": -23.63769,
        "longitude": -46.743319
      },
      {
        "id": 15153,
        "latitude": -23.421882,
        "longitude": -46.417527
      },
      {
        "id": 15154,
        "latitude": -23.962407,
        "longitude": -46.252035
      },
      {
        "id": 15155,
        "latitude": -23.957678,
        "longitude": -46.428069
      },
      {
        "id": 15156,
        "latitude": -23.436469,
        "longitude": -47.463998
      },
      {
        "id": 15157,
        "latitude": -23.445443,
        "longitude": -47.372676
      },
      {
        "id": 15158,
        "latitude": -23.46544,
        "longitude": -47.445165
      },
      {
        "id": 15159,
        "latitude": -22.983047,
        "longitude": -47.0348
      },
      {
        "id": 15160,
        "latitude": -23.492305,
        "longitude": -46.280252
      },
      {
        "id": 15161,
        "latitude": -23.552788,
        "longitude": -46.30654
      },
      {
        "id": 15162,
        "latitude": -23.337075,
        "longitude": -47.858571
      },
      {
        "id": 15163,
        "latitude": -23.012631,
        "longitude": -45.524779
      },
      {
        "id": 15164,
        "latitude": -23.032075,
        "longitude": -45.616579
      },
      {
        "id": 15165,
        "latitude": -23.036001,
        "longitude": -45.54607
      },
      {
        "id": 15166,
        "latitude": -20.419242,
        "longitude": -50.082587
      },
      {
        "id": 15167,
        "latitude": -23.621668,
        "longitude": -47.018517
      },
      {
        "id": 15168,
        "latitude": -23.540886,
        "longitude": -47.44296
      },
      {
        "id": 15169,
        "latitude": -23.552092,
        "longitude": -47.451853
      },
      {
        "id": 15170,
        "latitude": -10.945636,
        "longitude": -37.07117
      },
      {
        "id": 15172,
        "latitude": -22.545275,
        "longitude": -54.48243
      },
      {
        "id": 15173,
        "latitude": -10.505593,
        "longitude": -37.052318
      },
      {
        "id": 15174,
        "latitude": -10.510591,
        "longitude": -37.048681
      },
      {
        "id": 15175,
        "latitude": -10.505593,
        "longitude": -37.052318
      },
      {
        "id": 15176,
        "latitude": -11.268226,
        "longitude": -37.437834
      },
      {
        "id": 15177,
        "latitude": -10.214189,
        "longitude": -37.42125
      },
      {
        "id": 15178,
        "latitude": -10.844622,
        "longitude": -37.130984
      },
      {
        "id": 15179,
        "latitude": -10.844622,
        "longitude": -37.130984
      },
      {
        "id": 15180,
        "latitude": -10.844622,
        "longitude": -37.130984
      },
      {
        "id": 12,
        "latitude": -7.598009,
        "longitude": -72.76737
      },
      {
        "id": 28,
        "latitude": -9.753674,
        "longitude": -36.65535
      },
      {
        "id": 29,
        "latitude": -9.753674,
        "longitude": -36.65535
      },
      {
        "id": 31,
        "latitude": -9.753674,
        "longitude": -36.65535
      },
      {
        "id": 32,
        "latitude": -9.753674,
        "longitude": -36.65535
      },
      {
        "id": 33,
        "latitude": -9.73757,
        "longitude": -36.664699
      },
      {
        "id": 40,
        "latitude": -9.38628,
        "longitude": -37.089247
      },
      {
        "id": 13734,
        "latitude": -9.753674,
        "longitude": -36.65535
      },
      {
        "id": 13736,
        "latitude": -9.753674,
        "longitude": -36.65535
      }
    ]
  }
};
    return (
        <div className="max-w-7xl mx-auto p-6 lg:p-8">
            <header className="py-4 text-white">
                <IconLogo className="h-9 float-left mr-5" style={{width: "128"}}></IconLogo>
                <h1 className="text-3xl font-200">Leilão Extrajudicial CEF</h1>
            </header>
            <div className="bg-white rounded p-1">
                <OpenMap lng="-50.11952" lat="-27.48323" markers={markers.data.cef_properties}></OpenMap>
            </div>
        </div>
    );
}

export default CEF;
