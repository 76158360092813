// import IconLike from './IconLike';

function Footer({className}) {
    return (
        <footer className="flex justify-center mt-16 px-0 sm:items-center sm:justify-between">
            <div className="text-center text-sm text-gray-500 dark:text-gray-400 sm:text-left">
                <div className="flex items-center gap-4">
                    {/*<div*/}
                    {/*    className="group inline-flex items-center hover:text-gray-700 dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500">*/}
                    {/*    <IconLike></IconLike>*/}
                    {/*    Like*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="ml-4 text-center text-sm text-gray-500 dark:text-gray-400 sm:text-right sm:ml-0">
                v1.0
            </div>
        </footer>
    );
}

export default Footer;
