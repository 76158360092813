import Card from './../components/Card'
import IconLogo from './../components/IconLogo';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function Home() {
    return (
        <div className="max-w-7xl mx-auto p-6 lg:p-8">
            <header className="flex justify-center mt-5">
                <IconLogo className="h-25"/>
            </header>

            <h1 className="text-gray-300 font-extrabold text-3xl text-center mt-10">Site em construção.</h1>
            {/*<div className="mt-16">*/}
            {/*    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8">*/}
            {/*        <Link to="cef">*/}
            {/*            <Card title="Leilão Extrajudicial CEF">*/}
            {/*                Faça o acompanhamento ativo dos leilões extrajudiciais da Caixa Econômica Federal com*/}
            {/*                atualização diária.*/}
            {/*            </Card>*/}
            {/*        </Link>*/}
            {/*    </div>*/}

            {/*</div>*/}

            <Footer></Footer>
        </div>
    );
}

export default Home;
